import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { useNavigate, useParams } from "react-router-dom";
import { PAYMENT } from "../../../api/payment";

export default function PaymentList() {
  let { date } = useParams();
  const navigate = useNavigate();
  //states
  const [emp, setEmp] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  //states
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;

  const tableSessionRef = useRef(null);
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);

  emp.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });
  const topSessionRef = useRef(null);
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleEdit = (id) => {
    // console.log("data", id);
    navigate(`/booking/payment/${id}`);
  };

  const convertToKuwaitTime = (timestamp) => {
    const date = new Date(timestamp);
    const utcDate = new Date(date.toISOString());
    const kuwaitOffset = 3 * 60; // offset in minutes
    const kuwaitTime = new Date(utcDate.getTime() + kuwaitOffset * 60 * 1000);

    const year = kuwaitTime.getFullYear();
    const month = String(kuwaitTime.getMonth() + 1).padStart(2, '0');
    const day = String(kuwaitTime.getDate()).padStart(2, '0');
    let hours = kuwaitTime.getHours();
    const minutes = String(kuwaitTime.getMinutes()).padStart(2, '0');
    const seconds = String(kuwaitTime.getSeconds()).padStart(2, '0');

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };
  //get
  const getEmpList = async () => {
    try {
      const res = await PAYMENT.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (val) => {
    try {
      const res = await PAYMENT.SEARCH(val);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [searchVal, date]);
  //get
  console.log("emp", emp);
  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Payment Details" />
      </div>

      <div
        ref={tableSessionRef}
        className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
      >
        <div className="max-w-full overflow-x-auto">
          <input
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            value={searchVal}
            type="text"
            className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="Search by Payment ID..."
          />

          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Date
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Payment ID
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Status
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems !== undefined && (
                <>
                  {currentItems.map((data) => (
                    <tr key={data._id}>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {convertToKuwaitTime(data.timeStamp)}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.paymentId}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        {data.status === "success" ? (
                          <h5 className="text-green dark:text-white font-bold">
                            Success
                          </h5>
                        ) : data.status === "refund" ? (
                          <h5 className="text-primary dark:text-white font-bold">
                            Refund
                          </h5>
                        ) : data.status === "paid" ? (
                          <h5 className="text-green dark:text-white font-bold">
                            Refund paid
                          </h5>
                        ) : (
                          <h5 className="text-red dark:text-white font-bold">
                            Failed
                          </h5>
                        )}
                      </td>

                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(data._id)}
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          array={emp}
          scrollToSection={scrollToTableSection}
          setItemOffset={setItemOffset}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
