import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const BOOKING = {
  ADD(req) {
    const url = `${CONSTANT.URL}booking`;
    return API_REQUEST("POST", url, req);
  },
  CHALET_CHECK(req) {
    const url = `${CONSTANT.URL}booking/check-chalet-availability`;
    return API_REQUEST("POST", url, req);
  },
  ADD_MANY(req) {
    const url = `${CONSTANT.URL}booking/group`;
    return API_REQUEST("POST", url, req);
  },
  BOOKING_SUCCESS_BULK(req) {
    const url = `${CONSTANT.URL}booking/booking-success/bulk`;
    return API_REQUEST("POST", url, req);
  },
  CREATE_PDF(req) {
    const url = `${CONSTANT.URL}booking/booking-success/create-pdf`;
    return API_REQUEST("POST", url, req);
  },
  BOOKING_SUCCESS(req) {
    const url = `${CONSTANT.URL}booking/booking-success`;
    return API_REQUEST("POST", url, req);
  },
  CHECK_FILE(req) {
    const url = `${CONSTANT.URL}booking/booking-success/file-exist/${req}`;
    return API_REQUEST("POST", url);
  },
  UPDATE(req, id, token) {
    console.log("token in api ", token);
    const url = `${CONSTANT.URL}booking/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}booking/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}booking/search`;
    return API_REQUEST("GET", url, null, {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    });
  },
  SEARCH(date, pnr) {
    const url = `${CONSTANT.URL}booking/date/${date}/search/${pnr}`;
    return API_REQUEST("GET", url, null, {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    });
  },
  GET_BY_PNR(date, pnr) {
    const url = `${CONSTANT.URL}booking/date/${date}/pnr/${pnr}`;
    return API_REQUEST("GET", url, null, {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    });
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}booking/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_DATE(date) {
    const url = `${CONSTANT.URL}booking/date/${date}`;
    return API_REQUEST("GET", url);
  },
  GET_COUNT_TODAY(data) {
    const url = `${CONSTANT.URL}booking/dashboard-data`;
    return API_REQUEST("POST", url, data);
  },
};
