import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const BLOCK = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}block`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id, token) {
    const url = `${CONSTANT.URL}block/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}block/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE_FULL(id, token) {
    const url = `${CONSTANT.URL}block/${id}`;
    return API_REQUEST("DELETE", url, null, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}block/search`;
    return API_REQUEST("GET", url);
  },

  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}block/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_DATE(date) {
    const url = `${CONSTANT.URL}block/date/${date}`;
    return API_REQUEST("GET", url);
  },

  SEARCH(title) {
    const url = `${CONSTANT.URL}block/search/${title}`;
    return API_REQUEST("GET", url);
  },
};
