import { useEffect } from "react";
import Checkout from "./Checkout";
import { toast } from "react-toastify";
import TENT from "../../../assets/tent.png";
import COMING from "../../../assets/coming.png";
import { DC } from "../../../api/dlxChalet";
import { CS } from "../../../api/stdChalet";
import HOUSE from "../../../assets/house.png";
import { BOOKING } from "../../../api/booking";
import { useNavigate } from "react-router-dom";
import { PAYMENT } from "../../../api/payment";
import CHALET from "../../../assets/chalet.png";
import PARKICON from "../../../assets/nature.png";
import PARKHUMAN from "../../../assets/human.png";
import PARK from "../../../assets/parkticket.png";
import STANDARD from "../../../assets/standard.png";
import { TEMP_BOOKING } from "../../../api/tempBooking";
import { CONSTANT } from "../../../constant/Constant";
import { formatNumb } from "../../../utils/FormatNumb";
import { WORKING_HOURS } from "../../../api/workingHours";
import { TempContext } from "../../../utils/TempContext";
import React, { useContext, useRef, useState } from "react";
import { BookingContext } from "../../../utils/BookingContext";
import { numberToWords } from "../../../utils/NumberToWords";
import { formatDateString } from "../../../utils/FormatDateString";

export default function Book() {
  //useState
  const [paymentType, setPaymentType] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [tempId, setTempId] = useState("");
  const [wh, setWh] = useState({});
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const [priceData, setPriceData] = useState({
    odt: 0,
    cd: 0,
    cs: 0,
  });
  const [payment, setPayment] = useState({
    cardNo: "",
    expiryDate: "",
    phoneNumber: "",
    name: "",
    cvv: "",
  });
  const [loading, setLoading] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [showPayment, setShowPayment] = useState(true);
  const [validate, setValidate] = useState(false);
  const [counter, setCounter] = useState(0);
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  // const incrementCounter = () => {
  //   setCounter((prevCounter) => prevCounter + 1);
  // };
  const [todayCount, setTodayCount] = useState({
    odt: 0,
    cd: 0,
    cs: 0,
  });
  //useState

  //constant variables

  const { session, setSession } = useContext(BookingContext);
  const { temp, setTemp } = useContext(TempContext);
  const navigate = useNavigate();
  const [pnrVal, setPnrVal] = useState("");

  const [free, setFree] = useState({
    DC_FREE: 0,
    SC_FREE: 0,
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    pnr: "",
    date: formatDate(new Date()),
  });
  const startDateRef = useRef(null);
  const handleEditBooking = () => {
    setShowPayment(true);
  };

  const total =
    counter * priceData.odt + counter1 * priceData.cd + counter2 * priceData.cs;
  // const [expiryDate, setExpiryDate] = useState("");

  // const handleExpiryDateChange = (e) => {
  //   const input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
  //   let formattedInput = input;

  //   if (input.length >= 3) {
  //     formattedInput = `${input.slice(0, 2)}/${input.slice(2, 4)}`;
  //   }

  //   setExpiryDate(formattedInput);
  // };
  const incrementCounter = () => {
    setCounter((prevCounter) =>
      prevCounter < todayCount.odt ? prevCounter + 1 : prevCounter
    );
  };
  const incrementCounter1 = () => {
    setCounter1((prevCounter) =>
      prevCounter < todayCount.cd ? prevCounter + 1 : prevCounter
    );
  };
  const incrementCounter2 = () => {
    setCounter2((prevCounter) =>
      prevCounter < todayCount.cs ? prevCounter + 1 : prevCounter
    );
  };

  const decrementCounter = () => {
    setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounter1 = () => {
    setCounter1((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounter2 = () => {
    setCounter2((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  //Constant variables

  //dependant function
  const getExtraOdtFromCd = (count) => {
    var rtnVal = 0;
    if (free.DC_FREE === 0 || free.DC_FREE === 1) {
      rtnVal = 0;
    } else {
      // rtnVal = free.DC_FREE - 1;
      rtnVal = count * (free.DC_FREE - 1);
    }
    return rtnVal;
  };

  const getExtraOdtFromCs = (count) => {
    var rtnVal = 0;
    if (free.SC_FREE === 0 || free.SC_FREE === 1) {
      rtnVal = 0;
    } else {
      // rtnVal = free.SC_FREE - 1;
      rtnVal = count * (free.SC_FREE - 1);
    }
    return rtnVal;
  };
  const getExtraOdtFromCdLength = (count) => {
    var rtnVal = 0;
    if (free.DC_FREE === 0 || free.DC_FREE === 1) {
      rtnVal = 0;
    } else {
      rtnVal = count * (free.DC_FREE - 1);
    }
    return rtnVal;
  };

  const getExtraOdtFromCsLength = (count) => {
    var rtnVal = 0;
    if (free.SC_FREE === 0 || free.SC_FREE === 1) {
      rtnVal = 0;
    } else {
      rtnVal = count * (free.SC_FREE - 1);
    }
    return rtnVal;
  };

  const clearData = () => {
    setData({
      ...data,
      mobile: "",
      email: "",
      name: "",
      date: formatDate(new Date()),
    });
  };
  //dependant function
  //function
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // const clearPayment = () => {
  //   setPayment({
  //     ...payment,
  //     cardNo: "",
  //     expiryDate: "",
  //     phoneNumber: "",
  //     name: "",
  //     cvv: "",
  //   });
  // };

  const updateWorkingHour = async (reqArray, workingHours) => {
    var availableCSChalets = [];
    var availableCDChalets = [];
    var odtCount = 0;
    var csCount = 0;
    var cdCount = 0;
    var updatedCD = [];
    var updatedCS = [];
    var chaletData = await DC.GET_ALL();
    var chaletDC = chaletData.data;
    var chaletData = await CS.GET_ALL();
    var chaletCS = chaletData.data;

    availableCDChalets = chaletDC.filter(
      (chalet) => !workingHours.bookedCD.includes(chalet.title)
    );
    availableCSChalets = chaletCS.filter(
      (chalet) => !workingHours.bookedCS.includes(chalet.title)
    );

    let workingHourPromises = reqArray.map(async (req) => {
      if (req.type === 1) {
        cdCount = cdCount + 1;
        if (workingHours.bookedCD.length === 0) {
          updatedCD.push(chaletDC[0].title);
          chaletDC.splice(0, 1);
        } else if (workingHours.bookedCD.length === chaletDC.length) {
          // console.log("no chalet available");
        } else {
          // availableCDChalets = chaletDC.filter(
          //   (chalet) => !workingHours.bookedCD.includes(chalet.title)
          // );
          updatedCD.push(availableCDChalets[0].title);
          availableCDChalets.splice(0, 1);
        }
      } else if (req.type === 2) {
        csCount = csCount + 1;

        if (workingHours.bookedCS.length === 0) {
          updatedCS.push(chaletCS[0].title);
          chaletCS.splice(0, 1);
        } else if (workingHours.bookedCS.length === chaletCS.length) {
          // console.log("no chalet available");
        } else {
          // availableCSChalets = chaletCS.filter(
          //   (chalet) => !workingHours.bookedCS.includes(chalet.title)
          // );
          updatedCS.push(availableCSChalets[0].title);
          availableCSChalets.splice(0, 1);
        }
      } else if (req.type === 0) {
        odtCount = odtCount + 1;
      }
    });
    // Wait for all action to complete
    await Promise.all(workingHourPromises);
    const odtBookingLeft =
      workingHours.odt.noOfBooking - (workingHours.odt.bookingToday + odtCount);
    const cdBookingLeft =
      workingHours.cd.noOfBooking - (workingHours.cd.bookingToday + cdCount);
    const csBookingLeft =
      workingHours.cs.noOfBooking - (workingHours.cs.bookingToday + csCount);

    var updateReq = {
      date: workingHours.date,
      isPurged: false,
      bookedCD: workingHours.bookedCD.concat(updatedCD),
      bookedCS: workingHours.bookedCS.concat(updatedCS),
      odt: {
        price: workingHours.odt.price,
        noOfBooking: workingHours.odt.noOfBooking,
        bookingToday: workingHours.odt.bookingToday + odtCount,
        extraBooking: odtBookingLeft >= 0 ? 0 : Math.abs(odtBookingLeft),
      },
      cd: {
        price: workingHours.cd.price,
        noOfBooking: workingHours.cd.noOfBooking,
        bookingToday: workingHours.cd.bookingToday + cdCount,
        extraBooking: cdBookingLeft >= 0 ? 0 : Math.abs(cdBookingLeft),
      },
      cs: {
        price: workingHours.cs.price,
        noOfBooking: workingHours.cs.noOfBooking,
        bookingToday: workingHours.cs.bookingToday + csCount,
        extraBooking: csBookingLeft >= 0 ? 0 : Math.abs(csBookingLeft),
      },
    };
    const rtnVal = {
      updateReq: updateReq,
      cdNew: updatedCD,
      csNew: updatedCS,
      id: workingHours._id,
    };
    return rtnVal;
    // await WORKING_HOURS.UPDATE(updateReq, workingHours._id);
  };

  const addGroupData = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.ADD_MANY(values);
      // console.log("res", res);
      if (res.status === 200) {
        toast.success(`Created ${res.data.count} Bookings`);
        clearData();
        setLoading(false);
        // navigate(
        //   `/booking-details/date/${data.date}/pnr/${res.data.data[0].pnr}`
        // );
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const addOneData = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.ADD(values);
      // console.log("res", res);
      if (res.status === 200) {
        return res.data;
      } else {
        toast.error("Failed to create new admin");
        return null;
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      return null;
    }
  };
  const sendMail = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.CREATE_PDF(values);
      // console.log("res", res);
      if (res.status === 200) {
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const bookingSuccess = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.BOOKING_SUCCESS(values);
      // console.log("res", res);
      if (res.status === 200) {
      } else {
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleKnetPayment = async () => {
    if (data.name !== "" && data.email !== "" && data.phoneNumber !== "") {
      setValidate(false);
      // console.log("in");
      try {
        var values = {
          price: total,
          qty: 2,
        };
        const res = await PAYMENT.K_NET(values);
        // console.log("res", res);
      } catch (error) {
        setLoading(false);
      }
    } else {
      setValidate(true);
    }
  };
  const saveData = async (temp) => {
    try {
      await localStorage.setItem("temp", temp);
      // await localStorage.setItem("temp", JSON.stringify(temp));
      // console.log("Data successfully saved");
    } catch (e) {
      // console.log("Failed to save the data to the storage");
    }
  };
  const saveDataSession = async (data) => {
    try {
      await localStorage.setItem("session", JSON.stringify(data));
      // console.log("Data successfully saved");
    } catch (e) {
      // console.log("Failed to save the data to the storage");
    }
  };
  const createTempBooking = async (req) => {
    try {
      const res = await TEMP_BOOKING.ADD(req);
      // console.log("res", res);
      if (res.status === 200) {
        // console.log("res", res.data);
        // console.log("res._id", res.data._id);

        setTemp(res.data._id);
        // setTemp(res.data);
        saveData(res.data._id);
        // saveData(res.data);
        setShowPayment(false);
        setTempId(res.data._id);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // console.log("emailRegex.test(email)", emailRegex.test(email));
    return emailRegex.test(email);
  };
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneNumberRegex = /^\d{8}$/;
    return phoneNumberRegex.test(phoneNumber);
  };
  const proceedToPayment = async () => {
    if (
      data.name !== "" &&
      data.email !== "" &&
      isEmailValid(data.email) === true &&
      data.phoneNumber !== "" &&
      isPhoneNumberValid(data.phoneNumber) === true
    ) {
      let pnr = "AQMG-" + (Math.floor(Math.random() * 9000000000) + 1);
      setData({
        ...data,
        pnr: pnr,
      });
      data.pnr = pnr;
      setValidate(false);
      setLoading(true);
      // const odtArray = Array(
      //   counter + getExtraOdtFromCd(counter1) + getExtraOdtFromCs(counter2)
      // ).fill(0);
      const odtArray = Array(counter).fill(0);
      const cdArray = Array(counter1).fill(1);
      const csArray = Array(counter2).fill(2);
      const combinedArray1 = odtArray.concat(cdArray, csArray);
      const cdFreeArray = Array(getExtraOdtFromCdLength(counter1)).fill(3);
      const csFreeArray = Array(getExtraOdtFromCsLength(counter2)).fill(4);
      const combinedArray2 = combinedArray1.concat(cdFreeArray, csFreeArray);
      const reqArray = [];
      await combinedArray2.map(async (itm) => {
        var reqData = {
          bookedBy: data.name,
          email: data.email,
          mobNo: data.phoneNumber,
          pnr: pnr,
          date: data.date,
          qr: "",
          ticket: "",
          chaletType: itm,
          chaletNo: "",
          payment: {
            id: "",
            price: total,
            paymentMethod: "Card",
            isSuccess: false,
            session_id: "",
            reference_number: "",
          },
          groupSize: {
            totalNo:
              counter +
              counter1 +
              counter2 +
              getExtraOdtFromCd(counter1) +
              getExtraOdtFromCs(counter2),
            odtNo: counter,
            cdFree: getExtraOdtFromCd(counter1),
            scFree: getExtraOdtFromCs(counter2),
            // odtNo:
            //   counter +
            //   getExtraOdtFromCd(counter1) +
            //   getExtraOdtFromCs(counter2),
            cdNo: counter1,
            csNo: counter2,
            odtPrice: priceData.odt,
            cdPrice: priceData.cd,
            csPrice: priceData.cs,
            totalPrice: total,
          },
          isScanned: false,
          isPurged: false,
        };
        reqArray.push(reqData);
      });
      setSession(reqArray);
      saveDataSession(reqArray);
      var request = {
        pnr: pnr,
        data: reqArray,
        payment: {
          price: total,
          paymentMethod: "Card",
          isSuccess: false,
          session_id: "",
          reference_number: "",
        },
      };
      await createTempBooking(request);
    } else {
      setValidate(true);
      setShowPayment(true);
      setLoading(false);
    }
  };

  const handleAdd = async () => {
    const pnr = "wrong_pnr";
    // console.log("emailRegex.test(email)", isEmailValid(data.email));
    if (
      data.name !== "" &&
      data.email !== "" &&
      isEmailValid(data.email) === true &&
      data.phoneNumber !== "" &&
      isPhoneNumberValid(data.phoneNumber) === true
    ) {
      setValidate(false);
      // console.log("data", data);
      const odtArray = Array(
        counter + getExtraOdtFromCd(counter1) + getExtraOdtFromCs(counter2)
      ).fill(0);
      const cdArray = Array(counter1).fill(1);
      const csArray = Array(counter2).fill(2);
      const combinedArray = odtArray.concat(cdArray, csArray);
      const reqArray = [];
      const reqArrayHour = [];
      await combinedArray.map(async (itm) => {
        var reqData = {
          bookedBy: data.name,
          email: data.email,
          mobNo: data.phoneNumber,
          pnr: pnr,
          date: data.date,
          type: itm,
        };
        reqArrayHour.push(reqData);
      });

      // await addGroupData(reqArray);
      let resArray = [];
      let count = 0;
      const whUpdateReq = await updateWorkingHour(reqArrayHour, wh);
      // console.log("whUpdateReq", whUpdateReq);

      const cdList = [...whUpdateReq.cdNew];
      const csList = [...whUpdateReq.csNew];
      const cdListFree = [...whUpdateReq.cdNew];
      const csListFree = [...whUpdateReq.csNew];
      await combinedArray.map((itm) => {
        var chaletNo = "";
        if (itm === 1) {
          chaletNo = cdList[0];
          cdList.splice(0, 1);
        } else if (itm === 2) {
          chaletNo = csList[0];
          csList.splice(0, 1);
        } else if (itm === 0) {
          chaletNo = "";
        } else if (itm === 3) {
          chaletNo = cdListFree[0];
          cdListFree.splice(0, 1);
        } else if (itm === 4) {
          chaletNo = csListFree[0];
          csListFree.splice(0, 1);
        }
        var reqData = {
          bookedBy: data.name,
          email: data.email,
          mobNo: data.phoneNumber,
          pnr: pnr,
          date: data.date,
          qr: "",
          ticket: "",
          type: itm,
          chaletNo: chaletNo,
          payment: {
            price: total,
            paymentMethod: paymentType === 0 ? "Credit" : "Other",
            isSuccess: false,
          },
          groupSize: {
            totalNo: counter + counter1 + counter2,
            odtNo: counter,
            cdFree: getExtraOdtFromCd(counter1),
            scFree: getExtraOdtFromCs(counter2),
            cdNo: counter1,
            csNo: counter2,
            odtPrice: priceData.odt,
            cdPrice: priceData.cd,
            csPrice: priceData.cs,
            totalPrice: total,
          },
          isScanned: false,
          isPurged: false,
        };
        reqArray.push(reqData);
      });
      // console.log("reqArray", reqArray);
      let bookingPromises = reqArray.map(async (data) => {
        let addResp = await addOneData(data);
        // console.log("addResp", addResp);
        if (addResp !== null) {
          count += 1;
          resArray.push(addResp[0]);
        }
      });
      // Wait for all bookings to complete
      await Promise.all(bookingPromises);
      // Ensure all images are created before proceeding
      // console.log("resArray", resArray);
      navigate(`/booking-details/date/${reqArray[0].date}/pnr/${pnr}`);
      toast.success(`${count} Bookings created!`);

      const ticketArray = resArray.map((itm) => itm.ticket);
      var url = `${CONSTANT.BASE_URL}booking-details/date/${reqArray[0].date}/pnr/${pnr}`;
      var mailRequest = {
        date: reqArray[0].date,
        email: reqArray[0].email,
        url: url,
        pnr: pnr,
      };
      var successReq = {
        imgArray: ticketArray,
        pnr: pnr,
        mailRequest: mailRequest,
        reqArray: resArray,
      };
      await WORKING_HOURS.UPDATE(whUpdateReq.updateReq, whUpdateReq.id);
      await bookingSuccess(successReq);
    } else {
      setValidate(true);
    }
  };
  const getWorkingHours = async (date) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res.data[0]", res.data[0]);
      if (res.data[0] === undefined) {
        setFree({
          ...free,
          DC_FREE: 0,
          SC_FREE: 0,
        });
        free.DC_FREE = 0;
        free.SC_FREE = 0;
        setTodayCount({
          ...todayCount,
          odt: 0,
          cd: 0,
          cs: 0,
        });
        setPriceData({
          ...priceData,
          odt: 0,
          cd: 0,
          cs: 0,
        });
        priceData.odt = 0;
        priceData.cd = 0;
        priceData.cs = 0;
        todayCount.odt = 0;
        todayCount.cd = 0;
        todayCount.cs = 0;
        // console.log("todayCount", todayCount);
        toast.info(
          "No Bookings are accepted today! Please choose another date."
        );
      }

      if (
        res.data[0].odt.noOfBooking >= res.data[0].odt.bookingToday &&
        res.data[0].cd.noOfBooking == res.data[0].cd.bookingToday &&
        res.data[0].cs.noOfBooking == res.data[0].cs.bookingToday
      ) {
        toast.info(
          "All Bookings are filled for today! Please choose another date."
        );
      }
      setWh(res.data[0]);
      setFree({
        ...free,
        DC_FREE: res.data[0].dlxFree,
        SC_FREE: res.data[0].stdFree,
      });
      free.DC_FREE = res.data[0].dlxFree;
      free.SC_FREE = res.data[0].stdFree;
      setTodayCount({
        ...todayCount,
        odt: res.data[0].odt.noOfBooking - res.data[0].odt.bookingToday,
        cd: res.data[0].cd.noOfBooking - res.data[0].cd.bookingToday,
        cs: res.data[0].cs.noOfBooking - res.data[0].cs.bookingToday,
      });
      setPriceData({
        ...priceData,
        odt: res.data[0].odt.price,
        cd: res.data[0].cd.price,
        cs: res.data[0].cs.price,
      });
      priceData.odt = res.data[0].odt.price;
      priceData.cd = res.data[0].cd.price;
      priceData.cs = res.data[0].cs.price;
      todayCount.odt =
        res.data[0].odt.noOfBooking - res.data[0].odt.bookingToday;
      todayCount.cd = res.data[0].cd.noOfBooking - res.data[0].cd.bookingToday;
      todayCount.cs = res.data[0].cs.noOfBooking - res.data[0].cs.bookingToday;
      // console.log("todayCount", todayCount);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //function
  //useEffect
  useEffect(() => {
    getWorkingHours(data.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  //useEffect

  const ODT_CARD = {
    park: "Aqua Magic Water Park",
    title: "One Day Ticket",
    subTxt: "All-day access to unlimited fun",
    subTxt2: "No. Of Guest",
    icon: PARKICON,
    img: PARK,
    miniTxt1: "Kids under 3 enter free",
    miniTxt2: `ID required , ${
      todayCount.odt - counter >= 0 ? todayCount.odt - counter : 0
    } Bookings left`,
    price: priceData.odt,
    decrementCounter: decrementCounter,
    counter: counter,
    incrementCounter: incrementCounter,
    isBlocked: false,
  };

  const CD_CARD = {
    park: "Aqua Magic",
    title: "Chalet Deluxe",
    subTxt: "Experience the true luxury (COMING SOON)",
    subTxt2: `No. Of Chalet`,
    // subTxt2: `${numberToWords(free.DC_FREE)} Free Park Tickets`,
    icon: TENT,
    img: CHALET,
    miniTxt1: `Get ${numberToWords(free.DC_FREE)} Park Tickets`,
    // miniTxt1: " Get one free park ticket",
    miniTxt2: `${todayCount.cd - counter1} Bookings left`,
    price: priceData.cd,
    decrementCounter: decrementCounter1,
    counter: counter1,
    incrementCounter: incrementCounter1,
    isBlocked: true,
  };
  const CS_CARD = {
    park: "Aqua Magic",
    title: "Chalet Standard",
    subTxt: "Enjoy comfort all-day long",
    subTxt2: `No. Of Chalet`,
    // subTxt2: `${numberToWords(free.SC_FREE)} Free Park Tickets`,
    icon: HOUSE,
    img: STANDARD,
    miniTxt1: `Get ${numberToWords(free.SC_FREE)} Park Tickets`,
    // miniTxt1: " Get one free park ticket",
    miniTxt2: `${todayCount.cs - counter2} Bookings left`,
    price: priceData.cs,
    decrementCounter: decrementCounter2,
    counter: counter2,
    incrementCounter: incrementCounter2,
    isBlocked: false,
  };

  return (
    <div className="h-auto w-auto flex justify-center py-25 bg-bgbg">
      <div className="w-full h-auto px-2 max-w-screen-2xl">
        {showUser ? (
          <ShowDateHeader data={data} />
        ) : (
          <ShowSelectDate
            data={data}
            setData={setData}
            startDateRef={startDateRef}
          />
        )}
        <div className="w-full h-auto flex flex-col gap-10 lg:grid grid-cols-2 lg:grid-cols-3 mb-20">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3 col-span-2">
            <Card data={ODT_CARD} showPayment={showPayment} />
            <Card data={CD_CARD} showPayment={showPayment} />
            <Card data={CS_CARD} showPayment={showPayment} />
          </div>

          <div className="flex justify-center items-start lg:col-span-1 ">
            <div className=" w-full h-auto p-5 rounded-2xl bg-white">
              <div className="w-full h-auto">
                <p className="text-poppins text-primary text-2xl xl:text-3xl font-semibold">
                  Purchase summary
                </p>
              </div>
              <div className="w-full flex justify-between mt-2 lg:mt-5">
                <div className="w-[70%]">
                  <p className="text-sm xl:text-lg font-">
                    Park Ticket [{counter}×{priceData.odt}]
                  </p>
                </div>
                <div className="w-[30%]">
                  <p className="text-sm xl:text-lg font-semibold">
                    : KWD {(counter * priceData.odt).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-between mt-2 lg:mt-5">
                <div className="w-[70%]">
                  <p className="text-sm xl:text-lg leading-6 ">
                    Chalet Deluxe [{counter1}×{priceData.cd}]<br></br>
                    <span className="text-[10px] xl:text-sm text-mont font-thin">
                      {numberToWords(free.DC_FREE)} free park ticket per chalet
                    </span>
                  </p>
                </div>
                <div className="w-[30%]">
                  <p className="text-sm xl:text-lg font-semibold">
                    : KWD {(counter1 * priceData.cd).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="w-full flex justify-between mt-2 lg:mt-5">
                <div className="w-[70%]">
                  <p className="text-sm xl:text-lg leading-6">
                    Chalet Standard [{counter2}×{priceData.cs}]<br></br>
                    <span className="text-[10px] xl:text-sm text-mont font-thin">
                      {numberToWords(free.SC_FREE)} free park ticket per chalet
                    </span>
                  </p>
                </div>
                <div className="w-[30%]">
                  <p className="text-sm xl:text-lg font-semibold">
                    : KWD {(counter2 * priceData.cs).toLocaleString()}
                  </p>
                </div>
              </div>
              <hr className="border-t border-dashed mt-3 w-full"></hr>
              <div className="w-full flex justify-between  mt-8">
                <div className="w-[70%]">
                  <p className="text-sm xl:text-lg font-poppins font-bold">
                    TOTAL
                  </p>
                </div>
                <div className="w-[30%]">
                  <p className="text-sm xl:text-lg font-bold font-poppins">
                    : KWD {total.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!showUser ? (
          <div className="w-[100%] h-auto flex justify-center items-center lg:w-[66%]">
            <button
              className="bg-blue px-15 py-2 text-white rounded-md text-2xl"
              onClick={() => {
                if (counter > 0 || counter1 > 0 || counter2 > 0) {
                  setShowUser(true);
                } else {
                  toast.info("Add at least one ticket before proceeding!");
                }
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          <>
            <div className="bg-white  w-[100%] h-auto rounded-lg shadow-xl pt-5 mt-20 mb-10 flex flex-col gap-4 lg:w-[66%] p-10">
              <p className="text-3xl font-bold text-poppins text-center lg:text-left pt-5">
                Book as a Guest
              </p>
              <div className="w-full h-auto flex flex-col">
                <div className="flex flex-col gap-5  mb-[25px] lg:grid grid-cols-3">
                  <div className="w-[100%] flex flex-col  col-span-1">
                    <label className="w-[30%] block dark:text-white text-normal font-light font-mont pt-2 lg:w-[100%]">
                      Name
                    </label>
                    <div className="flex flex-col gap-2">
                      <input
                        type="text"
                        className="w-full font-poppins font-bold rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
                        value={data.name}
                        disabled={showPayment ? false : true}
                        onChange={(e) => {
                          setData({
                            ...data,
                            name: e.target.value,
                          });
                          data.name = e.target.value;
                        }}
                        placeholder="Enter Your Name"
                      />
                      {data.name === "" && validate && (
                        <p className="text-red text-sm mt-1">* Required</p>
                      )}
                    </div>
                  </div>
                  <div className="w-[100%] flex flex-col col-span-1">
                    <label className="w-[30%] block dark:text-white text-normal font-mont font-thin pt-2 lg:w-[100%]">
                      Email
                    </label>
                    <div className="flex flex-col gap-2">
                      <input
                        type="text"
                        className="w-full font-poppins rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 font-bold outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
                        value={data.email}
                        disabled={showPayment ? false : true}
                        onChange={(e) => {
                          setData({
                            ...data,
                            email: e.target.value,
                          });
                          data.email = e.target.value;
                        }}
                        placeholder="Enter Your Email"
                      />
                      {data.email === "" && validate ? (
                        <>
                          <p className="text-red text-sm mt-1">* Required</p>
                        </>
                      ) : (
                        <>
                          {isEmailValid(data.email) === false && validate && (
                            <p className="text-red text-sm">
                              * Invalid email format
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-[100%] flex flex-col col-span-1">
                    <label className="w-[30%] block dark:text-white text-normal font-mont font-thin pt-2 lg:w-[100%]">
                      Phone no
                    </label>
                    <div className="flex flex-col gap-2">
                      <input
                        type="text"
                        className="w-full font-poppins rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 font-bold outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
                        value={data.phoneNumber}
                        disabled={showPayment ? false : true}
                        onChange={(e) => {
                          setData({
                            ...data,
                            phoneNumber: e.target.value,
                          });
                          data.phoneNumber = e.target.value;
                        }}
                        placeholder="Enter Your Phone No"
                      />
                      {data.phoneNumber === "" && validate ? (
                        <p className="text-red text-sm mt-1">* Required</p>
                      ) : (
                        <>
                          {isPhoneNumberValid(data.phoneNumber) === false &&
                            validate && (
                              <p className="text-red text-sm mt-1">
                                * Invalid phone number
                              </p>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showPayment ? (
              <div className="w-[100%] h-auto flex justify-center items-center lg:w-[66%]">
                <button
                  className="bg-blue px-15 py-2 text-white rounded-md text-2xl"
                  onClick={() => {
                    // handleAdd();
                    proceedToPayment();
                  }}
                >
                  {loading ? (
                    <div className="flex px-8.5 py-1 w-full h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "Pay Now"
                  )}
                </button>
              </div>
            ) : (
              <>
                <div className="w-[100%] h-auto rounded-lg  pt-5 mt-20 mb-10 flex flex-col gap-4 lg:w-[66%] p-10 bg-white">
                  <p className="text-3xl font-bold text-poppins text-center lg:text-left pt-5 ">
                    Payment Method
                  </p>
                  {/* <div className="">
                    <p>Have some corrections in booking?</p>
                    <button
                      onClick={() => {
                        handleEditBooking();
                      }}
                      className="bg-primary text-white px-10 py-1 rounded-lg my-2"
                    >
                      Update My Bookings
                    </button>
                  </div> */}
                  <div className="w-full">
                    <Checkout
                      price={total}
                      tempId={tempId}
                      data={data}
                      pnr={data.pnr}
                    />
                  </div>
                </div>
              </>
              // <Checkout price={total} tempId={tempId} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

const Card = ({ data, showPayment }) => {
  return (
    <div className="bg-white w-full h-auto  rounded-2xl shadow-md flex flex-col justify-between">
      <div>
        <div className=" w-full flex justify-center items-center relative ">
          <img
            src={data.img}
            alt="images"
            style={{ width: "100%" }}
            className=" object-contain"
          />
          {data.isBlocked && (
            <div className=" h-full w-full absolute flex justify-center items-center">
              <img
                src={COMING}
                alt="images"
                // style={{ width: "100%" }}
                className=" object-contain w-[70%] "
              />
            </div>
          )}
        </div>
        <div className="w-full h-auto flex justify-center  p-3">
          <div className="w-[80%] h-auto">
            <p className="font-poppins text-primary font-thin text-sm lg:text-xs xl:text-sm leading-none">
              {data.park}
            </p>
            <p className="text-primary font-bold font-mont text-xl lg:text-base xl:text-2xl leading-none">
              {data.title}
            </p>
            <p className=" text-xs lg:text-[8px] xl:text-xs font-mont font-thin text-[#9C9C9C] ">
              {data.subTxt}
            </p>
          </div>
          <div className="w-[20%] flex justify-center items-center">
            <img
              src={data.icon}
              alt="images"
              style={{ width: "70%" }}
              className=" object-contain"
            />
          </div>
        </div>
        <div className="w-full h-auto grid mt-3 grid-cols-3 px-3">
          <div className="flex col-span-2 items-center justify-center">
            <div className="flex justify-center items-center w-[15%]">
              <img
                src={PARKHUMAN}
                alt="images"
                className=" object-contain w-full"
              />
            </div>
            <div className="h-auto  w-[85%]">
              <p className="font-bold text-sm lg:text-xs xl:text-sm font-mont leading-none">
                {data.subTxt2}
              </p>
              <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-bold leading-none">
                {data.miniTxt1}
              </p>
              <p className="text-xs lg:text-[8px] xl:text-xs font-mont font-thin leading-none">
                ({data.miniTxt2})
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center col-span-1">
            <div className="flex justify-center items-center cursor-pointer">
              <svg
                onClick={() => {
                  if (showPayment) {
                    data.decrementCounter();
                  }
                }}
                xmlns="http://www.w3.org/2000/svg"
                // width="22"
                // height="22"
                viewBox="0 0 22 22"
                className="h-5 w-5"
              >
                <g
                  id="Group_80"
                  data-name="Group 80"
                  transform="translate(-1263 -1167)"
                >
                  <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="22"
                    height="22"
                    rx="11"
                    transform="translate(1263 1167)"
                    fill="#0e6097"
                  />
                  <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="12"
                    height="2"
                    rx="1"
                    transform="translate(1268 1177)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div className="flex justify-center items-center px-2 lg:px-1 xl:px-3">
              <p className="text-poppins font-mont font-bold  text-lg lg:text-base xl:text-lg">
                {data.counter}
              </p>
            </div>
            <div className="flex justify-center items-center cursor-pointer">
              <svg
                onClick={() => {
                  if (showPayment) {
                    data.incrementCounter();
                  }
                }}
                xmlns="http://www.w3.org/2000/svg"
                // width="22"
                // height="22"
                viewBox="0 0 22 22"
                className="h-5 w-5"
              >
                <g
                  id="Group_79"
                  data-name="Group 79"
                  transform="translate(-1369 -1167)"
                >
                  <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="22"
                    height="22"
                    rx="11"
                    transform="translate(1369 1167)"
                    fill="#0e6097"
                  />
                  <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="12"
                    height="2"
                    rx="1"
                    transform="translate(1374 1177)"
                    fill="#fff"
                  />
                  <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="12"
                    height="2"
                    rx="1"
                    transform="translate(1381 1172) rotate(90)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <hr className="border-t border-dashed mt-3 w-[90%] mx-[5%]"></hr>
        <div className="w-full h-auto grid grid-cols-2 p-3">
          <div className="w-auto ">
            {/* <p className="font-semibold text-xs lg:text-[8px] xl:text-xs font-mont leading-4">
              Starting from AED<br></br>
            </p>
            <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin ">
              Including taxes
            </p> */}
          </div>
          <div className="w-full text-end">
            <p className="font-normal text-xl lg:text-base xl:text-xl font-mont">
              KWD {formatNumb(data.price)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
const ShowDateHeader = ({ data }) => {
  return (
    <div className="w-full h-auto py-10 grid grid-cols-3">
      <div className="col-span-3 lg:col-span-2 w-full flex flex-col lg:flex-row items-start justify-between lg:items-center">
        <div className="w-full">
          <p className="font-mont font-light text-3xl  w-self">
            Please Select Your Ticket
          </p>
          <p className="font-mont font-normal text-lg py-1 text-[#959595]">
            Choose Your Park Pass And Embark On An Unforgettable Journey!
          </p>
        </div>
        <button className="w-[250px] lg:w-[30%] h-full relative">
          <div className="flex w-full rounded-md  items-end justify-between">
            <div className="font-light text-start">
              <p className="text-sm">Selected Date</p>
              <p className="text-xl lg:text-lg xl:text-xl font-bold">
                {" "}
                {formatDateString(data.date)}
              </p>
            </div>
            <div className="w-auto h-auto mr-2.5">
              <svg
                id="calendar_2_"
                data-name="calendar (2)"
                xmlns="http://www.w3.org/2000/svg"
                width="21.238"
                height="21.238"
                viewBox="0 0 21.238 21.238"
              >
                <g id="Group_6" data-name="Group 6">
                  <g id="Group_5" data-name="Group 5">
                    <path
                      id="Path_53"
                      data-name="Path 53"
                      d="M279,280.609a1.661,1.661,0,0,0,1.659,1.659h1.95a1.661,1.661,0,0,0,1.659-1.659v-1.95A1.661,1.661,0,0,0,282.609,277h-1.95A1.661,1.661,0,0,0,279,278.659Zm1.659-1.95h1.95v1.95h-1.951Z"
                      transform="translate(-267.427 -265.51)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(14.983 8.324)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(11.653 8.324)"
                      fill="#0e6097"
                    />
                    <path
                      id="Path_54"
                      data-name="Path 54"
                      d="M20.409,14.6a.83.83,0,0,0,.83-.83V4.978A3.322,3.322,0,0,0,17.92,1.659H16.841V.83a.83.83,0,1,0-1.659,0v.83H11.407V.83a.83.83,0,0,0-1.659,0v.83H6.015V.83a.83.83,0,1,0-1.659,0v.83H3.319A3.322,3.322,0,0,0,0,4.978V17.92a3.322,3.322,0,0,0,3.319,3.319h14.6a3.322,3.322,0,0,0,3.319-3.319.83.83,0,1,0-1.659,0,1.661,1.661,0,0,1-1.659,1.659H3.319A1.661,1.661,0,0,1,1.659,17.92V4.978A1.661,1.661,0,0,1,3.319,3.319H4.356v.83a.83.83,0,1,0,1.659,0v-.83H9.748v.83a.83.83,0,0,0,1.659,0v-.83h3.775v.83a.83.83,0,1,0,1.659,0v-.83H17.92a1.661,1.661,0,0,1,1.659,1.659v8.794A.83.83,0,0,0,20.409,14.6Z"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(4.162 14.983)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_4"
                      data-name="Ellipse 4"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(4.162 8.324)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_5"
                      data-name="Ellipse 5"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(4.162 11.653)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_6"
                      data-name="Ellipse 6"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(8.324 11.653)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_7"
                      data-name="Ellipse 7"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(8.324 8.324)"
                      fill="#0e6097"
                    />
                    <circle
                      id="Ellipse_8"
                      data-name="Ellipse 8"
                      cx="0.832"
                      cy="0.832"
                      r="0.832"
                      transform="translate(8.324 14.983)"
                      fill="#0e6097"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </button>
      </div>
      <div className="col-span-1"></div>
    </div>
  );
};

const ShowSelectDate = ({ data, setData, startDateRef }) => {
  const handleStartDate = () => {
    startDateRef.current.showPicker();
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="w-auto h-auto py-10">
      <div className="w-auto">
        <p className="font-mont font-light text-3xl  w-self">
          Please Select Your Ticket
        </p>
        <p className="font-mont font-normal text-lg py-1 text-[#959595]">
          Choose Your Park Pass And Embark On An Unforgettable Journey!
        </p>
      </div>
      <button onClick={handleStartDate} className="w-full relative">
        <div className="flex px-3 py-2.5 w-full md:w-[90%] rounded-md  items-center justify-between shadow-lg mt-4 lg:w-[50%]">
          <div className="font-light text-start">
            <p className="text-sm"> {formatDateString(data.date)}</p>
          </div>
          <div className="w-auto h-auto mr-2.5">
            <svg
              id="calendar_2_"
              data-name="calendar (2)"
              xmlns="http://www.w3.org/2000/svg"
              width="21.238"
              height="21.238"
              viewBox="0 0 21.238 21.238"
            >
              <g id="Group_6" data-name="Group 6">
                <g id="Group_5" data-name="Group 5">
                  <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M279,280.609a1.661,1.661,0,0,0,1.659,1.659h1.95a1.661,1.661,0,0,0,1.659-1.659v-1.95A1.661,1.661,0,0,0,282.609,277h-1.95A1.661,1.661,0,0,0,279,278.659Zm1.659-1.95h1.95v1.95h-1.951Z"
                    transform="translate(-267.427 -265.51)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(14.983 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(11.653 8.324)"
                    fill="#0e6097"
                  />
                  <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M20.409,14.6a.83.83,0,0,0,.83-.83V4.978A3.322,3.322,0,0,0,17.92,1.659H16.841V.83a.83.83,0,1,0-1.659,0v.83H11.407V.83a.83.83,0,0,0-1.659,0v.83H6.015V.83a.83.83,0,1,0-1.659,0v.83H3.319A3.322,3.322,0,0,0,0,4.978V17.92a3.322,3.322,0,0,0,3.319,3.319h14.6a3.322,3.322,0,0,0,3.319-3.319.83.83,0,1,0-1.659,0,1.661,1.661,0,0,1-1.659,1.659H3.319A1.661,1.661,0,0,1,1.659,17.92V4.978A1.661,1.661,0,0,1,3.319,3.319H4.356v.83a.83.83,0,1,0,1.659,0v-.83H9.748v.83a.83.83,0,0,0,1.659,0v-.83h3.775v.83a.83.83,0,1,0,1.659,0v-.83H17.92a1.661,1.661,0,0,1,1.659,1.659v8.794A.83.83,0,0,0,20.409,14.6Z"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 14.983)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 14.983)"
                    fill="#0e6097"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>

        <input
          type="date"
          ref={startDateRef}
          min={today}
          onChange={(e) => {
            setData({
              ...data,
              date: e.target.value,
            });
          }}
          className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
        />
      </button>
    </div>
  );
};
