// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const usePreventBackNavigation = (redirectPath) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     console.log("usePreventBackNavigation hook triggered");

//     // Function to handle back button click
//     const handlePopState = (event) => {
//       event.preventDefault();
//       console.log("Back button pressed, redirecting...");
//       navigate(redirectPath, { replace: true });
//     };

//     window.addEventListener('popstate', handlePopState);

//     return () => {
//       window.removeEventListener('popstate', handlePopState);
//     };
//   }, [navigate, redirectPath]);

  
// };

// export default usePreventBackNavigation;


import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const usePreventBackNavigation = (redirectPath) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("usePreventBackNavigation hook triggered");

    // Push a dummy state to the history
    window.history.pushState({ noBackExitsApp: true }, '');

    // Function to handle back button click
    const handlePopState = (event) => {
      if (event.state && event.state.noBackExitsApp) {
        console.log("Back button pressed, redirecting...");
        navigate(redirectPath, { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, redirectPath]);
};

export default usePreventBackNavigation;
