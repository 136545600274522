import SEND from "../../../assets/send.png";
import NAT from "../../../assets/nature.png";
import HOUSE from "../../../assets/tent.png";
import DWLD from "../../../assets/dwnld.png";
import DW from "../../../assets/dw.png";
import SND from "../../../assets/snd.png";
import TENT from "../../../assets/house.png";
import { BOOKING } from "../../../api/booking";
import CHALET from "../../../assets/chalet.png";
import PARK from "../../../assets/parkticket.png";
import React, { useEffect, useRef, useState } from "react";
import STANDARD from "../../../assets/standard.png";
import { CONSTANT } from "../../../constant/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { numberToWords } from "../../../utils/NumberToWords";
import { formatDateString } from "../../../utils/FormatDateString";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import usePreventBackNavigation from "../../../utils/usePreventBackNavigation";
import { PAYMENT } from "../../../api/payment";
import { OFFERS } from "../../../api/offers";
export default function Ticket() {
  usePreventBackNavigation("/book-now");
  let { date, pnr } = useParams();
  const [offers, setOffers] = useState([]);
  //constant variables
  const navigate = useNavigate();
  const CD_DATA = {
    img: CHALET,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Deluxe Chalet and One Park Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: HOUSE,
  };
  const CS_DATA = {
    img: STANDARD,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Standard Chalet and One Park Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: TENT,
  };
  const OD_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "One Day Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  const FREE_CD_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Additional 1 Park Ticket with Deluxe Chalet ",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  const FREE_CS_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Additional 1 Park Ticket with Standard Chalet ",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  //constant variables
  //useState
  const [id, setId] = useState(0);
  const [show, setShow] = useState(false);
  const [noTicket, setNoTicket] = useState(false);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState({});
  const [type, setType] = useState("CD");
  const [array, setArray] = useState({
    odt: "",
    cd: "",
    cs: "",
    ps: "",
    freeCd: "",
    freeCs: "",
    freePs: "",
    freeodt: "",
  });

  const [noData, setNoData] = useState({
    img: "https://cdni.iconscout.com/illustration/premium/thumb/not-found-4064375-3363936.png?f=webp",
    title: "Booking Not Found!",
    txt1: "We cant find the booking for the current information.",
    txt2: "Please check the request url.",
  });
  //useState
  //dependant variables
  const shareUrl = (ticket) => {
    return `${CONSTANT.BASE_URL}ticket/${ticket}`;
    // return `${CONSTANT.BASE_URL}booking-details/date/2024-05-23/pnr/6681491318/identity/${id}`;
  };
  const downloadImage = (data) => {
    // Replace 'image_url.jpg' with the URL of the image you want to download
    const imageUrl = `${CONSTANT.BASE_URL}ticket/${data.ticketImage}`;
    window.open(imageUrl, "_blank");
  };
  const filterCdFreeArrayByChalet = (chaletNo) => {
    var rtnArray = [];
    array.freeCd.map((itm) => {
      if (itm.chaletNo === chaletNo) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };
  const filterCsFreeArrayByChalet = (chaletNo) => {
    var rtnArray = [];
    array.freeCs.map((itm) => {
      if (itm.chaletNo === chaletNo) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };

  //dependant variables
  //function
  const getBookingList = async (date, pnr) => {
    const odtArray = [];
    const cdArray = [];
    const csArray = [];
    const psArray = [];
    const freeCdArray = [];
    const freeCsArray = [];
    const freePsArray = [];
    const freeOdtArray = [];
    try {
      const res = await BOOKING.GET_BY_PNR(date, pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      if (res.data.length > 0) {
        console.log("res.data", res.data);
        setData(res.data);
        setPrice(res.data[0].groupSize);
        res.data[0].ticketMembers.map((itm) => {
          console.log("im", itm);
          if (itm.ticketType === 0) {
            odtArray.push(itm.chaletNo);
          } else if (itm.ticketType === 1) {
            cdArray.push(itm.chaletNo);
          } else if (itm.ticketType === 2) {
            csArray.push(itm.chaletNo);
          } else if (itm.ticketType === 7) {
            psArray.push(itm.chaletNo);
          } else if (itm.ticketType === 3) {
            freeCdArray.push(itm.chaletNo);
          } else if (itm.ticketType === 4) {
            freeCsArray.push(itm.chaletNo);
          } else if (itm.ticketType === 8) {
            freePsArray.push(itm.chaletNo);
          } else if (itm.ticketType === 9) {
            freePsArray.push(itm.chaletNo);
          }
          console.log("cdArray", cdArray);
          setArray({
            ...array,
            odt: odtArray,
            cd: cdArray,
            cs: csArray,
            ps: psArray,
            freeCd: freeCdArray,
            freeCs: freeCsArray,
            freePs: freePsArray,
            freeOdt: freeOdtArray,
          });
          array.odt = odtArray;
          array.cd = cdArray;
          array.cs = csArray;
          array.ps = csArray;
          array.freeCd = freeCdArray;
          array.freeCs = freeCsArray;
          array.freePs = freeCsArray;
          array.freeOdt = freeOdtArray;
          return data;
        });
        console.log("array", array);
      } else {
        setNoTicket(true);
        const checkPayment = await PAYMENT.GET_BY_PNR(pnr);
        if (checkPayment.data.length > 0) {
          if (checkPayment.data[0].status === "refund") {
            console.log("in refund");
            setNoData({
              ...noData,
              img: "https://static.vecteezy.com/system/resources/thumbnails/018/871/937/small_2x/icon-cloud-technology-or-blockchain-cloud-network-connection-access-to-all-devices-on-a-wireless-network-png.png",
              title: "Too Much Traffic",
              txt1: "We could'nt book ticket for you due to heavy traffic in the website",
              txt2: "The debited amount will be refunded within 3 working days",
            });
            //show the popup saying the booking is filled due to heavy traffic. Refund will be debited in 3 working days
          } else {
            // show booking Not Found
            console.log("in else");
          }
        } else {
          // show booking Not Found
          console.log("in error else");
        }
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //function

  const applyDiscount = (price, count, type) => {
    let discountAmount = 0;
    offers?.forEach((offer) => {
      if (offer.typeOfBooking === type) {
        if (offer.activeLength && count >= offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        } else if (!offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        }
      }
    });
    const discountedPrice = Math.max(0, price - discountAmount);
    return {
      originalPrice: price,
      discountedPrice,
      discountApplied: discountAmount > 0,
    };
  };

  const fetchInitialOffers = async (date) => {
    try {
      const res = await OFFERS.GET_BY_DATE(date);
      setOffers(res.data);
      // console.log("Initial offers:", res.data);
    } catch (error) {
      console.error("Error fetching initial offers:", error);
    }
  };

  const divRef = useRef(null);
  const buttonRef = useRef(null);
  //useEffect
  useEffect(() => {
    if (date && pnr) {
      getBookingList(date, pnr);
      fetchInitialOffers(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, pnr]);
  useEffect(() => {
    // Function to handle back button click
    const handlePopState = () => {
      navigate("/book-now", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handleClickOutside = (event) => {
    if (
      divRef.current &&
      !divRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show]);
  //useEffect

  return (
    <div className="h-auto w-auto flex justify-center py-25 bg-bgbg ">
      {noTicket ? (
        <div className=" w-[90%] h-auto max-w-screen-2xl flex flex-col text-center justify-center items-center">

          <p className="font-bold text-primary text-6xl mb-2">{noData.title}</p>
          <p className="font-bold text-black text-xl">{noData.txt1}</p>
          <p className="font-bold text-black text-xl">{noData.txt2}</p>
          <button
            onClick={() => {
              navigate("/");
            }}
            className="bg-primary px-10 py-2 rounded-lg text-white mt-5"
          >
            Go Back Home
          </button>
        </div>
      ) : (
        <div className=" w-[90%] h-auto max-w-screen-2xl ">
          <div className="w-auto h-auto py-5">
            <p className=" font-mont font-light text-3xl  w-self">My Ticket</p>
          </div>
          <div className=" w-auto h-auto  grid grid-cols-1 lg:grid-cols-4 gap-4 mb-2 ">
            <div className="col-span-3 w-full flex flex-col md:flex-row justify-between gap-5 ">
              <div className="w-full">
                <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                  Name
                </p>
                <p className="font-semibold font-mont text-xl leading-3">
                  {data.length > 0 ? data[0].name : "...."}
                </p>
              </div>
              <div className="w-full  ">
                <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                  Email
                </p>
                <p className="font-semibold font-mont text-xl leading-3">
                  {data.length > 0 ? data[0].email : "...."}
                </p>
              </div>
              <div className="w-full">
                <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                  Phone Number
                </p>
                <p className="font-semibold font-mont text-xl leading-3">
                  {data.length > 0 ? data[0].mobNo : "...."}
                </p>
              </div>
              <div className="w-full">
                <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                  Booking Date
                </p>
                <p className="font-semibold font-mont text-xl leading-3">
                  {data.length > 0 ? formatDateString(data[0].date) : "...."}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-5 ">
            <div className="col-span-2 w-full">
              <div className="w-full mt-15 grid">
                {/* <p className="font-poppins font-bold font-lg mb-3">
                Park Tickets
              </p> */}
                <TicketCard
                  shareUrl={shareUrl}
                  itm={data[0]}
                  downloadImage={downloadImage}
                  setShow={setShow}
                  show={show}
                  setType={setType}
                  data={OD_DATA}
                  buttonRef={buttonRef}
                  divRef={divRef}
                  array={array}
                />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1 w-full   mt-24">
              <div className="flex justify-center items-start lg:col-span-1 ">
                <div className=" w-full h-auto p-5 bg-white rounded-xl">
                  <div className="w-full h-auto">
                    <p className="text-poppins text-primary text-2xl xl:text-3xl font-semibold">
                      Purchase summary
                    </p>
                  </div>
                  {price.odtNo > 0 && (
                    <div className="w-full flex justify-between mt-2 lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg leading-6">
                          {/* {console.log("price.odtPrice", price.odtPrice)}
                          {console.log("price.odtPrice", price.cdPrice)}
                          {console.log("price.odtPrice", price.psPrice)}
                          {console.log("price.odtPrice", price.csPrice)} */}
                          Park Ticket [{price.odtNo}×{price.odtPrice}]
                          {/* Park Ticket [{price.odtNo}×{applyDiscount(price.odtPrice, price.odtNo, "One Day Ticket").discountedPrice !== price.odtPrice ? applyDiscount(price.odtPrice, price.odtNo, "One Day Ticket").discountedPrice : price.odtPrice}] */}
                          {/* Park Ticket [{price.odtNo}×{applyDiscount(price.odtPrice, price.odtNo, "One Day Ticket").discountedPrice}] */}
                          <br></br>
                          {price.odtFree > 0 && (
                            <span className="text-[10px] xl:text-sm text-mont font-thin">
                              {/* One free park ticket per chalet You have earned{" "} */}
                              You have earned {numberToWords(price.odtFree).toLowerCase()} free
                              Park Tickets
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.odtNo * price.odtPrice}
                          {/* : KWD {price.odtNo * (applyDiscount(6, price.odtNo, "One Day Ticket").discountedPrice )} */}
                          {/* : KWD {price.odtNo * (applyDiscount(price.odtPrice, price.odtNo, "One Day Ticket").discountedPrice !== price.odtPrice ? applyDiscount(price.odtPrice, price.odtNo, "One Day Ticket").discountedPrice : price.odtPrice)} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {price.cdNo > 0 && (
                    <div className="w-full flex justify-between   mt-2  lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg leading-6">
                          Chalet Deluxe [{price.cdNo}×{price.cdPrice}]<br></br>
                          {/* Chalet Deluxe [{price.cdNo}×{applyDiscount(price.cdPrice, price.cdNo, "Chalet Deluxe").discountedPrice}]<br></br> */}
                          {price.cdFree > 0 && (
                            <span className="text-[10px] xl:text-sm text-mont font-thin">
                              {/* One free park ticket per chalet You have earned{" "} */}
                              You have earned {numberToWords(price.cdFree).toLowerCase()} free
                              Park Tickets
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.cdNo * price.cdPrice}
                          {/* : KWD {price.cdNo * applyDiscount(price.cdPrice, price.cdNo, "Chalet Deluxe").discountedPrice} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {price.csNo > 0 && (
                    <div className="w-full flex justify-between mt-2 lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg leading-6">
                          Chalet Standard [{price.csNo}×{price.csPrice}]
                          {/* Chalet Standard [{price.csNo}×{applyDiscount(price.csPrice, price.csNo, "Chalet Standard").discountedPrice}] */}
                          <br></br>
                          {price.scFree > 0 && (
                            <span className="text-[10px] xl:text-sm text-mont font-thin">
                              {/* One free park ticket per chalet */}
                              You have earned {numberToWords(price.scFree).toLowerCase()} free
                              Park Tickets
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.csNo * price.csPrice}
                          {/* : KWD {price.csNo * applyDiscount(price.csPrice, price.csNo, "Chalet Standard").discountedPrice} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {price.psNo > 0 && (
                    <div className="w-full flex justify-between mt-2 lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg leading-6">
                          Premium Standard [{price.psNo}×{price.psPrice}]
                          {/* Premium Standard [{price.psNo}×{applyDiscount(price.psPrice, price.psNo, "Premium Standard").discountedPrice}] */}
                          <br></br>
                          {price.psFree > 0 && (
                            <span className="text-[10px] xl:text-sm text-mont font-thin">
                              {/* One free park ticket per chalet */}
                              You have earned {numberToWords(price.psFree).toLowerCase()} free
                              Park Tickets
                            </span>
                          )}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.psNo * price.psPrice}
                          {/* : KWD {price.psNo * applyDiscount(price.psPrice, price.psNo, "Premium Standard").discountedPrice} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {price.saNo > 0 && (
                    <div className="w-full flex justify-between mt-2 lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg font-">
                          Specially Abled [{price.saNo}×{price.saPrice}]
                          {/* Specially Abled [{price.saNo}×{applyDiscount(price.saPrice, price.saNo, "Specially Abled").discountedPrice}] */}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.saNo * price.saPrice}
                          {/* : KWD {price.saNo * applyDiscount(price.saPrice, price.saNo, "Specially Abled").discountedPrice} */}
                        </p>
                      </div>
                    </div>
                  )}
                  {price.mdNo > 0 && (
                    <div className="w-full flex justify-between mt-2 lg:mt-5">
                      <div className="w-[70%]">
                        <p className="text-sm xl:text-lg font-">
                          Nanny [{price.mdNo}×{price.mdPrice}]
                          {/* Nanny [{price.mdNo}×{applyDiscount(price.mdPrice, price.mdNo, "Maid One Day Ticket").discountedPrice}] */}
                        </p>
                      </div>
                      <div className="w-[30%]">
                        <p className="text-sm xl:text-lg font-semibold">
                          : KWD {price.mdNo * price.mdPrice}
                          {/* : KWD {price.mdNo * applyDiscount(price.mdPrice, price.mdNo, "Maid One Day Ticket").discountedPrice} */}
                        </p>
                      </div>
                    </div>
                  )}
                  <hr className="border-t border-2 border-dashed mt-3 w-full "></hr>
                  <div className="w-full flex justify-between  mt-8">
                    <div className="w-[70%]">
                      <p className="text-sm xl:text-lg font-poppins">TOTAL</p>
                    </div>
                    <div className="w-[35%]">
                      <p className="text-sm xl:text-lg font-poppins">
                        : KWD {price.totalPrice}
                      </p>
                    </div>
                  </div>
                  {/* <div className="w-full flex justify-between  mt-8">
                    <div className="w-[70%]">
                      <p className="text-sm xl:text-lg font-poppins">
                        DISCOUNT
                      </p>
                    </div>
                    <div className="w-[35%]">
                      <p className="text-sm xl:text-lg font-poppins">
                        : KWD {price.totalPrice}
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex justify-between  mt-8">
                    <div className="w-[70%]">
                      <p className="text-sm xl:text-lg font-poppins font-bold">
                        GRAND TOTAL
                      </p>
                    </div>
                    <div className="w-[35%]">
                      <p className="text-sm xl:text-lg font-poppins font-bold">
                        : KWD {price.totalPrice}
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const TicketCard = ({
  itm,
  shareUrl,
  downloadImage,
  ticketType,
  setShow,
  show,
  type,
  setType,
  data,
  buttonRef,
  divRef,
  array,
}) => {
  const parentRef = useRef(null);
  const [numDivs, setNumDivs] = useState(0);
  console.log("arrayarray", array);
  useEffect(() => {
    const updateDivs = () => {
      if (parentRef.current) {
        const parentHeight = parentRef.current.clientHeight; // Get the current height of the parent
        console.log("parent", parentHeight);
        const divHeight = 30; // Height of each child div
        const totalDivs = Math.floor(parentHeight / divHeight); // Calculate the number of divs to render (including margin)
        setNumDivs(totalDivs);
      }
    };

    // Initial calculation
    updateDivs();

    // Optional: Update divs on window resize
    window.addEventListener("resize", updateDivs);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", updateDivs);
  }, []);

  const renderDivs = () => {
    return Array.from({ length: numDivs }).map((_, index) => (
      <div
        key={index}
        className="bg-bgbg rounded-l-full w-[20px] h-[30px] my-[1px]"
      ></div>
    ));
  };

  return (
    <div className="w-auto h-auto px-[6%] py-[1%] bg-[#651fff] relative flex items-center">
      <div className="opacity-0 md:opacity-100 w-[50px] h-[100px] bg-bgbg rounded-r-full absolute left-[-1px] z-[99]"></div>
      <div className="absolute h-full">
        <img
          src="https://cdn.pixabay.com/photo/2014/03/25/16/34/world-map-297446_1280.png"
          alt="world map"
          className="object-cover lg:object-contain opacity-[20%] w-full h-full"
        />
      </div>
      <div className=" relative flex justify-between items-center w-full h-full ">
        <div className="w-[50px] h-[100px]  rounded-l-full rotate-180 absolute left-0 z-[99]"></div>
        {/* <div className="w-full h-full grid grid-cols-[75%_25%] z-9"> */}
        <div className="w-full h-full grid grid-cols-1 md:grid-cols-10  z-9 ">
          <div className="relative w-[98%] h-full p-5 my-3 border-none border-r-2 md:border-dotted border-white/30 col-span-7">
            <div className="flex flex-col md:flex-row items-center md:justify-between text-center md:text-start">
              <div className="flex justify-center  items-center w-[60%] h-auto p-2 md:hidden">
                <img
                  className="w-full object-contain"
                  src="https://s3.ap-south-1.amazonaws.com/media.aquamagickw.com/ticket/logo.png"
                  alt="aqualogo"
                />
              </div>
              <div className=" flex flex-col  justify-center items-start">
                <p className="text-white text-base font-light leading-tight font-poppins w-full">
                  AQUAMAGIC WATER PARK
                </p>
                <p className="text-white text-3xl font-extrabold leading-tight font-poppins mb-[2%] w-full">
                  PARK ENTRY TICKET
                </p>
                <p className="text-white text-sm font-light leading-tight font-poppins w-full">
                  ALL-DAY ACCESS TO UNLIMITED FUN
                </p>
              </div>
              <div className="opacity-0 md:opacity-100  justify-center  items-center w-[30%] h-0 md:h-auto p-2">
                <img
                  className="w-full object-contain"
                  src="https://s3.ap-south-1.amazonaws.com/media.aquamagickw.com/ticket/logo.png"
                  alt="aqualogo"
                />
              </div>
            </div>
            <hr className="border-t-2 border-dotted border-white/30 my-2 " />
            <div className="flex py-[2%]">
              <div className="pr-[2%]">
                <p className="text-white text-sm sm:text-base font-extralight font-poppins py-1 ">
                  NO OF GUESTS
                </p>
                <p className="text-white text-sm sm:text-base font-extralight font-poppins py-1">
                  BOOKING DATE
                </p>
                <p className="text-white text-sm sm:text-base font-extralight font-poppins py-1">
                  PAYMENT METHOD
                </p>
                <p className="text-white text-sm sm:text-base font-extralight font-poppins py-1">
                  CONFIRMATION ID
                </p>
              </div>
              <div>
                <p className="text-white text-sm sm:text-base font-bold font-poppins py-1">
                  <span className="pr-2">:</span>
                  {itm !== undefined ? itm.groupSize.odtNo + itm.groupSize.saNo + itm.groupSize.mdNo + itm.groupSize.cdFree + itm.groupSize.scFree + itm.groupSize.psFree + (itm.groupSize.odtFree !== undefined ? itm.groupSize.odtFree : 0) : "00"}
                  {/* {itm !== undefined ? itm.ticketMembers.length : "00"} */}
                </p>
                <p className="text-white text-sm sm:text-base font-bold font-poppins py-1">
                  <span className="pr-2">:</span>
                  {itm !== undefined ? formatDateString(itm.date) : ""}
                </p>
                <p className="text-white text-sm sm:text-base font-bold font-poppins py-1">
                  <span className="pr-2">:</span>
                  {itm !== undefined ? itm.payment.paymentType : ""}
                </p>
                <p className="text-white text-sm sm:text-base font-bold font-poppins py-1">
                  <span className="pr-2">:</span>
                  {itm !== undefined ? itm.pnr : ""}
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-dotted border-white/30 my-2" />
            {array.cd.length > 0 && (
              <>
                <div className="grid grid-cols-[35%_65%] ">
                  <div className="flex items-center justify-between ">
                    <div className="bg-white w-full px-[4%] py-[3%] h-full flex items-center">
                      <p className="text-black text-center text-sm font-semibold font-poppins m-0">
                        DELUXE CHALETS{" "}
                        {itm !== undefined ? itm.groupSize.cdNo : ""}
                      </p>
                    </div>
                    <div className="bg-transparent h-full flex items-center">
                      <div className="w-0 h-0 border-t-[10px] border-b-[10px] border-l-[10px] border-transparent border-l-white mt-2 mb-2"></div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5  sm:grid-cols-3 grid-cols-2 md:gap-5  gap-2 2xl:gap-8.5 ml-[2%] ">
                    {array.cd.map((item) => (
                      <div className="flex justify-center items-center text-white font-poppins italic font-light text-base">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="border-t-2 border-dotted border-white/30 my-2" />
              </>
            )}
            {array.cs.length > 0 && (
              <>
                <div className="grid grid-cols-[35%_65%] ">
                  <div className="flex items-center justify-between">
                    <div className="bg-white w-full px-[4%] py-[3%] h-full flex items-center">
                      <p className="text-black text-sm font-semibold font-poppins text-center m-0">
                        STANDARD CHALETS{" "}
                        {itm !== undefined ? itm.groupSize.csNo : ""}
                      </p>
                    </div>
                    <div className="bg-transparent h-full flex items-center">
                      <div className="w-0 h-0 border-t-[10px] border-b-[10px] border-l-[10px] border-transparent border-l-white mt-2 mb-2"></div>
                    </div>
                  </div>

                  <div className="grid md:grid-cols-5  sm:grid-cols-3 grid-cols-2 md:gap-5  gap-2 2xl:gap-8.5 ml-[2%] ">
                    {array.cs.map((item) => (
                      <div className="flex justify-center items-center text-white font-poppins italic font-light text-base">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="border-t-2 border-dotted border-white/30 my-2" />
              </>
            )}
            {array.ps.length > 0 && (
              <>
                <div className="grid grid-cols-[35%_65%] ">
                  <div className="flex items-center justify-between">
                    <div className="bg-white w-full px-[4%] py-[3%] h-full flex items-center">
                      <p className="text-black text-sm font-semibold font-poppins text-center m-0">
                        PREMIUM CHALETS{" "}
                        {itm !== undefined ? itm.groupSize.psNo : ""}
                      </p>
                    </div>
                    <div className="bg-transparent h-full flex items-center">
                      <div className="w-0 h-0 border-t-[10px] border-b-[10px] border-l-[10px] border-transparent border-l-white mt-2 mb-2"></div>
                    </div>
                  </div>
                  <div className="grid md:grid-cols-5  sm:grid-cols-3 grid-cols-2 md:gap-5  gap-2 2xl:gap-8.5 ml-[2%] ">
                    {array.ps.map((item) => (
                      <div className="flex justify-center items-center text-white font-poppins italic font-light text-base">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="border-t-2 border-dotted border-white/30 my-2" />
              </>
            )}
            <div className="grid xl:grid-cols-2  grid-cols-1 w-full h-auto  justify-between text-center md:text-start">
              {/* <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  GUEST FREE TICKET :{" "}
                  {itm !== undefined ? itm.groupSize.odtFree : ""}
                </p>
              </div> */}
              <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  DELUXE CHALET FREE TICKET :{" "}
                  {itm !== undefined ? itm.groupSize.cdFree : ""}
                </p>
              </div>
              <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  STANDARD CHALET FREE TICKET :{" "}
                  {itm !== undefined
                    ? (itm.groupSize.scFree + (itm.groupSize.psFree !== undefined ? itm.groupSize.psFree : 0))
                    : "0"}
                </p>
              </div>
            </div>
            <div className="grid xl:grid-cols-2  grid-cols-1  w-full h-auto  justify-between mt-0 md:mt-2 text-center md:text-start">
              <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  NANNY TICKET : {itm !== undefined ? (itm.groupSize.mdNo !== undefined ? itm.groupSize.mdNo : 0) : ""}
                </p>
              </div>
              <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  SPECIALLY ABLED TICKET :{" "}
                  {itm !== undefined ? (itm.groupSize.saNo !== undefined ? itm.groupSize.saNo : 0) : ""}
                </p>
              </div>
            </div>
            <div className="grid xl:grid-cols-2  mt-0 md:mt-2   grid-cols-1 w-full h-auto  justify-between text-center md:text-start">
              <div className=" col-span-1">
                <p className="text-[12px] font-poppins font-thin text-white">
                  GUEST FREE TICKET :{" "}
                  {itm !== undefined ? (itm.groupSize.odtFree !== undefined ? itm.groupSize.odtFree : 0)
                    : "0"}
                </p>
              </div>
            </div>
            <hr className="border-t-2 border-dotted border-white/30 my-2 block md:hidden" />
          </div>
          <div className="relative w-full h-full  flex flex-col col-span-3 justify-center items-center">
            <div className="flex justify-center items-center flex-col w-full">
              <div className="w-[80%] md:w-full h-auto bg-white flex flex-col justify-center items-center  rounded-2xl p-[5%]">
                {itm !== undefined && (
                  <img
                    className=" w-full object-contain"
                    src={`${CONSTANT.BASE_URL}qr/${itm.qrImage}`}
                    alt="ticketimg"
                  />
                )}
                <div className="flex flex-col items-center mt-[6%]">
                  <p>BOOKING ID</p>
                  <p className="font-semibold text-lg 2xl:text-xl text-center">
                    {itm !== undefined ? itm.pnr : ""}
                  </p>
                </div>
              </div>
              <div className="w-full h-auto py-[4%]">
                <p className="text-white text-center 2xl:text-[15px] text-[15px] font-medium  font-poppins px-[5%] 2xl:px-[5%] 2xl:py-[3%] py-0 sm:text-[13px] w-full">
                  OPENING TIME : 01:00 PM
                </p>
              </div>
              <div className="relative w-[50%] h-auto flex justify-between ">
                <div className="  2xl:py-[5%] 2xl:px-[5%]  px-[4%] py-[7%]">
                  <button onClick={() => downloadImage(itm)}>
                    <img
                      className="w-8 h-8  object-contain"
                      src={DW}
                      alt="download"
                    />
                  </button>
                </div>
                <div className=" 2xl:py-[5%] 2xl:px-[5%]  px-[4%] py-[7%]">
                  <button
                    ref={buttonRef}
                    onClick={() => {
                      setShow(!show);
                      setType(ticketType);
                    }}
                  >
                    <img
                      className="w-8 h-8 object-contain"
                      src={SND}
                      alt="SHAREE"
                    />
                  </button>
                </div>
                {show && (
                  <div
                    className="absolute flex  bottom-[-35%]  sm:bottom-[-40%] right-0  z-99 "
                    ref={divRef}
                  >
                    <FacebookShareButton
                      url={shareUrl(itm.ticketImage)}
                      className="mx-1"
                    >
                      <FacebookIcon size={25} round={true} />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={shareUrl(itm.ticketImage)}
                      className="mx-1"
                    >
                      <WhatsappIcon size={25} round={true} />
                    </WhatsappShareButton>
                    <EmailShareButton
                      url={shareUrl(itm.ticketImage)}
                      className="mx-1"
                    >
                      <EmailIcon size={25} round={true} />
                    </EmailShareButton>
                    <TelegramShareButton
                      url={shareUrl(itm.ticketImage)}
                      className="mx-1"
                    >
                      <TelegramIcon size={25} round={true} />
                    </TelegramShareButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={parentRef}
        className="absolute z-[99] right-[-1px] h-full flex flex-col"
      >
        {" "}
        {/* h-auto to allow dynamic height */}
        {renderDivs()}
      </div>
    </div>
  );
};
