import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { CS } from "../../../api/stdChalet";
import AddStandard from "./AddStandard";
import UpdateStandard from "./UpdateStandard";
import TableStandard from "./TableStandard";

export default function StandardChalet() {
  // states
  const [id, setId] = useState("");
  const [standardChalet, setStandardChalet] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [validate, setValidate] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [data, setData] = useState({
    _id: "",
    title: "",
  });
  // states
  //constant variables
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const topSessionRef = useRef(null);
  const tableSessionRef = useRef(null);
  const { token } = useContext(TokenContext);
  const endOffset = itemOffset + itemsPerPage;
  const { checkAccessTokenValidity } = UseAuth();
  const currentItems = standardChalet.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(standardChalet.length / itemsPerPage);
  standardChalet.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  });
  //constant variables
  //dependent functions
  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };
  const clearData = () => {
    setData({
      ...data,
      title: "",
      isPurged: false,
    });
  };
  const handleEdit = (item) => {
    scrollToSection();
    setIsEdit(true);
    setData({
      ...data,
      _id: item._id,
      title: item.title,
      isPurged: false,
    });
    data.title = item.title;
  };
  //dependent functions
  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await CS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch standard chalet information");
        } else {
          var req = {
            title: res.data.title,
            isPurged: false,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await CS.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        getStandardChaletList()
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Standard Chalet Deleted");
        navigate("/standard-chalet");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  //update
  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await CS.UPDATE(values, data._id);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
        toast.success("Standard Chalet Updated.");
        navigate("/standard-chalet");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const handleUpdate = async () => {
    if (window.confirm("Are you sure you want to update this data?")) {
      if (data.title !== "") {
        setValidate(false);
        setLoading(true);
        var req = {
          title: data.title,
          isPurged: false,
        };
        updateData(req);
      } else {
        setValidate(true);
      }
    }
  };
  //update
  ///add
  const addData = async (values) => {
    // console.log(values, "values");
    setLoading(true);
    try {
      const res = await CS.ADD(values, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          getStandardChaletList();
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAdd = async () => {
    if (window.confirm("Are you sure you want to add this data?")) {
      if (data.title !== "") {
        setValidate(false);
        var req = {
          title: data.title,
          isPurged: false,
        };
        addData(req);
        setLoading(false);
        setRefreshAdd(!refreshAdd);
        toast.success(`New Standard Chalet Created : ${req.title} .Please update the Standard chalets on the 'Business days' page.`);
        navigate("/standard-chalet");
      } else {
        setValidate(true);
      }
    }
  };
  ///add
  //get
  const getStandardChaletList = async () => {
    try {
      const res = await CS.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch standard chalet information");
      }
      setStandardChalet(res.data);
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const getSearchStandardChaletList = async (title) => {
    try {
      const res = await CS.SEARCH(title);
      if (res.status !== 200) {
        throw new Error("Failed to fetch standard chalet information");
      }
      setStandardChalet(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //get
  //useEffect
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (searchVal === "") {
      getStandardChaletList();
    } else {
      getSearchStandardChaletList(searchVal);
    }
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);
  //useEffect

  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Standard Chalet" />
      </div>
      {!isEdit && (
        <div className="rounded-sm mb-5 border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <AddStandard
            data={data}
            setData={setData}
            loading={loading}
            handleAdd={handleAdd}
            validate={validate}
          />
        </div>
      )}
      {isEdit && (
        <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <UpdateStandard
            data={data}
            setData={setData}
            handleUpdate={handleUpdate}
            loading={loading}
            backToAdd={backToAdd}
          />
        </div>
      )}
      <div ref={tableSessionRef}>
        <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <TableStandard
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            currentItems={currentItems}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
          />
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            array={standardChalet}
            setItemOffset={setItemOffset}
            scrollToSection={scrollToTableSection}
            pageCount={pageCount}
          />
        </div>
      </div>
    </div>
  );
}
