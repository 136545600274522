import React from 'react'
import COMING from "../../../assets/coming.png";
import PARKHUMAN from "../../../assets/human.png";
import { formatNumb } from '../../../utils/FormatNumb';
export default function CardBook(
  { data, showPayment, selectedChaletsPc, selectedChaletsNc, selectedChaletsDc }
) {

  return (
    <div className="bg-white w-full lg:h-[615px] md:h-full rounded-2xl shadow-md flex flex-col justify-between">
      <div className='flex flex-col justify-between h-full'>
        <div className=''>
          <div className=" w-full flex justify-center items-center relative">
            <img
              src={data.img}
              alt="images"
              style={{ width: "100%" }}
              className=" object-contain"
            />
            {data.isBlocked && (
              <div className=" h-full w-full absolute flex justify-center items-center">
                <img
                  src={COMING}
                  alt="images"
                  className=" object-contain w-[70%] "
                />
              </div>
            )}
          </div>
          {/* top */}
          <div className="w-full h-auto flex justify-center p-3">
            <div className="w-[80%] h-auto">
              <p className="font-poppins text-primary font-thin text-sm lg:text-xs xl:text-sm leading-none">
                {data.park}
              </p>
              <p className="text-primary font-bold font-mont text-xl lg:text-base xl:text-2xl leading-none">
                {data.title}
              </p>
              <p className=" text-xs lg:text-[8px] xl:text-xs font-mont font-thin text-[#9C9C9C] ">
                {data.subTxt}
              </p>
            </div>
            <div className="w-[20%] flex justify-center items-center">
              <img
                src={data.icon}
                alt="images"
                style={{ width: "70%" }}
                className=" object-contain"
              />
            </div>
          </div>
          <div className="w-full h-auto grid mt-3 grid-cols-3 px-3">
            <div className=" gap-1 flex col-span-2 items-center justify-center">
              <div className="flex justify-center items-center w-[15%]">
                <img
                  src={data.iconTp}
                  alt="images"
                  className=" object-contain w-full"
                />

              </div>
              <div className="h-auto  w-[85%]">
                <p className="font-bold text-sm lg:text-xs xl:text-sm font-mont leading-none">
                  {data.subTxtTp}
                </p>
                <div className="w-full">
                  <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin leading-none">
                    (KWD {formatNumb(data.priceTp)})
                  </p>
                </div>
              </div>
            </div>
            {!data.isButton && data.subTxtTp === "Guest" ?
              <div className="  flex justify-center items-center col-span-1">
                <div className="flex justify-center items-center">
                  <RemoveBtn showPayment={showPayment} decrement={data.decrementCounterTp} />
                </div>
                <div className="flex justify-center items-center px-2 lg:px-1 xl:px-3">
                  <p className="text-poppins font-mont font-bold  text-lg lg:text-base xl:text-lg">
                    {data.counterTp}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <AddBtn showPayment={showPayment} increment={data.incrementCounterTp} />
                </div>
              </div>
              : <>
                <div className='w-full h-full'>
                  <button onClick={() => {
                    showPayment &&
                      data.toggleTp()
                  }} className='w-full h-auto bg-primary text-white font-poppins  text-[10px] px-1 py-1  cursor-pointer'>SELECT CHALET</button>

                </div>
                {(selectedChaletsPc !== undefined && selectedChaletsPc.length > 0) ||
                  (selectedChaletsDc !== undefined && selectedChaletsDc.length > 0) ? (
                  <div className=' h-auto w-full col-span-3'>
                    <div className='w-full flex justify-end'>
                      <hr className="border-t border-dashed my-1 w-[90%]"></hr>
                    </div>
                    <p className='text-xs px-8  font-semibold'>Selected Chalet</p>
                    <div className='w-full grid grid-cols-5 px-8'>
                      {selectedChaletsPc && selectedChaletsPc.map((item) => (
                        <p key={item} className='text-xs italic'>{item}</p>
                      ))}
                      {selectedChaletsDc && selectedChaletsDc.map((item) => (
                        <p key={item} className='text-xs italic'>{item}</p>
                      ))}
                    </div>
                  </div>
                ) : null}

              </>
            }
            <div className='w-full col-span-3'>
              <hr className="border-t border-dashed mt-3 w-full col-span-3"></hr>
            </div>
          </div>

          {/* Specially Abled && Normal Chalet Standared */}


          {data.subTxtMd === "" ? (<div></div>) : (
            <div className="w-full h-auto grid mt-3 grid-cols-3 px-3">
              <div className="gap-1 flex col-span-2 items-center justify-center">
                <div className="flex justify-center items-center w-[15%]">
                  <img
                    src={data.iconMd}
                    alt="images"
                    className=" object-contain w-full"
                  />
                </div>
                <div className="h-auto  w-[85%]">
                  <p className="font-bold text-sm lg:text-xs xl:text-sm font-mont leading-none">
                    {data.subTxtMd}
                  </p>
                  <div className="w-full">
                    <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin leading-none">
                      (KWD {formatNumb(data.priceMd)})
                    </p>
                  </div>

                </div>
              </div>

              {!data.isButton ?
                <div className=" flex justify-center items-center col-span-1">
                  <div className="flex justify-center items-center">
                    <RemoveBtn showPayment={showPayment} decrement={data.decrementCounterMd} />
                  </div>
                  <div className="flex justify-center items-center px-2 lg:px-1 xl:px-3">
                    <p className="text-poppins font-mont font-bold  text-lg lg:text-base xl:text-lg">
                      {data.counterMd}
                    </p>
                  </div>
                  <div className="flex justify-center items-center">
                    <AddBtn showPayment={showPayment} increment={data.incrementCounterMd} />
                  </div>
                </div>
                : <>
                  <div className='w-full h-full'>
                    <button onClick={() => {
                      showPayment &&
                        data.toggleMd()
                    }} className='w-full h-auto bg-primary text-white font-poppins  text-[10px] px-1 py-1  cursor-pointer' >SELECT CHALET</button>
                  </div>
                  {selectedChaletsNc !== undefined && selectedChaletsNc.length > 0 &&
                    <div className=' h-auto w-full col-span-3'>
                      <div className='w-full flex justify-end'>
                        <hr className="border-t border-dashed my-1 w-[90%]"></hr>
                      </div>
                      <p className='text-xs px-8  font-semibold'>Selected Chalet</p>
                      <div className=' w-full  grid grid-cols-5 px-8 '>
                        {selectedChaletsNc.map((item) => (
                          <p className='text-xs italic'>{item}</p>
                        ))}
                      </div>
                    </div>
                  }
                </>
              }
              <div className='w-full col-span-3'>
                <hr className="border-t border-dashed mt-3 w-full col-span-3"></hr>
              </div>
            </div>
          )
          }



          {/* Nanny */}
          {data.subTxtBt === "" ? (<div />) : (
            <div className="w-full h-auto grid mt-3 grid-cols-3 px-3">
              <div className="gap-1 flex col-span-2 items-center justify-center">
                <div className="flex justify-center items-center w-[15%]">
                  <img
                    src={data.iconBt}
                    alt="images"
                    className=" object-contain w-full"
                  />
                </div>
                <div className="h-auto  w-[85%]">
                  <p className="font-bold text-sm lg:text-xs xl:text-sm font-mont leading-none">
                    {data.subTxtBt}
                  </p>
                  <div className="w-full">
                    <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin leading-none">
                      (KWD {formatNumb(data.priceBt)})
                    </p>
                  </div>

                </div>
              </div>

              <div className="flex justify-center items-center col-span-1">
                <div className="flex justify-center items-center">
                  <RemoveBtn showPayment={showPayment} decrement={data.decrementCounterBt} />
                </div>
                <div className="flex justify-center items-center px-2 lg:px-1 xl:px-3">
                  <p className="text-poppins font-mont font-bold  text-lg lg:text-base xl:text-lg">
                    {data.counterBt}
                  </p>
                </div>
                <div className="flex justify-center items-center">
                  <AddBtn showPayment={showPayment} increment={data.incrementCounterBt} />
                </div>
              </div>
              <div className='w-full col-span-3'>
                <hr className="border-t border-dashed mt-3 w-full col-span-3"></hr>
              </div>
            </div>
          )}
        </div>
        <div className='p-3'>
          {/* <div className='w-full col-span-3'>
            <hr className="border-t border-dashed mt-3 w-full col-span-3"></hr>
          </div> */}
          <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin leading-none">
            {data.miniTxt1}
          </p>
          <p className="font-mont text-xs lg:text-[8px] xl:text-xs font-thin leading-none" style={{ color: data.txtColor }}>
            {data.miniTxt2}
          </p>
        </div>
      </div>
    </div>
  )
}

const AddBtn = ({ increment, showPayment }) => {
  return (
    <svg
      onClick={() => {
        if (showPayment) {
          increment();
        }
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className="h-5 w-5"
    >
      <g
        id="Group_79"
        data-name="Group 79"
        transform="translate(-1369 -1167)"
      >
        <rect
          id="Rectangle_7"
          data-name="Rectangle 7"
          width="22"
          height="22"
          rx="11"
          transform="translate(1369 1167)"
          fill="#0e6097"
        />
        <rect
          id="Rectangle_8"
          data-name="Rectangle 8"
          width="12"
          height="2"
          rx="1"
          transform="translate(1374 1177)"
          fill="#fff"
        />
        <rect
          id="Rectangle_9"
          data-name="Rectangle 9"
          width="12"
          height="2"
          rx="1"
          transform="translate(1381 1172) rotate(90)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
const RemoveBtn = ({ decrement, showPayment }) => {
  return (
    <svg
      onClick={() => {
        if (showPayment) {
          decrement();
        }
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className="h-5 w-5"
    >
      <g
        id="Group_80"
        data-name="Group 80"
        transform="translate(-1263 -1167)"
      >
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="22"
          height="22"
          rx="11"
          transform="translate(1263 1167)"
          fill="#0e6097"
        />
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="12"
          height="2"
          rx="1"
          transform="translate(1268 1177)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
