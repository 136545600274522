import { BlockIcon } from "../../assets/icons/BlockIcon";
import { BookingIcon } from "../../assets/icons/BookingIcon";
import { CalenderIcon } from "../../assets/icons/CalendarIcon";
import { DashboardIcon } from "../../assets/icons/DashboardIcon";
import { EmployeeIcon } from "../../assets/icons/EmployeeIcon";
import { EventsIcon } from "../../assets/icons/EventsIcon";
import { HutIcon } from "../../assets/icons/HutIcon";
import { MoneyIcon } from "../../assets/icons/MoneyIcon";
import { OfferIcon } from "../../assets/icons/OfferIcon";
import { SliderIcon } from "../../assets/icons/SliderIcon";
import { TentIcon } from "../../assets/icons/TentIcon";

export const sidebarData = [
  {
    id: 1,
    mainTitle: "MENU",
    users: [0, 1, 2],
    menuOptions: [
      {
        id: 1,
        isSecured: false,
        users: [0, 1, 2],
        icon: <DashboardIcon />,
        title: "Dashboard",
        route: "/dashboard",
        isDrop: false,
      },

      {
        id: 2,
        isSecured: false,
        users: [0, 1, 2],
        icon: <BookingIcon />,
        title: "Add Booking",
        route: "/booking/add",
        isDrop: false,
      },
    ],
  },
  {
    id: 2,
    mainTitle: "LIST",
    users: [0, 1],
    menuOptions: [
      {
        id: 0,
        isSecured: false,
        users: [0, 1, 2],
        icon: <BookingIcon />,
        title: "Bookings",
        route: "/booking",
        isDrop: false,
      },
      {
        id: 1,
        isSecured: false,
        users: [0, 1, 2],
        icon: <BookingIcon />,
        title: "Old Bookings",
        route: "/old-booking",
        isDrop: false,
      },

      // {
      //   id: 2,
      //   isSecured: false,
      //   users: [0,1],
      //   icon: <EmployeeIcon />,
      //   title: "Booking Type",
      //   route: "/booking-type",
      //   isDrop: false,
      // },
      {
        id: 2,
        isSecured: false,
        users: [0],
        icon: <CalenderIcon />,
        title: "Business Days",
        route: "/business-days",
        isDrop: false,
      },
      {
        id: 3,
        isSecured: false,
        users: [0, 1],
        icon: <TentIcon />,
        title: "Deluxe Chalet",
        route: "/deluxe-chalet",
        isDrop: false,
      },
      {
        id: 4,
        isSecured: false,
        users: [0, 1],
        icon: <HutIcon />,
        title: "Standard Chalet",
        route: "/standard-chalet",
        isDrop: false,
      },
      {
        id: 5,
        isSecured: false,
        users: [0, 1],
        icon: <HutIcon />,
        title: "Premium Standard",
        route: "/premium-standard",
        isDrop: false,
      },
      {
        id: 6,
        isSecured: false,
        users: [0, 1],
        icon: <SliderIcon />,
        title: "Slider",
        route: "/manage-slider",
        isDrop: false,
      },

      // {
      //   id: 6,
      //   isSecured: true,
      //   users: [0],
      //   icon: <EmployeeIcon />,
      //   title: "Rides",
      //   route: "/rides",
      //   isDrop: true,
      //   subMenuOptions: [
      //     {
      //       id: 1,
      //       title: "Ride List",
      //       route: "/rideList",
      //     },
      //     {
      //       id: 2,
      //       title: "Add New Ride",
      //       route: "/rideAdd",
      //     },
      //   ],
      // },

      {
        id: 7,
        isSecured: true,
        users: [0, 1],
        icon: <EventsIcon />,
        title: "Events",
        route: "/events",
        isDrop: true,
        subMenuOptions: [
          {
            id: 1,
            title: "Events List",
            route: "/events",
          },
          {
            id: 2,
            title: "Add New Event",
            route: "/events/add",
          },
        ],
      },
      {
        id: 8,
        isSecured: true,
        users: [0, 1],
        icon: <OfferIcon />,
        title: "Offers",
        route: "/offers",
        isDrop: true,
        subMenuOptions: [
          {
            id: 1,
            title: "Offers List",
            route: "/offers",
          },
          {
            id: 2,
            title: "Add New Offer",
            route: "/offers/add",
          },
        ],
      },
      {
        id: 9,
        isSecured: true,
        users: [0, 1],
        icon: <EmployeeIcon />,
        title: "Contact",
        route: "/contact",
        isDrop: false,
      },
      {
        id: 10,
        isSecured: false,
        users: [0, 1],
        icon: <MoneyIcon />,
        title: "Payment Details",
        route: "/payment",
        isDrop: false,
      },
      {
        id: 11,
        isSecured: false,
        users: [0, 1],
        icon: <BlockIcon />,
        title: "Block Chalets",
        route: "/block-chalets",
        isDrop: false,
      },
     {
        id: 12,
        isSecured: true,
        users: [0, 1],
        icon: <OfferIcon />,
        title: "Report",
        route: "/report",
        isDrop: true,
        subMenuOptions: [
          {
            id: 1,
            title: "Summary Report",
            route: "/report",
          },
          {
            id: 2,
            title: "Detailed Report",
            route: "/detail-report",
          },
        ],
      },
      // {
      //   id: 12,
      //   isSecured: false,
      //   users: [0, 1],
      //   icon: <EmployeeIcon />,
      //   title: "Report",
      //   route: "/report",
      //   isDrop: false,
      // },
      // {
      //   id: 9,
      //   isSecured: true,
      //   users: [0],
      //   icon: <EmployeeIcon />,
      //   title: "Free Tickets",
      //   route: "/manage-ticket",
      //   isDrop: false,
      // },
    ],
  },
  {
    id: 3,
    mainTitle: "OTHERS",
    users: [0, 1, 2],
    menuOptions: [
      {
        id: 1,
        isSecured: true,
        users: [0],
        icon: <EmployeeIcon />,
        title: "Admin",
        route: "/admin",
        isDrop: true,
        subMenuOptions: [
          {
            id: 1,
            title: "Admin List",
            route: "/admin",
          },
          {
            id: 2,
            title: "Add New Admin",
            route: "/admin/add",
          },
        ],
      },
      {
        id: 2,
        isSecured: false,
        users: [0, 1, 2],
        icon: <EmployeeIcon />,
        title: "Profile",
        route: "/profile",
        isDrop: false,
      },
    ],
  },
];
