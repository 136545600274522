import React, { useEffect } from "react";
import POOL from "../../assets/video/parkpool.mp4";
import IN from "../../../src/assets/in.png";
import FB from "../../../src/assets/fb.png";
import TWITTER from "../../../src/assets/twitter.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLanguage from "../../utils/useLanguage";

export default function Hero() {
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationTex1 = {
    textHide: { opacity: 0, x: -400 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  // console.log("i18n", i18n.language)
  return (
    <div ref={txtRef} className="h-screen  w-full">
      <div className="h-full w-full  relative flex justify-center">
        <div className="absolute bg-back w-full h-full opacity-40"></div>
        <video className="object-cover w-[100%] h-screen" loop autoPlay muted>
          <source src={POOL} type="Video/mp4" />
        </video>
        <div className=" h-auto w-[80%] absolute flex justify-center items-center flex-col bottom-0 ">
          {i18n.language === "en" ?
            <motion.h1
              initial="hidden"
              animate={controls}
              variants={animationTex1}
              className="text-text mb-2 md:text-6xl font-thin font-mont w-full text-3xl"
            >
              STEP INTO A <br></br>
              <span className="font-extrabold">WORLD OF MAGIC ,</span>
              <span>WHERE</span>
              <br></br>
              <span className="font-extrabold">JOY SPRINGS TO LIFE !</span>
            </motion.h1> :
            <motion.h1
              initial="hidden"
              animate={controls}
              variants={animationTex1}
              className="text-text mb-2 md:text-6xl font-thin font-mont w-full text-3xl"
            >
              خطوة إلى أ <br></br>
              <span className="font-extrabold">عالم السحر، </span>
              <span>حيث</span>
              <br></br>
              <span className="font-extrabold">ينابيع الفرح في الحياة!</span>
            </motion.h1>
          }

          <div className=" w-full rounded-md flex justify-between items-center">
            <div className="h-auto p-4 my-2">
              <button
                onClick={() => {
                  navigate("/book-now");
                }}
                className="bg-yellow rounded-full text-black font-black text-xl px-10 py-3 font-poppins animate-customBounce hover:animate-none"
              >
                {t('book_now')}
              </button>
            </div>
          </div>
          <div className="h-auto flex ">
            <p className="text-white text-lg font-thin font-mont pb-3 mt-2">
              © {t('aqua_copy')}
            </p>
            <div className=" lg:w-45 w-auto h-auto flex ml-3 ">
              <div className=" w-10 h-10  flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  className="w-[25px] h-[25px]"
                  fill="#FFE45C"
                >
                  <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z" />
                </svg>
              </div>
              <div className=" h-10 w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  className="w-[20px] h-[20px]"
                  fill="#FFE45C"
                >
                  {" "}
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z" />
                </svg>
              </div>
              <div className=" h-10 w-10 flex justify-center items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 50 50"
                  className="w-[20px] h-[20px]"
                  fill="#FFE45C"
                >
                  {" "}
                  <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                </svg>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
