import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper/modules";
// import FRIEND from "../../assets/friend.png";
import FRIEND from "../../assets/ride1.png";
// import RIDE from "../../assets/ride2.png";
import YELLOW from "../../assets/yellow.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "swiper/css/pagination";

export default function Slide() {
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationTex1 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const animationTex2 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.2 } },
  };
  const animationTex3 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.4 } },
  };
  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);

  const array = [
    {
      id: 0,
      image: FRIEND,
      image2: YELLOW,
    },

    {
      id: 1,
      // img: "https://static.toiimg.com/photo/58572129.cms"
      image: FRIEND,
      image2: YELLOW,
    },
  ];
  return (
    <div
      ref={txtRef}
      className=" h-full w-full flex justify-center items-center md:p-20 p-10"
    >
      <div className="w-full h-auto flex items-center   ">
        <Swiper
          style={{
            "--swiper-navigation-color": "#7B899C",
          }}
          spaceBetween={20}
          // loop={true}
          // autoplay={{
          //     delay: 4000,
          //     disableOnInteraction: false,
          // }}
          // loop={true}
          // autoplay={{ delay: 2000 }}
          // speed={2000}
          slidesPerView={1}
          // breakpoints={
          //     {
          //         576: {
          //             slidesPerView: 1,
          //         },
          //         1000: {
          //             slidesPerView: 5,
          //         },

          //     }}
          grabCursor={true}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 1,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          modules={[EffectCoverflow, Autoplay]}
          className="mySwiper"
        >
          {array.map((data) => (
            <SwiperSlide key={data._id}>
              <div className=" w-full h-full flex justify-center items-center  ">
                <div className="relative  w-auto h-[70%] flex items-center justify-center rounded-xl shadow-lg ">
                  <div className=" w-auto h-auto">
                    <img
                      src={data.image}
                      alt="images"
                      className=" object-cover  rounded"
                    />
                  </div>
                  <div className="absolute left-0 bottom-0  h-full w-full">
                    <img
                      src={data.image2}
                      alt="images"
                      className=" object-co z-99 rounded-b-lg md:rounded-b-3xl w-full h-full "
                    />
                  </div>
                  <div className=" p-1 absolute bottom-0 left-0 flex justify-center items-center md:p-2 xl:p-8">
                    <p className="text-black xl:leading-10  sm:leading-5 leading-3  ">
                      <motion.p
                        initial="hidden"
                        animate={controls}
                        variants={animationTex1}
                      >
                        <span className="2xl:text-[45px] xl:text-[35px] font-mont font-thin md:text-[25px] text-[10px] ">
                          Slide into another year of fun!
                        </span>
                        <br></br>
                      </motion.p>
                      <motion.p
                        initial="hidden"
                        animate={controls}
                        variants={animationTex2}
                      >
                        <span className="2xl:text-[45px] xl:text-[30px] font-extrabold font-mont md:text-[20px] sm:text-[10px] text-[8px]">CELEBRATE </span >
                        <span className="2xl:text-[35px] xl:text-[27px] font-thin font-mont md:text-[17px] text-[12px]">your birthday</span>
                        <span className="2xl:text-[45px] xl:text-[30px] font-extrabold font-mont md:text-[22px] text-[10px]"> AT AQUAMAGIC
                        </span>

                        <br></br>
                      </motion.p>
                      <motion.p
                        initial="hidden"
                        animate={controls}
                        variants={animationTex3}
                      >
                        {" "}
                        <span className="2xl:text-[38px] xl:text-[35px] font-mont font-thin md:text-[25px] text-[10px]">
                          and <span className="2xl:text-[45px] xl:text-[35px] font-extrabold font-poppins md:text-[25px] text-[8px]"> MAKE IT UNFORGETTABLE!</span>
                        </span>
                      </motion.p>
                      <motion.p
                        initial="hidden"
                        animate={controls}
                        variants={animationTex3}
                      >
                        {" "}
                        <span className="2xl:text-[28px] xl:text-[20px] font-mont font-extralight md:text-[20px] text-[10px]">
                          Call us now to book your reservations 99573992
                        </span>
                      </motion.p>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
          }
        </Swiper >
      </div >
    </div >
  );
}
