import React from "react";
import { Route, Routes } from "react-router-dom";
import NoPage from "../pages/noPage/NoPage";
import AdminLogin from "../pages/unAuth/adminLogin/AdminLogin";
import ServerTimeout from "../pages/unAuth/serverTimeout/ServerTimeout";
import BookingDetails from "../pages/unAuth/bookingDetails/BookingDetails";
import Home from "../pages/unAuth/home/Home";
import IndividualDetails from "../pages/unAuth/individualDetails/IndividualDetails";
import BookNow from "../pages/unAuth/bookNow/BookNow";
import PrimaryLayout from "./PrimaryLayout";
import SecondaryLayout from "./SecondaryLayout";
import About from "../pages/unAuth/about/About";
import Contact from "../pages/unAuth/contact/Contact";
import UnderDev from "../pages/noPage/UnderDev";
import Book from "../pages/unAuth/bookNow/Book";
import Ticket from "../pages/unAuth/bookNow/Ticket"; 
import PaymentSuccess from "../pages/unAuth/payment/PaymentSuccess";

import TicketTest from "../pages/unAuth/newbook/Ticket";
import BookTest from "../pages/unAuth/newbook/Book";
import Terms from "../pages/unAuth/terms/Terms";
import Test from "../pages/unAuth/contact/Test";

export default function UnAuthLayout() {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <PrimaryLayout>
              <Home />
            </PrimaryLayout>
          }
        />
        <Route path="admin-login" element={<AdminLogin />} />
        <Route
          path="home"
          element={
            <PrimaryLayout>
              <Home />
            </PrimaryLayout>
          }
        />
        <Route
          path="/contact"
          element={
            <SecondaryLayout>
              {/* <Test /> */}
              <Contact />
            </SecondaryLayout>
          }
        />
        <Route
          path="/terms-and-condetions"
          element={
            <SecondaryLayout>
              <Terms />
            </SecondaryLayout>
          }
        />
        <Route
          path="/payment-success"
          element={
            <SecondaryLayout>
              <PaymentSuccess />
            </SecondaryLayout>
          }
        />
        <Route
          path="/news"
          element={
            <SecondaryLayout>
              <UnderDev />
            </SecondaryLayout>
          }
        />
        <Route
          path="/faq"
          element={
            <SecondaryLayout>
              <UnderDev />
            </SecondaryLayout>
          }
        />
        <Route
          path="/about"
          element={
            <SecondaryLayout>
              <About />
            </SecondaryLayout>
          }
        />
        {/* <Route
          path="booking-details/date/:date/pnr/:pnr"
          element={
            <SecondaryLayout>
              <BookingDetails />
            </SecondaryLayout>
          }
        /> */}
        <Route
          // path="/ticket"
          path="booking-details/date/:date/pnr/:pnr"
          element={
            <SecondaryLayout>
              <Ticket />
            </SecondaryLayout>
          }
        />

    
        <Route
          path="book-now"
          element={
            <SecondaryLayout>
              {/* <BookNow /> */}
              <Book />
            </SecondaryLayout>
          }
        />

        {/* <Route
          path="/newbook"
          element={
            <SecondaryLayout>
              <Book />
            </SecondaryLayout>
          }
        /> */}

        <Route
          path="booking-details/date/:date/pnr/:pnr/identity/:id"
          element={
            <SecondaryLayout>
              <IndividualDetails />
            </SecondaryLayout>
          }
        />
        <Route path="server-timeout" element={<ServerTimeout />} />
        <Route
          path="*"
          element={
            <SecondaryLayout>
              <NoPage />
            </SecondaryLayout>
          }
        />
      </Route>
    </Routes>
  );
}
