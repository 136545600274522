import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { WORKING_HOURS } from "../../../api/workingHours";
import { useNavigate } from "react-router-dom";
import { formatDateString } from "../../../utils/FormatDateString";

export default function Booking() {
  const [searchVal, setSearchVal] = useState("");
  const [emp, setEmp] = useState([]);
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  emp.sort((a, b) => {
    const dateA = a.date.split("T")[0];
    const dateB = b.date.split("T")[0];

    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  // console.log("emp", emp);
  const topSessionRef = useRef(null);
  const navigate = useNavigate();
  const handleEdit = (item) => {
    // console.log("data", item);
    navigate(`/booking/user/${item.date}`);
  };

  //get
  const getEmpList = async () => {
    try {
      const res = await WORKING_HOURS.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
      console.log("dcsdvcdfvd", res.data)
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (name) => {
    try {
      const res = await WORKING_HOURS.SEARCH(name);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [searchVal]);
  //get
  const tableSessionRef = useRef(null);
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Bookings" />
      </div>

      <div
        ref={tableSessionRef}
        className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
      >
        <div className="max-w-full overflow-x-auto">
          <input
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            value={searchVal}
            type="text"
            className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="Search by booking date..."
          />

          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                  Date
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  One day Bookings
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Deluxe Chalet Bookings
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Premium Standard Bookings
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Standard Chalet Bookings
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems !== undefined && (
                <>
                  {currentItems.map((data) => (
                    <tr key={data._id}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {formatDateString(data.date)}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white ">
                          {data.odt.bookingToday}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.cd.bookingToday}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.ps !== undefined ? data.ps.bookingToday : 0}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.cs.bookingToday}
                        </h5>
                      </td>

                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(data)}
                          >
                            View Bookings
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          array={emp}
          setItemOffset={setItemOffset}
          scrollToSection={scrollToTableSection}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
