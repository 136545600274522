import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const DC = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}deluxe-chalet`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id) {
    const url = `${CONSTANT.URL}deluxe-chalet/${id}`;
    return API_REQUEST("PUT", url, req);
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}deluxe-chalet/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}deluxe-chalet/search`;
    return API_REQUEST("GET", url);
  },

  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}deluxe-chalet/${id}`;
    return API_REQUEST("GET", url);
  },

  SEARCH(title) {
    const url = `${CONSTANT.URL}deluxe-chalet/search/${title}`;
    return API_REQUEST("GET", url);
  },
};
