import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { useNavigate, useParams } from "react-router-dom";
import { BOOKING } from "../../../api/booking";

export default function UsersList() {
  let { date } = useParams();
  const navigate = useNavigate();
  //states
  const [emp, setEmp] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  //states
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const tableSessionRef = useRef(null);
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  emp.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const topSessionRef = useRef(null);
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const handleEdit = (id) => {
    // console.log("data", id);
    navigate(`/booking/user-details/${id}`);
  };
  //get
  const getEmpList = async (date) => {
    try {
      const res = await BOOKING.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (date, pnr) => {
    try {
      const res = await BOOKING.SEARCH(date, pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    if (searchVal === "") {
      getEmpList(date);
    } else getSearchEmpList(date, searchVal);
  }, [searchVal, date]);
  //get

  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="User List" />
      </div>

      <div
        ref={tableSessionRef}
        className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
      >
        <div className="max-w-full overflow-x-auto">
          <input
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            value={searchVal}
            type="text"
            className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="Search by pnr number..."
          />
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                {/* <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Type
                </th> */}
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Name
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  One Day Ticket
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Chalet Deluxe
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Premium Standard
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Chalet Standard
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Free Tickets
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Nanny
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Specially Abled
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Type
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Status
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems !== undefined && (
                <>
                  {currentItems.map((data) => (
                    <tr key={data._id}>
                      {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.type === 0 && "One Day Ticket"}
                          {data.type === 1 && "Deluxe Chalet"}
                          {data.type === 2 && "Standard Chalet"}
                          {data.type === 3 && "Free Ticket from Deluxe"}
                          {data.type === 4 && "Free Ticket from Standard"}
                        </h5>
                      </td> */}
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.name}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.odtNo}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.cdNo}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.psNo !== undefined
                            ? data.groupSize.psNo
                            : 0}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.csNo}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                        {data.groupSize.cdFree +
                            data.groupSize.scFree +
                            (data.groupSize.psFree !== undefined
                              ? data.groupSize.psFree
                              : 0)+(data.groupSize.odtFree !== undefined
                                ? data.groupSize.odtFree
                                : 0)}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.mdNo !== undefined
                            ? data.groupSize.mdNo
                            : 0}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.groupSize.saNo !== undefined
                            ? data.groupSize.saNo
                            : 0}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        {/* {data.payment.paymentType === "Cash" ? (
                          <h5 className="text-primary dark:text-primary font-bold">
                            Offline
                          </h5>
                        ) : ( */}
                          <h5 className="text-success dark:text-success font-bold">
                            {data.payment.paymentType}
                          </h5>
                        {/* // )} */}
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        {data.isScanned ? (
                          <h5 className="text-primary dark:text-primary font-bold">
                            Scanned
                          </h5>
                        ) : (
                          <h5 className="text-red dark:text-red font-bold">
                            Not Scanned
                          </h5>
                        )}
                      </td>

                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(data._id)}
                          >
                            View Details
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          array={emp}
          scrollToSection={scrollToTableSection}
          setItemOffset={setItemOffset}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
