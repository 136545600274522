import React from 'react'

export default function TableBlock(
  { handleEdit, setSearchVal, searchVal, formatDateString, currentItems }
) {
  return (
    <div className="max-w-full overflow-x-auto">
      <input
        onChange={(e) => {
          setSearchVal(e.target.value);
        }}
        value={searchVal}
        type="text"
        className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
        placeholder="Search by booking date..."
      />

      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-2 text-left dark:bg-meta-4">
            <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
              Date
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              Blocked Deluxe Chalet
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              Blocked Standard Chalet
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              Blocked Premium Standard Chalet
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems !== undefined && (
            <>
              {currentItems.map((data) => (
                <tr key={data._id}>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      {formatDateString(data.date)}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {data.blockedCD.length} Chalets Blocked
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {data.blockedCS.length} Chalets Blocked
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {data.blockedPS.length} Chalets Blocked
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <div className="flex items-center space-x-3.5">
                      <button
                        className="hover:text-primary"
                        onClick={() => handleEdit(data)}
                      >
                        Unblock Chalets
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}
