export const EmployeeIcon = () => {
  return (
    <svg
      className="fill-current"
      width="18"
      height="18"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M38,28.9a.94.94,0,0,0-.28-.67l-6.63-6.63a.92.92,0,0,0-.26-.17V4.54a.93.93,0,0,0-.93-.93h-27A.93.93,0,0,0,2,4.54V40a.93.93,0,0,0,.93.93H27.26l3.45,3.44h2.67l-3.7-3.7a2.11,2.11,0,0,0,1.1-1.37,1,1,0,0,0,.19-.15.94.94,0,0,0,0-1.33L25.2,32.06a1.1,1.1,0,0,1,0-1.56,1.06,1.06,0,0,1,.61-.27l.06,0a1.09,1.09,0,0,1,.89.29L29.22,33h0l1.36,1.36A.94.94,0,1,0,31.92,33l-1.09-1.09V24l5.27,5.27v7.47a.94.94,0,0,0,.28.67l6.95,6.95H46l-8-8Zm-9,1.12-.85-.85a3,3,0,0,0-1.49-.8V8.16H6.36v26H24.67l4.05,4.06.28.28a.9.9,0,0,0,0,.3.23.23,0,0,1-.23.23H4.12a.23.23,0,0,1-.23-.23V5.73a.23.23,0,0,1,.23-.23h24.6a.23.23,0,0,1,.23.23ZM9.46,26.18V24.32h14v1.86Zm7.44,2.42v1.86H9.46V28.6Zm-7.44-6.7V20h14v1.86Zm0-4.28V15.77h14v1.86Zm0-4.28V11.49h14v1.86ZM15,37v-.32a.61.61,0,0,1,.65-.57h1.49a.61.61,0,0,1,.65.57V37a.61.61,0,0,1-.65.58H15.67A.61.61,0,0,1,15,37Z" />
    </svg>
  );
};