import React from "react";
import Intro from "../../../components/home/Intro";
import Hero from "../../../components/home/Hero";
import Slide from "../../../components/home/Slide";
import Events from "../../../components/home/Events";
import Offer from "../../../components/home/Offer";

export default function Home() {
  return (
    <div>
      <Hero />
      <Intro />
      <Slide />
      <Offer />
      <Events />
    </div>
  );
}
