import React from "react";
export default function UpdateBlock({
  backToAdd,
  data,
  loading,
  handleUpdate,
  handleCSUnBlock,
  handlePSUnBlock,
  Select,
  handleCDUnBlock,
  formatDateString,
}) {
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex flex-col">
            <div className="px-2 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Date <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {formatDateString(data.date)}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Booked Deluxe Chalets
              </label>
              <Select
                isMulti
                value={data.cdBlockArray}
                options={data.cdBlockArray}
                onChange={(e) => {
                  handleCDUnBlock(e);
                }}
                isOptionDisabled={() => true} // Disable all options
              />
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Booked Standard Chalet
              </label>
              <Select
                isMulti
                value={data.csBlockArray}
                options={data.csBlockArray}
                onChange={(e) => {
                  handleCSUnBlock(e);
                }}
                isOptionDisabled={() => true} // Disable all options
              />
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Booked Premium Standard Chalet
              </label>
              <Select
                isMulti
                value={data.psBlockArray}
                options={data.psBlockArray}
                onChange={(e) => {
                  handlePSUnBlock(e);
                }}
                isOptionDisabled={() => true} // Disable all options
              />
            </div>
          </div>

          <div className="flex flex-col h-auto sm:pt-7">
            <div className="px-2 py-3 h-auto">
              <button
                onClick={() => {
                  handleUpdate();
                }}
                type="submit"
                className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Update Blocked List"
                )}
              </button>
              <button
                onClick={() => {
                  backToAdd();
                }}
                type="button"
                className="flex w-full justify-center py-3 font-sm text-sm text-red"
              >
                Block New
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
