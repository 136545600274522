import React from 'react'

export default function GuestBook(
    {data ,validate ,showPayment ,isPhoneNumberValid ,setData ,isEmailValid}
) {
  return (
    <div className="bg-white  w-[100%] h-auto rounded-lg shadow-xl pt-5 mt-20 mb-10 flex flex-col gap-4 lg:w-[66%] p-10">
    <p className="text-3xl font-bold text-poppins text-center lg:text-left pt-5">
      Book as a Guest
    </p>
    <div className="w-full h-auto flex flex-col">
      <div className="flex flex-col gap-5  mb-[25px] lg:grid grid-cols-3">
        <div className="w-[100%] flex flex-col  col-span-1">
          <label className="w-[30%] block dark:text-white text-normal font-light font-mont pt-2 lg:w-[100%]">
            Name
          </label>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              className="w-full font-poppins font-bold rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
              value={data.name}
              disabled={showPayment ? false : true}
              onChange={(e) => {
                setData({
                  ...data,
                  name: e.target.value,
                });
                data.name = e.target.value;
              }}
              placeholder="Enter Your Name"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
              }}
            />
            {data.name === "" && validate && (
              <p className="text-red text-sm mt-1">* Required</p>
            )}
          </div>
        </div>
        <div className="w-[100%] flex flex-col col-span-1">
          <label className="w-[30%] block dark:text-white text-normal font-mont font-thin pt-2 lg:w-[100%]">
            Email
          </label>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              className="w-full font-poppins rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 font-bold outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
              value={data.email}
              disabled={showPayment ? false : true}
              onChange={(e) => {
                setData({
                  ...data,
                  email: e.target.value,
                });
                data.email = e.target.value;
              }}
              placeholder="Enter Your Email"
              
            />
            {data.email === "" && validate ? (
              <>
                <p className="text-red text-sm mt-1">* Required</p>
              </>
            ) : (
              <>
                {isEmailValid(data.email) === false && validate && (
                  <p className="text-red text-sm">
                    * Invalid email format
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-[100%] flex flex-col col-span-1">
          <label className="w-[30%] block dark:text-white text-normal font-mont font-thin pt-2 lg:w-[100%]">
            Phone no
          </label>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              className="w-full font-poppins rounded-md text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-2 px-5 font-bold outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary lg:w-[100%]"
              value={data.phoneNumber}
              disabled={showPayment ? false : true}
              onChange={(e) => {
                setData({
                  ...data,
                  phoneNumber: e.target.value,
                });
                data.phoneNumber = e.target.value;
              }}
              placeholder="Enter Your Phone No"
            />
            {data.phoneNumber === "" && validate ? (
              <p className="text-red text-sm mt-1">* Required</p>
            ) : (
              <>
                {isPhoneNumberValid(data.phoneNumber) === false &&
                  validate && (
                    <p className="text-red text-sm mt-1">
                      * Invalid phone number
                    </p>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
