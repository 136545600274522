import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { WORKING_HOURS } from "../../../api/workingHours";
import { formatDateString } from "../../../utils/FormatDateString";
import { DC } from "../../../api/dlxChalet";
import { CS } from "../../../api/stdChalet";
import Select from "react-select";
import { BLOCK } from "../../../api/block";
import AddBlock from "./AddBlock";
import UpdateBlock from "./UpdateBlock";
import TableBlock from "./TableBlock";
import { formatDate } from "../../../utils/ManagaeDate";
import { PS } from "../../../api/preStd";

export default function BlockChelet() {
  // states
  const [id, setId] = useState("");
  const [emp, setEmp] = useState([]);
  const [count, setCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [validate, setValidate] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [length, setLength] = useState({
    dlx: 0,
    std: 0,
    psd: 0,
    odt: 100,
  });
  const [selectVal, setSelectVal] = useState({
    dlxIni: [],
    stdIni: [],
    psdIni: [],
    dlx: [],
    std: [],
    psd: [],
    dlxVal: "",
    stdVal: "",
    psdVal: "",
  });
  const [data, setData] = useState({
    identity: "",
    date: "",
    working_hour: [],
    csBlock: "",
    cdBlock: "",
    psBlock: "",
    csBlockArray: [],
    cdBlockArray: [],
    psBlockArray: [],
    csBlockArrayIni: [],
    cdBlockArrayIni: [],
    psBlockArrayIni: [],
  });
  // states
  //constant variables
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const topSessionRef = useRef(null);
  const tableSessionRef = useRef(null);
  const { token } = useContext(TokenContext);
  const endOffset = itemOffset + itemsPerPage;
  const { checkAccessTokenValidity } = UseAuth();
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  // Get today's date in YYYY-MM-DD format for the min attribute
  const today = new Date().toISOString().split("T")[0];
  emp.sort((a, b) => {
    const dateA = a.date.split("T")[0];
    const dateB = b.date.split("T")[0];
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  //constant variables
  //dependent functions
  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };
  const clearData = () => {
    setData({
      ...data,
      date: "",

      startDate: "",
      endDate: "",
      odtPrice: 0,
      odtBookings: length.odt,
      cdPrice: 0,
      cdBookings: length.dlx,
      csPrice: 0,
      csBookings: length.std,
      isPurged: false,
      cdFree: 2,
      csFree: 1,
    });
  };
  function getDates(startDate, endDate) {
    const datesArray = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    while (currentDate <= lastDate) {
      datesArray.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return datesArray;
  }
  const handleEdit = (item) => {
    scrollToSection();
    const cdBlocked = [];
    const csBlocked = [];
    const psBlocked = [];
    // console.log("item", item);
    if (item.blockedCD.length > 0)
      item.blockedCD.map((itm) => {
        var val = { value: itm, label: itm };
        cdBlocked.push(val);
      });
    if (item.blockedCS.length > 0)
      item.blockedCS.map((itm) => {
        var val = { value: itm, label: itm };
        csBlocked.push(val);
      });
    if (item.blockedPS.length > 0)
      item.blockedPS.map((itm) => {
        var val = { value: itm, label: itm };
        psBlocked.push(val);
      });
    // console.log("cdBlock", cdBlocked);
    // console.log("csBlock", csBlocked);

    setData({
      ...data,
      date: item.date,
      csBlockArray: csBlocked,
      cdBlockArray: cdBlocked,
      psBlockArray: psBlocked,
      csBlockArrayIni: csBlocked,
      cdBlockArrayIni: cdBlocked,
      psBlockArrayIni: psBlocked,
      identity: item._id,
    });
    data.csBlockArray = csBlocked;
    data.cdBlockArray = cdBlocked;
    data.psBlockArray = psBlocked;
    data.csBlockArrayIni = csBlocked;
    data.cdBlockArrayIni = cdBlocked;
    data.psBlockArrayIni = psBlocked;
    data.identity = item._id;
    data.date = item.date;
    // console.log("data", data);
    setIsEdit(true);
    var arrayDlx = [];
    var arrayStd = [];
    var arrayPsd = [];
    setSelectVal({
      ...selectVal,
      dlxIni: arrayDlx,
      stdIni: arrayStd,
      psdIni: arrayPsd,
    });
    selectVal.dlxIni = arrayDlx;
    selectVal.stdIni = arrayStd;
    selectVal.psdIni = arrayPsd;
  };
  const getWorkingHourByDate = async (date) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      } else {
        return res.data;
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const handleDateChange = async (e) => {
    const selectedDate = e.target.value;
    const workingHour = await getWorkingHourByDate(selectedDate);
    setData({
      ...data,
      date: selectedDate,
      working_hour: workingHour,
    });
    data.date = selectedDate;
    data.working_hour = workingHour;
    if (workingHour.length > 0) {
      var csArray = [];
      var cdArray = [];
      var psArray = [];
      selectVal.std.map((itm) => {
        if (workingHour[0].bookedCS.includes(itm.value)) {
          console.log(`${itm} is already booked.`);
        } else {
          csArray.push(itm);
        }
      });
      selectVal.dlx.map((itm) => {
        if (workingHour[0].bookedCD.includes(itm.value)) {
          console.log(`${itm} is already booked.`);
        } else {
          cdArray.push(itm);
        }
      });
      selectVal.psd.map((itm) => {
        if (workingHour[0].bookedPS.includes(itm.value)) {
          console.log(`${itm} is already booked.`);
        } else {
          psArray.push(itm);
        }
      });
      setSelectVal({
        ...selectVal,
        stdIni: csArray,
        dlxIni: cdArray,
        psdIni: psArray,
      });
      selectVal.stdIni = csArray;
      selectVal.dlxIni = cdArray;
      selectVal.psdIni = psArray;
    } else {
      toast.info("There is no booking set for the selected day!");
    }
  };
  const handleCSBlock = (e) => {
    var csValue = {
      label: e.value,
      value: e.value,
    };
    setSelectVal({
      ...selectVal,
      stdVal: csValue,
    });
    selectVal.stdVal = csValue;
    setData({
      ...data,
      csBlock: e.value,
    });
    data.csBlock = e.value;
  };
  const handleCSUnBlock = (e) => {
    setData({
      ...data,
      csBlockArray: e,
    });
    data.csBlockArray = e;
  };
  const handleCDUnBlock = (e) => {
    setData({
      ...data,
      cdBlockArray: e,
    });
    data.cdBlockArray = e;
  };
  const handleCDBlock = (e) => {
    // console.log("e", e);
    var cdValue = {
      label: e.value,
      value: e.value,
    };
    setSelectVal({
      ...selectVal,
      dlxVal: cdValue,
    });
    selectVal.dlxVal = cdValue;
    setData({
      ...data,
      cdBlock: e.value,
    });
    data.cdBlock = e.value;
  };
  const handlePSUnBlock = (e) => {
    setData({
      ...data,
      psBlockArray: e,
    });
    data.psBlockArray = e;
  };
  const handlePSBlock = (e) => {
    // console.log("e", e);
    var psValue = {
      label: e.value,
      value: e.value,
    };
    setSelectVal({
      ...selectVal,
      psdVal: psValue,
    });
    selectVal.psdVal = psValue;
    setData({
      ...data,
      psBlock: e.value,
    });
    data.psBlock = e.value;
  };
  const fetchDeluxeChaletLength = async () => {
    try {
      const res = await DC.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        dlx: array,
      });
      selectVal.dlx = array;
      setLength({
        ...length,
        dlx: res.data.length,
      });
      setData({
        ...data,
        cdBookings: res.data.length,
      });
      length.dlx = res.data.length;
      data.cdBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchStandardChaletLength = async () => {
    try {
      const res = await CS.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        std: array,
      });
      selectVal.std = array;
      setLength({
        ...length,
        std: res.data.length,
      });
      setData({
        ...data,
        csBookings: res.data.length,
      });
      length.std = res.data.length;
      data.csBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchPreStandardChaletLength = async () => {
    try {
      const res = await PS.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        psd: array,
      });
      selectVal.psd = array;
      setLength({
        ...length,
        psd: res.data.length,
      });
      setData({
        ...data,
        psBookings: res.data.length,
      });
      length.psd = res.data.length;
      data.psBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const handleDateEnd = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      endDate: selectedDate,
    });
  };
  const handleInputChange = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        odtBookings: inputValue,
      });
    }
  };
  const handlePriceOdt = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        odtPrice: inputValue,
      });
    }
  };
  const handlePriceCd = (e) => {
    const inputValue = parseInt(e.target.value);
    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        cdPrice: inputValue,
      });
    }
  };
  const handlePriceCs = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        csPrice: inputValue,
      });
    }
  };
  //dependant function
  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );
    if (confirmed) {
      try {
        const res = await WORKING_HOURS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch booking information");
        } else {
          var req = {
            date: res.data[0].date,
            price: res.data[0].price,
            isPurged: false,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await WORKING_HOURS.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Deleted Working day");
        navigate("/business-days");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  //update
  const handleUpdate = async () => {
    if (data.date !== "") {
      setLoading(true);
      setValidate(false);
      const getByDate = await WORKING_HOURS.GET_BY_DATE(data.date);
      const w_h = getByDate.data[0];
      var updatedCDList = [];
      var updatedCSList = [];
      var updatedPSList = [];
      var removedCDList = [];
      var removedCSList = [];
      var removedPSList = [];
      //checking if there is at least one blocked chalet in standard
      if (data.cdBlockArrayIni.length > 0) {
        data.cdBlockArrayIni.map((itm) => {
          if (data.cdBlockArray.includes(itm)) {
            console.log(`${itm.value} is there.`);
          } else {
            //setting removed chalet names to array -- for deluxe
            removedCDList.push(itm.value);
          }
        });
      } else {
        console.log("in else");
      }
      //no changes made in the block list -- for deluxe
      if (data.cdBlockArray.length === data.cdBlockArrayIni.length) {
        updatedCDList = w_h.bookedCD;
      }
      //checking if there is at least one blocked chalet in deluxe
      if (data.csBlockArrayIni.length > 0) {
        data.csBlockArrayIni.map((itm) => {
          if (data.csBlockArray.includes(itm)) {
            console.log(`${itm.value} is there.`);
          } else {
            //setting removed chalet names to array -- for standard
            removedCSList.push(itm.value);
          }
        });
      }
      //no changes made in the block list -- for standard
      if (data.csBlockArray.length === data.csBlockArrayIni.length) {
        updatedCSList = w_h.bookedCS;
      }
      //checking if there is at least one blocked chalet in premium Std
      if (data.psBlockArrayIni.length > 0) {
        data.psBlockArrayIni.map((itm) => {
          if (data.psBlockArray.includes(itm)) {
            console.log(`${itm.value} is there.`);
          } else {
            //setting removed chalet names to array -- for standard
            removedPSList.push(itm.value);
          }
        });
      }
      //no changes made in the block list -- for standard
      if (data.psBlockArray.length === data.psBlockArrayIni.length) {
        updatedPSList = w_h.bookedPS;
      }
      if (removedCDList.length > 0) {
        w_h.bookedCD.map((itm) => {
          if (removedCDList.includes(itm)) {
            console.log(" in if", itm);
          } else {
            //setting the request for updated chalets - deluxe
            updatedCDList.push(itm);
            console.log("in else", itm);
          }
        });
      }
      if (removedCSList.length > 0) {
        w_h.bookedCS.map((itm) => {
          if (removedCSList.includes(itm)) {
            console.log(" in if", itm);
          } else {
            //setting the request for updated chalets - standard
            updatedCSList.push(itm);
            console.log("in else", itm);
          }
        });
      }
      if (removedPSList.length > 0) {
        w_h.bookedPS.map((itm) => {
          if (removedPSList.includes(itm)) {
            console.log(" in if", itm);
          } else {
            //setting the request for updated chalets - standard
            updatedPSList.push(itm);
            console.log("in else", itm);
          }
        });
      }
      var cdReq = {
        bookingToday: w_h.cd.bookingToday,
        extraBooking: w_h.cd.extraBooking,
        noOfBooking: w_h.cd.noOfBooking + removedCDList.length,
        price: w_h.cd.price,
      };
      var csReq = {
        bookingToday: w_h.cs.bookingToday,
        extraBooking: w_h.cs.extraBooking,
        noOfBooking: w_h.cs.noOfBooking + removedCSList.length,
        price: w_h.cs.price,
      };
      var psReq = {
        bookingToday:
          w_h.ps !== undefined ? w_h.ps.bookingToday : 0,
        extraBooking:
          w_h.ps !== undefined ? w_h.ps.extraBooking : 0,
        noOfBooking:
          w_h.ps !== undefined
            ? w_h.ps.noOfBooking + removedPSList.length
            : 0,
        price: w_h.ps !== undefined ? w_h.ps.price : 0,
      };

      var wh_req = {
        bookedCD: updatedCDList,
        bookedCS: updatedCSList,
        bookedPS: updatedPSList,
        cd: cdReq,
        cs: csReq,
        ps: psReq,
      };
      // console.log("wh_req", wh_req);
      //updating working hour
      const wh_update_res = await WORKING_HOURS.UPDATE(wh_req, w_h._id);
      const reqCdBlock = [];
      data.cdBlockArray.map((itm) => {
        reqCdBlock.push(itm.value);
      });
      const reqCsBlock = [];
      data.csBlockArray.map((itm) => {
        reqCsBlock.push(itm.value);
      });
      const reqPsBlock = [];
      data.psBlockArray.map((itm) => {
        reqPsBlock.push(itm.value);
      });
      var req = {
        blockedCD: reqCdBlock,
        blockedCS: reqCsBlock,
        blockedPS: reqPsBlock,
      };
      if (
        data.cdBlockArray.length == 0 &&
        data.csBlockArray.length === 0 &&
        data.psBlockArray.length === 0
      ) {
        //delete block data
        await deleteWholeBlock(data.identity);
        setLoading(false);
      } else {
        await createOldBlock(req, data.identity);
        setLoading(false);
        //update block data
      }
      // console.log("identity", data.identity);
      // console.log("req", req);
    } else {
      setValidate(true);
      console.log("inelse");
    }
  };
  //update
  ///add
  const createNewBlock = async (req) => {
    try {
      const res = await BLOCK.ADD(req, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          setRefreshAdd(!refreshAdd);
          toast.success("Added Block List");
          navigate("/block-chalets");
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const createOldBlock = async (req, id) => {
    try {
      const res = await BLOCK.UPDATE(req, id, token);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setReRunData(null);
        setRefreshUpdate(!refreshUpdate);
        toast.success("Updated Block List");
        navigate("/block-chalets");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setId(id);
        setReRunData(req);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const deleteWholeBlock = async (id) => {
    try {
      // console.log("id", id);
      const res = await BLOCK.DELETE_FULL(id, token);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setReRunData(null);
        setDeleteId("");
        setRefreshDelete(!refreshDelete);
        toast.success("Deleted Block List");
        navigate("/block-chalets");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(id);
        setDeleteId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  const handleAdd = async () => {
    if (data.date !== "") {
      setLoading(true);
      if (data.cdBlock !== "" || data.csBlock !== "" || data.psBlock !== "") {
        const getByDate = await WORKING_HOURS.GET_BY_DATE(data.date);
        // console.log("getByDate", getByDate);
        const w_h = getByDate.data[0];
        var cdBlockList = [];
        var csBlockList = [];
        var psBlockList = [];
        // console.log("in", w_h);
        var wh_req = {
          bookedCD: w_h.bookedCD,
          bookedCS: w_h.bookedCS,
          bookedPS: w_h.bookedPS,
          cd: w_h.cd,
          cs: w_h.cs,
          ps: w_h.ps,
        };
        //check if booking data with the same date is there
        const blockData = await BLOCK.GET_BY_DATE(data.date);
        // console.log("blockData", blockData);
        // //  if the length of the response array is greater than zero,
        if (blockData.data.length > 0) {
          csBlockList = blockData.data[0].blockedCS;
          psBlockList = blockData.data[0].blockedPS;
          cdBlockList = blockData.data[0].blockedCD;
        }
        //creating request
        if (data.csBlock !== "") {
          csBlockList.push(data.csBlock);
          wh_req.bookedCS.push(data.csBlock);
          var cs = {
            bookingToday: wh_req.cs.bookingToday,
            extraBooking: wh_req.cs.extraBooking,
            noOfBooking: wh_req.cs.noOfBooking - 1,
            price: wh_req.cs.price,
          };
          wh_req.cs = cs;
        }
        if (data.cdBlock !== "") {
          cdBlockList.push(data.cdBlock);
          wh_req.bookedCD.push(data.cdBlock);
          var cd = {
            bookingToday: wh_req.cd.bookingToday,
            extraBooking: wh_req.cd.extraBooking,
            noOfBooking: wh_req.cd.noOfBooking - 1,
            price: wh_req.cd.price,
          };
          wh_req.cd = cd;
        }
        if (data.psBlock !== "") {
          psBlockList.push(data.psBlock);
          wh_req.bookedPS.push(data.psBlock);
          var ps = {
            bookingToday: wh_req.ps.bookingToday,
            extraBooking: wh_req.ps.extraBooking,
            noOfBooking: wh_req.ps.noOfBooking - 1,
            price: wh_req.ps.price,
          };
          wh_req.ps = ps;
        }
        var req = {
          date: data.date,
          blockedCD: cdBlockList,
          blockedCS: csBlockList,
          blockedPS: psBlockList,
          isPurged: false,
        };
        // console.log("req", req);
        // console.log("wh_req", wh_req);

        // update working hour here
        const wh_update_res = await WORKING_HOURS.UPDATE(wh_req, w_h._id);
        // console.log("wh_update_res", wh_update_res);

        // //if the length of response array is zero, create the new block data
        // console.log("blockDataBottom", blockData);
        if (blockData.data.length > 0) {
          // console.log("blockData");
          // //update the data
          await createOldBlock(req, blockData.data[0]._id);
          setLoading(false);
        } else {
          // console.log("in add");
          await createNewBlock(req);
          setLoading(false);
          // //add the data
        }
        setSelectVal({
          ...selectVal,
          stdVal: "",
          dlxVal: "",
          psdVal: "",
        });
        setData({
          ...data,
          csBlock: "",
          cdBlock: "",
          psBlock: "",
        });
      } else {
        toast.info(`Haven't selected any chalets to block`);
      }
      setValidate(false);

      setLoading(false);
    } else {
      setValidate(true);
    }
  };
  ///add
  //get
  const getEmpList = async () => {
    try {
      const res = await BLOCK.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res.data", res.data);
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (date) => {
    try {
      const res = await WORKING_HOURS.SEARCH(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //get
  //useEffect
  useEffect(() => {
    if (reRunData !== null) deleteWholeBlock(deleteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  useEffect(() => {
    if (reRunData !== null) createOldBlock(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    if (reRunData !== null) createNewBlock(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);
  useEffect(() => {
    fetchDeluxeChaletLength();
    fetchStandardChaletLength();
    fetchPreStandardChaletLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //useEffect
  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Block Chalets" />
      </div>
      {!isEdit && (
        <AddBlock
          data={data}
          selectVal={selectVal}
          Select={Select}
          loading={loading}
          handleAdd={handleAdd}
          validate={validate}
          toast={toast}
          setData={setData}
          setSelectVal={setSelectVal}
          handleCDBlock={handleCDBlock}
          handleCSBlock={handleCSBlock}
          handlePSBlock={handlePSBlock}
          today={today}
          handleDateChange={handleDateChange}
        />
      )}
      {isEdit && (
        <UpdateBlock
          data={data}
          backToAdd={backToAdd}
          loading={loading}
          handleUpdate={handleUpdate}
          handleCSUnBlock={handleCSUnBlock}
          handlePSUnBlock={handlePSUnBlock}
          Select={Select}
          handleCDUnBlock={handleCDUnBlock}
          formatDateString={formatDateString}
        />
      )}
      <div ref={tableSessionRef}>
        <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <TableBlock
            handleEdit={handleEdit}
            setSearchVal={setSearchVal}
            searchVal={searchVal}
            formatDateString={formatDateString}
            currentItems={currentItems}
          />
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            array={emp}
            setItemOffset={setItemOffset}
            scrollToSection={scrollToTableSection}
            pageCount={pageCount}
          />
        </div>
      </div>
    </div>
  );
}
