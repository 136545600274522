import FAM from "../../../assets/fam.png";
import GRL from "../../../assets/girls.png";
import LOGO from "../../../assets/logo.png";
import { useParams } from "react-router-dom";
import { BOOKING } from "../../../api/booking";
import React, { useEffect, useState } from "react";
import { formatDateString } from "../../../utils/FormatDateString";
export default function IndividualDetails() {
  let { id } = useParams();

  //useState
  const [data, setData] = useState([]);
  // console.log("id", data.name);
  //useState
  
  //function
  const getBookingData = async (id) => {
    try {
      const res = await BOOKING.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setData(res.data[0]);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //function
  //useEffect
  useEffect(() => {
    getBookingData(id);
  }, [id]);
  //useEffect

  return (
    <div className="w-full h-full md:h-screen flex justify-center bg-blue items-center py-10 md:py-20 px-4 md:px-0">
      {data.name !== undefined ? (
        <div className="w-[400px] h-auto bg-white rounded-xl flex flex-col justify-between items-center">
          <div className="grid grid-cols-2 px-5 py-10 gap-2">
            <img src={FAM} alt="fam" className="w-full" />
            <div className="flex justify-center items-center flex-col">
              <img src={LOGO} alt="fam" className="w-[30%]" />
              <p className="font-bold text-xl">Aqua Magic</p>
              <p className="font-thin text-sm">{formatDateString(data.date)}</p>
            </div>
          </div>
          <div className=" w-full h-[20px] relative flex justify-center">
            <div className="mt-1 h-[20px] absolute left-0 w-full text-center font-black flex justify-center items-center">
              -----------------------------------------------
            </div>
            <div className="bg-blue h-[30px] w-[30px] rounded-full absolute left-[-4%]"></div>
            <div className="bg-blue h-[30px] w-[30px] rounded-full absolute right-[-4%]"></div>
          </div>
          <div className="grid grid-cols-2 px-5 py-10 gap-5">
            <div className="flex justify-center items-center flex-col">
              <p className="font-bold text-xl">
                {data.type === "Park" ? "Park " : "Chalot "} Booking
              </p>
              <p className="font-thin text-sm">{formatDateString(data.date)}</p>
              <p className="font-thin text-sm">
                {data.name} - [{data.category}]
              </p>
              <p className="font-thin text-sm">{data.email}</p>
            </div>
            <img src={`/qr/${data.qr}`} alt="fam" className="w-full" />
          </div>
          <div className=" w-full h-[20px] relative flex justify-center">
            <div className="mt-1 h-[20px] absolute left-0 w-full text-center font-black flex justify-center items-center">
              -----------------------------------------------
            </div>
            <div className="bg-blue h-[30px] w-[30px] rounded-full absolute left-[-4%]"></div>
            <div className="bg-blue h-[30px] w-[30px] rounded-full absolute right-[-4%]"></div>
          </div>
          <div className="grid grid-cols-2 px-5 py-10 gap-5">
            <img src={GRL} alt="fam" className="w-full" />
            <div className="flex justify-center items-center flex-col">
              <p className="font-bold text-xl">
                Group of {data.groupSize.total}
              </p>
              <p className="font-thin text-sm">
                {data.groupSize.children} Children
              </p>
              <p className="font-thin text-sm">{data.groupSize.adult} Adult</p>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-white">Loading...</p>
      )}
    </div>
  );
}
