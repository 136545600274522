export const formatDateString = (date) => {
  var dateObj = new Date(date);
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var formattedDate =
    monthNames[dateObj.getMonth()] +
    " " +
    dateObj.getDate() +
    ", " +
    dateObj.getFullYear();
  return formattedDate;
};
