import React, { useEffect, useState } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Autoplay, EffectCube, Pagination } from "swiper/modules";
import { SLIDER } from "../../api/slider";
import BORDER from "../../assets/oval1.png";
import BORDER2 from "../../assets/egg1.png";
import "./Intro.css"

export default function Intro() {
  const [session1Images, setSession1Images] = useState([]);
  const [session2Images, setSession2Images] = useState([]);
  const [loading, setLoading] = useState(true);
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  const animationTex1 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const animationTex2 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.2 } },
  };

  const animationTex3 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.4 } },
  };
  const animationTx1 = {
    textHide: { opacity: 0, x: -500 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationTx2 = {
    textHide: { opacity: 0, x: -500 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2.2 } },
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await SLIDER.GET_ALL();
        const baseURL = "/slider";

        const session1Urls = response.data.flatMap((image) =>
          (image.session1 || []).map((sessionOneImage) => ({
            ...image,
            session1: `${baseURL}/${sessionOneImage}`,
          }))
        );

        const session2Urls = response.data.flatMap((image) =>
          (image.session2 || []).map((sessionTwoImage) => ({
            ...image,
            session2: `${baseURL}/${sessionTwoImage}`,
          }))
        );

        setSession1Images(session1Urls);
        setSession2Images(session2Urls);
        setLoading(false);

      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);
  return (
    <>
      {!loading && (
        <div ref={txtRef} className="w-full bg-intro ">
          <div className="w-full bg-intro h-full flex justify-center items-center">
            <div className=" w-[80%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 items-center my-[130px]">
              <div className='w-auto relative'>
                <img src={BORDER2} alt="" className='object-contain relative z-99' />
                <div className='slider absolute bottom-[4%] left-[6.5%] w-[89%] h-[90%] '>
                  <Swiper
                    loop={true}
                    autoplay={{ delay: 2000 }}
                    speed={2000}
                    modules={[Pagination, Autoplay]}
                    className="w-full h-full slider"
                  >
                    {session1Images.map((data) => (
                      <SwiperSlide key={data.session1}>
                        <div className="w-full h-full slider">
                          <img src={data.session1} alt="" className='slider object-cover w-full h-full' />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              <div className=" col-span-1 h-auto  ">
                <div className=" w-auto h-auto">
                  <p className="text-black leading-9">
                    <motion.p
                      initial="hidden"
                      animate={controls}
                      variants={animationTex1}
                    >
                      <span className="text-[41px] font-mont font-thin ">
                        CELEBRATE
                      </span>
                      <br></br>
                    </motion.p>
                    <motion.p
                      initial="hidden"
                      animate={controls}
                      variants={animationTex2}
                    >
                      <span className="text-[41px] font-black font-poppins ">
                        YOUR BIRTHDAY{" "}
                      </span>
                      <br></br>
                    </motion.p>
                    <motion.p
                      initial="hidden"
                      animate={controls}
                      variants={animationTex3}
                    >
                      {" "}
                      <span className="text-[41px] font-semibold font-poppins">
                        WITH US !
                      </span>
                    </motion.p>
                  </p>
                </div>
                <div className=" mt-4">
                  <p className="text-base lg:text-lg md:text-lg font-mont font-normal tracking-tighter ">
                    Slide into another year of fun! Celebrate your birthday at AquaMagic and make it unforgettable.<br></br>
                    <br></br>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-intro h-full flex justify-center items-center">
            <div className=" w-[80%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 items-center ">
              <div className=" col-span-1 h-auto  ">
                <div className=" w-auto h-auto grid text-end">
                  <p className="text-black leading-9 ">
                    <motion.p
                      initial="hidden"
                      animate={controls}
                      variants={animationTx1}
                    >
                      <span className="text-[41px] font-mont font-thin">
                        AWESOME
                      </span>
                      <br></br>
                    </motion.p>
                    <motion.p
                      initial="hidden"
                      animate={controls}
                      variants={animationTx2}
                    >
                      <span className="text-[41px] font-black font-poppins">
                        VIA EXPERIENCE
                      </span>
                    </motion.p>
                  </p>
                </div>
                <div className=" mt-4">
                  <p className="text-base lg:text-lg md:text-lg font-mont font-normal tracking-tighter text-right">
                    Let your kids splash around at the water park while you take it easy in your private chalet, the perfect family getaway!<br></br>
                    <br></br>
                  </p>
                </div>
              </div>
              <div className='w-auto relative  flex justify-center md:justify-start '>
                <img src={BORDER} alt="" className='object-contain relative z-99 w-[80%]' />
                <div className='absolute bottom-[6%] md:left-[4%] left-[14%] w-[73%] h-[89%] rounded-full'>
                  <Swiper
                    loop={true}
                    autoplay={{ delay: 2000 }}
                    speed={2000}
                    modules={[Pagination, Autoplay]}
                    className="w-full h-full rounded-full"
                  >
                    {session2Images.map((data) => (
                      <SwiperSlide key={data.session2}>
                        <div className="w-full h-full rounded-full ">
                          <img src={data.session2} alt="images" className='object-cover rounded-full w-full h-full p-1' />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
