export const IMG = {
  // BG: require("./bg.png")
};
export const TERMS = {
  ONE: require("./terms/page1.png"),
  TWO: require("./terms/page2.png"),
  THR: require("./terms/page3.png"),
  FOU: require("./terms/page4.png"),
  FIV: require("./terms/page5.png"),
  SIX: require("./terms/page6.png"),
  SEV: require("./terms/page7.png"),
  EIG: require("./terms/page8.png"),
};
