import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { BOOKING } from "../../../api/booking";
import { formatDateString } from "../../../utils/FormatDateString";
import { AuthContext } from "../../../utils/AuthContext";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { WORKING_HOURS } from "../../../api/workingHours";
import { formatDateTime } from "../../../utils/FormatDateTime";
import { CONSTANT } from "../../../constant/Constant";
import EXPIRED from "../../../assets/expired.png";
import { date } from "yup";

export default function UsersDetails() {
  const { checkAccessTokenValidity } = UseAuth();
  let { id } = useParams();
  const [emp, setEmp] = useState(null);
  const [wh, setWh] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { token } = useContext(TokenContext);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [imageExists, setImageExists] = useState(false);
  const [chaletName, setChaletName] = useState({
    dlx: "",
    std: "",
    psd: "",
  });

  // const formatDateToKuwaitTime = (dateString) => {
  //   // Create a Date object from the UTC timestamp
  //   const date = new Date(dateString);

  //   // Convert to Kuwait Time (UTC+3)
  //   const kuwaitDate = new Date(date.getTime() + 3 * 60 * 60 * 1000);

  //   // Extract day, month, year, hour, minute, and second
  //   const day = String(kuwaitDate.getDate()).padStart(2, '0');
  //   const month = String(kuwaitDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  //   const year = kuwaitDate.getFullYear();
  //   const hour = String(kuwaitDate.getHours()).padStart(2, '0');
  //   const minute = String(kuwaitDate.getMinutes()).padStart(2, '0');
  //   const second = String(kuwaitDate.getSeconds()).padStart(2, '0');

  //   // Format to DD-MM-YYYY HH:MM:SS
  //   return `${day}-${month}-${year} ${hour}:${minute}:${second}`;
  // };

  const formatDateToKuwaitTime = (dateString) => {
    // Create a Date object from the UTC timestamp
    const date = new Date(dateString);

    // Options for Kuwait Time (UTC+3) formatting
    const options = {
      timeZone: "Asia/Kuwait",
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    // Format the date to 12-hour format
    return date.toLocaleString("en-US", options);
  };

  // console.log("imagexist", imageExists);
  const navigate = useNavigate();
  // console.log(user);
  const setChaletNames = (data) => {
    // console.log("data", data);
    const dlxArray = [];
    const stdArray = [];
    const psdArray = [];
    data.map((item) => {
      if (item.ticketType === 1) {
        dlxArray.push(item.chaletNo);
      } else if (item.ticketType === 2) {
        stdArray.push(item.chaletNo);
      } else if (item.ticketType === 7) {
        psdArray.push(item.chaletNo);
      }
    });

    setChaletName({
      ...chaletName,
      dlx: dlxArray.join(", "),
      std: stdArray.join(", "),
      psd: psdArray.join(", "),
    });
    chaletName.dlx = dlxArray.join(", ");
    chaletName.std = stdArray.join(", ");
    chaletName.psd = psdArray.join(", ");
  };
  // console.log("chaletName", chaletName);
  //get
  const getEmpList = async (id) => {
    try {
      const res = await BOOKING.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data[0]);
      // console.log("res.data[0]", res.data[0]);
      setChaletNames(res.data[0].ticketMembers);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };

  const updateWh = async (values, id) => {
    try {
      const res = await WORKING_HOURS.UPDATE(values, id);
      if (res.status === 200 || res.status === 201) {
        // console.log("Data updated successfully");
      } else {
        // toast.error("Failed to create data");
        throw new Error("Failed to update data");
      }
    } catch (error) {
      // console.log("error", error);
    }
  };

  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING.UPDATE(values, id, token);
      setReRunData(null);
      setLoading(false);
      if (res.status === 200 || res.status === 201) {
        const updatedIsScanned = res.data.isScanned;
        setRefresh(!refresh);
        toast.success(
          `Marked as ${updatedIsScanned ? "Scanned" : "Unscanned"} successfully`
        );
        // toast.success("Updated successfully");
      } else {
        toast.error("Failed to update data");
        throw new Error("Failed to update data");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefresh(!refresh);
      }
    }
  };

  const updateStatus = async () => {
    if (!emp) return;
    const updatedStatus = !emp.isScanned;
    const req = {
      ...emp,
      isScanned: updatedStatus,
    };
    updateData(req);
  };
  const CheckImageExist = (imageName) => {
    const img = new Image();
    img.src = `${CONSTANT.BASE_URL}qr/${imageName}`;
    // console.log("image", imageName);
    img.onload = () => {
      setImageExists(true);
      return true;
    };

    img.onerror = () => {
      setImageExists(false);
      return false;
    };
  };
  const filterCdFreeArrayByChalet = (chaletNo, array) => {
    // console.log("chaletNo", chaletNo);
    var rtnArray = [];
    array.map((itm) => {
      if (itm.chaletNo === chaletNo && itm.type === 3) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };
  const filterOdtFreeArrayByOdt = (type, array) => {
    // console.log("chaletNo", chaletNo);
    var rtnArray = [];
    array.map((itm) => {
      if (itm.type === type && itm.type === 0) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };
  const filterCsFreeArrayByChalet = (chaletNo, array) => {
    var rtnArray = [];
    array.map((itm) => {
      if (itm.chaletNo === chaletNo && itm.type === 4) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };

  const manageWorkingHours = (data, groupData) => {
    var freeArray = [];
    var groupSize = {
      totalNo: groupData[0].groupSize.totalNo,
      odtNo: groupData[0].groupSize.odtNo,
      cdNo: groupData[0].groupSize.cdNo,
      csNo: groupData[0].groupSize.csNo,
      cdFree: groupData[0].groupSize.cdFree,
      scFree: groupData[0].groupSize.scFree,
      odtFree: groupData[0].groupSize.odtFree,
      odtPrice: groupData[0].groupSize.odtPrice,
      cdPrice: groupData[0].groupSize.cdPrice,
      csPrice: groupData[0].groupSize.csPrice,
      totalPrice: groupData[0].groupSize.totalPrice,
    };
    var cdData = {
      bookingToday: data.cd.bookingToday,
      extraBooking: data.cd.extraBooking,
      noOfBooking: data.cd.noOfBooking,
      price: data.cd.price,
    };
    var csData = {
      bookingToday: data.cs.bookingToday,
      extraBooking: data.cs.extraBooking,
      noOfBooking: data.cs.noOfBooking,
      price: data.cs.price,
    };
    var odtData = {
      bookingToday: data.odt.bookingToday,
      extraBooking: data.odt.extraBooking,
      noOfBooking: data.odt.noOfBooking,
      price: data.odt.price,
    };
    if (!emp) return;
    if (emp.type === 0) {
      freeArray = filterOdtFreeArrayByOdt(emp.ticketType, groupData);
      groupSize.totalNo = groupSize.totalNo - 1;
      groupSize.odtNo = groupSize.odtNo - 1;
      odtData.bookingToday = odtData.bookingToday - 1;
      odtData.extraBooking =
        odtData.extraBooking > 0 ? odtData.extraBooking - 1 : 0;
    } else if (emp.type === 1) {
      freeArray = filterCdFreeArrayByChalet(emp.chaletNo, groupData);
      groupSize.totalNo = groupSize.totalNo - 1;
      groupSize.cdNo = groupSize.cdNo - 1;
      data.bookedCD.splice(data.bookedCD.indexOf(emp.chaletNo), 1);
      cdData.bookingToday = cdData.bookingToday - 1;
      cdData.extraBooking =
        cdData.extraBooking > 0 ? cdData.extraBooking - 1 : 0;
    } else if (emp.type === 2) {
      groupSize.totalNo = groupSize.totalNo - 1;
      groupSize.csNo = groupSize.csNo - 1;
      data.bookedCS.splice(data.bookedCS.indexOf(emp.chaletNo), 1);
      csData.bookingToday = csData.bookingToday - 1;
      csData.extraBooking =
        csData.extraBooking > 0 ? csData.extraBooking - 1 : 0;
    } else if (emp.type === 3) {
      groupSize.totalNo = groupSize.totalNo - 1;
      groupSize.cdFree = groupSize.cdFree - 1;
      // console.log("free sd");
    } else if (emp.type === 4) {
      groupSize.totalNo = groupSize.totalNo - 1;
      groupSize.scFree = groupSize.scFree - 1;
      // console.log("free cs");
    }
    groupSize.totalPrice =
      groupSize.cdNo * groupSize.cdPrice +
      groupSize.csNo * groupSize.csPrice +
      groupSize.odtNo * groupSize.odtPrice;
    // console.log("groupSize", groupSize);
    const req = {
      bookedCD: data.bookedCD,
      bookedCS: data.bookedCS,
      cd: cdData,
      cs: csData,
      odt: odtData,
    };
    updateWh(req, data._id);
    // console.log("reqq", req);
    // console.log("freearray", freeArray);
  };

  const getGroupData = async (date, pnr) => {
    try {
      const res = await BOOKING.GET_BY_PNR(date, pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res.gd", res.data);
      getWorkingHour(date, res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };

  const getWorkingHour = async (date, groupData) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res.wh", res.data);
      manageWorkingHours(res.data[0], groupData);
      setWh(res.data[0]);
      // console.log("setWh(res.data[0])", res.data[0]);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };

  // console.log("wh", wh)
  // console.log("emp", emp);

  const deleteBooking = async () => {
    // await getWorkingHour(emp.date);
    await getGroupData(emp.date, emp.pnr);
    const req = {
      ...emp,
      isPurged: true,
    };
    await updateData(req);
    getWorkingHour(emp.date, [emp]);
    navigate(`/booking/user/${emp.date}`);
  };

  useEffect(() => {
    if (reRunData !== null) updateData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    getEmpList(id);
  }, [id, refresh]);
  // console.log("emp", emp);
  //get
  useEffect(() => {
    if (emp !== null) {
      CheckImageExist(emp.qrImage);
    }
  }, [emp]);

  const goToPaymentDetails = () => {
    navigate(`/booking/payment/${emp.pnr}`);
  };

  const downloadImage = (data) => {
    // Replace 'image_url.jpg' with the URL of the image you want to download
    const imageUrl = `${CONSTANT.BASE_URL}ticket/${data.ticketImage}`;
    window.open(imageUrl, "_blank");
  };

  return (
    <div>
      <div>
        <Breadcrumbs pageName="User Details" />
      </div>

      {emp !== null && (
        <>
          {/* <div className="rounded-sm mt-5 flex flex-col justify-center items-center border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"> */}
          <div className="rounded-sm mt-5 flex flex-col justify-center items-center border border-stroke px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            {/* <div className="h-[210px] w-[210px] flex justify-center items-center bg-white rounded-lg padding-[20px]">
              <img
                src={`https://aquamagickw.com/qr/MVgzcPbEFzc1JcmD4hZR.png`}
                alt="qr"
                className="h-[200px]"
              />
            </div> */}

            <img
              src={imageExists ? `/qr/${emp.qrImage}` : EXPIRED}
              alt="qr"
              className="h-[200px] bg-white rounded-lg padding-10"
            />
            <h5 className="font-medium text-2xl text-black dark:text-white py-5">
              {emp.name}
            </h5>
          </div>
          {user.role === 0 && (
            <div className="rounded-sm mt-5 flex justify-between items-center border border-stroke px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
              <div className="p-6.5 ">
                <button
                  type="submit"
                  onClick={() => emp && updateStatus()}
                  className={`flex w-full justify-center rounded ${emp && emp.isScanned ? "bg-red px-10" : "bg-primary px-12.5"
                    }  py-3  font-medium text-gray`}
                >
                  {loading ? (
                    <div
                      className={`flex h-auto items-center justify-center ${emp && emp.isScanned ? "px-15" : "px-12.5"
                        }`}
                    >
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : emp && emp.isScanned ? (
                    "Mark As Unscanned"
                  ) : (
                    "Mark As Scanned"
                  )}
                </button>
              </div>
              <div className="p-6.5 ">
                <button
                  type="submit"
                  onClick={() => emp && downloadImage(emp)}
                  className="flex w-full justify-center rounded bg-red py-3 px-20 font-medium text-gray"
                >
                  {loadingDel ? (
                    <div className="flex h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "View Ticket"
                  )}
                </button>
              </div>
            </div>
          )}

          <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Date</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {formatDateString(emp.date)}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Created At
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {formatDateToKuwaitTime(emp.createdAt)}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">PNR</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.pnr}
                </h5>
              </div>
              {/* <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Type</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.type === 0 && "One Day Park Ticket"}
                  {emp.type === 1 && "Deluxe Chalet"}
                  {emp.type === 2 && "Standard Chalet"}
                  {emp.type === 3 && "Free Ticket from Deluxe"}
                  {emp.type === 4 && "Free Ticket from Standard"}
                </h5>
              </div> */}
              {/* <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Chalet No
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.chaletNo === "" ? "No Chalet choosed" : emp.chaletNo}
                </h5>
              </div> */}
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Status
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.isScanned ? "Scanned" : "Not Scanned"}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Email
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.email}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Phone
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.mobNo}
                </h5>
              </div>
              {/* {emp.payment.session_id !== "" && (
                <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                  <h5 className="font-medium text-black dark:text-white">
                    Payment Session Id
                  </h5>
                  <h5 className="font-medium text-black dark:text-white">
                    {emp.payment.session_id}
                  </h5>
                </div>
              )} */}
              {/* {emp.payment.reference_number !== "" && (
                <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                  <h5 className="font-medium text-black dark:text-white">
                    Payment Reference Number
                  </h5>
                  <h5 className="font-medium text-black dark:text-white">
                    {emp.payment.reference_number}
                  </h5>
                </div>
              )} */}
              {/* {emp.payment.session_id !== "" && (
                <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-end">
                  <button
                    className="font-medium text-white rounded-md dark:text-white bg-primary px-10 py-2"
                    onClick={() => {
                      goToPaymentDetails();
                    }}
                  >
                    View Payment Details
                  </button>
                </div>
              )} */}
            </div>
          </div>

          <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <p className=" px-4 py-2 border-b border-[#eee] dark:border-strokedark">
                Booking Details
              </p>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  One Day Ticket
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.odtNo} Nos
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Deluxe Chalets ( {emp.groupSize.cdNo} Nos)
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {chaletName.dlx}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Premium Standard Chalets ({" "}
                  {emp.groupSize.psNo === undefined ? 0 : emp.groupSize.psNo}{" "}
                  Nos)
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {chaletName.psd}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Standard Chalets ( {emp.groupSize.csNo} Nos)
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {chaletName.std}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Specially Abled One Day Ticket
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.saNo === undefined ? 0 : emp.groupSize.saNo}{" "}
                  Nos
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Nanny One Day Ticket
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.mdNo === undefined ? 0 : emp.groupSize.mdNo}{" "}
                  Nos
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Guest Free Tickets
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.odtFree === undefined ? 0 : emp.groupSize.odtFree} Nos
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Deluxe Chalets Free Tickets
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.cdFree} Nos
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Standard Chalets Free Tickets
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {emp.groupSize.scFree +
                    (emp.groupSize.psFree !== undefined
                      ? emp.groupSize.psFree
                      : 0)}{" "}
                  Nos
                </h5>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
