import { useContext, useState, useEffect } from "react";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { UseAuth } from "../../../utils/UseAuth";
import { AuthContext } from "../../../utils/AuthContext";
import { OFFERS } from "../../../api/offers";
import Select from "react-select";

export default function OfferUpdate() {
  const { checkAccessTokenValidity } = UseAuth();
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingShow, setLoadingShow] = useState(false);

  const [data, setData] = useState({
    title: "",
    description: "",
    offerImage: "",
    imgView: "",
    typeOfBooking: "",
    date: "",
    startDate: "",
    endDate: "",
    activeLength: "",
    discountPercentage: "",
    discountAmount: "",
    discountType: true,
    typeOfOfferDay: true,
    offerPriceReduction: true,
    isActive: true,
    isShow: true,
    isPurged: false,
  });

  const today = new Date().toISOString().split("T")[0];

  const [validate, setValidate] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const updateStatusActive = async () => {
    if (!data) return;
    const updatedStatus = !data.isActive;
    const req = {
      ...data,
      isActive: updatedStatus,
    };
    const rtnVal = await updateState(req, id, token);
    console.log("object", rtnVal);
    if (rtnVal.status === 200) {
      toast.success(
        `Offer Is ${
          rtnVal.data.isActive ? "Activated" : "InActivated"
        } successfully`
      );
    } else {
      toast.error("Failed to update Offer");
      throw new Error("Failed to update Offer");
    }
  };
  const updateStatusShow = async () => {
    if (!data) return;
    const updatedStatus = !data.isShow;
    const req = {
      ...data,
      isShow: updatedStatus,
    };

    const rtnVal = await updateState(req, id, token);
    console.log("object", rtnVal);
    if (rtnVal.status === 200) {
      toast.success(
        `Offer Is ${
          rtnVal.data.isShow ? "shown to Customer" : "hidden from Customer"
        } successfully`
      );
    } else {
      toast.error("Failed to update Offer");
      throw new Error("Failed to update Offer");
    }
  };

  const options = [
    { value: 0, label: "One Day Ticket" },
    { value: 1, label: "Chalet Deluxe" },
    { value: 2, label: "Chalet Standard" },
    { value: 3, label: "Maid One Day Ticket" },
    { value: 4, label: "Specially Abled" },
    { value: 5, label: "Premium Standard" },
  ];

  useEffect(() => {
    const getOffer = async (id) => {
      try {
        const res = await OFFERS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch Offer information");
        }
        const eventData = res.data;
        const initialSelectedOption = options.find(
          (option) => option.label === eventData.typeOfBooking
        );
        setData({
          _id: eventData._id,
          title: eventData.title,
          description: eventData.description,
          offerImage: eventData.offerImage,
          imgView: `/offer/${eventData.offerImage}`,
          typeOfBooking: eventData.typeOfBooking,
          date: eventData.date,
          // startDate: eventData.startDate,
          // endDate: eventData.endDate,
          activeLength: eventData.activeLength,
          discountPercentage: eventData.discountPercentage,
          discountAmount: eventData.discountAmount,
          discountType: eventData.discountType,
          typeOfOfferDay: eventData.typeOfOfferDay,
          offerPriceReduction: eventData.offerPriceReduction,
          isPurged: eventData.isPurged,
          isActive: eventData.isActive,
          isShow: eventData.isShow,
          selectedOption: initialSelectedOption,
        });
        console.log("ddddddd", eventData);
        console.log("ddddddd", data.isActive);
      } catch (error) {
        console.error("Error loading event: ", error);
      }
    };
    getOffer(id);
  }, [id, refresh]);

  const handleImageChange = (file) => {
    const fileLoaded = URL.createObjectURL(file);
    setData((prevData) => ({
      ...prevData,
      offerImage: file,
      imgView: fileLoaded,
    }));
  };

  const updateData = async (formData, id, token) => {
    try {
      const res = await OFFERS.UPDATE(formData, id, token);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Updated offer");
        navigate("/offers");
      } else {
        toast.error("Failed to update Offer");
        throw new Error("Failed to update Offer");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating Offer: ", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setLoading(true); // trigger re-run of the effect
      } else {
        toast.error("Failed to update Offer");
      }
    }
  };
  const updateState = async (formData, id, token) => {
    try {
      const res = await OFFERS.UPDATE(formData, id, token);
      setLoading(false);
      var rtnVal = res;
      // if (res.status === 200) {
      //   const updatedIsActive = res.data.isActive;
      //   toast.success(
      //     `Offer Is ${
      //       updatedIsActive ? "Activated" : "InActivated"
      //     } successfully`
      //   );
      //   setRefresh(!refresh);
      //   // navigate("/off");
      // } else if (res.status === 200) {
      //   const updatedIsShow = res.data.isShow;
      //   toast.success(
      //     `Offer Is ${
      //       updatedIsShow ? "shown to Customer" : "hidden from Customer"
      //     } successfully`
      //   );
      setRefresh(!refresh);
      return rtnVal;
      // navigate("/off");
      // } else {
      //   toast.error("Failed to update Offer");
      //   throw new Error("Failed to update Offer");
      // }
    } catch (error) {
      setLoading(false);
      console.error("Error updating Offer: ", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setLoading(true); // trigger re-run of the effect
      } else {
        toast.error("Failed to update Offer");
      }
    }
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    if (data.offerImage) {
      formData.append("offerImage", data.offerImage);
    }
    formData.append(
      "typeOfBooking",
      data.selectedOption ? data.selectedOption.label : ""
    );
    formData.append(
      "discountPercentage",
      data.discountType ? values.discountPercentage : ""
    );
    formData.append(
      "discountAmount",
      !data.discountType ? values.discountAmount : ""
    );
    formData.append(
      "activeLength",
      !data.offerPriceReduction ? values.activeLength : ""
    );
    formData.append("date", data.date);
    // formData.append("startDate", !data.typeOfOfferDay ? data.startDate : "");
    // formData.append("endDate", !data.typeOfOfferDay ? data.endDate : "");
    formData.append("discountType", data.discountType);
    // formData.append("typeOfOfferDay", data.typeOfOfferDay);
    formData.append("offerPriceReduction", data.offerPriceReduction);
    formData.append("isPurged", data.isPurged);
    formData.append("isActive", data.isActive);
    formData.append("isShow", data.isShow);
    updateData(formData, data._id);
  };

  const handleTodayDateChange = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      date: selectedDate,
    });
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      startDate: selectedDate,
    });
  };

  const handleDateEnd = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      endDate: selectedDate,
    });
  };

  const MyTextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <textarea
          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
          {...field}
          {...props}
        />
        {/* <ErrorMessage name={props.name} component="div" /> */}
      </>
    );
  };

  // useEffect(() => {
  //   getOffer(id);
  // }, [id, refresh]);
  return (
    <div>
      <Breadcrumbs pageName="Update Offer" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            Update Details
          </h3>
        </div>
        <Formik
          initialValues={data}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="grid grid-cols-1 gap-9 sm:grid-cols-2">
            <div className="flex flex-col gap-9 ">
              <div className="p-6.5">
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Title <span className="text-meta-1">*</span>
                  </label>
                  <Field
                    placeholder="Enter the Employee name"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="title"
                  />
                  <ErrorMessage
                    component="a"
                    className="text-danger"
                    name="title"
                  />
                </div>
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Description <span className="text-meta-1">*</span>
                  </label>
                  <MyTextArea
                    name="description"
                    rows="5"
                    placeholder="Enter the offer description"
                  />
                  {/* <Field
                    placeholder="Enter the Employee id"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="description"
                  /> */}
                  <ErrorMessage
                    component="a"
                    className="text-danger"
                    name="description"
                  />
                </div>

                <div className="w-full h-auto">
                  {/* ENABLE TO SHOW PERCENTAGE */}
                  {/* <label className="mb-2.5 block text-black dark:text-white">
                    Type of Discount
                  </label>
                  <div className="flex w-full ">
                    <button
                      onClick={() => {
                        setData({
                          ...data,
                          discountType: true,
                        });
                      }}
                      type="button"
                      className={`${
                        data.discountType === true
                          ? "bg-light"
                          : "bg-transparent "
                      } flex w-[50%] justify-center rounded-l border border-primary  p-3 font-medium text-primary `}
                    >
                      Percentage
                    </button>
                    <button
                      onClick={() => {
                        setData({
                          ...data,
                          discountType: false,
                        });
                      }}
                      type="button"
                      className={`${
                        data.discountType !== true
                          ? "bg-light"
                          : "bg-transparent "
                      } flex w-[50%] justify-center rounded-r  border border-primary  p-3 font-medium text-primary `}
                    >
                      Amount
                    </button>
                  </div> */}
                  <div className="my-4.5">
                    {data.discountType === true ? (
                      <>
                        <label className="mb-2.5 block text-black dark:text-white">
                          Discount (%)<span className="text-meta-1">*</span>
                        </label>
                        <Field
                          placeholder="Enter Discount Percentage"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          name="discountPercentage"
                        />
                        <ErrorMessage
                          component="a"
                          className="text-danger"
                          name="discountPercentage"
                        />
                      </>
                    ) : (
                      <>
                        <label className="mb-2.5 block text-black dark:text-white">
                          Discount (KWD)<span className="text-meta-1">*</span>
                        </label>
                        <Field
                          placeholder="Enter amount to be discounted"
                          className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          name="discountAmount"
                        />
                        <ErrorMessage
                          component="a"
                          className="text-danger"
                          name="discountAmount"
                        />
                      </>
                    )}
                  </div>
                  <div className="w-full h-auto">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Type Of Offer
                    </label>
                    <div className="flex w-full ">
                      <button
                        onClick={() => {
                          setData({
                            ...data,
                            offerPriceReduction: true,
                          });
                        }}
                        type="button"
                        className={`${
                          data.offerPriceReduction === true
                            ? "bg-light"
                            : "bg-transparent "
                        } flex w-[50%] justify-center rounded-l border border-primary  p-3 font-medium text-primary `}
                      >
                        Single
                      </button>
                      <button
                        onClick={() => {
                          setData({
                            ...data,
                            offerPriceReduction: false,
                          });
                        }}
                        type="button"
                        className={`${
                          data.offerPriceReduction !== true
                            ? "bg-light"
                            : "bg-transparent "
                        } flex w-[50%] justify-center rounded-r  border border-primary  p-3 font-medium text-primary `}
                      >
                        Combo
                      </button>
                    </div>
                    <div className="my-4.5 py-2">
                      {data.offerPriceReduction === true ? (
                        <></>
                      ) : (
                        <>
                          <label className="mb-2.5 block text-black dark:text-white">
                          No. of Bookings<span className="text-meta-1">*</span>
                          </label>
                          <Field
                            placeholder="Enter the no. of tickets for discount"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            name="activeLength"
                          />
                          <ErrorMessage
                            component="a"
                            className="text-danger"
                            name="activeLength"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="py-6.5 flex gap-5">
                  <div className="w-full h-auto">
                    <i>Now Offer is {data.isActive ? "Active" : "Inactive"} </i>
                    <button
                      type="button"
                      onClick={() => data && updateStatusActive()}
                      className={`flex w-full justify-center ${
                        data && !data.isActive
                          ? "bg-success px-10"
                          : "bg-red px-12.5"
                      } rounded bg-primary p-3 font-medium text-gray`}
                    >
                      {loadingActive ? (
                        <div
                          className={`flex h-auto items-center justify-center ${
                            data && !data.isActive ? "px-15" : "px-12.5"
                          }`}
                        >
                          {" "}
                          <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                        </div>
                      ) : data && !data.isActive ? (
                        "Active"
                      ) : (
                        "InActive"
                      )}
                    </button>
                  </div>
                  <div className="w-full h-auto">
                    <i>Now Offer is {data.isShow ? "Shown" : "Hidden"} </i>
                    <button
                      type="button"
                      onClick={() => data && updateStatusShow()}
                      className={`flex w-full justify-center ${
                        data && !data.isShow
                          ? "bg-primary px-10"
                          : "bg-black-2 px-12.5"
                      } rounded p-3 font-medium text-gray`}
                    >
                      {loadingActive ? (
                        <div
                          className={`flex h-auto items-center justify-center ${
                            data && !data.isShow ? "px-15" : "px-12.5"
                          }`}
                        >
                          {" "}
                          <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                        </div>
                      ) : data && !data.isShow ? (
                        "Show to Customer"
                      ) : (
                        "Hide from Customer"
                      )}
                    </button>
                  </div>
                  {/* <button
                    type="submit"
                    onClick={() => emp && updateStatus()}
                    className={`flex w-full justify-center rounded ${
                      emp && emp.isScanned
                        ? "bg-red px-10"
                        : "bg-primary px-12.5"
                    }  py-3  font-medium text-gray`}
                  >
                    {loading ? (
                      <div
                        className={`flex h-auto items-center justify-center ${
                          emp && emp.isScanned ? "px-15" : "px-12.5"
                        }`}
                      >
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : emp && emp.isScanned ? (
                      "Mark As Unscanned"
                    ) : (
                      "Mark As Scanned"
                    )}
                  </button> */}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-9 h-auto">
              <div className="p-6.5 h-auto">
                <div className="mb-4.5 w-full h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Type of Booking
                  </label>
                  <Select
                    options={options}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled
                    value={data.selectedOption} // Set the value from state
                    onChange={(option) =>
                      setData((prevData) => ({
                        ...prevData,
                        selectedOption: option,
                      }))
                    }
                    // isLoading={true}
                  />
                </div>
                <div className="mb-6 flex flex-col gap-6 xl:flex-row ">
                  <div className="w-full ">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Offer Image
                      <span className="text-meta-1">*</span>
                    </label>
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="logo-file"
                        className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                      >
                        {data.imgView !== "" ? (
                          <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                            <img
                              src={data.imgView}
                              alt="images"
                              className="h-full object-contain"
                            />

                            <button
                              type="button"
                              className="transition transform w-full h-full
                            translate-y-8 ease-in-out invisible  
                            absolute group-hover:visible 
                            text-white group-hover:translate-y-0 bg-black flex justify-center items-center opacity-75 "
                              onClick={() => {
                                setData((prevData) => ({
                                  ...prevData,
                                  offerImage: "",
                                  imgView: "",
                                }));
                              }}
                            >
                              <svg
                                className="fill-current"
                                width="30"
                                height="30"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                  fill=""
                                />
                                <path
                                  d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                  fill=""
                                />
                                <path
                                  d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                  fill=""
                                />
                                <path
                                  d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 16"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                />
                              </svg>
                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                <span className="font-semibold">
                                  Click to upload image
                                </span>
                              </p>
                              <p className="text-xs text-gray-500 dark:text-gray-400">
                                PNG, JPG or JPEG (MAX. 800x400px)
                              </p>
                            </div>
                            <input
                              id="logo-file"
                              type="file"
                              className="hidden "
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e) => {
                                handleImageChange(e.target.files[0]);
                              }}
                            />
                          </>
                        )}
                      </label>
                    </div>
                    <ErrorMessage
                      component="a"
                      className="text-danger"
                      name="logoView"
                    />
                  </div>
                </div>

                <div className="w-full h-auto">
                  {/* <label className="mb-2.5 block text-black dark:text-white">
                    Type of Offer Day
                  </label>
                  <div className="flex w-full ">
                    <button
                      onClick={() => {
                        setData({
                          ...data,
                          typeOfOfferDay: true,
                        });
                      }}
                      type="button"
                      className={`${
                        data.typeOfOfferDay === true
                          ? "bg-light"
                          : "bg-transparent "
                      } flex w-[50%] justify-center rounded-l border border-primary  p-3 font-medium text-primary `}
                    >
                      Single Day
                    </button>
                    <button
                      onClick={() => {
                        setData({
                          ...data,
                          typeOfOfferDay: false,
                        });
                      }}
                      type="button"
                      className={`${
                        data.typeOfOfferDay !== true
                          ? "bg-light"
                          : "bg-transparent "
                      } flex w-[50%] justify-center rounded-r  border border-primary  p-3 font-medium text-primary `}
                    >
                      Multi Day
                    </button>
                  </div> */}
                  <div className="my-4.5">
                    {/* {data.typeOfOfferDay === true ? ( */}
                    <div className="py-2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Date <span className="text-meta-1">*</span>
                      </label>
                      <input
                        onChange={handleTodayDateChange}
                        type="date"
                        min={today} // Set min attribute to disable past dates
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        name="date"
                        value={data.date}
                        disabled
                      />
                      {/* {data.startDate === "" && validate && (
                          <p className="text-red text-sm mt-1">* Required</p>
                        )} */}
                    </div>
                    {/* ) : ( */}
                    {/* <div className="flex gap-19 w-full h-auto">
                        <div className="py-2 w-[50%] h-auto">
                          <label className="mb-2.5 block text-black dark:text-white">
                            Start Date <span className="text-meta-1">*</span>
                          </label>
                          <input
                            onChange={handleDateChange}
                            type="date"
                            min={today} // Set min attribute to disable past dates
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            name="date"
                            value={data.startDate}
                          />
                          {data.startDate === "" && validate && (
                            <p className="text-red text-sm mt-1">* Required</p>
                          )}
                        </div>

                        <div className="py-2 h-auto w-[50%]">
                          <label className="mb-2.5 block text-black dark:text-white">
                            End Date <span className="text-meta-1">*</span>
                          </label>
                          <input
                            type="date"
                            min={today} // Set min attribute to disable past dates
                            onChange={handleDateEnd}
                            placeholder="Enter price for this event"
                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            name="price"
                            value={data.endDate}
                          />
                          {data.endDate === "" && validate && (
                            <p className="text-red text-sm mt-1">* Required</p>
                          )}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="p-6.5">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? (
                    <div className="flex h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "Update Offer"
                  )}
                </button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
