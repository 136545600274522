import Select from "react-select";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BOOKING } from "../../../api/booking";
import { UseAuth } from "../../../utils/UseAuth";
import React, { useEffect, useState } from "react";
import { CONSTANT } from "../../../constant/Constant";
import { WORKING_HOURS } from "../../../api/workingHours";

export default function BookNow() {
  //constant variables
  var number = 0;
  const imgArray = [];
  const { checkAccessTokenValidity } = UseAuth();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  const ageOption = [
    {
      value: "0",
      label: "0 Years Old",
    },
    {
      value: "1",
      label: "1 Years Old",
    },
    {
      value: "2",
      label: "2 Years Old",
    },
    {
      value: "3",
      label: "3 Years Old",
    },
    {
      value: "4",
      label: "3+ Years Old",
    },
  ];
  //constant variables

  //useState

  const [showAge, setShowAge] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadCard, setLoadCard] = useState(false);
  const [id, setId] = useState("");
  const [reRunDataHour, setReRunDataHour] = useState(null);
  const [refreshSuccess, setRefreshSuccess] = useState(false);

  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [validateUser, setValidateUser] = useState(false);
  const [success, setSuccess] = useState({
    ticketArray: [],
    count: 0,
    updateData: {},
    updateId: "",
    pnr: "",
  });
  const [user, setUser] = useState({
    name: "",
    category: "Adult",
    type: "Park",
    price: "",
    mobile: "",
    age: "3+",
  });
  const [cardId, setCardId] = useState(0);

  const [data, setData] = useState({
    mobile: "",
    email: "",
    working_hours: {},
    date: formatDate(new Date()),
    user: [
      {
        name: "",
        category: "Adult",
        type: "Park",
        price: "",
        mobile: "",
        age: "3+",
      },
    ],
  });
  const [price, setPrice] = useState({
    adultNo: 0,
    adultPrice: 0,
    childNo: 0,
    childPrice: 0,
    totalPrice: 0,
    parkNo: 0,
    parkPrice: 0,
    chalotNo: 0,
    chalotPrice: 0,
  });
  const [ageValue, setAgeValue] = useState({
    value: "0",
    label: "0 Years Old",
  });
  const [userlength, setUserLength] = useState(0);
  const [typeValue, setTypeValue] = useState(0);
  const [categoryValue, setCategoryValue] = useState(0);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [reRunData, setReRunData] = useState(null);

 //useState
 //functions
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  //dependant function
  const clearData = () => {
    setData({
      ...data,
      mobile: "",
      email: "",
      user: [],
    });
    setSuccess({
      ...success,
      ticketArray: [],
      count: 0,
      updateData: {},
      updateId: "",
      pnr: "",
    });
  };
  //dependant function
  const goToInitialState = () => {
    setValidateUser(false);
    setUser({
      ...user,
      name: "",
      mobile: "",
      category: "Adult",
      type: "Park",
      age: "3+",
    });
    setShowAge(false);
    setCategoryValue(0);
    setTypeValue(0);
  };
  const updateWorkingHour = async (values, id) => {
    try {
      const res = await WORKING_HOURS.UPDATE(values, id);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunDataHour(values);
        setId(id);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const getBookingData = async (date) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setData({
        ...data,
        working_hours: res.data[0],
      });
      data.user[0].price = res.data[0].parkPrice;
      data.working_hours = res.data[0];
      setPrice({
        ...price,
        adultNo: 1,
        childNo: 0,
        totalPrice: res.data[0].parkPrice,
        parkNo: 1,
        parkPrice: res.data[0].parkPrice,
        chalotNo: 0,
        chalotPrice: 0,
      });
      price.parkPrice = res.data[0].parkPrice;
      price.totalPrice = res.data[0].totalPrice;
      price.adultNo = res.data[0].adultNo;
      price.parkNo = res.data[0].parkNo;
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const priceInitialState = () => {
    price.adultNo = 0;
    price.adultPrice = 0;
    price.childNo = 0;
    price.childPrice = 0;
    price.totalPrice = 0;
    price.parkNo = 0;
    price.parkPrice = 0;
    price.chalotNo = 0;
    price.chalotPrice = 0;

    setPrice({
      ...price,
      adultNo: 0,
      adultPrice: 0,
      childNo: 0,
      childPrice: 0,
      totalPrice: 0,
      parkNo: 0,
      parkPrice: 0,
      chalotNo: 0,
      chalotPrice: 0,
    });
  };
  const handleAddUsers = async () => {
    if (
      user.name !== "" &&
      user.mobile !== "" &&
      user.category !== "" &&
      user.type !== ""
    ) {
      setLoading(true);
      var array = data.user;
      var userVal = {
        name: user.name,
        category: user.category,
        type: user.type,
        age: user.age,
        mobile: user.mobile,
        price:
          user.category === "Adult"
            ? user.type === "Park"
              ? data.working_hours.parkPrice
              : data.working_hours.chalotPrice
            : 0,
      };
      array.push(userVal);
      setData({
        ...data,
        user: array,
      });
      goToInitialState();
      setLoading(false);
    } else {
      setValidateUser(true);
    }
  };

  const addData = async (values) => {
    setLoading(true);
    try {
      // console.log("values", values.qr);
      const res = await BOOKING.ADD(values);
      imgArray.push(`${res.data.img}.png`);
      number = number + 1;
      setSuccess({
        ...success,
        ticketArray: imgArray,
      });
      setSuccess({
        ...success,
        count: number,
      });
      success.ticketArray = imgArray;
      success.count = number;
      setReRunData(null);
      setRefreshSuccess(!refreshSuccess);
      if (res.status === 200) {
        // console.log("created a user")
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAddBooking = async () => {
    if (
      data.working_hours.noOfBooking - data.working_hours.bookingToday >=
      data.user.length
    ) {
      if (data.email !== "") {
        setLoading(true);
        setValidate(false);
        var pnr = Math.floor(Math.random() * 9000000000) + 1;
        setSuccess({
          ...success,
          pnr: pnr,
        });
        success.pnr = pnr;
        await data.user.map(async (item) => {
          var req = {
            name: item.name,
            email: data.email,
            mobNo: item.mobile,
            pnr: pnr.toString(),
            date: data.date,
            age: item.age,
            price: item.price,
            qr: "",
            ticket: "",
            type: item.type,
            category: item.category,

            groupSize: {
              children: price.childNo,
              adult: price.adultNo,
              total: price.totalPrice,
              parkNo: price.parkNo,
              parkPrice: price.parkPrice,
              chalotNo: price.chalotNo,
              chalotPrice: price.chalotPrice,
            },
            isPurged: false,
            isScanned: false,
          };
          addData(req);
          return req;
        });

        const bookingLeft =
          data.working_hours.noOfBooking -
          (data.working_hours.bookingToday + data.user.length);
        const updateReq = {
          date: data.working_hours.date,
          noOfBooking: data.working_hours.noOfBooking,
          bookingToday: data.working_hours.bookingToday + data.user.length,
          extraBooking: bookingLeft >= 0 ? 0 : Math.abs(bookingLeft),
          chalotPrice: data.working_hours.chalotPrice,
          parkPrice: data.working_hours.parkPrice,
          isPurged: data.working_hours.isPurged,
        };
        setSuccess({
          ...success,
          updateData: updateReq,
          updateId: data.working_hours._id,
        });
        success.updateData = updateReq;
        success.updateId = data.working_hours._id;

        // updateWorkingHour(updateReq, data.working_hours._id);
        // var url = `${CONSTANT.BASE_URL}booking-details/date/${data.date}/pnr/${pnr}`;
        // var request = {
        //   date: data.date,
        //   email: data.email,
        //   url: url,
        // };
        // sendBulkEmail(request);
        // navigate(`/booking-details/date/${data.date}/pnr/${pnr}`);
        // toast.success(`Created ${data.user.length} Bookings`);
      } else {
        setValidate(true);
      }
    } else {
      toast.error("Not enough booking space available for today");
    }
  };
  const calculatePrice = async (user) => {
    // console.log("user", user);
    if (user.length > 0) {
      // if (user.length > 0 && user.length !== userlength) {
      setUserLength(userlength + 1);
      priceInitialState();
      user.map((itm) => {
        // console.log("itm", itm);
        // console.log("price.childNo", price);
        setPrice({
          ...price,
          adultNo: itm.category === "Adult" ? price.adultNo + 1 : 0,
          adultPrice:
            itm.category === "Adult"
              ? price.adultPrice + Number(itm.price)
              : price.adultPrice,
          childNo: itm.category === "Child" ? price.childNo + 1 : price.childNo,
          childPrice:
            itm.category === "Child"
              ? price.childPrice + Number(itm.price)
              : price.childPrice,
          totalPrice: price.totalPrice + Number(itm.price),
          parkNo: itm.type === "Park" ? price.parkNo + 1 : price.parkNo,
          parkPrice:
            Number(price.parkNo) * Number(data.working_hours.parkPrice),
          chalotNo: itm.type === "Chalet" ? price.chalotNo + 1 : price.chalotNo,
          chalotPrice: price.chalotNo * Number(data.working_hours.chalotPrice),
        });
        price.adultNo =
          itm.category === "Adult" ? price.adultNo + 1 : price.adultNo;
        price.adultPrice =
          itm.category === "Adult"
            ? price.adultPrice + Number(itm.price)
            : price.adultPrice;
        price.childNo =
          itm.category === "Child" ? price.childNo + 1 : price.childNo;
        price.childPrice =
          itm.category === "Child"
            ? price.childPrice + Number(itm.price)
            : price.childPrice;
        price.totalPrice = price.totalPrice + Number(itm.price);
        price.parkNo = itm.type === "Park" ? price.parkNo + 1 : price.parkNo;
        price.parkPrice =
          Number(price.parkNo) * Number(data.working_hours.parkPrice);
        price.chalotNo =
          itm.type === "Chalet" ? price.chalotNo + 1 : price.chalotNo;
        price.chalotPrice =
          price.chalotNo * Number(data.working_hours.chalotPrice);
      });
    } else {
      setPrice({
        ...price,
        adultNo: 0,
        adultPrice: 0,
        childNo: 0,
        childPrice: 0,
        totalPrice: 0,
        parkNo: 0,
        parkPrice: 0,
        chalotNo: 0,
        chalotPrice: 0,
      });
    }
  };
  const checkfileExist = async (path) => {
    var rtnVal = false;
    const res = await BOOKING.CHECK_FILE(path);
    if (res.data.data === true) {
      rtnVal = true;
    } else {
      rtnVal = false;
    }
    return rtnVal;
  };
  const sendBulkEmail = async (values) => {
    try {
      const res = await BOOKING.BOOKING_SUCCESS_BULK(values);
      if (res.data.data === true) {
        setLoading(false);
        toast.success(`Created ${data.user.length} Bookings`);
        clearData();
        navigate(`/booking-details/date/${data.date}/pnr/${success.pnr}`);
      }
      // console.log("res", res);
    } catch (error) {
      // console.log("erro", error);
    }
  };
  const createCommonPdf = async (values) => {
    try {
      const res = await BOOKING.CREATE_PDF(values);
      if (res.data.data === true) {
        var url = `${CONSTANT.BASE_URL}booking-details/date/${data.date}/pnr/${success.pnr}`;
        var request = {
          date: data.date,
          email: data.email,
          url: url,
          pnr: success.pnr,
        };
        sendBulkEmail(request);
      }
      // console.log("res pdf", res);
    } catch (error) {
      // console.log("erro", error);
    }
  };

  //function

  //onchange functions
  const handleCategoryChange = (numb) => {
    setCategoryValue(numb);
    setUser({
      ...user,
      category: numb === 0 ? "Adult" : "Child",
    });
    user.category = numb === 0 ? "Adult" : "Child";

    if (numb === 1) {
      setShowAge(true);
      setAgeValue({
        ...ageValue,
        value: "0",
        label: "0 Years Old",
      });
    } else {
      setShowAge(false);
    }
  };
  const handleAgeChange = (e) => {
    if (e.value === "4") {
      setUser({
        ...user,
        age: "3+",
        category: "Adult",
      });
      setCategoryValue(0);
      setAgeValue({
        ...ageValue,
        value: e.value,
        label: "3+ Years Old",
      });
    } else {
      setCategoryValue(1);
      setUser({
        ...user,
        age: e.value,
        category: "Child",
      });
      setAgeValue({
        ...ageValue,
        value: e.value,
        label: `${e.value} Years Old`,
      });
    }
  };
  const handleTypeChange = (numb) => {
    setTypeValue(numb);
    setUser({
      ...user,
      type: numb === 1 ? "Chalet" : "Park",
    });
    user.type = numb === 1 ? "Chalet" : "Park";
  };
  //onchange functions
  //dependant function
  const validityCheck = () => {
    var rtnVal = false;
    data.user.map((itm) => {
      if (itm.name !== "" && itm.park !== "" && itm.category !== "") {
        setValidateUser(false);
        rtnVal = true;
      } else {
        rtnVal = false;
        setValidateUser(true);
      }
    });
    return rtnVal;
  };
  const addOneMoreUser = async () => {
    if (validityCheck()) {
      // setUserLength(userlength + 1);
      var array = data.user;
      var user = {
        name: "",
        category: "Adult",
        type: "Park",
        price: data.working_hours.parkPrice,
        mobile: "",
        age: "3+",
      };
      array.push(user);
      setData({
        ...data,
        user: array,
      });
      data.user = data.user;
      calculatePrice(data.user);
    }
  };
  //dependant function

 
  //aftersuccess
  const afterSuccess = async (success) => {
    const pdfReq = {
      imgArray: success.ticketArray,
      pnr: success.pnr,
    };
    await createCommonPdf(pdfReq);
    updateWorkingHour(success.updateData, success.updateId);
  };
  //aftersuccess
  
//useEffect
  useEffect(() => {
    // console.log("in price");
    calculatePrice(data.user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.user, typeValue, categoryValue]);
  useEffect(() => {
    getBookingData(data.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.date]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (reRunDataHour !== null) updateWorkingHour(reRunDataHour, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    // console.log("success.count", success.count);
    // console.log("data.user.length", data.user.length);
    if (success.count === data.user.length && success.count > 0) {
      const resPromise = BOOKING.SEARCH(data.date, success.pnr);
      resPromise.then(
        function (value) {
          if (value.data.length > 0 && value.data[0].ticket !== "") {
            const response = checkfileExist(value.data[0].ticket);
            response.then(
              function (value) {
                // console.log("value", value);
                if (value === true) {
                  afterSuccess(success);
                } else {
                  setRefreshSuccess(!refreshSuccess);
                }
              },
              function (error) {
                // console.log("error", error);
              }
            );
          } else {
            setRefreshSuccess(!refreshSuccess);
            // console.log("in ifs else");
          }
        },
        function (error) {
          // console.log("error", error);
        }
      );
    } else {
      // setRefreshSuccess(!refreshSuccess);
      // console.log(" in else");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, refreshSuccess]);
  //useEffect
  return (
    <div className="pt-40 px-5 lg:px-25 flex items-center flex-col justify-center bg-bgbg w-full h-auto py-10 font-poppins">
      <div className="max-w-screen-2xl gap-5 grid grid-cols-10 w-full">
        <div className="col-span-10 lg:col-span-7 xl:col-span-8">
          <div className="grid grid-cols-1 sm:grid-cols-4 pb-6 gap-5">
            <div className="flex flex-col">
              <label className="block text-black dark:text-white text-sm">
                Email <span className="text-meta-1">*</span>
              </label>
              <input
                onChange={(e) => {
                  setData({
                    ...data,
                    email: e.target.value,
                  });
                }}
                type="text"
                placeholder="Enter the email"
                className="w-full  placeholder-primary text-xl rounded border-[1.5px] border-stroke bg-transparent py-2 font-bold  text-primary border-none  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="nmob"
                value={data.email}
              />
              {data.email === "" && validate && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
            <div className="flex flex-col">
              <label className="block text-black dark:text-white text-sm">
                Date
              </label>
              <input
                onChange={(e) => {
                  setData({
                    ...data,
                    date: e.target.value,
                  });
                }}
                type="date"
                className="w-full font-bold text-xl rounded text-primary border-none  border-[1.5px] border-stroke bg-transparent py-1.5  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="date"
                value={data.date}
                min={today} // Set the min attribute to today's date
              />
            </div>
            <div className="flex flex-col"></div>

            <div className="flex flex-col">
              {data.working_hours.noOfBooking !== undefined && (
                <p className="text-red font-semibold text-sm">
                  *{" "}
                  {data.working_hours.noOfBooking -
                    data.working_hours.bookingToday >=
                  0
                    ? data.working_hours.noOfBooking -
                      data.working_hours.bookingToday
                    : 0}{" "}
                  Bookings left for today
                </p>
              )}
            </div>
          </div>

          <p className="text-black font-bold text-md mb-2">Personal Details</p>

          {data.user.map((evt, index) => (
            <div
              // key={evt.name}
              className="grid relative grid-cols-8 gap-2 pb-10 shadow-md bg-white rounded-lg  py-7 px-6 mb-5 border-[1px] border-stroke"
            >
              <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                <p className="text-grey font-thin text-[10px]">
                  Name <span className="text-meta-1">*</span>
                </p>
                <input
                  onChange={(e) => {
                    setUser({
                      ...user,
                      name: e.target.value,
                    });

                    data.user[index].name = e.target.value;
                  }}
                  value={evt.name}
                  type="text"
                  placeholder="Enter the name"
                  className="w-full text-sm rounded-md border-[1.5px] border-stroke bg-transparent py-2 px-2 font-bold outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
                {data.user[index].name === "" && validateUser && (
                  <p className="text-red text-sm mt-1 absolute bottom-5">
                    * Required
                  </p>
                )}
              </div>
              <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                <p className="text-grey font-thin text-[10px]">Mobile</p>
                <input
                  type="text"
                  onChange={(e) => {
                    setUser({
                      ...user,
                      mobile: e.target.value,
                    });
                    data.user[index].mobile = e.target.value;
                  }}
                  value={evt.mobile}
                  placeholder="Enter the mobile no:"
                  className="w-full text-sm rounded-md border-[1.5px] border-stroke bg-transparent py-2 px-2 font-bold outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                <p className="text-grey font-thin text-[10px]">
                  Type <span className="text-meta-1">*</span>
                </p>

                <div className="w-full h-full rounded-md  grid grid-cols-2 border-[1.5px] border-stroke">
                  <button
                    onClick={() => {
                      data.user[index].type = "Park";
                      data.user[index].price =
                        data.user[index].category === "Child"
                          ? 0
                          : data.working_hours.parkPrice;
                      handleTypeChange(0);
                      calculatePrice(data.user);
                    }}
                    className={` w-full h-full rounded-md text-sm font-bold ${
                      evt.type === "Park"
                        ? "bg-white border-b-4 border-[1px] border-primary"
                        : "bg-white"
                    }`}
                  >
                    Park
                  </button>
                  <button
                    onClick={() => {
                      data.user[index].type = "Chalet";
                      data.user[index].price =
                        data.user[index].category === "Child"
                          ? 0
                          : data.working_hours.chalotPrice;
                      handleTypeChange(1);
                      calculatePrice(data.user);
                    }}
                    className={` w-full h-full rounded-md text-sm font-bold ${
                      evt.type === "Chalet"
                        ? "bg-white border-b-4 border-[1px] border-primary"
                        : "bg-white"
                    }`}
                  >
                    Chalet
                  </button>
                </div>
                {user.type === "" && validateUser === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
              </div>
              <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                <p className="text-grey font-thin text-[10px]">
                  Category <span className="text-meta-1">*</span>
                </p>
                <div className="w-full h-full rounded-md  grid grid-cols-2 border-[1.5px] border-stroke">
                  <button
                    onClick={() => {
                      data.user[index].category = "Adult";
                      data.user[index].price =
                        data.user[index].type === "Park"
                          ? data.working_hours.parkPrice
                          : data.working_hours.chalotPrice;
                      setCardId(index);
                      handleCategoryChange(0);
                      calculatePrice(data.user);
                    }}
                    className={` w-full h-full rounded-md text-sm font-bold ${
                      evt.category === "Adult"
                        ? "bg-white border-b-4 border-[1px] border-primary"
                        : "bg-white"
                    }`}
                  >
                    Adult
                  </button>
                  <button
                    onClick={() => {
                      data.user[index].category = "Child";
                      setCardId(index);
                      handleCategoryChange(1);
                      data.user[index].age = "0";
                      data.user[index].price = 0;
                      calculatePrice(data.user);
                    }}
                    className={` w-full h-full rounded-md text-sm font-bold ${
                      evt.category === "Child"
                        ? "bg-white border-b-4 border-[1px] border-primary"
                        : "bg-white"
                    }`}
                  >
                    Child
                  </button>
                </div>
                {user.category === "" && validateUser === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
              </div>
              {evt.category === "Child" && (
                <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                  <p className="text-grey font-thin text-[10px]">Age</p>
                  <Select
                    onChange={(e) => {
                      if (e.value === "4") {
                        data.user[index].category = "Adult";
                        data.user[index].price =
                          data.user[index].type === "Chalet"
                            ? data.working_hours.chalotPrice
                            : data.working_hours.parkPrice;
                      } else {
                        data.user[index].price = 0;
                      }
                      data.user[index].age = e.value;
                      handleAgeChange(e);
                    }}
                    value={{ label: `${evt.age} Years Old`, value: evt.age }}
                    placeholder="Choose the age"
                    options={ageOption}
                    className="text-sm"
                  />
                </div>
              )}

              <button
                onClick={() => {
                  if (data.user.length > 1) {
                    var newArray = [
                      ...data.user.slice(0, index),
                      ...data.user.slice(index + 1),
                    ];
                    setData({
                      ...data,
                      user: newArray,
                    });
                    data.user = newArray;
                  }
                }}
                className="bg-light absolute right-1 top-1 h-auto w-auto p-1 rounded-full border-red border-[1.5px] flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  width="32px"
                  height="32px"
                  className="h-4 w-4"
                  fill="red"
                >
                  <path d="M29,7.5c0-1.93-1.57-3.5-3.5-3.5H17V3c0-0.553-0.447-1-1-1s-1,0.447-1,1v1H6.5C4.57,4,3,5.571,3,7.5c0,0.553,0.447,1,1,1	s1-0.447,1-1C5,6.673,5.673,6,6.5,6h19C26.327,6,27,6.673,27,7.5S26.327,9,25.5,9c-0.09,0-0.171,0.029-0.254,0.051	c-0.064-0.017-0.122-0.046-0.19-0.049c-0.02-0.001-0.037,0.008-0.056,0.008L25,9H10.989l0.873,16.412	c0.044,0.828-0.591,1.534-1.418,1.578c-0.014,0-0.027,0.001-0.041,0.001c-0.033-0.002-0.065-0.006-0.097-0.009	c-0.078-0.003-0.15-0.025-0.225-0.041C9.41,26.766,8.9,26.179,8.859,25.455L7.998,9.945c-0.03-0.551-0.487-0.973-1.054-0.942	c-0.551,0.03-0.974,0.502-0.942,1.054l0.861,15.511C6.971,27.493,8.565,29,10.493,29h11.014c1.928,0,3.522-1.508,3.63-3.434	l0.811-14.612C27.665,10.732,29,9.277,29,7.5z" />
                </svg>
              </button>
            </div>
          ))}
          <div className="w-full h-auto flex justify-end">
            <button
              className="text-primary py-5 px-3 font-bold"
              onClick={() => {
                // handleAddUsers();
                addOneMoreUser();
              }}
            >
              Add another person
            </button>
          </div>
        </div>
        <div className="col-span-10 lg:col-span-3 xl:col-span-2 flex flex-col gap-2 p-5 rounded-lg shadow-md my-4 h-auto bg-white">
          <p className="font-bold text-[#226D9F] text-[25px]">Price Details</p>
          <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
            <div className="flex flex-row justify-between px-[10px]">
              <p>Chalet</p>
              <p> {price.chalotNo} Nos</p>
            </div>
            <div className="flex flex-row justify-between px-[10px]">
              <p>Park</p>
              <p> {price.parkNo} Nos</p>
            </div>
          </div>
          {/* <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
            <div className="flex flex-row justify-between px-[10px]">
              <p>
                Chalet [{price.chalotNo} * {data.working_hours.chalotPrice}]
              </p>
              <p> ${price.chalotPrice}/-</p>
            </div>
            <div className="flex flex-row justify-between px-[10px]">
              <p>
                Park [{price.parkNo} * {data.working_hours.parkPrice}]
              </p>
              <p> ${price.parkPrice}/-</p>
            </div>
          </div> */}
          <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
            <div className="flex flex-row justify-between px-[10px]">
              <p>Chalet Price</p>
              <p> ${data.working_hours.chalotPrice}/-</p>
            </div>
            <div className="flex flex-row justify-between px-[10px]">
              <p>Park Price</p>
              <p> ${data.working_hours.parkPrice}/-</p>
            </div>
          </div>
          <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
            <div className="flex flex-row justify-between px-[10px]">
              <p>Adult</p>
              <p> {price.adultNo} Nos</p>
            </div>
            <div className="flex flex-row justify-between px-[10px]">
              <p>Children</p>
              <p> {price.childNo} Nos</p>
            </div>
            <div className="flex flex-row justify-between px-[10px]">
              <p className="font-bold">Total</p>
              <p className="font-bold"> {price.adultNo + price.childNo} Nos</p>
            </div>
          </div>

          <div className="">
            <div className="flex flex-row justify-between px-[10px]">
              <p className="font-bold">Total Payable</p>
              <p className="font-bold">${price.totalPrice} /-</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-auto max-w-screen-2xl grid grid-cols-10 py-2">
        <p className="text-black font-normal text-sm col-span-10 lg:col-span-7 xl:col-span-8 flex items-center">
          {data.email !== ""
            ? ` Order confirmation will be sent to "${data.email}"`
            : "Please enter an email Id"}
        </p>

        <div className="col-span-10 lg:col-span-3 xl:col-span-2 flex justify-center items-center">
          <button
            className="bg-secondary text-white py-2 px-15 font-bold rounded-md"
            type="submit"
            onClick={() => {
              handleAddBooking();
            }}
          >
            {loading ? (
              <div className="flex h-auto items-center justify-center ">
                <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
              </div>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
