import GIF from "./loading.gif";
import SEND from "../../../assets/send.png";
import DWLD from "../../../assets/dwnld.png";
import { BOOKING } from "../../../api/booking";
import React, { useEffect, useState } from "react";
import { CONSTANT } from "../../../constant/Constant";
import { useParams, useNavigate } from "react-router-dom";
import { formatDateString } from "../../../utils/FormatDateString";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
export default function BookingDetails() {
  //constant function
  const navigate = useNavigate();
  //constant function
  //useState
  let { date, pnr } = useParams();
  const [id, setId] = useState(0);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  //useState

  //get function for bookinglist
  const getBookingList = async (date, pnr) => {
    try {
      const res = await BOOKING.SEARCH(date, pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setData(res.data);
    } catch (error) {
    }
  };
  //get function for bookinglist

  //useEffect
  useEffect(() => {
    getBookingList(date, pnr);
  }, [date, pnr]);
  //useEffect
  //functions
  const shareUrl = (ticket) => {
    return `${CONSTANT.BASE_URL}ticket/${ticket}`;
    // return `${CONSTANT.BASE_URL}booking-details/date/2024-05-23/pnr/6681491318/identity/${id}`;
  };
  const goToIndividualScreen = (id) => {
    navigate(`/booking-details/date/2024-05-23/pnr/6681491318/identity/${id}`);
  };
  const downloadImage = (data) => {
    // Replace 'image_url.jpg' with the URL of the image you want to download
    const imageUrl = `${CONSTANT.BASE_URL}ticket/${data.ticket}`;
    window.open(imageUrl, "_blank");
  };
  //functions
  return (
    <div className="pt-40 px-5 lg:px-25 flex items-center justify-center bg-bgrnd w-full h-auto py-10">
      <div className="max-w-screen-2xl gap-5 grid grid-cols-3 w-full">
        <div className="col-span-3 lg:col-span-2">
          {data.length > 0 ? (
            <>
              {data.map((item, index) => (
                <div
                  className="grid grid-cols-8  bg-white rounded-lg shadow-md my-4 pl-4 pr-4 sm:pr-0 py-4 sm:py-0"
                  key={item._id}
                >
                  <div className="col-span-4 sm:col-span-2 flex flex-col justify-center">
                    <p className="text-grey font-thin text-[10px]">Name</p>
                    <p className="font-semibold text-xl">{item.name}</p>
                  </div>
                  <div className="col-span-3 sm:col-span-1 flex flex-col justify-center">
                    <p className="text-grey font-thin text-[10px]">Type</p>
                    <p className="font-semibold text-xl">{item.type}</p>
                  </div>
                  <div className="col-span-1 flex flex-col justify-center">
                    <p className="text-grey font-thin text-[10px]">Category</p>
                    <p className="font-semibold text-xl">{item.category}</p>
                  </div>
                  <div className="col-span-4 sm:col-span-2 flex flex-col justify-center pt-2 sm:mt-0">
                    <p className="text-grey font-thin text-[10px]">
                      Booking Date
                    </p>
                    <p className="font-semibold text-xl">
                      {formatDateString(item.date)}{" "}
                    </p>
                  </div>
                  <div className="col-span-4 sm:col-span-2 sm:mt-0 flex justify-between items-center">
                    <div className="w-[60%] sm:w-full py-1">
                      <img
                        src={`/qr/${item.qr}`}
                        alt="qr"
                        className="w-[50%] sm:w-[60%] object-cover rounded-md m-5"
                      />
                    </div>
                    <div className="w-[40%] rounded-l-lg sm:rounded-l-none rounded-r-lg col-span-3 sm:col-span-1 relative flex bg-yellow justify-center items-center  h-full py-2">
                      <div className="w-full flex flex-col justify-center items-center">
                        <button
                          onClick={() => downloadImage(item)}
                          // onClick={() => goToIndividualScreen(item._id)}
                          className=""
                        >
                          <svg
                            fill="#0e6097"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            // width="800px"
                            // height="800px"
                            className="w-5"
                            viewBox="0 0 442.04 442.04"
                          >
                            <g>
                              <g>
                                <path
                                  d="M221.02,341.304c-49.708,0-103.206-19.44-154.71-56.22C27.808,257.59,4.044,230.351,3.051,229.203
			c-4.068-4.697-4.068-11.669,0-16.367c0.993-1.146,24.756-28.387,63.259-55.881c51.505-36.777,105.003-56.219,154.71-56.219
			c49.708,0,103.207,19.441,154.71,56.219c38.502,27.494,62.266,54.734,63.259,55.881c4.068,4.697,4.068,11.669,0,16.367
			c-0.993,1.146-24.756,28.387-63.259,55.881C324.227,321.863,270.729,341.304,221.02,341.304z M29.638,221.021
			c9.61,9.799,27.747,27.03,51.694,44.071c32.83,23.361,83.714,51.212,139.688,51.212s106.859-27.851,139.688-51.212
			c23.944-17.038,42.082-34.271,51.694-44.071c-9.609-9.799-27.747-27.03-51.694-44.071
			c-32.829-23.362-83.714-51.212-139.688-51.212s-106.858,27.85-139.688,51.212C57.388,193.988,39.25,211.219,29.638,221.021z"
                                />
                              </g>
                              <g>
                                <path
                                  d="M221.02,298.521c-42.734,0-77.5-34.767-77.5-77.5c0-42.733,34.766-77.5,77.5-77.5c18.794,0,36.924,6.814,51.048,19.188
			c5.193,4.549,5.715,12.446,1.166,17.639c-4.549,5.193-12.447,5.714-17.639,1.166c-9.564-8.379-21.844-12.993-34.576-12.993
			c-28.949,0-52.5,23.552-52.5,52.5s23.551,52.5,52.5,52.5c28.95,0,52.5-23.552,52.5-52.5c0-6.903,5.597-12.5,12.5-12.5
			s12.5,5.597,12.5,12.5C298.521,263.754,263.754,298.521,221.02,298.521z"
                                />
                              </g>
                              <g>
                                <path d="M221.02,246.021c-13.785,0-25-11.215-25-25s11.215-25,25-25c13.786,0,25,11.215,25,25S234.806,246.021,221.02,246.021z" />
                              </g>
                            </g>
                          </svg>
                          {/* <img src={DWLD} alt="" className="h-4" /> */}
                        </button>
                        <button
                          onClick={() => {
                            setId(index);
                            setShow(!show);
                          }}
                        >
                          <img src={SEND} alt="" className="h-4 mt-3 " />
                        </button>
                      </div>
                      {show && id === index && (
                        <div className="absolute flex bottom-[-35%] sm:bottom-[-12%] right-0 z-99">
                          <FacebookShareButton
                            url={shareUrl(item.ticket)}
                            className="mx-1"
                          >
                            <FacebookIcon size={25} round={true} />
                          </FacebookShareButton>
                          <WhatsappShareButton
                            url={shareUrl(item.ticket)}
                            className="mx-1"
                          >
                            <WhatsappIcon size={25} round={true} />
                          </WhatsappShareButton>
                          <EmailShareButton
                            url={shareUrl(item.ticket)}
                            className="mx-1"
                          >
                            <EmailIcon size={25} round={true} />
                          </EmailShareButton>
                          <TelegramShareButton
                            url={shareUrl(item.ticket)}
                            className="mx-1"
                          >
                            <TelegramIcon size={25} round={true} />
                          </TelegramShareButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="w-full flex h-full justify-center flex-col items-center">
              <img src={GIF} alt="" />
              <p className="text-primary text-xl font-bold">Fetching data...</p>
            </div>
          )}
        </div>
        {data.length > 0 && (
          <div className="col-span-3 lg:col-span-1 flex flex-col gap-2 p-5 rounded-lg shadow-md my-4 h-auto bg-white">
            <p className="font-bold text-[#226D9F] text-[25px]">
              Price Details
            </p>
            <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
              <div className="flex flex-row justify-between px-[10px]">
                <p>Chalot Price</p>
                <p> ${data[0].groupSize.chalotPrice}/-</p>
              </div>
              <div className="flex flex-row justify-between px-[10px]">
                <p>Park Price</p>
                <p> ${data[0].groupSize.parkPrice}/-</p>
              </div>
            </div>
            <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
              <div className="flex flex-row justify-between px-[10px]">
                <p>Chalot No</p>
                <p> {data[0].groupSize.chalotNo} Nos</p>
              </div>
              <div className="flex flex-row justify-between px-[10px]">
                <p>Park No</p>
                <p> {data[0].groupSize.parkNo} Nos</p>
              </div>
            </div>
            {/* <div className="py-[10px] border-dashed border-b-2 border-[#003679]">
              <div className="flex flex-row justify-between px-[10px]">
                <p>Adult</p>
                <p> {data[0].groupSize.adultNo} Nos</p>
              </div>
              <div className="flex flex-row justify-between px-[10px]">
                <p>Children</p>
                <p> {data[0].groupSize.children} Nos</p>
              </div>
              <div className="flex flex-row justify-between px-[10px]">
                <p className="font-bold">Total</p>
                <p className="font-bold">
                  {" "}
                  {data[0].groupSize.children + data[0].groupSize.adult} Nos
                </p>
              </div>
            </div> */}
            {/* <div className="py-[20px] border-dashed border-b-2 border-[#003679]">
              <form>
                <input
                  type="text"
                  placeholder="Voucher Code"
                  className="w-[100%] outline-none h-10 pl-[5%] border border-gray text-lg rounded-[5px]"
                />
              </form>
            </div> */}
            <div className="">
              <div className="flex flex-row justify-between px-[10px]">
                <p className="font-bold">Total Paid</p>
                <p className="font-bold">
                  ${data[0].groupSize.total}
                  /-
                </p>
              </div>
              {/* <div className="flex flex-row justify-between px-[10px]">
                <p>Your total savings on this order</p>
                <p>: $223</p>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
