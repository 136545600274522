import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";
export const SLIDER = {
  ADD(req) {
    const url = `${CONSTANT.URL}slider`;
    return API_REQUEST("POST", url, req, {
      "Content-Type": "multipart/form-data",
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}slider`;
    return API_REQUEST("GET", url);
  },

  UPDATE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}slider/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}slider/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}slider/${id}`;
    return API_REQUEST("GET", url);
  },
};
