import React from "react";
import ReactApexChart from "react-apexcharts";

export default function LineChart({ dates, data }) {
  // Function to add two arrays element-wise
  const addArrays = (arr1, arr2) => {
    const length = Math.max(arr1.length, arr2.length);
    return Array.from({ length }, (_, i) => (arr1[i] || 0) + (arr2[i] || 0));
  };
  const options = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: dates,
      // categories: [

      //   1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002,
      //   2003, 2004, 2005, 2006, 2007, 2008,
      // ],
    },
  };
  const series = [
    {
      name: "Total",
      data: data.totalWeekArray,
      // data: [30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70, 91],
    },
    {
      name: "Chalet Deluxe",
      data: data.chaletDeluxeWeekArray,
    },
    {
      name: "Chalet Standard",
      data: data.chaletStandardWeekArray,
    },
    {
      name: "Chalet Premium Standard",
      data: data.chaletPStandardWeekArray,
    },
    {
      name: "Park",
      data: data.parkWeekArray,
    },
    {
      name: "Free Ticket with Deluxe",
      data: data.freeDeluxeWeekArray,
    },
    {
      name: "Free Ticket with Guest",
      data: data.freeOdtWeekArray,
    },
    {
      name: "Free Ticket with Standard",
      data: addArrays(data.freeStandardWeekArray, data.freePStandardWeekArray),
    },
  ];
  // console.log("series", series);

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5">
      <div>
        <h3 className="text-xl font-semibold text-black dark:text-white">
          Bookings Per Day
        </h3>
      </div>

      <div className="mb-2">
        <div id="chartFour" className="-ml-5 ">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            width="100%"
            height="400"
          />
        </div>
      </div>
    </div>
  );
}
