import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const REPORT = {
    GET_ALL() {
        const url = `${CONSTANT.URL}report/all`;
        return API_REQUEST("GET", url);
    },
    GET_TOTALS_BY_DATE(date) {
        const url = `${CONSTANT.URL}report/totals/${date}`;
        return API_REQUEST("GET", url);
    },
    GET_BY_DATE(date) {
        const url = `${CONSTANT.URL}report/date/${date}`;
        return API_REQUEST("GET", url);
    },
    GET_BY_DATE_RANGE(startDate, endDate) {
        const url = `${CONSTANT.URL}report/date-range/${startDate}/to/${endDate}`;
        return API_REQUEST("GET", url);
    },
    GET_DETAILED_REPORT(startDate, endDate, pageNo, pageLength, scannedType, paymentType) {
        const url = `${CONSTANT.URL}report/detailed-report/${startDate}/to/${endDate}/isScanned-${scannedType}/paymentType-${paymentType}/pageno-${pageNo}/pagesize-${pageLength}`;
        return API_REQUEST("GET", url);
    },
    GET_EXCEL(startDate, endDate, scannedType, paymentType) {
        const url = `${CONSTANT.URL}report/export-detailed-report/${startDate}/to/${endDate}/isScanned-${scannedType}/paymentType-${paymentType}`;
        return API_REQUEST("GET", url, null, {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    }

}