export const OfferIcon = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.88 122.68"
            fill="white"
            className="w-[18px]"
        >
            <g>
                <path
                    className="st0"
                    d="M12.27,56.72h23.88c0,0.06,0,0.13,0,0.19V88H5.91V62.54C5.91,59.34,8.78,56.72,12.27,56.72L12.27,56.72z M20.36,27.17c-5.33-3.73-8.68-8.45-9.94-12.65c-0.9-3.02-0.83-5.95,0.22-8.43c1.21-2.84,3.53-4.91,6.97-5.74 c4.81-1.17,12.29,0.51,22.2,7.25c1.26,0.86,3.54,2.45,5.78,4.02c2.65,1.85,5.22,3.65,7.39,5.15c2.31-2.27,5.47-3.68,8.96-3.68 c3.82,0,7.24,1.68,9.58,4.33c2.35-1.62,5.29-3.68,8.33-5.81c2.24-1.57,4.53-3.17,5.78-4.02c9.9-6.74,17.39-8.42,22.2-7.25 c3.44,0.84,5.76,2.9,6.97,5.74c1.05,2.48,1.12,5.42,0.22,8.43c-1.26,4.2-4.61,8.92-9.94,12.65h13.48c2.37,0,4.31,1.8,4.31,4v14.51 c0,2.2-1.94,4-4.31,4H4.31c-2.37,0-4.31-1.8-4.31-4V31.17c0-2.2,1.94-4,4.31-4H20.36L20.36,27.17z M85.64,19.89 c-2.57,1.8-5.1,3.56-7.49,5.13c2.29-0.18,4.89-0.51,7.6-1.04c1.44-0.28,2.85-0.6,4.17-0.97c1.3-0.36,2.63-0.79,3.98-1.31l0.02-0.01 c6.57-2.52,10.39-6.67,11.41-10.07c0.23-0.76,0.29-1.32,0.18-1.59c-0.01-0.01-0.01-0.02-0.01-0.02l0,0.01 c0,0.03,0.02,0.14-0.03,0.13c-2.34-0.57-6.9,0.87-14.12,5.79C88.78,17.69,87.2,18.8,85.64,19.89L85.64,19.89z M105.5,10.02 C105.5,10.02,105.5,10.03,105.5,10.02L105.5,10.02L105.5,10.02z M47.31,25.02c-2.4-1.56-4.92-3.33-7.49-5.13 c-1.56-1.09-3.15-2.2-5.7-3.94c-7.22-4.92-11.78-6.36-14.12-5.79c-0.05,0.01-0.03-0.1-0.03-0.13l0,0c0,0,0,0-0.01,0.02 c-0.11,0.27-0.05,0.82,0.18,1.59c1.02,3.4,4.84,7.55,11.41,10.07l0.02,0.01c1.35,0.52,2.69,0.95,3.98,1.31 c1.33,0.37,2.73,0.69,4.17,0.97C42.42,24.5,45.02,24.84,47.31,25.02L47.31,25.02z M47.51,56.72h63.09c3.5,0,6.36,2.62,6.36,5.81V88 H47.52V56.92C47.52,56.85,47.52,56.79,47.51,56.72L47.51,56.72z M116.97,99.52v17.34c0,3.19-2.86,5.81-6.36,5.81H47.52V99.52 H116.97L116.97,99.52z M36.15,122.68H12.27c-3.49,0-6.36-2.61-6.36-5.81V99.52h30.24V122.68L36.15,122.68z"
                />
            </g>
        </svg>
    );
};