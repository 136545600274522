import {  useState } from "react";
import { useEffect } from "react";
import { EVENTS } from "../../../api/events";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";


export default function EventView() {
  const IMG_PATH = "/event/";
  let { id } = useParams();
  const [data, setData] = useState({
    title: "",
    description: "",
    eventImage: "",
    imgView: "",
  });

  const getDepartment = async (id) => {
    try {
      const res = await EVENTS.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch events information");
      }
      console.log("s",res)
      setData({
        ...data,
        _id: res.data._id,
        title: res.data.title,
        description: res.data.description,
        eventImage: IMG_PATH + res.data.eventImage,
      });
      data._id = res.data._id;
      data.title = res.data.title;
      data.description = res.data.description;
      data.eventImage = IMG_PATH + res.data.eventImage;
    } catch (error) {
      console.log("Error loading topics: ", error);
      }
      console.log(data.eventImage)
  };
  useEffect(() => {
    getDepartment(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="View Event" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            {data.title}
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
          <div className="flex flex-col gap-9 ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Title <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.title}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Description <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.description}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 h-auto">
            <div className="p-6.5 h-auto">
              <div className="mb-8 flex flex-col gap-6 xl:flex-row ">
                <div className="w-full ">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Profile Image
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="logo-file"
                      className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                    >
                      <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                        <img
                          src={data.eventImage}
                          alt="images"
                          className="h-full object-contain"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
