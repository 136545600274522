import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const TEMP_BOOKING = {
  ADD(req) {
    const url = `${CONSTANT.URL}temp-booking`;
    return API_REQUEST("POST", url, req);
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}temp-booking`;
    return API_REQUEST("GET", url);
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}temp-booking/${id}`;
    return API_REQUEST("GET", url);
  },
  DELETE( id) {
    const url = `${CONSTANT.URL}temp-booking/${id}`;
    return API_REQUEST("DELETE", url);
  },
};
