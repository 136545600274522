import { CONSTANT } from '../constant/Constant';
import { API_REQUEST } from './api';
export const EVENTS = {

  ADD(req, token) {
    const url = `${CONSTANT.URL}events`;
    return API_REQUEST("POST", url, req, {
      "Content-Type": "multipart/form-data",
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}events`;
    return API_REQUEST("GET", url );
  },

  UPDATE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}events/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}events/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}events/${id}`;
    return API_REQUEST("GET", url);
  },

};