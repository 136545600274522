import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from '../assets/languages/en.json'
import AR from '../assets/languages/ar.json'
i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'en', // Default language
        debug: true, // Set to false in production

        interpolation: {
            escapeValue: false, // React already escapes values
        },

        resources: {
            en: {
                translation: EN
            },
            ar: {
                translation: AR
            },
        },
    });

export default i18n;
