import React, { useEffect, useState } from "react";
import OFFER from "../../../src/assets/offers.png";
import { OFFERS } from "../../api/offers";
import BORDER from "../../assets/oval1.png";
export default function Offer() {
  const [offer, setOffer] = useState([]);
  useEffect(() => {
    console.log("object");
    const fetchImages = async () => {
      try {
        const response = await OFFERS.GET_ACTIVE();
        // console.log("response", response);
        const baseURL = "/offer";
        const imageUrls = response.data.map((image) => ({
          ...image,
          img: `${baseURL}/${image.offerImage}`,
        }));
        setOffer(imageUrls);
      } catch (error) {}
    };
    fetchImages();
  }, []);

  // console.log("offer", offer);

  const gridColumnsLg =
    offer.length === 1
      ? "lg:grid-cols-1"
      : offer.length === 2
      ? "lg:grid-cols-2"
      : "lg:grid-cols-3";

  if (offer.length === 0) {
    return null;
  }

  return (
    <div className="bg-white h-full w-full flex justify-center items-center">
      <div className=" h-full w-[80%]  py-10 max-w-screen-2xl">
        <div className=" w-full h-auto flex justify-center items-center">
          <p className="font-mont text-[49px] font-thin">SPECIAL</p>
        </div>
        <div className="w-full h-auto  flex justify-center items-center py-3 mb-8">
          <img
            src={OFFER}
            alt="images"
            style={{ width: "65%", height: "65%" }}
            className=" object-contain"
          />
        </div>
        <div className=" w-full h-auto flex justify-center items-center">
          {/* <p className='font-mont text-[26px] font-normal text-center leading-8'>Duis ut egestas dolor. Sed vel tellus pulvinar, sodales diam viverra, <br></br>Suspendisse dignissim nisl sit amet diam facilisis,</p> */}
        </div>
        <div
          className={`h-auto w-full grid grid-cols-1 ${gridColumnsLg} gap-12 lg:gap-10 py-10`}
        >
          {offer.map((data) => (
            <div
              key={data.id}
              className="col-span-1 flex flex-col justify-center items-center"
            >
              {/* <div className='bg-red'> */}
              <div className="w-auto relative  flex justify-center">
                <img
                  src={BORDER}
                  alt=""
                  className="object-contain relative z-99 w-[80%]"
                />
                <div className="absolute bottom-[6%]  left-[14%] w-[73%] h-[89%] rounded-full">
                  <div className="w-full h-full rounded-full ">
                    <img
                      src={data.img}
                      alt="images"
                      className="object-cover rounded-full w-full h-full p-1"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full h-auto flex justify-center items-center mt-2">
                <p className="font-mont text-[26px] text-primary font-bold">
                  {data.title}
                </p>
              </div>
              <div className="w-full h-auto flex justify-center items-center">
                <p className="font-mont text-[20px] text-center line-clamp-2">
                  {data.description}
                </p>
              </div>
              {/* <div className="h-auto py-5 w-auto flex justify-center items-center">
                <button className="bg-red rounded-full text-white font-black text-lg px-10 py-3 font-poppins animate-customBounce hover:animate-none">
                  More Details
                </button>
              </div> */}
              {/* </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
