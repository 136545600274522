import React, { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import UnAuthLayout from "./UnAuthLayout";
import { AuthContext } from "../utils/AuthContext";
import AdminLayout from "./AdminLayout";
import { TokenContext } from "../utils/TokenContext";
import { TempContext } from "../utils/TempContext";
import { BookingContext } from "../utils/BookingContext";

export default function Configuration() {
  const { user, setUser } = useContext(AuthContext);
  const { token, setToken } = useContext(TokenContext);
  const { temp, setTemp } = useContext(TempContext);
  const { session, setSession } = useContext(BookingContext);
  // console.log("user", user);
  // console.log("token", token);
  // console.log("temp", temp);
  let asyncValue = {};
  let asyncTokValue = {};
  let asyncTempValue = {};
  let asyncSessionValue = {};
  const readData = async () => {
    try {
      const storedValue = await localStorage.getItem("user");
      const storedTokenValue = await localStorage.getItem("token");
      const storedTempValue = await localStorage.getItem("temp");
      const storedSessionValue = await localStorage.getItem("session");
     
      if (storedValue !== null) {
        asyncValue = JSON.parse(storedValue);
        if (asyncValue !== null) {
          setUser(asyncValue);
        }
      }
      if (storedSessionValue !== null) {
        asyncSessionValue = JSON.parse(storedSessionValue);
        if (asyncSessionValue !== null) {
          setSession(asyncSessionValue);
        }
      }
      if (storedTokenValue !== null) {
        asyncTokValue = storedTokenValue;
        if (asyncTokValue !== null) {
          setToken(asyncTokValue);
        }
      }
      if (storedTempValue !== null) {
        asyncTempValue = storedTempValue;
        // asyncTempValue = JSON.parse(storedTempValue);

        if (asyncTempValue !== null) {
          setTemp(asyncTempValue);
        }
      }
    } catch (e) {
      console.log("Failed to fetch the input from storage", e);
    }
  };
  // console.log("token", token)
  useEffect(() => {
    readData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <BrowserRouter>
      {user === null ? <UnAuthLayout /> : <AdminLayout />}
    </BrowserRouter>
  );
}
