import React from "react";
export default function AddBlock({
  data,
  selectVal,
  Select,
  loading,
  handleAdd,
  validate,
  toast,
  setData,
  setSelectVal,
  handleCDBlock,
  handlePSBlock,
  handleCSBlock,
  today,
  handleDateChange,
}) {
  // console.log("selectVal", selectVal);
  return (
    <div className="rounded-sm mb-5 border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full">
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex flex-col">
            <div className="px-2 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Choose Date <span className="text-meta-1">*</span>
              </label>
              <input
                onChange={handleDateChange}
                type="date"
                min={today} // Set min attribute to disable past dates
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="date"
                value={data.date}
              />
              {data.startDate === "" && validate && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Block Deluxe Chalet
              </label>
              <Select
                value={selectVal.dlxVal}
                options={selectVal.dlxIni}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => {
                  if (data.working_hour.length > 0 && e !== null) {
                    handleCDBlock(e);
                  } else {
                    setSelectVal({
                      ...selectVal,
                      dlxVal: "",
                    });
                    setData({
                      ...data,
                      cdBlock: "",
                    });
                    toast.info("Please choose a date that accept bookings");
                  }
                }}
              />
              {data.csPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Block Standard Chalet
              </label>
              <Select
                value={selectVal.stdVal}
                options={selectVal.stdIni}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => {
                  if (data.working_hour.length > 0 && e !== null) {
                    handleCSBlock(e);
                  } else {
                    setSelectVal({
                      ...selectVal,
                      stdVal: "",
                    });
                    setData({
                      ...data,
                      csBlock: "",
                    });
                    toast.info("Please choose a date that accept bookings");
                  }
                }}
              />
              {data.csPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Block Premium Standard Chalet
              </label>
              <Select
                value={selectVal.psdVal}
                options={selectVal.psdIni}
                isClearable={true}
                isSearchable={true}
                onChange={(e) => {
                  if (data.working_hour.length > 0 && e !== null) {
                    handlePSBlock(e);
                  } else {
                    setSelectVal({
                      ...selectVal,
                      psdVal: "",
                    });
                    setData({
                      ...data,
                      psBlock: "",
                    });
                    toast.info("Please choose a date that accept bookings");
                  }
                }}
              />
              {data.psPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>

          <div className="flex flex-col h-auto sm:pt-7">
            <div className="px-2 py-3 h-auto">
              <button
                onClick={() => {
                  handleAdd();
                }}
                type="submit"
                className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary py-1.5 p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Block Chalet"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
