import { toast } from "react-toastify";
import { BOOKING } from "../../../api/booking";
import { useNavigate } from "react-router-dom";
import GIF from "../../admin/dashboard/loading.gif";
import { TEMP_BOOKING } from "../../../api/tempBooking";
import { TempContext } from "../../../utils/TempContext";
import React, { useContext, useEffect, useState } from "react";
import usePreventBackNavigation from "../../../utils/usePreventBackNavigation";
export default function PaymentSuccess() {
  usePreventBackNavigation("/book-now");
  //**constant variables
  const navigate = useNavigate();
  const { temp, setTemp } = useContext(TempContext);

  //**constant variables
  //useState
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    pnr: "",
    date: "",
    booking: {},
  });
  //useState
  //function

  const goToBookings = () => {
    navigate(`/booking-details/date/${data.date}/pnr/${data.pnr}`);
  };
  const saveData = async () => {
    try {
      await localStorage.setItem("temp", "");
      // console.log("Data successfully saved");
    } catch (e) {
      // console.log("Failed to save the data to the storage");
    }
  };

  const getTempData = async (id) => {
    try {
      const res = await TEMP_BOOKING.GET_BY_ID(id);
      if (res.status === 200) {
        console.log("res-temp_by_id", res.data);
        setData({
          ...data,
          pnr: res.data[0].pnr,
          booking: res.data[0],
          date: res.data[0].date,
        });
        data.pnr = res.data[0].pnr;
        data.booking = res.data[0];
        data.date = res.data[0].date;
        await bookingByPnr(res.data[0].pnr, res.data[0].date);
      } else {
        throw new Error("Failed to fetch temporary booking data");
      }
    } catch (error) {
      // console.error("Error fetching temporary booking data:", error);
    }
  };
  const deleteTempData = async (id) => {
    try {
      const res = await TEMP_BOOKING.DELETE(id);
      if (res.status === 200) {
        console.log("res-temp_by_id", res.data);
        setTemp("null");
        saveData();
      } else {
        throw new Error("Failed to fetch temporary booking data");
      }
    } catch (error) {
      // console.error("Error fetching temporary booking data:", error);
    }
  };
  const [refresh, setRefresh] = useState(false);
  const bookingByPnr = async (pnr, date) => {
    // console.log("in ", pnr, "date", date);
    try {
      const res = await BOOKING.GET_BY_PNR(date, pnr);
      if (res.status === 200) {
        setLoading(false);
        deleteTempData(temp);
        navigate(`/booking-details/date/${date}/pnr/${pnr}`);
      } else {
        toast.error("Failed to book your ticket for the selected date!");
        toast.info(
          "The amount debited will be refunded within 3 working days."
        );
        navigate(`/`);
        setRefresh(!refresh);
      }
    } catch (error) {
      setRefresh(!refresh);
    }
  };
  //function

  //useEffect
  useEffect(() => {
    setLoading(true);
    if (temp !== "null") {
      getTempData(temp);
    }
  }, [temp]);

  useEffect(() => {
    if (data.pnr !== "") {
      bookingByPnr(data.pnr, data.date);
    } else {
      setRefresh(!refresh);
    }
  }, [data]);

  useEffect(() => {
    const handlePopState = (event) => {
      // Navigate to a specific page when the back button is clicked
      navigate("/", { replace: true });
    };
    // console.log("in useEffect");

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  //useEffect
  return (
    <div className="py-25 ">
      <div className="w-full flex h-full justify-center flex-col items-center">
        <h1>Payment Successful!</h1>
      </div>
      {loading ? (
        <div className="w-full flex h-full justify-center flex-col items-center">
          <img src={GIF} alt="" />
          <p className="text-primary text-xl font-bold">Booking Tickets....</p>
        </div>
      ) : (
        <div className="w-full flex h-full justify-center flex-col items-center">
          <button
            onClick={() => {
              goToBookings();
            }}
            className="bg-primary px-20 py-2 rounded-lg text-white my-2"
          >
            View Bookings
          </button>
        </div>
      )}
      {/* You can add more logic to handle the redirect URL as needed */}
    </div>
  );
}
