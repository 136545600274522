import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const CONTACT = {
  ADD(req) {
    const url = `${CONSTANT.URL}contact`;
    return API_REQUEST("POST", url, req);
  },
  // ADD(req, token) {
  //   const url = `${CONSTANT.URL}contact`;
  //   return API_REQUEST("POST", url, req, {
  //     Authorization: `Bearer ${token}`,
  //   });
  // },

  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}contact/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}contact/search`;
    return API_REQUEST("GET", url);
  },

  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}contact/${id}`;
    return API_REQUEST("GET", url);
  },

};
