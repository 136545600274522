import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useNavigationType, Link, UNSAFE_NavigationContext } from 'react-router-dom';


export default function Test() {
  const [isChanged, setIsChanged] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();




  function useNavigationPrompt(message, when, redirectPath) {
    const navigate = useNavigate();
    const location = useLocation();
    const previousPath = location.state?.from || redirectPath;

    useEffect(() => {
      window.history.pushState({ noBackExitsApp: true }, '');

      const handleClose = ((event) => {
        if (when) {
          event.preventDefault()
          event.returnValue = ""
        }
      })

      // Function to handle back button click
      const handlePopState = (event) => {
        console.log('@innnnn', event.state)
        if (event.state && event.state.noBackExitsApp) {
          console.log("Back button pressed, redirecting...");
          if (when) {
            const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
            if (confirmLeave) {
              navigate(previousPath, { replace: true });
            }
          }
          else {
            navigate(previousPath, { replace: true });
          }
        }
        else {
          navigate(previousPath, { replace: true });
        }
      };

      const handleNavigation = (event) => {
        if (when) {
          const confirmLeave = window.confirm(message);
          if (!confirmLeave) {
            event.preventDefault();
          }
        }
      };

      window.addEventListener("beforeunload", handleClose)
      window.addEventListener('popstate', handlePopState);
      // window.addEventListener('click', handleNavigation); // Listen for link clicks

      return () => {
        window.removeEventListener("beforeunload", handleClose)
        window.removeEventListener('popstate', handlePopState);
        // window.removeEventListener('click', handleNavigation);
      };
    }, [when, message, navigate, location.pathname]);
  }

  const clearStateData = (newpath, when, event) => {
    if (newpath !== location.pathname) {
      if (when) {
        console.log("clear function")
      } else {
        navigate(newpath, { replace: true });
      }
    } else {
      event.preventDefault(); // Prevents unnecessary action
      navigate(newpath, { replace: true });
    }
  };
  // const handleNavigationState = (newpath, when, event) => {
  //   if (newpath !== location.pathname) {
  //     console.log("Nav button pressed, redirecting...", when);

  //     if (when) {
  //       const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
  //       if (confirmLeave) {
  //         console.log("User confirmed, navigating...");
  //         navigate(newpath, { replace: true });
  //       } else {
  //         console.log("User canceled, staying on the current page");
  //         event.preventDefault(); // Prevents the default action (e.g., page refresh)
  //         // navigate(location.pathname, { replace: true });
  //       }
  //     } else {
  //       console.log("No unsaved changes, navigating...");
  //       navigate(newpath, { replace: true });
  //     }
  //   } else {
  //     console.log("Navigating to the same path, no action needed");
  //     event.preventDefault(); // Prevents unnecessary action
  //     navigate(newpath, { replace: true });
  //   }
  // };
  // useEffect(() => {
  //   const handleClick = (event) => {
  //     const newpath = event.view.location.pathname;
  //     handleNavigationState(newpath, isChanged, event);
  //   };

  //   document.body.addEventListener('click', handleClick);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     document.body.removeEventListener('click', handleClick);
  //   };
  // }, [isChanged]);

  useEffect(() => {
    document.body.addEventListener('click', (event) => {
      console.log(event.view.location.pathname)
      // handleNavigationState(event.view.location.pathname, isChanged, event)
      clearStateData(event.view.location.pathname, isChanged, event)
    });
  }, [isChanged]);

  useNavigationPrompt("You have unsaved changes. Are you sure you want to leave?", isChanged, "/");


  return (
    <div className='bg- h-screen w-screen pt-50'>
      <h1>Your Component Content</h1>

      <button className='bg-red text-white px-5 py-2 rounded-lg mx-10' onClick={() => {
        setIsChanged(!isChanged)
      }}>Change Value -- {isChanged ? "Changed" : "Not Changed"}</button>

      <Link to="/another-page" className='bg-blue text-white px-5 py-2 rounded-lg mx-10'>
        Go to Another Page
      </Link>
    </div>
  );
}
