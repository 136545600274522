import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { BOOKING_TYPE } from "../../../api/bookingType";

export default function BookingType() {
  const { token } = useContext(TokenContext);
  const { checkAccessTokenValidity } = UseAuth();
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [reRunData, setReRunData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emp, setEmp] = useState([]);
  const [id, setId] = useState("");
  const [data, setData] = useState({
    _id: "",
    type: "",
    adult_price: "",
    child_price: "",
    isPurged: false,
  });
  const tableSessionRef = useRef(null);
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  emp.sort((a, b) => {
    const dateTimeA = new Date(a.createdAt).getTime();
    const dateTimeB = new Date(b.createdAt).getTime();

    return dateTimeB - dateTimeA;
  });
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  const navigate = useNavigate();

  const topSessionRef = useRef(null);

  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const handleEdit = (item) => {
    scrollToSection();
    setIsEdit(true);
    setData({
      ...data,
      _id: item._id,
      type: item.type,
      adult_price: item.adult_price,
      child_price: item.child_price,
      isPurged: false,
    });
    data.type = item.type;
    data.adult_price = item.adult_price;
    data.child_price = item.child_price;
  };
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };

  const clearData = () => {
    setData({
      ...data,
      type: "",
      adult_price: "",
      child_price: "",
      isPurged: false,
    });
  };
  //validate
  const [validate, setValidate] = useState(false);
  //validate

  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await BOOKING_TYPE.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch booking information");
        } else {
          var req = {
            type: res.data[0].type,
            adult_price: res.data[0].adult_price,
            child_price: res.data[0].child_price,
            isPurged: false,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await BOOKING_TYPE.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Deleted admin");
        navigate("/booking-type");
      } else {
        toast.error("Failed to delete admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  //delete

  //update
  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await BOOKING_TYPE.UPDATE(values, data._id, token);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
        toast.success("Updated admin");
        navigate("/booking-type");
      } else {
        toast.error("Failed to updated admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const handleUpdate = async () => {
    if (
      data.type !== "" &&
      data.adult_price !== "" &&
      data.child_price !== ""
    ) {
      setValidate(false);
      setLoading(true);
      var req = {
        type: data.type,
        adult_price: data.adult_price,
        child_price: data.child_price,
        isPurged: false,
      };
      updateData(req);
    } else {
      setValidate(true);
    }
  };
  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  //update

  ///add
  const addData = async (values) => {
    setLoading(true);
    values.password = values.name + "@" + 2024;
    try {
      const res = await BOOKING_TYPE.ADD(values, token);
      setLoading(false);
      setReRunData(null);
      clearData();
      if (res.status === 200) {
        setRefreshAdd(!refreshAdd);
        toast.success("Created New Admin");
        navigate("/booking-type");
      } else {
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAdd = async () => {
    if (
      data.type !== "" &&
      data.adult_price !== "" &&
      data.child_price !== ""
    ) {
      setValidate(false);
      setLoading(true);
      var req = {
        type: data.type,
        adult_price: data.adult_price,
        child_price: data.child_price,
        isPurged: false,
      };
      addData(req);
    } else {
      setValidate(true);
    }
  };
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  ///add

  //get
  const getEmpList = async () => {
    try {
      const res = await BOOKING_TYPE.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (name) => {
    try {
      const res = await BOOKING_TYPE.SEARCH(name);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);
  //get

  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Booking Types" />
      </div>
      <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="max-w-full overflow-x-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="flex flex-col">
              <div className="px-2 py-2">
                <label className="mb-2.5 block text-black dark:text-white">
                  Type <span className="text-meta-1">*</span>
                </label>
                <input
                  onChange={(e) => {
                    setData({
                      ...data,
                      type: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="Enter name of event"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="type"
                  value={data.type}
                />
                {data.type === "" && validate && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Adult Price <span className="text-meta-1">*</span>
                </label>
                <input
                  type="number"
                  onChange={(e) => {
                    setData({
                      ...data,
                      adult_price: e.target.value,
                    });
                  }}
                  placeholder="Enter adult price for this event"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="adult_price"
                  value={data.adult_price}
                />
                {data.adult_price === "" && validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Child Price <span className="text-meta-1">*</span>
                </label>
                <input
                  type="number"
                  onChange={(e) => {
                    setData({
                      ...data,
                      child_price: e.target.value,
                    });
                  }}
                  placeholder="Enter child price for this event"
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                  name="child_price"
                  value={data.child_price}
                />
                {data.child_price === "" && validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
              </div>
            </div>
            <div className="flex flex-col h-auto sm:pt-7">
              <div className="px-2 py-3 h-auto">
                <button
                  onClick={() => {
                    if (isEdit) {
                      handleUpdate();
                    } else {
                      handleAdd();
                    }
                  }}
                  type="submit"
                  className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                >
                  {loading ? (
                    <div className="flex h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : isEdit ? (
                    "Update Type"
                  ) : (
                    "Add Type"
                  )}
                </button>
                {isEdit && (
                  <button
                    onClick={() => {
                      backToAdd();
                    }}
                    type="button"
                    className="flex w-full justify-center py-3 font-sm text-sm text-red"
                  >
                    Add New
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={tableSessionRef} className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="max-w-full overflow-x-auto">
          <input
            onChange={(e) => {
              setSearchVal(e.target.value);
            }}
            value={searchVal}
            type="text"
            className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
            placeholder="Search by booking type..."
          />

          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                  Type
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Adult Price
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Child Price
                </th>
                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems !== undefined && (
                <>
                  {currentItems.map((data) => (
                    <tr key={data._id}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.type}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.adult_price}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <h5 className="font-medium text-black dark:text-white">
                          {data.child_price}
                        </h5>
                      </td>

                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <button
                            className="hover:text-primary"
                            onClick={() => handleEdit(data)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="fill-current"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill=""
                                d="M13.6568542,2.34314575 C14.4379028,3.12419433 14.4379028,4.39052429 13.6568542,5.17157288 L6.27039414,12.558033 C5.94999708,12.87843 5.54854738,13.105727 5.10896625,13.2156223 L2.81796695,13.7883721 C2.45177672,13.8799197 2.12008033,13.5482233 2.21162789,13.182033 L2.78437771,10.8910338 C2.894273,10.4514526 3.12156995,10.0500029 3.44196701,9.72960586 L10.8284271,2.34314575 C11.6094757,1.56209717 12.8758057,1.56209717 13.6568542,2.34314575 Z M10.1212441,4.46435931 L4.14907379,10.4367126 C3.95683556,10.6289509 3.82045738,10.8698207 3.75452021,11.1335694 L3.38388341,12.6161166 L4.86643062,12.2454798 C5.1301793,12.1795426 5.37104912,12.0431644 5.56328736,11.8509262 L11.5352441,5.87835931 L10.1212441,4.46435931 Z M11.5355339,3.05025253 L10.8282441,3.75735931 L12.2422441,5.17135931 L12.9497475,4.46446609 C13.3402718,4.0739418 13.3402718,3.44077682 12.9497475,3.05025253 C12.5592232,2.65972824 11.9260582,2.65972824 11.5355339,3.05025253 Z"
                              ></path>
                            </svg>
                          </button>
                          <button
                            className="hover:text-primary"
                            onClick={() => handleDelete(data._id)}
                          >
                            <svg
                              className="fill-current"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                fill=""
                              />
                              <path
                                d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                fill=""
                              />
                              <path
                                d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                fill=""
                              />
                              <path
                                d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                fill=""
                              />
                            </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <PaginatedItems
          itemsPerPage={itemsPerPage}
          array={emp}
          scrollToSection={scrollToTableSection}
          setItemOffset={setItemOffset}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
