// utils/useLanguage.js
import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const useLanguage = () => {
  const [language, setLanguage] = useLocalStorage('language', 'en'); // Default to English

  useEffect(() => {
    const dir = language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.dir = dir;
  }, [language]);

  return [language, setLanguage];
};

export default useLanguage;
