// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_txt {
  font-weight: 700;
  /* font-size: 20px; */
  margin-bottom: 5px;
}
.desp_txt {
  font-weight: 400;
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/unAuth/terms/Terms.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".title_txt {\r\n  font-weight: 700;\r\n  /* font-size: 20px; */\r\n  margin-bottom: 5px;\r\n}\r\n.desp_txt {\r\n  font-weight: 400;\r\n  margin-bottom: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
