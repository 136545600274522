import { CONSTANT } from '../constant/Constant';
import { API_REQUEST } from './api';
export const OFFERS = {

  ADD(req, token) {
    const url = `${CONSTANT.URL}offers`;
    return API_REQUEST("POST", url, req, {
      "Content-Type": "multipart/form-data",
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}offers`;
    return API_REQUEST("GET", url);
  },
  GET_ACTIVE() {
    const url = `${CONSTANT.URL}offers/active/show`;
    return API_REQUEST("GET", url);
  },

  UPDATE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}offers/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  DELETE(req, id, token) {
    console.log("id", id);
    const url = `${CONSTANT.URL}offers/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    });
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}offers/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_DATE(date) {
    const url = `${CONSTANT.URL}offers/date/${date}`;
    return API_REQUEST("GET", url);
  },
};