import * as Yup from "yup";
import Content from './Content';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import CONTACT from "../../../assets/contact.png";
import { CONTACT as CONTACT_API } from "../../../api/contact";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
export default function Contact() {
  
  //constant variables
  const MyTextArea = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <textarea
          className="w-full h-36 font-poppins py-3 px-3 border-[1.5px] border-stroke bg-transparent  font-medium outline-none transition focus:border-black active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-black"
          {...field}
          {...props}
        />
      </>
    );
  };
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
    isPurged: false
  })
  //constant variables

  //function
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    try {
      const res = await CONTACT_API.ADD(values);
      setLoading(false);
      if (res.status === 200) {
        toast.success("Your message has been sent successfully!");
        setData(values);
        resetForm();
      } else {
        toast.error("Failed to send message. Please try again later.");
        throw new Error("Failed to send message");
      }
    } catch (error) {
      setLoading(false);
      console.error('Failed to send message:', error);
      toast.error("Failed to send message. Please try again later.");
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    message: Yup.string().required("Required").min(10, "Too Short!"),
    email: Yup.string().required("Required"),
  });
  //function
  return (
    <div className='h-auto w-full pt-20'>
      <div className=' w-full h-70 md:h-100'>
        <img
          src={CONTACT}
          alt="images"
          style={{ width: "100%", height: "100%" }}
          className=" object-cover"
        />
      </div>
      <div className='w-full bg-intro h-full flex justify-center items-center'>
        <div className='  w-[75%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-25  my-[130px]'>
          <div className='   h-auto col-span-1 '>
            <div className=" w-full h-auto ">
              <p className="text-2xl font-extrabold font-poppins 2xl:text-[60px]">
                CONTACT US
              </p>
              <p className="2xl:text-[19px] text-base mt-7 font-poppins text-ashh">
                Get in touch with us today!
              </p>
            </div>
            <Formik
              initialValues={data}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <Form className=' w-full h-auto  grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 '>
                <div className=''>
                  <div className="grid grid-cols-1 gap-9 sm:grid-cols-1 ">
                    <div className="flex flex-col gap-9">
                      <div className="mt-4">
                        <div className="w-full">
                          <label className="mb-2.5 block dark:text-white text-[14px] font-poppins">
                            Your Name
                          </label>
                          <Field
                            placeholder="Enter Your Name"
                            className="w-full font-poppins text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-black active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            name="name"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '');
                            }}
                          />
                          <ErrorMessage
                            component="a"
                            className="text-danger"
                            name="name"
                          />
                        </div>
                        <div className="w-full mt-4">
                          <label className="mb-2.5 text-black font-poppins block dark:text-white text-[14px]">
                            Your email
                          </label>
                          <Field
                            placeholder="Enter Your email"
                            className="w-full font-poppins text-ashh text-[15px] border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-black disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-black"
                            name="email"
                            onInput={(e) => {
                              // Allow only alphabets, @, and dots, remove others
                              e.target.value = e.target.value.replace(/[^a-zA-Z0-9@.]/g, '');
                            }}
                          />
                          <ErrorMessage
                            component="a"
                            className="text-danger"
                            name="email"
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className=''>
                  <div className="w-full mt-4">
                    <label className="mb-2.5  block text-black dark:text-white text-[14px] font-poppins">
                      Message
                    </label>
                    <MyTextArea
                      name="message"
                      rows="4"
                      placeholder="Please explain your enquiry"
                      onInput={(e) => {
                        // Allow only alphabets, @, dots, and commas, remove others
                        e.target.value = e.target.value.replace(/[^a-zA-Z@., ]/g, '');
                      }}
                    />
                    <ErrorMessage
                      component="a"
                      className="text-danger"
                      name="message"
                    />
                  </div>
                </div>
                <div className=' w-full h-auto py-5 '>
                  <button className=' bg-yellow text-black text-xl h-15 w-45 font-bold font-poppins text-center'
                    type="submit">
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "SUBMIT"
                    )} </button>
                </div>
              </Form>
            </Formik>
          </div>
          <div className=' col-span-1 h-auto '>
            {/* <div className=' w-auto h-auto pt-6'>
              <p className='text-contact font-semibold font-poppins text-[25px]'>OFFICE ADDRESS</p>
            </div>
            <div className=' w-auto h-auto pt-1'>
              <p className='text-black font-semibold text-[20px] font-poppins'>Aqua magic <br></br>
                <span className='font-thin text-ashh'>Aqua Magic Rawdat Al Jahhaniya, Al Rayyan, </span><br></br><span className='font-thin text-ashh'> Kuwait, </span></p>
            </div>
            <div className=' w-auto h-auto pt-6'>
              <p className='text-contact font-semibold font-poppins text-[25px]'>CONTACT</p>
              <p className='text-ashh font-thin text-[20px]  font-poppins pt-2'>Email : sales.dubai@gmail.com <br></br><span className='ml-16'>Contact.dubai@gmail.com</span></p>
            </div>
            <div className=' w-auto h-auto pt-6'>
              <p className='text-ashh font-thin text-[20px] font-poppins'>Toll Free: +91 8089898455, Mobile: +91 8943300302</p>
            </div> */}
          </div>
        </div>
      </div>
      < div > <Content />  </div >
    </div>
  )
}
