export const HutIcon = () => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.88 115.63"
            fill="white"
            className="w-[18px]"
        >
            <title>hut</title>
            <path d="M19.86,63.7a3.33,3.33,0,0,1,0,.63l-7.12,45.16H42.52V87.16a18.26,18.26,0,0,1,36.52,0v22.33h30.85L103.67,64.2a4.07,4.07,0,0,1,0-.5Zm28.8,45.79H72.9V87.16a12.12,12.12,0,0,0-24.24,0v22.33ZM109.78,63.7l6.62,48.21a2.81,2.81,0,0,1,.07.65,3.06,3.06,0,0,1-3.07,3.07H9.13a2.49,2.49,0,0,1-.47,0,3.07,3.07,0,0,1-2.56-3.49L13.73,63.7H0A138.81,138.81,0,0,1,42.74,11.16c19.92-14.89,18.4-14.9,37.91.09A141.25,141.25,0,0,1,122.88,63.7Z" />
        </svg>
    );
};