export const BlockIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        fill="#fff"
        className="w-[19px]"
      >
        <path d="M 25 2 C 12.326643 2 2 12.326643 2 25 C 2 37.673357 12.326643 48 25 48 C 37.673357 48 48 37.673357 48 25 C 48 12.326643 37.673357 2 25 2 z M 25 4 C 36.588643 4 46 13.411357 46 25 C 46 36.588643 36.588643 46 25 46 C 13.411357 46 4 36.588643 4 25 C 4 13.411357 13.411357 4 25 4 z M 25 6 C 20.523675 6 16.401638 7.5702753 13.152344 10.169922 L 12.28125 10.867188 L 39.132812 37.71875 L 39.830078 36.847656 C 42.429725 33.598362 44 29.476325 44 25 C 44 14.532599 35.467401 6 25 6 z M 25 8 C 34.382599 8 42 15.617401 42 25 C 42 28.596865 40.748192 31.842246 38.833984 34.591797 L 15.408203 11.166016 C 18.157754 9.2518073 21.403135 8 25 8 z M 10.867188 12.28125 L 10.169922 13.152344 C 7.5702753 16.401638 6 20.523675 6 25 C 6 35.467401 14.532599 44 25 44 C 29.476325 44 33.598362 42.429725 36.847656 39.830078 L 37.71875 39.132812 L 10.867188 12.28125 z M 11.166016 15.408203 L 34.591797 38.833984 C 31.842246 40.748192 28.596865 42 25 42 C 15.617401 42 8 34.382599 8 25 C 8 21.403135 9.2518073 18.157754 11.166016 15.408203 z" />
      </svg>
    );
  };