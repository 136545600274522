import React from 'react'

export default function Content() {
    return (
       <div className='bg-bgbg'>
      {/* <div className='bg-bgbg h-auto w-auto flex justify-center items-center 2xl:py-15 sm:py-0 '>
            <div className=' w-[65%] h-auto 2xl:py-[100px] sm:py-[50px]  '>
                <div className=' h-auto w-auto '>
                    <p className='text-black lg:text-[48px] text-[30px] text-center font-mont font-thin leading-10 '>STEP INTO A <br></br><span className='text-black  font-extrabold'>WORLD OF MAGIC,</span><span>WHERE</span><br></br><span className='font-extrabold text-black'>JOY SPRINGS TO LIFE !</span></p>
                </div>
                <div className=' 2xl:pt-10 h-auto 2xl:w-auto lg:w-[800px] md:w-[700px]  max-w-screen-2xl pt-3  '>
                    <p className=' font-mont text-black font-normal text-center lg:text-[22px] text-base md:text-[20px]'>Duis ut egestas dolor. Sed vel tellus pulvinar, sodales diam viverra, luctus leo. Suspendisse dignissim nisl sit amet diam facilisis, ac scelerisque nulla dignissim. Pellentesque lobortis fringilla sagittis. In bibendum felis in purus ultrices, sed elementum lacus.<br></br><br></br> Consequat. Nulla id finibus leo, et mattis magna. Mauris nec ultrices urna, a sodales lectus. Nunc condimentum, dui sit amet condimentum rhoncus, erat sapien aliquet mi, et pellentesque tortor nunc nec velit. Duis interdum justo eget ante viverra, eu gravida lorem euismod. Nulla ornare, lectus vel vulputate faucibus, velit lorem auctor erat, ut mollis ipsum purus et metus. Nulla libero orci, dictum a tellus eget, aliquam ornare nibh. Sed sed ornare lorem. Nulla nisi quam.</p>
                </div>
            </div>
        </div> */}
      </div> 
    )
}
