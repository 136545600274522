import { BOOKING } from "../../../api/booking";
import { TEMP_BOOKING } from "../../../api/tempBooking";
import { CONSTANT } from "../../../constant/Constant";
import { TempContext } from "../../../utils/TempContext";
import { BookingContext } from "../../../utils/BookingContext";
import React, { useContext, useEffect, useRef, useState } from "react";

const Checkout = ({ price, tempId, data, pnr }) => {
  //constant variables
  const { temp, setTemp } = useContext(TempContext);
  const { session, setSession } = useContext(BookingContext);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const scriptLoaded = useRef(false);
  const checkoutInitialized = useRef(false);

  const DOMAIN = `${CONSTANT.BASE_URL}`;
  const REDIRECT_URL = `${CONSTANT.BASE_URL}payment-success`;
  const CANCEL_URL = `${CONSTANT.BASE_URL}book-now`;
  const WEBHOOK_URL = `${CONSTANT.BASE_URL}api/payment/webhook`;

  //constant variables

  //useState
  const [addData, setAddData] = useState([]);
  //useState

  //functions
  const getTempData = async (id) => {
    try {
      const res = await TEMP_BOOKING.GET_BY_ID(id);
      if (res.status === 200) {
        setAddData(res.data[0]?.data || []);
        // setTemp(res.data[0].data);
      } else {
        throw new Error("Failed to fetch temporary booking data");
      }
    } catch (error) {
      // console.error("Error fetching temporary booking data:", error);
    }
  };
  //functions
  //useEffect
  useEffect(() => {
    getTempData(tempId);
  }, [tempId]);
  const clearStorage = async () => {
    try {
      await localStorage.setItem("session", null);
      // console.log("Storage successfully cleared!");
    } catch (e) {
      // console.log("Failed to clear the async storage.");
    }
  };
  useEffect(() => {
    if (checkoutInitialized.current) {
      // console.log("Checkout already initialized");
      return; // Prevent multiple initializations
    }

    if (!scriptLoaded.current) {
      // console.log("Loading checkout script");

      const script = document.createElement("script");
      script.src = "https://assets.ottu.net/checkout/v3/checkout.min.js";
      script.async = true;

      script.onload = async () => {
        scriptLoaded.current = true;
        // console.log("Checkout script loaded");

        if (!window.Checkout) {
          // console.error("Checkout object not found on window");
          return;
        }

        window.beforePayment = function (data) {
          // console.log("Before payment callback triggered", data);
          return new Promise((resolve) => {
            fetch(`${DOMAIN}/basket/freeze`, {
              method: "POST",
            })
              .then(() => {
                window.Checkout.showPopup(
                  "redirect",
                  data.message || "Redirecting to the payment page",
                  null
                );
                resolve(true);
              })
              .catch((err) => {
                // console.error("Error in beforePayment:", err);
                resolve(true);
              });
          });
        };

        window.errorCallback = function (data) {
          console.error("Error callback triggered:", data);
          const validFormsOfPayments = ["token_pay", "redirect"];
          if (
            validFormsOfPayments.includes(data.form_of_payment) ||
            data.challenge_occurred
          ) {
            const message =
              "Oops, something went wrong. Refresh the page and try again.";
            window.Checkout.showPopup("error", data.message || message);
          }
          unfreezeBasket();
          setPaymentStatus("error");
        };

        window.successCallback = function (data) {
          console.log("Success callback triggered:", data);
          // window.location.href = `${REDIRECT_URL}?session_id=${data.redirect_url}&tempId=${tempId}`;
          setPaymentStatus("success");
          setSession(null);
          clearStorage();
        };

        window.cancelCallback = function (data) {
          console.warn("Cancel callback triggered:", data);
          if (
            data.payment_gateway_info &&
            data.payment_gateway_info.pg_name === "kpay"
          ) {
            // console.log(
            //   "if.pg_response",
            //   data.payment_gateway_info.pg_response
            // );
            window.Checkout.showPopup(
              "error",
              "",
              data.payment_gateway_info.pg_response
            );
          } else if (
            data.form_of_payment === "token_pay" ||
            data.challenge_occurred
          ) {
            const message =
              "Oops, something went wrong. Refresh the page and try again.";
            window.Checkout.showPopup("error", data.message || message);
            // console.log("message", message);
            // console.log("data.message", data.message);
          }
          setSession(null);
          clearStorage();
          unfreezeBasket();
          window.location.href = CANCEL_URL;
          setPaymentStatus("canceled");
        };

        function unfreezeBasket() {
          fetch(`${DOMAIN}/basket/unfreeze`, {
            method: "POST",
          }).catch((err) => console.error("Error unfreezing basket:", err));
        }

        async function getCheckoutSessionId() {
          // console.log("Fetching checkout session ID", checkoutInitialized);
          if (checkoutInitialized.current === false)
            try {
              const response = await fetch(CONSTANT.OTTU_BASE_URL, {
                method: "POST",
                headers: {
                  Authorization: `Api-Key ${CONSTANT.OTTU_API_KEY}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  type: "e_commerce", //prod
                  pg_codes: CONSTANT.PG_CODE,
                  amount: price.toString(),
                  // customer_id: "sandbox",
                  currency_code: "KWD",
                  redirect_url: REDIRECT_URL,
                  webhook_url: WEBHOOK_URL,
                  customer_phone: data.phoneNumber,
                  customer_email: data.email,
                  extra: {
                    full_name: data.name,
                    pnr_no: pnr,
                  },
                  // customer_phone: "1234567890",
                  // customer_email: "test@gmail.com",
                  // extra: {
                  //   full_name: "Allan",
                  //   pnr_no: "PNR_NO",
                  // },
                }),
              });

              const responseData = await response.json();
              // console.log("Checkout session ID fetched:", responseData);
              return responseData.session_id;
            } catch (err) {
              // console.error("Error fetching checkout session ID:", err);
            }
        }

        const sessionid = await getCheckoutSessionId();
        if (sessionid && checkoutInitialized.current === false) {
          document.getElementById("loading").classList.add("d-none");
          // console.log("Initializing checkout with session ID:", sessionid);
          window.Checkout.init({
            selector: "checkout",
            merchant_id: CONSTANT.MERCHANT_ID, //prod
            // merchant_id: "sandbox.ottu.net", //dev
            session_id: sessionid,
            apiKey: CONSTANT.OTTU_API_KEY_INIT,
            formsOfPayment: [
              "applePay",
              "tokenPay",
              "ottuPG",
              "redirect",
              "googlePay",
              "stcPay",
            ],
            theme: {
              "title-text": { "font-family": "system-ui" },
              amount: { "font-family": "system-ui" },
              "amount-label": { "font-family": "system-ui" },
              "amount-currency": { "font-family": "system-ui" },
              "pay-button": { "font-family": "system-ui" },
              "payment-method-name": { "font-family": "system-ui" },
            },
          });

          checkoutInitialized.current = true;
        } else {
          // console.error("Failed to initialize checkout: No session ID");
        }
      };

      document.head.appendChild(script);

      return () => {
        // console.log("Cleaning up checkout process");
        document.head.removeChild(script);
      };
    } else {
      // console.log("Checkout script already loaded");
    }
  }, [price, addData]);
  //useEffect
  window.onerror = function (message, source, lineno, colno, error) {
    console.error("Global error caught: ", message, source, lineno, colno, error);
  };
  console.log("checkout", paymentStatus)
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center p-2" id="loading">
          <div
            className="spinner-border"
            style={{ color: "red" }}
            role="status"
          >
            <span className="visually-hidden"></span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col" style={{ padding: "20px" }}>
          <div id="checkout"></div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
