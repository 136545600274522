export const CONSTANT = {
  URL: "https://aquamagickw.com/api/",
  BASE_URL: "https://aquamagickw.com/",

  // URL: "https://qa.aquamagickw.com/api/",
  // BASE_URL: "https://qa.aquamagickw.com/",

  // URL: "http://localhost:8080/api/",
  // BASE_URL: "http://localhost:3000/",

  // OTTU_API_KEY: "GYj5Na8H.29g9hqNjm11nORQMa2WiZwIBQQ49MdAL", //dev
  // OTTU_API_KEY_INIT: "GYj5Na8H.29g9hqNjm11nORQMa2WiZwIBQQ49MdAL", //dev
  // OTTU_BASE_URL: "https://sandbox.ottu.net/b/checkout/v1/pymt-txn/", //dev
  // PG_CODE: ["aub-test-alhil", "knet-test"], //dev
  // MERCHANT_ID: "sandbox.ottu.net" //dev
  
  OTTU_API_KEY: "vXlPnlgy.Js1rPknpXzexR5n2hbpiqNk7O4U2jvPD", //prod
  OTTU_API_KEY_INIT: "d60015aa9551067894a24ed3eb6bb3998004821a", //prod
  OTTU_BASE_URL: "https://online.aquamagickw.com/b/checkout/v1/pymt-txn/", //prod
  PG_CODE: ["hesabe"],  //prod
  MERCHANT_ID : "online.aquamagickw.com" //prod
};
export const BookingType = [
  {
    label: "Park",
    value: "Park",
  },
  {
    label: "Chalot",
    value: "Chalot",
  },
];


/// 0 => One Day Ticket
/// 1 => Deluxe Chalet
/// 2 => Standard Chalet
/// 3 => Deluxe Free Ticket
/// 4 => Standard Free Ticket
/// 5 => Maid ticket 
/// 6 => Specially Abled
/// 7 => Premium Standard
/// 8 => Free Premium Standard
/// 9 => Free guest