import React from 'react';
import useLanguage from '../../utils/useLanguage';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const [language, setLanguage] = useLanguage();
    const AR = "https://cdn.britannica.com/82/5782-004-BD7056FF/Flag-United-Arab-Emirates.jpg"
    const EN = "https://upload.wikimedia.org/wikipedia/commons/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg"
    const { i18n } = useTranslation();
    return (
        <label
            className={`relative m-0 block h-8 w-14 rounded-full ${language === 'ar' ? 'bg-primary' : 'bg-stroke'
                }`}
        >
            {/* <img src={language === 'ar' ? EN : AR} alt="flag" className='w-full h-full object-cover rounded-full' /> */}
            <input
                type="checkbox"
                onChange={() => {
                    setLanguage(language === 'en' ? 'ar' : 'en');
                    i18n.changeLanguage(language === 'en' ? 'ar' : 'en');
                }}
                className="absolute top-0 z-50 m-0 h-full w-full cursor-pointer opacity-0"
            />
            <span
                className={`absolute top-1/2 left-[3px] flex h-6 w-6 -translate-y-1/2 translate-x-0 items-center justify-center rounded-full bg-white shadow-switcher duration-75 ease-linear ${language === 'en' && 'right-[27px] !translate-x-full'
                    }`}
            >
                <span className={`${language === 'ar' ? 'hidden' : 'block'} h-full w-full flex justify-center items-center`}>
                    <p className='text-red text-sm font-bold'>AR</p>
                </span>
                <span className={`${language === 'ar' ? 'block' : 'hidden'} h-full w-full flex justify-center items-center`}>
                    <p className='text-primary text-sm font-bold'>EN</p>
                </span>
            </span>
        </label>
    );
};

export default LanguageSwitcher;


const EnglishIcon = () => {
    return (
        <svg data-name="Layer 1" viewBox="0 0 24 24" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M18.97021,19.75732,15.34912,5.27246A2.9958,2.9958,0,0,0,12.43848,3h-.877A2.9958,2.9958,0,0,0,8.65088,5.27246L5.02979,19.75732a1.0001,1.0001,0,0,0,1.94042.48536L8.28082,15h7.43836l1.31061,5.24268a1.0001,1.0001,0,0,0,1.94042-.48536ZM8.78082,13l1.81049-7.24219A.99825.99825,0,0,1,11.56152,5h.877a.99825.99825,0,0,1,.97021.75781L15.21918,13Z" fill="#6563ff" /></svg>
    )
}
const ArabicIcon = () => {
    return (
        <svg class="bi bi-badge-ar" fill="currentColor" height="16" width="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="m3.794 11 .47-1.542H6.27L6.739 11H8L5.996 5.001H4.607L2.595 11h1.2zm1.503-4.852.734 2.426h-1.52l.734-2.426h.052zm5.598-1.147H8.5V11h1.173V8.763h1.064L11.787 11h1.327L11.91 8.583C12.455 8.373 13 7.779 13 6.9c0-1.147-.773-1.9-2.105-1.9zm-1.222 2.87V5.933h1.05c.63 0 1.05.347 1.05.989 0 .633-.408.95-1.067.95H9.673z" /><path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z" /></svg>
    )
}