import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { WORKING_HOURS } from "../../../api/workingHours";
import { formatDateString } from "../../../utils/FormatDateString";
import { DC } from "../../../api/dlxChalet";
import { CS } from "../../../api/stdChalet";
import { PS } from "../../../api/preStd";
import Select from "react-select";
import { BLOCK } from "../../../api/block";
import TableData from "./TableData";
import EditNew from "./EditNew";
import AddNew from "./AddNew";
import { getDates } from "../../../utils/ManagaeDate";

export default function BusinessHours() {
  // states
  const [id, setId] = useState("");
  const [emp, setEmp] = useState([]);
  const [count, setCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [validate, setValidate] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [length, setLength] = useState({
    dlx: 0,
    std: 0,
    psd: 0,
    md: 0,
    sa: 0,
    odt: 100,
  });
  const [data, setData] = useState({
    _id: "",
    startDate: "",
    endDate: "",

    bookedCD: [],
    bookedCS: [],
    bookedPS: [],

    odtBookings: length.odt,
    cdBookings: length.dlx,
    csBookings: length.std,
    psBookings: length.psd,
    saBookings: length.sa,
    mdBookings: length.md,

    odtToday: 0,
    odtPrice: 0,
    odtExtra: 0,
    odtFree: 0,


    mdToday: 0,
    mdPrice: 0,
    mdExtra: 0,

    saToday: 0,
    saPrice: 0,
    saExtra: 0,

    cdToday: 0,
    cdPrice: 0,
    cdExtra: 0,
    cdFree: 2,

    csToday: 0,
    csPrice: 0,
    csExtra: 0,
    csFree: 1,

    psToday: 0,
    psPrice: 0,
    psExtra: 0,
    psFree: 1,

    isPurged: false,
  });
  const [selectVal, setSelectVal] = useState({
    dlxIni: [],
    stdIni: [],
    psdIni: [],
    dlx: [],
    std: [],
    psd: [],
  });
  const [errorMessage, setErrorMessage] = useState("");
  // states
  //constant variables
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const topSessionRef = useRef(null);
  const tableSessionRef = useRef(null);
  const { token } = useContext(TokenContext);
  const endOffset = itemOffset + itemsPerPage;
  const { checkAccessTokenValidity } = UseAuth();
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  // Get today's date in YYYY-MM-DD format for the min attribute
  const today = new Date().toISOString().split("T")[0];
  //constant variables
  //dependent functions
  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  emp.sort((a, b) => {
    const dateA = a.date.split("T")[0];
    const dateB = b.date.split("T")[0];
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };
  const clearData = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
      odtPrice: 0,
      odtBookings: length.odt,
      cdPrice: 0,
      cdBookings: length.dlx,
      csPrice: 0,
      csBookings: length.std,
      isPurged: false,
      cdFree: 2,
      csFree: 1,
      odtFree: 0,
    });
  };
  const [maxCount, setMaxCount] = useState({
    cd: 0,
    cs: 0,
    ps: 0,
  });
  const [blockVal, setBlockVal] = useState({
    cd: 0,
    cs: 0,
    ps: 0,
  });
  const getBlockByDate = async (date) => {
    try {
      const res = await BLOCK.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      } else {
        // console.log("res", res.data);
        if (res.data.length > 0) {
          setBlockVal({
            ...blockVal,
            cd: res.data[0].blockedCD.length,
            cs: res.data[0].blockedCS.length,
            ps:
              res.data[0].blockedPS !== undefined
                ? res.data[0].blockedPS.length
                : 0,
          });
          blockVal.cd = res.data[0].blockedCD.length;
          blockVal.cs = res.data[0].blockedCS.length;
          blockVal.ps =
            res.data[0].blockedPS !== undefined
              ? res.data[0].blockedPS.length
              : 0;
        } else {
          setBlockVal({
            ...blockVal,
            cd: 0,
            cs: 0,
            ps: 0,
          });
          blockVal.cd = 0;
          blockVal.cs = 0;
          blockVal.ps = 0;
        }
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const handleEdit = async (item) => {
    scrollToSection();
    setIsEdit(true);
    await getBlockByDate(item.date);
    const todaysCDBookings = item.cd.bookingToday;
    const todaysCSBookings = item.cs.bookingToday;
    const cdNum = selectVal.dlx.length;
    const csNum = selectVal.std.length;
    const psNum = selectVal.psd.length;
    // //  if the length of the response array is greater than zero,
    setMaxCount({
      ...maxCount,
      cd: cdNum - blockVal.cd,
      cs: csNum - blockVal.cs,
      ps: psNum - blockVal.ps,
    });
    maxCount.cd = cdNum - blockVal.cd;
    maxCount.cs = csNum - blockVal.cs;
    maxCount.ps = psNum - blockVal.ps;
    // console.log("maxCount", maxCount);
    // console.log("data", data);
    var arrayDlx = [];
    var arrayStd = [];
    var arrayPsd = [];
    selectVal.std.map((itm) => {
      if (item.bookedCS.includes(itm.value)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayStd.push(itm);
      }
      return arrayStd;
    });
    selectVal.dlx.map((itm) => {
      if (item.bookedCD.includes(itm.value)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayDlx.push(itm);
      }
      return arrayDlx;
    });
    selectVal.psd.map((itm) => {
      if (item.bookedPS.includes(itm.value)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayPsd.push(itm);
      }
      return arrayPsd;
    });
    setSelectVal({
      ...selectVal,
      dlxIni: arrayDlx,
      stdIni: arrayStd,
      psdIni: arrayPsd,
    });
    selectVal.dlxIni = arrayDlx;
    selectVal.stdIni = arrayStd;
    selectVal.psdIni = arrayPsd;
    // console.log("item", item);
    // console.log("selectVal", selectVal);
    // console.log("maxCount", maxCount);
    setData({
      ...data,
      _id: item._id,
      date: item.date,

      odtPrice: item.odt.price,
      odtBookings: item.odt.noOfBooking,
      odtToday: item.odt.bookingToday,
      mdPrice: item.odt.mdPrice !== undefined ? item.odt.mdPrice : 0,
      saPrice: item.odt.saPrice !== undefined ? item.odt.saPrice : 0,
      odtExtra: item.odt.extraBooking,

      bookedCD: item.bookedCD,
      bookedCS: item.bookedCS,
      bookedPS: item.bookedPS,

      cdPrice: item.cd.price,
      cdBookings: item.cd.noOfBooking,
      cdToday: item.cd.bookingToday,
      cdExtra: item.cd.extraBooking,

      csPrice: item.cs.price,
      csBookings: item.cs.noOfBooking,
      csToday: item.cs.bookingToday,
      csExtra: item.cs.extraBooking,

      psPrice: item.ps !== undefined ? item.ps.price : 0,
      psBookings:
        item.ps !== undefined ? item.ps.noOfBooking : selectVal.psd.length,
      psToday: item.ps !== undefined ? item.ps.bookingToday : 0,
      psExtra: item.ps !== undefined ? item.ps.extraBooking : 0,

      cdFree: item.dlxFree,
      csFree: item.stdFree,
      odtFree: item.odtFree,
      isPurged: false,
    });
    data.date = item.date;
    data.bookedCD = item.bookedCD;
    data.bookedCS = item.bookedCS;
    data.bookedPS = item.bookedPS;

    data.odtBookings = item.odt.noOfBooking;
    data.cdBookings = item.cd.noOfBooking;
    data.csBookings = item.cs.noOfBooking;
    data.psBookings =
      item.ps !== undefined ? item.ps.noOfBooking : selectVal.psd.length;

    data.odtToday = item.odt.bookingToday;
    data.cdToday = item.cd.bookingToday;
    data.csToday = item.cs.bookingToday;
    data.psToday = item.ps !== undefined ? item.ps.bookingToday : 0;

    data.odtPrice = item.odt.price;
    data.cdPrice = item.cd.price;
    data.csPrice = item.cs.price;
    data.psPrice = item.ps !== undefined ? item.ps.price : 0;
    data.mdPrice = item.odt.mdPrice;
    data.saPrice = item.odt.saPrice;

    data.odtExtra = item.odt.extraBooking;
    data.cdExtra = item.cd.extraBooking;
    data.csExtra = item.cs.extraBooking;
    data.psExtra = item.ps !== undefined ? item.ps.extraBooking : 0;

    data.cdFree = item.dlxFree;
    data.csFree = item.stdFree;
    data.odtFree = item.odtFree;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      startDate: selectedDate,
    });
  };
  const fetchDeluxeChaletLength = async () => {
    try {
      const res = await DC.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        dlx: array,
      });
      selectVal.dlx = array;
      setLength({
        ...length,
        dlx: res.data.length,
      });
      setData({
        ...data,
        cdBookings: res.data.length,
      });
      length.dlx = res.data.length;
      data.cdBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchStandardChaletLength = async () => {
    try {
      const res = await CS.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        std: array,
      });
      selectVal.std = array;
      setLength({
        ...length,
        std: res.data.length,
      });
      setData({
        ...data,
        csBookings: res.data.length,
      });
      length.std = res.data.length;
      data.csBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchPremiumStandardLength = async () => {
    try {
      const res = await PS.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        psd: array,
      });
      selectVal.psd = array;
      setLength({
        ...length,
        psd: res.data.length,
      });
      setData({
        ...data,
        psBookings: res.data.length,
      });
      length.psd = res.data.length;
      data.psBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  //dependent functions
  const handleDateEnd = (e) => {
    const selectedDate = e.target.value;
    setData({
      ...data,
      endDate: selectedDate,
    });
  };
  const handleInputChange = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        odtBookings: inputValue,
      });
    }
  };
  const handlePriceOdt = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        odtPrice: inputValue,
      });
    }
  };
  const handlePriceCd = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        cdPrice: inputValue,
      });
    }
  };
  const handlePriceCs = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        csPrice: inputValue,
      });
    }
  };
  const handlePricePs = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        psPrice: inputValue,
      });
    }
  };
  const handlePriceSa = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        saPrice: inputValue,
      });
    }
  };
  const handlePriceMd = (e) => {
    const inputValue = parseInt(e.target.value);

    if (inputValue < 0) {
      setErrorMessage("Invalid number. Please enter a non-negative number.");
    } else {
      setErrorMessage("");
      setData({
        ...data,
        mdPrice: inputValue,
      });
    }
  };
  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await WORKING_HOURS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch booking information");
        } else {
          var req = {
            date: res.data[0].date,
            price: res.data[0].price,
            isPurged: false,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await WORKING_HOURS.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Deleted Working day");
        navigate("/business-days");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete
  //update
  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await WORKING_HOURS.UPDATE(values, data._id);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
        toast.success("Updated business day .");
        navigate("/business-days");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  // console.log("data",data)
  const handleUpdate = async () => {
    if (window.confirm("Are you sure you want to update this data?")) {
      if (
        data.date !== "" &&
        data.cdBookings <= maxCount.cd &&
        data.csBookings <= maxCount.cs
      ) {
        setValidate(false);
        setLoading(true);
        var req = {
          date: data.date,
          isPurged: false,
          bookedCD: data.bookedCD,
          bookedCS: data.bookedCS,
          bookedPS: data.bookedPS,
          odt: {
            price: data.odtPrice,
            saPrice: data.saPrice,
            mdPrice: data.mdPrice,
            noOfBooking: data.odtBookings,
            bookingToday: data.odtToday,
            extraBooking:
              data.odtExtra === 0
                ? data.odtExtra
                : Math.abs(data.odtBookings - data.odtToday),
          },
          cd: {
            price: data.cdPrice,
            noOfBooking: data.cdBookings,
            bookingToday: data.cdToday,
            extraBooking:
              data.cdExtra === 0
                ? data.cdExtra
                : Math.abs(data.cdBookings - data.cdToday),
          },
          cs: {
            price: data.csPrice,
            noOfBooking: data.csBookings,
            bookingToday: data.csToday,
            extraBooking:
              data.csExtra === 0
                ? data.csExtra
                : Math.abs(data.csBookings - data.csToday),
          },
          ps: {
            price: data.psPrice,
            noOfBooking: data.psBookings,
            bookingToday: data.psToday,
            extraBooking:
              data.psExtra === 0
                ? data.psExtra
                : Math.abs(data.psBookings - data.psToday),
          },
          stdFree: data.csFree,
          dlxFree: data.cdFree,
          odtFree: data.odtFree,
        };
        updateData(req);
        // console.log("upd", req);
      } else {
        setValidate(true);
        // console.log("inelse");
      }
    }
  };
  //update
  ///add
  const addData = async (values) => {
    // console.log(values, "values");
    setLoading(true);
    try {
      const res = await WORKING_HOURS.ADD(values, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          setRefreshAdd(!refreshAdd);
          setCount(count + 1);
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAdd = async () => {
    if (window.confirm("Are you sure you want to add this data?")) {
      if (
        data.startDate !== "" &&
        data.endDate !== "" &&
        (data.odtBookings !== 0 || data.odtBookings !== "") &&
        (data.cdBookings !== 0 || data.cdBookings !== "") &&
        (data.csBookings !== 0 || data.csBookings !== "") &&
        (data.odtPrice !== 0 || data.odtPrice !== "") &&
        (data.cdPrice !== 0 || data.cdPrice !== "") &&
        (data.csPrice !== 0 || data.csPrice !== "") &&
        (data.cdFree !== 0 || data.cdFree !== "") &&
        (data.odtFree !== 0 || data.odtFree !== "") &&
        (data.csFree !== 0 || data.csFree !== "")
      ) {
        const dateArray = getDates(data.startDate, data.endDate);
        setValidate(false);
        await Promise.all(
          dateArray.map((item) => {
            var req = {
              date: item,
              isPurged: false,
              bookedCD: [],
              bookedCS: [],
              bookedPS: [],
              odt: {
                price: data.odtPrice,
                saPrice: data.saPrice,
                mdPrice: data.mdPrice,
                noOfBooking: data.odtBookings,
                bookingToday: 0,
                extraBooking: 0,
              },
              cd: {
                price: data.cdPrice,
                noOfBooking: data.cdBookings,
                bookingToday: 0,
                extraBooking: 0,
              },
              cs: {
                price: data.csPrice,
                noOfBooking: data.csBookings,
                bookingToday: 0,
                extraBooking: 0,
              },
              ps: {
                price: data.psPrice,
                noOfBooking: data.psBookings,
                bookingToday: 0,
                extraBooking: 0,
              },
              stdFree: data.csFree,
              dlxFree: data.cdFree,
              odtFree: data.odtFree,
            };
            // console.log("req", req);
            return addData(req);
          })
        );
        setLoading(false);
        setRefreshAdd(!refreshAdd);
        toast.success(`Created business dates`);
        navigate("/business-days");
      } else {
        setValidate(true);
      }
    }
  };
  ///add
  //get
  const getEmpList = async () => {
    try {
      const res = await WORKING_HOURS.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res", res.data);
      setEmp(res.data);
      // console.log("getall", res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (date) => {
    try {
      const res = await WORKING_HOURS.SEARCH(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //get
  //useEffect
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);
  useEffect(() => {
    fetchDeluxeChaletLength();
    fetchStandardChaletLength();
    fetchPremiumStandardLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //useEffect

  const handleKeyDown = (e) => {
    // Allow only backspace, delete, tab, escape, enter, and numbers
    if (
      e.key === "Backspace" ||
      e.key === "Delete" ||
      e.key === "Tab" ||
      e.key === "Escape" ||
      e.key === "Enter" ||
      (e.key === "a" && e.ctrlKey === true) || // Allow Ctrl+A
      (e.key === "c" && e.ctrlKey === true) || // Allow Ctrl+C
      (e.key === "v" && e.ctrlKey === true) || // Allow Ctrl+V
      (e.key === "x" && e.ctrlKey === true) || // Allow Ctrl+X
      (e.key >= "0" && e.key <= "9") || // Allow numbers 0-9
      (e.key >= "Numpad0" && e.key <= "Numpad9") // Allow numpad numbers
    ) {
      // Allow key press
      return;
    }
    // Prevent default if the key is not allowed
    e.preventDefault();
  };
  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Business Days" />
      </div>
      {!isEdit && (
        <AddNew
          data={data}
          validate={validate}
          onKeyDown={handleKeyDown}
          setData={setData}
          today={today}
          loading={loading}
          errorMessage={errorMessage}
          handleAdd={handleAdd}
          handlePriceCs={handlePriceCs}
          handlePricePs={handlePricePs}
          handlePriceMd={handlePriceMd}
          handlePriceSa={handlePriceSa}
          handlePriceCd={handlePriceCd}
          handlePriceOdt={handlePriceOdt}
          handleInputChange={handleInputChange}
          handleDateEnd={handleDateEnd}
          handleDateChange={handleDateChange}
        />
      )}
      {isEdit && (
        <EditNew
          backToAdd={backToAdd}
          data={data}
          onKeyDown={handleKeyDown}
          maxCount={maxCount}
          validate={validate}
          setData={setData}
          loading={loading}
          handleUpdate={handleUpdate}
          handlePriceCs={handlePriceCs}
          handlePricePs={handlePricePs}
          handlePriceMd={handlePriceMd}
          handlePriceSa={handlePriceSa}
          handlePriceCd={handlePriceCd}
          handlePriceOdt={handlePriceOdt}
          handleInputChange={handleInputChange}
          formatDateString={formatDateString}
          selectVal={selectVal}
        />
      )}
      <div ref={tableSessionRef}>
        <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <TableData
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            formatDateString={formatDateString}
            currentItems={currentItems}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
          />
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            array={emp}
            setItemOffset={setItemOffset}
            scrollToSection={scrollToTableSection}
            pageCount={pageCount}
          />
        </div>
      </div>
    </div>
  );
}
