import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { WORKING_HOURS } from "../../../api/workingHours";
import { useNavigate } from "react-router-dom";
import { formatDateString } from "../../../utils/FormatDateString";
import { REPORT } from "../../../api/report";
import { toast } from "react-toastify";
import Select from "react-select";
import ExcelJS from 'exceljs';

export default function ReportToday() {
    const [searchVal, setSearchVal] = useState("");
    const cachedEmp = useRef(null);
    const [emp, setEmp] = useState([]);
    const [loadingDel, setLoadingDel] = useState(false);
    const [loadingEx, setloadingEx] = useState(false);
    const [showSingleDateInput, setShowSingleDateInput] = useState(false);
    const [showMultiDateInput, setShowMultiDateInput] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const [data, setData] = useState({
        startDate: "",
    });
    const [filter, setFilter] = useState({
        bookingType: "all",
        paymentType: "all",
        scannedType: "all",
    });
    const [MultiData, setMultiData] = useState({
        startDate: "",
        endDate: ""
    });
    const getChaletName = (num) => {
        var rtnVal = ""
        if (num == 0) {
            rtnVal = "One Day Ticket"
        } else if (num == 1) {
            rtnVal = "Chalet Deluxe"
        } else if (num == 2) {
            rtnVal = "Chalet Standard"
        } else if (num == 3) {
            rtnVal = "Maid One Day Ticket"
        } else if (num == 4) {
            rtnVal = "Specially Abled"
        } else if (num == 5) {
            rtnVal = "Premium Standard"
        } else if (num == 6) {
            rtnVal = "Online Payment Amount"
        } else if (num == 7) {
            rtnVal = "Offline Payment Amount"
        } else if (num == "all") {
            rtnVal = "All"
        }
        return rtnVal
    }

    const bookingOptions = [
        { value: "all", label: "All" },
        { value: 0, label: "One Day Ticket" },
        { value: 1, label: "Chalet Deluxe" },
        { value: 2, label: "Chalet Standard" },
        { value: 3, label: "Maid One Day Ticket" },
        { value: 4, label: "Specially Abled" },
        { value: 5, label: "Premium Standard" },
        { value: 6, label: "Online Payment Amount" },
        { value: 7, label: "Offline Payment Amount" },
    ];
    const paymentOptions = [
        { value: "all", label: "All" },
        { value: "online", label: "Online" },
        { value: "offline", label: "Offline" },
    ];
    const scannedOptions = [
        { value: "all", label: "All" },
        { value: "scanned", label: "Scanned" },
        { value: "notScanned", label: "Not Scanned" },
    ];

    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;

    const topSessionRef = useRef(null);
    const tableSessionRef = useRef(null);
    const navigate = useNavigate();


    const handleSelectChange = (option) => {
        setSelectedOption(option);
    };

    const getReportList = async (date) => {
        try {
            if (!date) return;

            const res = await REPORT.GET_BY_DATE(date);
            setLoadingDel(false)
            if (res.status !== 200) {
                throw new Error("Failed to fetch report information");
            }
            const { workingHours, totals } = res.data;

            if (!Array.isArray(workingHours) || !totals) {
                throw new Error("Invalid data format");
            }
            const totalsMap = {
                [date]: totals,
            };

            const combinedData = workingHours.map(wh => {
                const total = totalsMap[date] || {};
                return {
                    ...wh,
                    totalOdtAmount: total.odtAmount || 0,
                    totalMdAmount: total.mdAmount || 0,
                    totalSaAmount: total.saAmount || 0,
                    totalCdAmount: total.cdAmount || 0,
                    totalCsAmount: total.csAmount || 0,
                    totalPsAmount: total.psAmount || 0,
                    totalAmountPerDay: total.totalAmountPerDay || 0,
                    totalOneDayTicket: total.totalOneDayTicket || 0,
                    totalNannyTicket: total.totalNannyTicket || 0,
                    totalSpeciallyAbledTicket: total.totalSpeciallyAbledTicket || 0,
                    totalPremiumChalet: total.totalPremiumChalet || 0,
                    totalScTicket: total.totalScTicket || 0,
                    totalCdTicket: total.totalCdTicket || 0,
                    totalFreeTickets: total.totalFreeTickets || 0,
                    totalOnlinePayment: total.totalOnlinePayment || 0,
                    totalOfflinePayment: total.totalOfflinePayment || 0,
                    totalScanned: total.totalScanned || 0,
                    totalUnscanned: total.totalUnscanned || 0,
                    totalAmountDay: total.totalAmountDay || 0,
                    // totalToday
                };
            });

            setEmp(combinedData);
        } catch (error) {
            setLoadingDel(false)
            setEmp([])
            console.error("Error fetching report list:", error.message);
            toast.error("No data found for the specified date");
        }
    };

    const getReportListRange = async (startDate, endDate) => {
        try {
            if (!startDate || !endDate) return;

            const res = await REPORT.GET_BY_DATE_RANGE(startDate, endDate);
            setLoadingDel(false)

            if (res.status !== 200) {
                toast.error(res.message);
                throw new Error("Failed to fetch report information");
            }
            const totals = res.data.rtnVal;
            setEmp(totals)
            setLoadingDel(false)
            if (totals.length === 0) {
                toast.error("No data found for the specified date range")
            }

        } catch (error) {
            // console.error("Error fetching report list:", error.message);
            setLoadingDel(false)
            toast.error("No data found for the specified date range")


        }
    };


    const sortedEmp = Array.isArray(emp)
        ? emp.sort((a, b) => {
            const dateA = a.date.split("T")[0];
            const dateB = b.date.split("T")[0];
            return dateB.localeCompare(dateA); // Sort in descending order
        })
        : [];

    const currentItems = sortedEmp.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(sortedEmp.length / itemsPerPage);

    const scrollToTableSection = () => {
        tableSessionRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setData({
            startDate: selectedDate,
        });
        setMultiData({
            startDate: "",
            endDate: ""
        });
    };

    const handleMultiDateChangeStart = (e) => {
        const selectedDate = e.target.value;
        setMultiData((prevData) => ({
            ...prevData,
            startDate: selectedDate,
        }));
        setEmp([])
        setData({
            startDate: "",
        });
    };

    const handleMultiDateChangeEnd = (e) => {
        const selectedDate = e.target.value;
        setMultiData((prevData) => ({
            ...prevData,
            endDate: selectedDate,
        }));
        setEmp([])
        setData({
            endDate: "",
        });
    };

    useEffect(() => {
        if (cachedEmp.current) {
            setEmp(cachedEmp.current);
        }
    }, []);

    const handleGetReport = () => {
        if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
            setLoadingDel(true)
            getReportListRange(MultiData.startDate, MultiData.endDate).then(data => {
                cachedEmp.current = data;
            });
        } else if (data.startDate) {
            setLoadingDel(true)
            getReportList(data.startDate).then(data => {
                cachedEmp.current = data;
            });
        } else {
            setEmp([])
            toast.warn("Please select a valid date or date range.");
        }
    };

    const toggleSingleDateInput = () => {
        setShowSingleDateInput((prevState) => {
            if (!prevState) {
                setShowMultiDateInput(false);
                setEmp([]);
                setMultiData([]);
            }
            return !prevState;
        });
    };

    const toggleMultiDateInput = () => {
        setShowMultiDateInput((prevState) => {
            if (!prevState) {
                setShowSingleDateInput(false);
                setEmp([]);
                setData([]);
            }
            return !prevState;
        });
    };

    const exportToExcel = (singleDate, startDate, endDate, emp) => {
        setloadingEx(true)
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');

        worksheet.columns = [
            { header: 'Date', key: 'date', width: 20 },
            { header: 'One day', key: 'oneDay', width: 20 },
            { header: 'Deluxe Chalet', key: 'deluxeChalet', width: 20 },
            { header: 'Premium Chalet', key: 'premiumChalet', width: 20 },
            { header: 'Standard Chalet', key: 'standardChalet', width: 20 },
            { header: 'Free Tickets', key: 'freeTickets', width: 20 },
            { header: 'Nanny', key: 'nanny', width: 20 },
            { header: 'Specially Abled', key: 'speciallyAbled', width: 20 },
            { header: 'One Day Ticket Amount', key: 'onedayAmount', width: 30 },
            { header: 'Deluxe Chalet Amount', key: 'deluxeAmount', width: 30 },
            { header: 'Premium Chalet Amount', key: 'premiumChaletAmount', width: 30 },
            { header: 'Standard Chalet Amount', key: 'standardAmount', width: 30 },
            { header: 'Nanny Ticket Amount', key: 'nannyAmount', width: 30 },
            { header: 'Specially Abled Amount', key: 'saAmount', width: 30 },
            { header: 'Online Payment (Nos)', key: 'onlinePayment', width: 20 },
            { header: 'Offline Payment (Nos)', key: 'singleofflinePayment', width: 30 },
            // { header: 'Single Offline Payment (Nos)', key: 'singleofflinePayment', width: 30 },
            // { header: 'Split Offline Payment (Nos)', key: 'splitofflinePayment', width: 30 },
            { header: 'Total Amount (KWD)', key: 'totalAmount', width: 20 },
            { header: 'Scanned', key: 'scanned', width: 20 },
            { header: 'UnScanned', key: 'unScanned', width: 20 },
        ];

        worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF156097' },
            };
            cell.font = {
                color: { argb: 'FFFFFFFF' },
                bold: true,
            };
        });

        console.log("eeeeeeeeeeeeeee", emp);

        emp.forEach((data) => {
            worksheet.addRow({
                date: formatDateString(data.date),
                oneDay: data.totalOneDayTicket,
                deluxeChalet: data.totalCdTicket,
                premiumChalet: data.totalPsTicket,
                standardChalet: data.totalCsTicket,
                freeTickets: data.cdFreeTickets + data.csFreeTickets + data.psFreeTickets + data.odtFreeTickets,
                nanny: data.totalMdTicket,
                speciallyAbled: data.totalSaTicket,
                onedayAmount: data.totalOdtAmountSum,
                deluxeAmount: data.totalCdAmountSum,
                premiumChaletAmount: data.totalPsAmountSum,
                standardAmount: data.totalCsAmountSum,
                nannyAmount: data.totalMdAmountSum,
                saAmount: data.totalSaAmountSum,
                onlinePayment: data.onlineBookings,
                singleofflinePayment: data.singleofflineBookings,
                // splitofflinePayment: data.splitofflineBookings,
                // offlinePayment: data.offlineBookings,
                totalAmount: data.totalOdtAmountSum + data.totalCdAmountSum + data.totalPsAmountSum + data.totalCsAmountSum + data.totalMdAmountSum + data.totalSaAmountSum,
                scanned: data.scannedBookings,
                unScanned: data.unscannedBookings,
            });
        });

        //     // Calculate totals for each column
        const totalOneDay = emp.reduce((acc, data) => acc + (data.totalOneDayTicket || 0), 0);
        const totalCD = emp.reduce((acc, data) => acc + (data.totalCdTicket || 0), 0);
        const totalPC = emp.reduce((acc, data) => acc + (data.totalPsTicket || 0), 0);
        const totalSC = emp.reduce((acc, data) => acc + (data.totalCsTicket || 0), 0);
        const totalFree = emp.reduce((acc, data) => acc + ((data.psFreeTickets || 0) + (data.cdFreeTickets || 0) + (data.csFreeTickets || 0) + (data.odtFreeTickets || 0)), 0);
        const totalNanny = emp.reduce((acc, data) => acc + (data.totalMdTicket || 0), 0);
        const totalSA = emp.reduce((acc, data) => acc + (data.totalSaTicket || 0), 0);
        const totalOdtAmountPerDay = emp.reduce((acc, data) => acc + (data.totalOdtAmountSum || 0), 0);
        const totalCdAmountPerDay = emp.reduce((acc, data) => acc + (data.totalCdAmountSum || 0), 0);
        const totalPsAmountPerDay = emp.reduce((acc, data) => acc + (data.totalPsAmountSum || 0), 0);
        const totalCsAmountPerDay = emp.reduce((acc, data) => acc + (data.totalCsAmountSum || 0), 0);
        const totalNannyAmountPerDay = emp.reduce((acc, data) => acc + (data.totalMdAmountSum || 0), 0);
        const totalSaAmountPerDay = emp.reduce((acc, data) => acc + (data.totalSaAmountSum || 0), 0);
        const totalOnlinePay = emp.reduce((acc, data) => acc + (data.onlineBookings || 0), 0);
        const totalSingleOfflinePay = emp.reduce((acc, data) => acc + (data.singleofflineBookings || 0), 0);
        // const totalSplitOfflinePay = emp.reduce((acc, data) => acc + (data.splitofflineBookings || 0), 0);
        const totalAmountDay = emp.reduce((acc, data) => acc + ((data.totalOdtAmountSum || 0) + (data.totalCdAmountSum || 0) + (data.totalPsAmountSum || 0) + (data.totalCsAmountSum || 0) + (data.totalMdAmountSum || 0) + (data.totalSaAmountSum || 0)), 0);
        const totalScanned = emp.reduce((acc, data) => acc + (data.scannedBookings || 0), 0);
        const totalUnScanned = emp.reduce((acc, data) => acc + (data.unscannedBookings || 0), 0);


        // Add total row at the bottom
        worksheet.addRow({
            date: 'Total',
            oneDay: totalOneDay,
            deluxeChalet: totalCD,
            premiumChalet: totalPC,
            standardChalet: totalSC,
            freeTickets: totalFree,
            nanny: totalNanny,
            speciallyAbled: totalSA,
            onlinePayment: totalOnlinePay,
            singleofflinePayment: totalSingleOfflinePay,
            // splitofflinePayment: totalSplitOfflinePay,
            // offlinePayment: totalOfflinePay,
            totalAmount: totalAmountDay,
            scanned: totalScanned,
            unScanned: totalUnScanned,
            onedayAmount: totalOdtAmountPerDay,
            deluxeAmount: totalCdAmountPerDay,
            premiumChaletAmount: totalPsAmountPerDay,
            standardAmount: totalCsAmountPerDay,
            nannyAmount: totalNannyAmountPerDay,
            saAmount: totalSaAmountPerDay
        }).eachCell({ includeEmpty: true }, (cell) => {
            cell.font = { bold: true };
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            let fileName;
            if (singleDate) {
                fileName = `Summary_report-${formatDateString(singleDate)}.xlsx`;
            } else if (startDate && endDate) {
                fileName = `Summary_report-${formatDateString(startDate)}-${formatDateString(endDate)}.xlsx`;
            } else {
                fileName = 'Summary_report.xlsx';
            }

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            URL.revokeObjectURL(url);
            setloadingEx(false)
        });
    };

    const calculateTotals = (emp) => {
        return {
            totalOneDay: emp.reduce((acc, data) => acc + (data.totalOneDayTicket || 0), 0),
            totalCD: emp.reduce((acc, data) => acc + (data.totalCdTicket || 0), 0),
            totalPC: emp.reduce((acc, data) => acc + (data.totalPsTicket || 0), 0),
            totalSC: emp.reduce((acc, data) => acc + (data.totalCsTicket || 0), 0),
            totalFree: emp.reduce((acc, data) => acc + ((data.psFreeTickets || 0) + (data.cdFreeTickets || 0) + (data.csFreeTickets || 0) + (data.odtFreeTickets || 0)), 0),
            totalNanny: emp.reduce((acc, data) => acc + (data.totalMdTicket || 0), 0),
            totalSA: emp.reduce((acc, data) => acc + (data.totalSaTicket || 0), 0),
            totalOdtAmountPerDay: emp.reduce((acc, data) => acc + (data.totalOdtAmountSum || 0), 0),
            totalCdAmountPerDay: emp.reduce((acc, data) => acc + (data.totalCdAmountSum || 0), 0),
            totalPsAmountPerDay: emp.reduce((acc, data) => acc + (data.totalPsAmountSum || 0), 0),
            totalCsAmountPerDay: emp.reduce((acc, data) => acc + (data.totalCsAmountSum || 0), 0),
            totalNannyAmountPerDay: emp.reduce((acc, data) => acc + (data.totalMdAmountSum || 0), 0),
            totalSaAmountPerDay: emp.reduce((acc, data) => acc + (data.totalSaAmountSum || 0), 0),
            totalOnlinePay: emp.reduce((acc, data) => acc + (data.onlineBookings || 0), 0),
            totalSingleOfflinePay: emp.reduce((acc, data) => acc + (data.singleofflineBookings || 0), 0),
            // totalSplitOfflinePay: emp.reduce((acc, data) => acc + (data.splitofflineBookings || 0), 0),
            totalAmountDay: emp.reduce((acc, data) => acc + ((data.totalOdtAmountSum || 0) + (data.totalCdAmountSum || 0) + (data.totalPsAmountSum || 0) + (data.totalCsAmountSum || 0) + (data.totalMdAmountSum || 0) + (data.totalSaAmountSum || 0)), 0),
            totalScanned: emp.reduce((acc, data) => acc + (data.scannedBookings || 0), 0),
            totalUnScanned: emp.reduce((acc, data) => acc + (data.unscannedBookings || 0), 0),
        };
    };

    const {
        totalOneDay,
        totalCD,
        totalPC,
        totalSC,
        totalFree,
        totalNanny,
        totalSA,
        totalOnlinePay,
        totalSingleOfflinePay,
        // totalSplitOfflinePay,
        // totalOfflinePay,
        totalAmountDay,
        totalScanned,
        totalUnScanned,
        totalOdtAmountPerDay,
        totalCdAmountPerDay,
        totalPsAmountPerDay,
        totalCsAmountPerDay,
        totalNannyAmountPerDay,
        totalSaAmountPerDay
    } = calculateTotals(emp);

    useEffect(() => {
        // console.log("filter", filter)
    }, [filter]);

    return (
        <div>
            <div ref={topSessionRef}>
                <Breadcrumbs pageName="Report" />
            </div>

            <div
                ref={tableSessionRef}
                className=" rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
            >
                <div className="max-w-full">
                    <div className="flex gap-15 lg:justify-start justify-center ">
                        {/* <button
                            type="submit"
                            className={`flex w-auto justify-center rounded py-4 px-17 font-medium text-gray  ${showSingleDateInput ? "bg-green" : "bg-primary"}`}
                            onClick={toggleSingleDateInput}
                        >
                            Single Day Report
                        </button> */}
                        {/* <button
                            type="submit"
                            className={`flex w-auto justify-center rounded bg-blue py-4 px-17 font-medium text-gray ${showMultiDateInput ? "bg-green" : "bg-primary"}`}
                            onClick={toggleMultiDateInput}
                        >
                            Report
                        </button> */}
                    </div>
                    {/* {(showSingleDateInput || showMultiDateInput) && (
                        <div className="flex lg:flex-row flex-col overflow-hidden gap-4 items-center justify-center ">
                            <div className="md:w-[500px] w-[250px] py-5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Type of Booking
                                </label>
                                <Select
                                    options={bookingOptions}
                                    menuPortalTarget={document.body}
                                    isSearchable={true}
                                    value={{ value: filter.bookingType, label: getChaletName(filter.bookingType) }}
                                    onChange={(e) => {
                                        console.log("ee", e)
                                        setFilter({ ...filter, bookingType: e.value })
                                        filter.bookingType = e.value
                                    }}
                                />
                            </div>
                            <div className=" md:w-[500px] w-[250px] py-5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Filter By Payment
                                </label>
                                <Select
                                    options={paymentOptions}
                                    menuPortalTarget={document.body}
                                    isSearchable={true}
                                    value={{ value: filter.paymentType, label: filter.paymentType.toUpperCase() }}
                                    onChange={(e) => {
                                        console.log("ee", e)
                                        setFilter({ ...filter, paymentType: e.value })
                                        filter.paymentType = e.value
                                    }}
                                />
                            </div>
                            <div className=" md:w-[500px] w-[250px] py-5">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Scanned/Unscanned
                                </label>
                                <Select
                                    options={scannedOptions}
                                    menuPortalTarget={document.body}
                                    isSearchable={true}
                                    value={{ value: filter.scannedType, label: filter.scannedType.toUpperCase() }}
                                    onChange={(e) => {
                                        console.log("ee", e)
                                        setFilter({ ...filter, scannedType: e.value })
                                        filter.scannedType = e.value
                                    }}
                                />
                            </div>
                        </div>
                    )} */}
                    <div className="py-3 lg:flex items-center ">
                        {/* {showSingleDateInput && (
                            <div className="flex flex-col h-auto py-2 w-auto">
                                <label className="font-semibold text-black dark:text-white">Select Date</label>
                                <input
                                    onChange={handleDateChange}
                                    type="date"
                                    className="lg:w-[300px] rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    name="date"
                                    placeholder="Select Date"
                                    value={data.startDate}
                                />
                            </div>
                        )} */}
                        {/* {showMultiDateInput && ( */}
                        <div className=" md:flex items-center lg:w-[50%] w-full">
                            <div className=" flex flex-col h-auto py-2 lg:w-[50%] w-full mr-[4%]">
                                <label className="font-semibold text-black dark:text-white">
                                    Start Date
                                </label>
                                <input
                                    onChange={handleMultiDateChangeStart}
                                    type="date"
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    name="date"
                                    value={MultiData.startDate}
                                />
                            </div>
                            <div className="flex flex-col h-auto py-2 lg:w-[50%] w-full">
                                <label className="font-semibold text-black dark:text-white">
                                    End Date
                                </label>
                                <input
                                    onChange={handleMultiDateChangeEnd}
                                    type="date"
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    name="date"
                                    value={MultiData.endDate}
                                />
                            </div>

                        </div>
                        {/* )} */}

                        {/* {( showMultiDateInput) && ( */}
                        {(MultiData.startDate && MultiData.endDate) && (
                            <div className="flex items-center xl:justify-end xl:pl-0 pl-6 pt-6 gap-8 lg:w-[50%] justify-center">
                                {emp.length > 0 && (
                                    <button
                                        type="submit"
                                        onClick={() => exportToExcel(data.startDate, MultiData.startDate, MultiData.endDate, emp)}
                                        className="flex w-aut justify-center rounded bg-success py-5 w-[200px] font-medium text-gray"
                                    >
                                        {loadingEx ? (
                                            <div className="flex h-auto items-center justify-center px-[27.5px]">
                                                <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                                            </div>
                                        ) : (
                                            "Export to Excel"
                                        )}
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    onClick={handleGetReport}
                                    className="flex w-aut justify-center rounded bg-black-2 py-5 w-[200px] font-medium text-gray"
                                >
                                    {loadingDel ? (
                                        <div className="flex h-auto items-center justify-center px-[27.5px] ">
                                            <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                                        </div>
                                    ) : (
                                        "Get Report"
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    {emp.length > 0 && (
                        <div className=" overflow-x-auto ">
                            <table className="w-full table-auto ">
                                <thead>
                                    <tr className=" bg-gray-2 text-left dark:bg-meta-4 position-sticky">
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            Date
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            One day
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Deluxe Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Premium Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Standard Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Free Tickets
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Nanny
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Specially Abled
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            One Day Ticket Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Deluxe Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Premium Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Standard Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Nanny Ticket Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Specially Abled Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Online Payment (Nos)
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Offline Payment (Nos)
                                        </th>
                                        {/* <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Split Offline Payment (Nos)
                                        </th> */}
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Total Amount (KWD)
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Scanned
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            UnScanned
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {console.log("emp", emp)}
                                    {emp.length > 0 ? (
                                        emp.map((data) => (
                                            <tr key={data._id}>
                                                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {formatDateString(data.date)}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalOneDayTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalCdTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalPsTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalCsTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.cdFreeTickets + data.csFreeTickets + data.psFreeTickets + data.odtFreeTickets || 0}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalMdTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalSaTicket}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalOdtAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalCdAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalPsAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalCsAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalMdAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalSaAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.onlineBookings}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.singleofflineBookings}
                                                    </h5>
                                                </td>
                                                {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.splitofflineBookings}
                                                    </h5>
                                                </td> */}
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalOdtAmountSum + data.totalCdAmountSum + data.totalPsAmountSum + data.totalCsAmountSum + data.totalMdAmountSum + data.totalSaAmountSum}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.scannedBookings}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.unscannedBookings}
                                                    </h5>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="11" className="py-5 text-center font-bold text-red">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                    {emp.length > 0 && (
                                        <tr className="bg-gray text-left dark:bg-meta-4">
                                            <td className="border-b border-[#eee] py-5 px-11 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    Total
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalOneDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCD}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalPC}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSC}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalFree}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalNanny}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSA}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalOdtAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCdAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalPsAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCsAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalNannyAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSaAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalOnlinePay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSingleOfflinePay}
                                                </h5>
                                            </td>
                                            {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSplitOfflinePay}
                                                </h5>
                                            </td> */}
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalAmount} */}
                                                    {totalAmountDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalScanned}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalUnScanned}
                                                </h5>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
