import React, { useState } from "react";
import LOGO from "../../../src/assets/logo.png";
import CLOCK from "../../../src/assets/clock.png";
// import PHONE from "../../../src/assets/phone.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const array = [
  {
    id: 1,
    title: "home",
    link: "/",
  },
  {
    id: 2,
    title: "about_us",
    link: "/about",
  },
  {
    id: 3,
    title: "faq",
    link: "/faq",
  },
  {
    id: 4,
    title: "news",
    link: "/news",
  },
  {
    id: 6,
    title: "contact_us",
    link: "/contact",
  },
];
const Hamburger = ({ open, setOpen }) => {
  return (
    <div
      className="flex relative w-8 h-8 flex-col justify-between items-center lg:hidden z-20"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {/* hamburger button */}
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${open ? "rotate-45 translate-y-3.5 bg-yellow" : "bg-white"
          }`}
      />
      <span
        className={`h-1 bg-white rounded-lg transition-all duration-300 ease-in-out ${open ? "w-[0px] bg-black" : "w-8"
          }`}
      />
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${open ? "-rotate-45 -translate-y-3.5 bg-yellow" : "bg-white"
          }`}
      />
    </div>
  );
};

function MobileNav({ open, setOpen }) {
  const { t } = useTranslation();
  return (
    <div
      className={`absolute lg:hidden top-0 left-0 h-auto w-screen bg-blue transform ${open ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out filter drop-shadow-md z-10`}
    >
      <div className="flex items-center justify-between filter drop-shadow-md px-10 mt-2">
        <Link to="/" onClick={() => setOpen(false)}>
          <img onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }} src={LOGO} alt="images" className=" object-cover h-[100px]" />
        </Link>
      </div>
      <div>
        <div className="flex flex-col mt-5">

          {array.map((data) => (
            <Link
              to={data.link}
              className="text-sm font-medium text-yellow my-2 text-center font-mont"
              onClick={() =>
                setTimeout(() => {
                  setOpen(!open);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 100)
              }
            >
              {t(data.title)}
            </Link>
          ))}

          <div className="h-auto flex w-auto flex-col justify-center items-center">
            <div className="h-auto w-auto flex items-center mb-[2%]">
              <img
                src={CLOCK}
                alt="images"
                className="object-contain h-4 w-4"
              />
              <p className="text-white text-xs font-light font-mont pl-1">
              {t('opening_time')}
              </p>
            </div>
            {/* <div className="h-auto w-auto flex justify-end">
              <p className="text-white text-xs font-light font-mont ">
                {t('second_time')}
              </p>
            </div> */}
            {/* <div className="h-auto w-auto flex items-center my-5">
              <img
                src={PHONE}
                alt="images"
                className=" object-contain h-4 w-4"
              />
              <p className=" text-white text-xs font-light font-mont pl-1">
                <span className="font-semibold">For Enquiries : </span> +971 5
                000 3333{" "}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Header() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className=" h-auto w-full flex justify-center">
      <div className=" h-full  w-[80%] flex justify-between items-center">
        <MobileNav open={open} setOpen={setOpen} />
        <Link onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }} className=" h-full w-auto " to="/">
          <img
            src={LOGO}
            alt="images"
            className=" object-contain mt-2 lg:mt-0 h-[100px] lg:h-[150px]"
          />
        </Link>
        <Hamburger open={open} setOpen={setOpen} />
        <div className="hidden h-auto w-full lg:flex flex-col items-end">
          <div className="h-auto  w-auto">
            <div className="h-auto w-auto flex items-center">
              <img
                src={CLOCK}
                alt="images"
                className="object-contain h-4 w-4"
              />
              <p className="text-white text-xs font-light font-mont pl-1">
                {t('opening_time')}
              </p>
            </div>
            {/* <div className="h-auto w-auto flex justify-end">
              <p className="text-white text-xs font-light font-mont ">
                {t('second_time')}
              </p>
            </div> */}
            {/* <div className="h-auto w-auto flex items-center pl-5">
              <img
                src={PHONE}
                alt="images"
                className=" object-contain h-4 w-4"
              />
              <p className=" text-white text-xs font-light font-mont pl-1">
                <span className="font-semibold">For Enquiries : </span> +971 5
                000 3333{" "}
              </p>
            </div> */}
          </div>
          <div className=" h-auto w-auto flex items-center mt-4">
            <Link onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
              to="/"
              className="text-white font-light text-base font-mont hover:text-yellow"
            >
              {t('home')}
            </Link>
            <div className="h-[10px] w-[2px] bg-white mx-3 rounded"></div>
            <Link onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
              to="/about"
              className="text-white font-light text-base font-mont hover:text-yellow"
            >
              {t('about_us')}
            </Link>
            <div className="h-[10px] w-[2px] bg-white mx-3 rounded"></div>
            <Link onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
              to="/faq"
              className="text-white font-light text-base font-mont hover:text-yellow"
            >
              {t('faq')}
            </Link>
            <div className="h-[10px] w-[2px] bg-white mx-3 rounded"></div>
            <Link onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
              to="/news"
              className="text-white font-light text-base font-mont hover:text-yellow"
            >
              {t('news')}
            </Link>
            <div className="h-[10px] w-[2px] bg-white mx-3 rounded"></div>
            <Link onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
              to="/contact"
              className="text-white font-light text-base font-mont hover:text-yellow"
            >
              {t('contact_us')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
