import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/Header";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import EmpList from "../pages/admin/employee/EmpList";
import EmpAdd from "../pages/admin/employee/EmpAdd";
import EmpUpdate from "../pages/admin/employee/EmpUpdate";
import NoPage from "../pages/noPage/NoPage";
import AdminSideBar from "../components/sidebar/AdminSideBar";
import EmpView from "../pages/admin/employee/EmpView";
import ProfileScreen from "../pages/admin/profile/ProfileScreen";
import BookingType from "../pages/admin/bookingType/BookingType";
import BusinessHours from "../pages/admin/businessHours/BusinessHours";
import Booking from "../pages/admin/bookingList/Booking";
import OldBooking from "../pages/admin/bookingsGrpList/Booking";
import { AuthContext } from "../utils/AuthContext";
import NotAllowed from "../pages/noPage/NotAllowed";
import BookingAdd from "../pages/admin/bookingAdd/BookingAdd";
import UsersList from "../pages/admin/bookingList/UsersList";
import OldUsersList from "../pages/admin/bookingsGrpList/UsersList";
import UsersDetails from "../pages/admin/bookingList/UserDetails";
import OldUsersDetails from "../pages/admin/bookingsGrpList/UserDetails";
// import RidesAdd from "../pages/admin/rides/RidesAdd";
// import Ridelist from "../pages/admin/rides/Ridelist";
// import RideUpdate from "../pages/admin/rides/RideUpdate";
// import RideView from "../pages/admin/rides/RideView";
import EventAdd from "../pages/admin/events/EventAdd";
import EventList from "../pages/admin/events/EventList";
import EventView from "../pages/admin/events/EventView";
import EventUpdate from "../pages/admin/events/EventUpdate";
import DeluxeChalet from "../pages/admin/deluxeChalet/DeluxeChalet";
import StandardChalet from "../pages/admin/standardChalet/StandardChalet";
import OfferList from "../pages/admin/offers/OfferList";
import OfferAdd from "../pages/admin/offers/OfferAdd";
import OfferUpdate from "../pages/admin/offers/OfferUpdate";
import OfferView from "../pages/admin/offers/OfferView";
import SliderManage from "../pages/admin/sliders/SliderManage";
import ManageFreeTicket from "../pages/admin/ticket/ManageFreeTicket";
import ContactList from "../pages/admin/contact/ContactList";
import ContactView from "../pages/admin/contact/ContactView";
import PaymentView from "../pages/admin/bookingList/PaymentView";
import PaymentList from "../pages/admin/payment/PaymentList";
import BlockChelet from "../pages/admin/blockChalet/BlockChelet";
import ReportToday from "../pages/admin/report/ReportToday";
import ReportView from "../pages/admin/report/ReportView";
import PremiumStandard from "../pages/admin/premiumStandard/PremiumStandard";
import DetailedReport from "../pages/admin/report/detailedReport/DetailedReport";

export default function AdminLayout() {
  const { user } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div suppressHydrationWarning={true}>
      <div className="dark:bg-boxdark-2 dark:text-bodydark">
        <div className="flex h-screen overflow-hidden">
          <AdminSideBar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div>
              <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <Routes>
                  <Route path="/">
                    <Route index element={<Dashboard />} />
                    <Route path="dashboard" element={<Dashboard />} />

                    <Route
                      path="old-booking/user/:date"
                      element={
                        user.role === 0 ||
                          user.role === 1 ||
                          user.role === 2 ? (
                          <OldUsersList />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="booking/user/:date"
                      element={
                        user.role === 0 ||
                          user.role === 1 ||
                          user.role === 2 ? (
                          <UsersList />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="payment"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <PaymentList />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="block-chalets"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <BlockChelet />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />

                    <Route
                      path="booking/user-details/:id"
                      element={<UsersDetails />}
                    />
                    <Route
                      path="old-booking/user-details/:id"
                      element={<OldUsersDetails />}
                    />
                    <Route
                      path="booking/payment/:pnr"
                      element={<PaymentView />}
                    />
                    <Route path="booking" element={<Booking />} />
                    <Route path="old-booking" element={<OldBooking />} />
                    <Route path="booking/add" element={<BookingAdd />} />
                    {/* <Route path="/rideAdd" element={<RidesAdd />} />
                    <Route path="/rideList" element={<Ridelist />} />
                    <Route path="update/:id" element={<RideUpdate />} />
                    <Route path="view/:id" element={<RideView />} /> */}

                    <Route
                      path="business-days"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <BusinessHours />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="deluxe-chalet"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <DeluxeChalet />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="standard-chalet"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <StandardChalet />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route
                      path="premium-standard"
                      element={
                        user.role === 0 || user.role === 1 ? (
                          <PremiumStandard />
                        ) : (
                          <NotAllowed />
                        )
                      }
                    />
                    <Route path="profile" element={<ProfileScreen />} />
                    <Route path="manage-slider" element={<SliderManage />} />
                    <Route path="events">
                      <Route index element={<EventList />} />
                      <Route path="add" element={<EventAdd />} />
                      <Route path="view/:id" element={<EventView />} />
                      <Route path="update/:id" element={<EventUpdate />} />
                    </Route>
                    <Route path="offers">
                      <Route index element={<OfferList />} />
                      <Route path="add" element={<OfferAdd />} />
                      <Route path="view/:id" element={<OfferView />} />
                      <Route path="update/:id" element={<OfferUpdate />} />
                    </Route>
                    <Route path="report">
                      <Route index element={<ReportToday />} />
                      <Route path="view/:date" element={<ReportView />} />
                    </Route>
                    <Route path="detail-report">
                      <Route index element={<DetailedReport />} />
                    </Route>
                    <Route path="contact">
                      <Route index element={<ContactList />} />
                      <Route path="view/:id" element={<ContactView />} />
                    </Route>
                    <Route path="admin">
                      <Route
                        index
                        element={user.role === 0 ? <EmpList /> : <NotAllowed />}
                      />
                      <Route
                        path="add"
                        element={user.role === 0 ? <EmpAdd /> : <NotAllowed />}
                      />
                      <Route
                        path="update/:id"
                        element={
                          user.role === 0 ? <EmpUpdate /> : <NotAllowed />
                        }
                      />
                      <Route
                        path="view/:id"
                        element={user.role === 0 ? <EmpView /> : <NotAllowed />}
                      />
                    </Route>

                    <Route path="*" element={<NoPage />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
