import React, { useEffect } from "react";
import ABOUT from "../../../assets/Abouthero.png";
import AB1 from "../../../assets/ride1.png";
import AB2 from "../../../assets/Aboutii3.png";
import AB3 from "../../../assets/aboutii2.png";
import BORDER2 from "../../../assets/egg1.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate, useLocation } from "react-router-dom";
import usePreventBackNavigation from "../../../utils/usePreventBackNavigation";
import "../../../components/home/Intro.css";

export default function About() {
  usePreventBackNavigation("/book-now");
  //constant variables
  const controls = useAnimation();
  const [txtRef, inTxt] = useInView();
  //constant variables
  //animation functions
  const animationTex1 = {
    textHide: { opacity: 0, x: 5000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationTex2 = {
    textHide: { opacity: 0, x: 1000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationTex3 = {
    textHide: { opacity: 0, x: 7000 },
    textShow: { opacity: 1, x: 0, transition: { duration: 1.5 } },
  };
  const animationTx1 = {
    textHide: { opacity: 0, x: -500 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2 } },
  };
  const animationTx2 = {
    textHide: { opacity: 0, x: -500 },
    textShow: { opacity: 1, x: 0, transition: { duration: 2.2 } },
  };
  //animation functions
  //useEffect
  useEffect(() => {
    if (inTxt) {
      controls.start("textShow");
    } else {
      controls.start("textHide");
    }
  }, [controls, inTxt]);
  //useEffect
  return (
    <div ref={txtRef} className="h-auto w-full bg-bgbg mt-20">
      <div className=" w-full h-70 md:h-100 ">
        <img
          src={ABOUT}
          alt="images"
          style={{ width: "100%", height: "100%" }}
          className=" object-cover"
        />
      </div>
      <div className="w-full bg-intro h-full flex justify-center items-center">
        <div className=" w-[80%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 items-center my-[130px]">
          <div className=" h-auto col-span-1 flex justify-center items-center">
            <div className="w-auto relative">
              <img
                src={BORDER2}
                alt=""
                className="object-contain relative z-99"
              />
              <div className="slider absolute bottom-[4%] left-[6.5%] w-[89%] h-[90%] ">
                <div className="w-full h-full slider">
                  <img
                    src={AB2}
                    alt=""
                    className="slider object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-span-1 h-auto ">
            <div className=" w-auto h-auto">
              <p className="text-black leading-9">
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex1}
                >
                  <span className="text-[41px] font-mont font-thin ">
                    Welcome to{" "}
                    <span className="text-[41px] font-black font-poppins ">
                      AquaMagic{" "}
                    </span>
                    the
                  </span>
                  <br></br>
                </motion.p>
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex2}
                >
                  <span className="text-[41px] font-black font-poppins ">
                    ultimate family destination
                  </span>{" "}
                  <span className="text-[41px] font-mont font-thin ">
                    WHERE
                  </span>
                  <br></br>
                </motion.p>
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex3}
                >
                  <span className="text-[41px] font-black font-poppins">
                    FUN AND ADVENTURE AWAIT!
                  </span>
                </motion.p>
              </p>
            </div>
            <div className=" mt-4">
              <p className="text-base lg:text-lg md:text-lg font-mont font-normal tracking-tighter ">
                Welcome to AquaMagic the ultimate family destination where
                exciting adventures awaits! Come and enjoy our waterpark which
                is designed for family fun time. We offer you a blend of
                thrilling and relaxing days of unforgettable memories.<br></br>
                <br></br> our mission is to create a safe and enjoyable
                environment where families can come together to experience
                endless fun and create lasting memories.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-intro h-full flex justify-center items-center  pb-25 ">
        <div className=" w-[80%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 items-center  ">
          <div className=" col-span-1 h-auto ">
            <div className=" w-auto h-auto  grid text-end">
              <p className="text-black leading-8">
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex1}
                >
                  <span className="text-[41px] font-mont font-thin ">
                    Family-Friendly Attractions{" "}
                    <span className="text-[41px] font-black font-poppins">
                      {" "}
                      FROM
                    </span>
                  </span>
                  <br></br>
                </motion.p>

                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex2}
                >
                  <span className="text-[41px] font-black font-poppins ">
                    exhilarating water slides
                  </span>{" "}
                  <span className="text-[41px] font-mont font-thin ">AND</span>
                  <br></br>
                </motion.p>

                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex3}
                >
                  <span className="text-[41px] font-black font-poppins">
                    SPLASH PADS !
                  </span>
                </motion.p>
              </p>
            </div>
            <div className=" mt-4  ">
              <p className="text-base lg:text-lg md:text-lg font-mont font-normal tracking-tighter text-right">
                For our thrilling slides, We have 8 exhilarating water slides
                with our safety operators on stand by. Refreshing Outdoor water
                splash pads for all ages to play A special water playground with
                built in water guns and a large water dumping bucket for a big
                fun splash<br></br>
                <br></br>
              </p>
            </div>
          </div>
          <div className=" h-auto col-span-1 flex justify-center items-center">
             <div className="w-auto relative">
              <img
                src={BORDER2}
                alt=""
                className="object-contain relative z-99"
              />
              <div className="slider absolute bottom-[4%] left-[6.5%] w-[89%] h-[90%] ">
                <div className="w-full h-full slider">
                  <img
                    src={AB1}
                    alt=""
                    className="slider object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white flex flex-col items-center justify-center">
        <div className="h-auto w-auto py-25  lg:px-59 px-28  max-w-screen-2xl ">
            <div className=" h-auto w-auto">
              <motion.p
                initial="hidden"
                animate={controls}
                variants={animationTex3}
                className="text-black text-[45px] text-center font-mont font-thin leading-10  "
              >
                A Comfortable retreat <br></br>
                <span className="text-black p-2  font-extrabold">
                  for parents,
                </span>
                <span className="xl:text-[45px] text-[35px]">WHILE</span>
                <br></br>
                <span className="font-extrabold text-black ">
                  KEEPING AN EYE ON LITTLE ONES!
                </span>
              </motion.p>
            </div>
            <div className=" 2xl:pt-10 h-auto 2xl:w-auto lg:w-[860px] md:w-auto  pt-3">
              <p className=" font-mont text-black font-normal text-center lg:text-lg text-base  tracking-tighter  md:text-[20px]">
                We also offer private resting rooms for families to rest and
                unwind in our comfortable private chalets. While you relax, our
                trustworthy staff will keep on eye on the little ones.<br></br>
                <br></br>
                <div className="text-start ">
                  <li className="mb-[2%]">
                    <span className="font-mont text-black font-bold lg:text-[22px] text-base md:text-[20px]">
                      Dining Options :{" "}
                    </span>
                    <span>
                      {" "}
                      Enjoy a variety of delicious food and beverage options at
                      our family-friendly eateries, catering to all tastes and
                      dietary needs.
                    </span>
                  </li>
                  <li>
                    <span className="font-mont text-black font-bold lg:text-[22px] text-base md:text-[20px]">
                      Special Events :{" "}
                    </span>
                    Let us help you and host your parties! We provide birthday
                    parties and special events with an indoor space. Also enjoy
                    your special event and swim at our lovely family friendly
                    park. We guarantee an unforgettable experience with your
                    family and friends
                  </li>
                </div>
              </p>
            </div>
          </div>
        </div>
      <div className="w-full bg-intro h-full flex justify-center items-center py-25  ">
        <div className=" w-[80%] h-auto max-w-screen-2xl grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10 items-center  ">
          <div className=" col-span-1 h-auto  ">
            <div className=" w-auto h-auto  grid text-end">
              <p className="text-black leading-7">
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex1}
                >
                  <span className="text-[41px] font-mont font-thin ">
                    SAFETY OF
                  </span>
                  <br></br>
                </motion.p>
                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex2}
                >
                  <span className="text-[41px] font-black font-poppins ">
                    OUR GUESTS
                  </span>{" "}
                  <span className="text-[41px] font-mont font-thin ml-1 ">
                    IS
                  </span>
                  <br></br>
                </motion.p>

                <motion.p
                  initial="hidden"
                  animate={controls}
                  variants={animationTex3}
                >
                  <span className="text-[41px] font-black font-poppins">
                    OUR TOP PRIORITY
                  </span>
                </motion.p>
              </p>
            </div>
            <div className=" mt-4  ">
              <p className="text-base lg:text-lg md:text-lg font-mont font-normal tracking-tighter text-right">
                The safety of our guests is our top priority. Our park is
                staffed with highly trained lifeguards and safety personnel who
                are always on duty to ensure a secure environment. We adhere to
                stringent safety protocols and conduct regular inspections to
                maintain the highest standards.<br></br>
                <br></br>
              </p>
            </div>
          </div>
          <div className=" h-auto col-span-1 flex justify-center items-center">
             <div className="w-auto relative">
              <img
                src={BORDER2}
                alt=""
                className="object-contain relative z-99"
              />
              <div className="slider absolute bottom-[4%] left-[6.5%] w-[89%] h-[90%] ">
                <div className="w-full h-full slider">
                  <img
                    src={AB3}
                    alt=""
                    className="slider object-cover w-full h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
