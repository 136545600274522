import React, { useEffect, useState } from "react";
import { BLOCK } from "../../../api/block";
export default function EditNew({
  backToAdd,
  data,
  onKeyDown,
  maxCount,
  validate,
  setData,
  loading,
  handleUpdate,
  handlePriceCs,
  handlePricePs,
  handlePriceMd,
  handlePriceSa,
  handlePriceCd,
  handlePriceOdt,
  handleInputChange,
  formatDateString,
  selectVal,
}) {
  const [showChaletDetails, setShowChaletDetails] = useState(false);
  const [blockVal, setBlockVal] = useState(null);
  const getBlockByDate = async (date) => {
    try {
      const res = await BLOCK.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch booking information");
      } else {
        console.log("res", res.data);
        if (res.data.length > 0) {
          setBlockVal(res.data[0]);
        } else {
          setBlockVal(null);
        }
      }
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  console.log("blockVal", blockVal);
  useEffect(() => {
    getBlockByDate(data.date);
  }, [data.date]);
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex flex-col">
            <div className="px-2 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Date <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {formatDateString(data.date)}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted One day tickets - (
                {data.odtBookings - data.odtToday >= 0
                  ? data.odtBookings - data.odtToday
                  : 0}
                &nbsp;left)
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handleInputChange}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtNoOfBooking"
                value={data.odtBookings}
              />
              {data.odtBookings === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Deluxe Chalet - (
                {data.cdBookings - data.cdToday >= 0
                  ? data.cdBookings - data.cdToday
                  : 0}
                &nbsp;left)
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                max={maxCount.cd}
                onChange={(e) => {
                  setData({
                    ...data,
                    cdBookings: e.target.value,
                  });
                }}
                placeholder="No of deluxe accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtNoOfBooking"
                value={data.cdBookings}
              />
              {data.cdBookings >= maxCount.cd + 1 && (
                <p className="text-red text-sm mt-1">
                  * Maximum of {maxCount.cd} chalets available in AquaMagic.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Standard Clalet - (
                {data.csBookings - data.csToday >= 0
                  ? data.csBookings - data.csToday
                  : 0}
                &nbsp;left)
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                max={maxCount.cs}
                onChange={(e) => {
                  setData({
                    ...data,
                    csBookings: e.target.value,
                  });
                }}
                placeholder="No of standard deluxe accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtNoOfBooking"
                value={data.csBookings}
              />

              {data.csBookings >= maxCount.cs + 1 && (
                <p className="text-red text-sm mt-1">
                  * Maximum of {maxCount.cs} chalets available in AquaMagic.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Premium Standard Clalet - (
                {data.psBookings - data.psToday >= 0
                  ? data.psBookings - data.psToday
                  : 0}
                &nbsp;left)
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                max={maxCount.ps}
                onChange={(e) => {
                  setData({
                    ...data,
                    psBookings: e.target.value,
                  });
                }}
                placeholder="No of premium standard accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtNoOfBooking"
                value={data.psBookings}
              />

              {data.psBookings >= maxCount.ps + 1 && (
                <p className="text-red text-sm mt-1">
                  * Maximum of {maxCount.ps} chalets available in AquaMagic.
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                One Day Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceOdt}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtPrice"
                value={data.odtPrice}
              />
              {data.odtPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Chalet Deluxe Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceCd}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="cdPrice"
                value={data.cdPrice}
              />
              {data.cdPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Chalet Standard Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceCs}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="csPrice"
                value={data.csPrice}
              />
              {data.csPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Premium Standard Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePricePs}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="psPrice"
                value={data.psPrice}
              />
              {data.psPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Nanny Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceMd}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="mdPrice"
                value={data.mdPrice}
              />
              {data.mdPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Specially Abled Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceSa}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="saPrice"
                value={data.saPrice}
              />
              {data.saPrice === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Per Deluxe
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    cdFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="cdFree"
                value={data.cdFree}
              />
              {data.cdFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Per Standard
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    csFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="csFree"
                value={data.csFree}
              />
              {data.csFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Guest
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    odtFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtFree"
                value={data.odtFree}
              />
              {data.odtFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>

          <div className="flex flex-col h-auto sm:pt-7">
            <div className="px-2 py-3 h-auto">
              <button
                onClick={() => {
                  handleUpdate();
                }}
                type="submit"
                className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Update Data"
                )}
              </button>
              <div className="flex justify-between">
                <button
                  onClick={() => {
                    backToAdd();
                  }}
                  type="button"
                  className="flex w-full justify-center py-3 font-sm text-sm text-red"
                >
                  Add New
                </button>
                <button
                  onClick={() => {
                    setShowChaletDetails(!showChaletDetails);
                  }}
                  type="button"
                  className="flex w-full justify-center py-3 font-sm text-sm text-red"
                >
                  {showChaletDetails ? "Hide " : "Show "}Chalet Details
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* // Chalet view session */}
        {showChaletDetails && (
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Available Deluxe Chalet Info
                </label>
                <div
                  className={`${
                    selectVal.dlxIni.length === 0 ? "h-12" : "h-auto"
                  } w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary`}
                >
                  {selectVal.dlxIni.map((data) => (
                    <p className="bg-gray px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                      {data.value}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Available Standard Chalet Info
                </label>
                <div
                  className={`${
                    selectVal.stdIni.length === 0 ? "h-12" : "h-auto"
                  } w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary`}
                >
                  {selectVal.stdIni.map((data) => (
                    <p className="bg-gray px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                      {data.value}
                    </p>
                  ))}
                </div>
              </div>
                    
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Available Premium Standard Chalet Info
                </label>
                <div
                  className={`${
                    selectVal.psdIni.length === 0 ? "h-12" : "h-auto"
                  } w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary`}
                >
                  {selectVal.psdIni.map((data) => (
                    <p className="bg-gray px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                      {data.value}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Blocked Deluxe Chalet Info
                </label>
                {blockVal !== null &&
                blockVal.blockedCD.length > 0 &&
                blockVal.blockedCD !== undefined ? (
                  <div className="h-auto w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {blockVal.blockedCD.map((data) => (
                      <p className="bg-lightred px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                        {data}
                      </p>
                    ))}
                  </div>
                ) : (
                  <NotBlocked />
                )}
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Blocked Standard Chalet Info
                </label>
                {blockVal !== null &&
                blockVal.blockedCS.length > 0 &&
                blockVal.blockedCS !== undefined ? (
                  <div className="h-auto w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {blockVal.blockedCS.map((data) => (
                      <p className="bg-lightred px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                        {data}
                      </p>
                    ))}
                  </div>
                ) : (
                  <NotBlocked />
                )}
              </div>
            </div>
            <div className="flex flex-col h-auto">
              <div className="px-2 py-2 h-auto">
                <label className="mb-2.5 block text-black dark:text-white">
                  Blocked Premium Standard Chalet Info
                </label>
                {blockVal !== null &&
                blockVal.blockedPS.length > 0 &&
                blockVal.blockedPS !== undefined ? (
                  <div className="h-auto w-full rounded border-[1.5px] grid grid-cols-4 gap-2 border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {blockVal.blockedPS.map((data) => (
                      <p className="bg-lightred px-2 text-black text-center rounded-lg cursor-pointer border border-primary">
                        {data}
                      </p>
                    ))}
                  </div>
                ) : (
                  <NotBlocked />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const NotBlocked = () => {
  return (
    <div className="h-12 w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
      <p className="px-2 text-black text-center rounded-lg cursor-pointer ">
        No Chalet Blocked
      </p>
    </div>
  );
};
