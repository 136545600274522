// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    border-radius: 401% 180% 310% 245% / 397% 270% 298% 178%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/Intro.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;AAC5D","sourcesContent":[".slider {\r\n    border-radius: 401% 180% 310% 245% / 397% 270% 298% 178%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
