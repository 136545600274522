export const MoneyIcon = () => {
    return (
      <svg
        fill="#fff"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="w-[19px]"
      >
        <g>
          <g>
            <path
              d="M439.645,414.69V283.834c0-80.866-52.54-149.688-125.276-174.133c15.477-15.161,25.106-36.272,25.106-59.598v-33.39
         c0-12.384-13.061-20.48-24.161-14.932c-37.138,18.572-81.482,18.572-118.628,0c-11.114-5.558-24.161,2.571-24.161,14.932v33.39
         c0,23.326,9.629,44.437,25.106,59.598c-72.736,24.445-125.276,93.266-125.276,174.133V414.69
         c-19.434,6.89-33.39,25.458-33.39,47.225C38.964,489.532,61.433,512,89.05,512H422.95c27.617,0,50.085-22.468,50.085-50.085
         C473.035,440.148,459.079,421.58,439.645,414.69z M205.915,41.41c32.513,10.227,67.658,10.227,100.17,0v8.694
         c0,27.617-22.468,50.085-50.085,50.085s-50.085-22.468-50.085-50.085V41.41z M422.95,478.61H89.05
         c-9.206,0-16.695-7.489-16.695-16.695c0-9.206,7.489-16.695,16.695-16.695c9.22,0,16.695-7.475,16.695-16.695v-144.69
         c0-82.851,67.405-150.255,150.255-150.255s150.255,67.405,150.255,150.255v144.69c0,9.22,7.475,16.695,16.695,16.695
         c9.206,0,16.695,7.489,16.695,16.695C439.645,471.12,432.156,478.61,422.95,478.61z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M263.128,291.335c-17.515-9.258-35.627-18.833-35.627-29.761c0-15.343,12.482-27.825,27.825-27.825
         c15.343,0,27.825,12.482,27.825,27.825c0,9.22,7.475,16.695,16.695,16.695s16.695-7.475,16.695-16.695
         c0-27.966-18.858-51.594-44.52-58.882v-7.898c0-9.22-7.475-16.695-16.695-16.695c-9.22,0-16.695,7.475-16.695,16.695v7.898
         c-25.662,7.287-44.52,30.916-44.52,58.882c0,31.047,29.616,46.702,53.414,59.281c17.515,9.258,35.626,18.832,35.626,29.759
         c0,15.343-12.482,27.825-27.825,27.825c-15.343,0-27.825-12.482-27.825-27.825c0-9.22-7.475-16.695-16.695-16.695
         c-9.22,0-16.695,7.475-16.695,16.695c0,27.966,18.858,51.594,44.52,58.882v7.898c0,9.22,7.475,16.695,16.695,16.695
         c9.22,0,16.695-7.475,16.695-16.695v-7.898c25.663-7.287,44.52-30.916,44.52-58.882
         C316.541,319.568,286.925,303.914,263.128,291.335z"
            />
          </g>
        </g>
      </svg>
    );
  };