import { useEffect } from "react";
import Checkout from "./Checkout";
import { toast } from "react-toastify";
import TENT from "../../../assets/tent.png";
import COMING from "../../../assets/coming.png";
import { DC } from "../../../api/dlxChalet";
import { CS } from "../../../api/stdChalet";
import HOUSE from "../../../assets/house.png";
import { BOOKING } from "../../../api/booking";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENT } from "../../../api/payment";
import CHALET from "../../../assets/chalet.png";
import PARKICON from "../../../assets/nature.png";
import PARKHUMAN from "../../../assets/human.png";
import CLOSE from "../../../assets/closecha.png";
import PARK from "../../../assets/parkticket.png";
import STANDARD from "../../../assets/standard.png";
import { TEMP_BOOKING } from "../../../api/tempBooking";
import { CONSTANT } from "../../../constant/Constant";
import { formatNumb } from "../../../utils/FormatNumb";
import { WORKING_HOURS } from "../../../api/workingHours";
import { TempContext } from "../../../utils/TempContext";
import React, { useContext, useRef, useState } from "react";
import { BookingContext } from "../../../utils/BookingContext";
import { numberToWords } from "../../../utils/NumberToWords";
import { formatDateString } from "../../../utils/FormatDateString";
import PurchaseBook from "./PurchaseBook";
import GuestBook from "./GuestBook";
import CardBook from "./CardBook";
import DateBook from "./DateBook";
import SAICON from "../../../assets/SAICON.png";
import MDICON from "../../../assets/MDICON.png";
import CSICON from "../../../assets/hometest2.png";
import TENTICON from "../../../assets/tenticon.png";
import { OFFERS } from "../../../api/offers";
import { PS } from "../../../api/preStd";

export default function Book() {
  //useState
  const [tempId, setTempId] = useState("");
  const [counterSa, setCounterSa] = useState(0);
  const [counterMd, setCounterMd] = useState(0);
  const [counterPc, setCounterPc] = useState(0);
  const [offers, setOffers] = useState([]);
  const [priceData, setPriceData] = useState({
    odt: 0,
    cd: 0,
    cs: 0,
    ps: 0,
    md: 0,
    sa: 0,
  });
  const [chaletNo, setChaletNo] = useState({
    dlx: [],
    std: [],
    psd: [],
  });
  const [loading, setLoading] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [showPayment, setShowPayment] = useState(true);
  const [togglePs, setTogglePs] = useState(false);
  const [toggleSc, setToggleSc] = useState(false);
  const [toggleDc, setToggleDc] = useState(false);
  const [validate, setValidate] = useState(false);
  const [counter, setCounter] = useState(0);
  const [counter1, setCounter1] = useState(0);
  const [counter2, setCounter2] = useState(0);

  const [todayCount, setTodayCount] = useState({
    odt: 0,
    cd: 0,
    cs: 0,
    ps: 0,
  });
  //useState

  //constant variables
  const { temp, setTemp } = useContext(TempContext);
  const navigate = useNavigate();

  const [free, setFree] = useState({
    DC_FREE: 0,
    SC_FREE: 0,
    ODT_FREE: 0,

  });
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    pnr: "",
    date: formatDate(new Date()),
  });
  const startDateRef = useRef(null);

  const calculateTotalPrice = () => {
    // Initial total price calculation
    let totalPrice =
      counter * priceData.odt +
      counter1 * priceData.cd +
      counter2 * priceData.cs +
      counterSa * priceData.sa +
      counterMd * priceData.md +
      counterPc * priceData.ps;

    // Iterate through each offer and check if it's applicable
    if (offers && offers.length > 0) {
      offers.forEach((offer) => {
        const activeLength = parseInt(offer.activeLength, 10);
        const discountAmount = parseFloat(offer.discountAmount);

        // Check if the offer is for "One Day Ticket" and applicable
        if (
          offer.typeOfBooking === "One Day Ticket" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counter >= activeLength
        ) {
          totalPrice -= discountAmount * counter;
        } else if (
          offer.typeOfBooking === "Chalet Standard" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counter2 >= activeLength
        ) {
          totalPrice -= discountAmount * counter2;
        } else if (
          offer.typeOfBooking === "Chalet Deluxe" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counter1 >= activeLength
        ) {
          totalPrice -= discountAmount * counter1;
        } else if (
          offer.typeOfBooking === "Specially Abled" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counterSa >= activeLength
        ) {
          totalPrice -= discountAmount * counterSa;
        } else if (
          offer.typeOfBooking === "Maid One Day Ticket" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counterMd >= activeLength
        ) {
          totalPrice -= discountAmount * counterMd;
        } else if (
          offer.typeOfBooking === "Premium Standard" &&
          activeLength > 0 &&
          discountAmount > 0 &&
          counterPc >= activeLength
        ) {
          totalPrice -= discountAmount * counterPc;
        }
      });
    }


    return totalPrice;
  };

  const total = calculateTotalPrice();

  const applyDiscount = (price, count, type) => {
    let discountAmount = 0;
    offers?.forEach((offer) => {
      if (offer.typeOfBooking === type) {
        if (offer.activeLength && count >= offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        } else if (!offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        }
      }
    });
    const discountedPrice = Math.max(0, price - discountAmount);
    return {
      originalPrice: price,
      discountedPrice,
      discountApplied: discountAmount > 0,
    };
  };

  const [discount, setDiscount] = useState({
    parkTicket: 0,
    speciallyAbledTicket: 0,
    nannyTicket: 0,
    chaletStandard: 0,
    premiumStandard: 0,
    chaletDeluxe: 0,
    total: 0,
  });

  const parkTicket = applyDiscount(priceData.odt, counter, "One Day Ticket");
  const speciallyAbledTicket = applyDiscount(
    priceData.sa,
    counterSa,
    "Specially Abled"
  );
  const nannyTicket = applyDiscount(
    priceData.md,
    counterMd,
    "Maid One Day Ticket"
  );
  const chaletStandard = applyDiscount(
    priceData.cs,
    counter2,
    "Chalet Standard"
  );
  const premiumStandard = applyDiscount(
    priceData.ps,
    counterPc,
    "Premium Standard"
  );
  const chaletDeluxe = applyDiscount(priceData.cd, counter1, "Chalet Deluxe");

  const totalDiscountedPrice =
    parkTicket.discountedPrice * counter +
    speciallyAbledTicket.discountedPrice * counterSa +
    nannyTicket.discountedPrice * counterMd +
    chaletStandard.discountedPrice * counter2 +
    premiumStandard.discountedPrice * counterPc +
    chaletDeluxe.discountedPrice * counter1;

  const discountAmount = total - totalDiscountedPrice;
  useEffect(() => {
    setDiscount({
      ...discount,
      parkTicket: parkTicket,
      speciallyAbledTicket: speciallyAbledTicket,
      nannyTicket: nannyTicket,
      chaletStandard: chaletStandard,
      premiumStandard: premiumStandard,
      chaletDeluxe: chaletDeluxe,
      total: discountAmount,
    });
    discount.parkTicket = parkTicket;
    discount.speciallyAbledTicket = speciallyAbledTicket;
    discount.nannyTicket = nannyTicket;
    discount.chaletStandard = chaletStandard;
    discount.premiumStandard = premiumStandard;
    discount.chaletDeluxe = chaletDeluxe;
    discount.total = discountAmount;
  }, [total]);

  const incrementCounter = () => {
    setCounter((prevCounter) =>
      prevCounter < todayCount.odt - (counterSa + counterMd)
        ? prevCounter + 1
        : prevCounter
    );

    if (counter === todayCount.odt - (counterSa + counterMd)) {
      toast.info("Reached Maximum Bookings for the Day");
    }
  };
  const incrementCounter1 = () => {
    setCounter1((prevCounter) =>
      prevCounter < todayCount.cd ? prevCounter + 1 : prevCounter
    );
  };
  const incrementCounter2 = () => {
    setCounter2((prevCounter) =>
      prevCounter < todayCount.cs ? prevCounter + 1 : prevCounter
    );
  };

  const incrementCounterSa = () => {
    // if (priceData.sa > 0)
    setCounterSa((prevCounter) =>
      prevCounter < todayCount.odt - (counter + counterMd)
        ? prevCounter + 1
        : prevCounter
    );
    if (counterSa === todayCount.odt - (counter + counterMd)) {
      toast.info("Reached Maximum Bookings for the Day");
    }
  };
  const incrementCounterMd = () => {
    // if (priceData.md > 0)
    setCounterMd((prevCounter) =>
      prevCounter < todayCount.odt - (counter + counterSa)
        ? prevCounter + 1
        : prevCounter
    );
    if (counterMd === todayCount.odt - (counter + counterSa)) {
      toast.info("Reached Maximum Bookings for the Day");
    }
  };
  const incrementCounterPc = () => {
    setCounterPc((prevCounter) =>
      prevCounter < todayCount.ps ? prevCounter + 1 : prevCounter
    );
  };

  const decrementCounterSa = () => {
    setCounterSa((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounterMd = () => {
    setCounterMd((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounterPc = () => {
    setCounterPc((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };

  const decrementCounter = () => {
    setCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounter1 = () => {
    setCounter1((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  const decrementCounter2 = () => {
    setCounter2((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
  };
  //Constant variables

  //dependant function
  const getExtraOdtFromCd = (count) => {
    var rtnVal = 0;
    if (free.DC_FREE === 0) {
      rtnVal = 0;
    } else {
      // rtnVal = free.DC_FREE - 1;
      rtnVal = count * (free.DC_FREE);
    }
    return rtnVal;
  };

  const fetchInitialOffers = async () => {
    try {
      const res = await OFFERS.GET_BY_DATE(data.date);
      setOffers(res.data);
    } catch (error) {
      console.error("Error fetching initial offers:", error);
    }
  };

  const getExtraOdtFromCs = (count) => {
    var rtnVal = 0;
    if (free.SC_FREE === 0) {
      rtnVal = 0;
    } else {
      // rtnVal = free.SC_FREE - 1;
      rtnVal = count * (free.SC_FREE);
    }
    return rtnVal;
  };
  const getExtraOdtFromCdLength = (count) => {
    var rtnVal = 0;
    if (free.DC_FREE === 0) {
      rtnVal = 0;
    } else {
      rtnVal = count * (free.DC_FREE);
    }
    return rtnVal;
  };

  const getExtraOdtFromCsLength = (count) => {
    var rtnVal = 0;
    if (free.SC_FREE === 0) {
      rtnVal = 0;
    } else {
      rtnVal = count * (free.SC_FREE);
    }
    return rtnVal;
  };

  const getExtraOdtFromOdtLength = (count) => {
    var rtnVal = 0;

    // Check if free.ODT_FREE is either 0 or undefined
    if (free.ODT_FREE === 0 || free.ODT_FREE === undefined) {
      console.log("in if length - free.ODT_FREE is 0 or undefined");
      rtnVal = 0;
    } else if (count === 2) {
      console.log("in else length");
      console.log("odtcount", count);
      console.log("free.ODT_FREE LENGTH", free.ODT_FREE);

      // Assign free.ODT_FREE if it's valid, otherwise 0
      rtnVal = free.ODT_FREE || 0;
      console.log("rtnVal", rtnVal);
    }

    return rtnVal;
  };

  const getExtraOdtFromOdt = (count) => {
    var rtnVal = 0;
    console.log("count in extra", count);

    // Check if free.ODT_FREE is 0 or undefined
    if (free.ODT_FREE === 0 || free.ODT_FREE === undefined) {
      console.log("in if - free.ODT_FREE is 0 or undefined");
      rtnVal = 0;
    } else if (count === 2) {
      console.log("in else - count is 2");
      rtnVal = free.ODT_FREE || 0; // Assign free.ODT_FREE or 0 if undefined
      console.log("free.ODT_FREE", free.ODT_FREE);
    }

    return rtnVal;
  };

  const clearData = () => {
    setData({
      ...data,
      mobile: "",
      email: "",
      name: "",
      date: formatDate(new Date()),
    });
  };
  //dependant function
  //function
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const saveData = async (temp) => {
    try {
      await localStorage.setItem("temp", temp);

    } catch (e) {
      console.log("Failed to save the data to the storage");
    }
  };

  const createTempBooking = async (req) => {
    try {
      const res = await TEMP_BOOKING.ADD(req);
      if (res.status === 200) {
        setTemp(res.data._id);
        saveData(res.data._id);
        setShowPayment(false);
        setTempId(res.data._id); // no need
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to create new admin");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneNumberRegex = /^\d{8}$/;
    return phoneNumberRegex.test(phoneNumber);
  };
  const checkChaletAvailablility = async (values) => {
    setLoading(true);
    console.log("values", values);
    try {
      const res = await BOOKING.CHALET_CHECK(values);
      setLoading(false);
      console.log("res", res);
      if (res.data !== null) {
        const rtnVal = await createTempBooking(values)
        return rtnVal;
      } else {
        toast.error("All chalets are boooked!");
        return null;
      }
    } catch (error) {
      console.log("error", error)
      if (error.response.status !== undefined && error.response.status === 400)
        toast.error(error.response.data.message);
      setLoading(false);
      return null;
    }
  };
  const proceedToPayment = async () => {
    if (
      data.name !== "" &&
      data.email !== "" &&
      isEmailValid(data.email) === true &&
      data.phoneNumber !== "" &&
      isPhoneNumberValid(data.phoneNumber) === true
    ) {
      setValidate(false);
      const odtArray = Array(counter).fill(0);
      const cdArray = Array(counter1).fill(1);
      const csArray = Array(counter2).fill(2);
      const combinedArray1 = odtArray.concat(cdArray, csArray);
      // Check if there are exactly two tickets in the odtArray
      let odtFreeArray = [];
      if (odtArray.length === 2) {
        odtFreeArray = Array(getExtraOdtFromOdtLength(counter)).fill(9);
      }
      const cdFreeArray = Array(getExtraOdtFromCdLength(counter1)).fill(3);
      const csFreeArray = Array(getExtraOdtFromCsLength(counter2)).fill(4);
      const psFreeArray = Array(getExtraOdtFromCsLength(counterPc)).fill(8);
      const combinedArray2 = combinedArray1.concat(
        cdFreeArray,
        csFreeArray,
        psFreeArray
      );
      const mdArray = Array(counterMd).fill(5);
      const saArray = Array(counterSa).fill(6);
      const pcArray = Array(counterPc).fill(7);
      const combinedArray3 = combinedArray2.concat(mdArray, saArray, pcArray);
      const reqUserArray = [];

      const selectedDc = [...selectedChaletsDc];
      const selectedNc = [...selectedChaletsNc];
      const selectedPc = [...selectedChaletsPc];
      const selectedDcFree = [...selectedChaletsDc];
      const selectedNcFree = [...selectedChaletsNc];
      const selectedPcFree = [...selectedChaletsPc];
      await combinedArray3.map((itm) => {
        var chaletNo = "";
        if (itm === 1) {
          chaletNo = selectedDc.shift();
        } else if (itm === 2) {
          chaletNo = selectedNc.shift();
        } else if (itm === 7) {
          chaletNo = selectedPc.shift();
        } else if (itm === 3) {
          chaletNo = selectedDcFree.shift();
        } else if (itm === 4) {
          chaletNo = selectedNcFree.shift();
        } else if (itm === 8) {
          chaletNo = selectedPcFree.shift();
        }
        var reqData = {
          ticketType: itm,
          chaletNo: chaletNo,
        };
        reqUserArray.push(reqData);
      });
      const pnr = "AQMG-" + (Math.floor(Math.random() * 9000000000) + 1);
      setData({
        ...data,
        pnr: pnr,
      });
      data.pnr = pnr;
      const request = {
        name: data.name,
        email: data.email,
        mobNo: data.phoneNumber,
        pnr: pnr,
        date: data.date,
        qrImage: "",
        ticketImage: "",
        ticketMembers: reqUserArray,
        payment: {
          paymentType: "Online Payment",
          paymentId: "",
        },
        groupSize: {
          totalNo:
            counter +
            getExtraOdtFromOdt(counter) +
            getExtraOdtFromCd(counter1) +
            getExtraOdtFromCs(counter2) +
            counter1 +
            counter2 +
            counterMd +
            counterSa +
            counterPc,
          odtNo: counter,
          odtFree: getExtraOdtFromOdt(counter),
          cdFree: getExtraOdtFromCd(counter1),
          scFree: getExtraOdtFromCs(counter2),
          psFree: getExtraOdtFromCs(counterPc),
          cdNo: counter1,
          csNo: counter2,
          psNo: counterPc,
          saNo: counterSa,
          mdNo: counterMd,
          // odtPrice: priceData.odt,
          // cdPrice: priceData.cd,
          // csPrice: priceData.cs,
          // psPrice: priceData.ps,
          // mdPrice: priceData.md,
          // saPrice: priceData.sa,
          odtPrice: parkTicket.discountedPrice,
          cdPrice: chaletDeluxe.discountedPrice,
          csPrice: chaletStandard.discountedPrice,
          psPrice: premiumStandard.discountedPrice,
          mdPrice: nannyTicket.discountedPrice,
          saPrice: speciallyAbledTicket.discountedPrice,
          totalPrice: totalDiscountedPrice,
        },
        isScanned: false,
        isPurged: false,
      };
      console.log("request", request)

      // check chelet availability = pending
      await checkChaletAvailablility(request);
    } else {
      setValidate(true);
    }
  };

  const getWorkingHours = async (date) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }


      if (res.data[0] === undefined) {
        setFree({
          ...free,
          DC_FREE: 0,
          SC_FREE: 0,
          ODT_FREE: 0,
        });
        free.DC_FREE = 0;
        free.SC_FREE = 0;
        free.ODT_FREE = 0;
        setTodayCount({
          ...todayCount,
          odt: 0,
          cd: 0,
          cs: 0,
          ps: 0,
        });
        setPriceData({
          ...priceData,
          odt: 0,
          cd: 0,
          cs: 0,
          ps: 0,
          md: 0,
          sa: 0,
        });
        priceData.odt = 0;
        priceData.cd = 0;
        priceData.cs = 0;
        priceData.ps = 0;
        priceData.md = 0;
        priceData.sa = 0;
        todayCount.odt = 0;
        todayCount.cd = 0;
        todayCount.cs = 0;
        todayCount.ps = 0;
        setCounter(0);
        setCounter1(0);
        setCounter2(0);
        setCounterSa(0);
        setCounterMd(0);
        setCounterPc(0);
        toast.info(
          "No Bookings are accepted today! Please choose another date."
        );
      } else {
        if (
          res.data[0].odt.noOfBooking - res.data[0].odt.bookingToday <
          counter + counterSa + counterMd
        ) {
          setCounter(0);
          setCounterSa(0);
          setCounterMd(0);
        }
        if (
          res.data[0].cs.noOfBooking - res.data[0].cs.bookingToday <
          counter2
        ) {
          setCounter2(0);
        }
        if (
          res.data[0].ps.noOfBooking - res.data[0].ps.bookingToday <
          counterPc
        ) {
          setCounterPc(0);
        }
        if (
          res.data[0].cd.noOfBooking - res.data[0].cd.bookingToday <
          counter1
        ) {
          setCounter1(0);
        }
        if (res.data[0].odt.mdPrice === 0) {
          setCounterMd(0);
        }
        if (res.data[0].ps.price === 0) {
          setCounterPc(0);
        }
        if (res.data[0].odt.saPrice === 0) {
          setCounterSa(0);
        }
        if (res.data[0].odt.odtPrice === 0) {
          setCounter(0);
        }

        if (
          res.data[0].odt.noOfBooking <= res.data[0].odt.bookingToday &&
          res.data[0].cd.noOfBooking == res.data[0].cd.bookingToday &&
          res.data[0].cs.noOfBooking == res.data[0].cs.bookingToday
        ) {
          toast.info(
            "All Bookings are filled for today! Please choose another date."
          );
        }
        setFree({
          ...free,
          DC_FREE: res.data[0].dlxFree,
          SC_FREE: res.data[0].stdFree,
          ODT_FREE: res.data[0].odtFree,
        });
        free.DC_FREE = res.data[0].dlxFree;
        free.SC_FREE = res.data[0].stdFree;
        free.ODT_FREE = res.data[0].odtFree;
        setTodayCount({
          ...todayCount,
          odt: res.data[0].odt.noOfBooking - res.data[0].odt.bookingToday,
          cd: res.data[0].cd.noOfBooking - res.data[0].cd.bookingToday,
          cs: res.data[0].cs.noOfBooking - res.data[0].cs.bookingToday,
          ps:
            res.data[0].ps !== undefined
              ? res.data[0].ps.noOfBooking - res.data[0].ps.bookingToday
              : 0,
        });
        setPriceData({
          ...priceData,
          odt: res.data[0].odt.price,
          cd: res.data[0].cd.price,
          cs: res.data[0].cs.price,
          ps: res.data[0].ps !== undefined ? res.data[0].ps.price : 0,
          md:
            res.data[0].odt.mdPrice !== undefined ? res.data[0].odt.mdPrice : 0,
          sa:
            res.data[0].odt.saPrice !== undefined ? res.data[0].odt.saPrice : 0,
        });
        priceData.odt = res.data[0].odt.price;
        priceData.cd = res.data[0].cd.price;
        priceData.cs = res.data[0].cs.price;
        priceData.ps = res.data[0].ps !== undefined ? res.data[0].ps.price : 0;
        priceData.md =
          res.data[0].odt.mdPrice !== undefined ? res.data[0].odt.mdPrice : 0;
        priceData.sa =
          res.data[0].odt.saPrice !== undefined ? res.data[0].odt.saPrice : 0;
        todayCount.odt =
          res.data[0].odt.noOfBooking - res.data[0].odt.bookingToday;
        todayCount.cd =
          res.data[0].cd.noOfBooking - res.data[0].cd.bookingToday;
        todayCount.cs =
          res.data[0].cs.noOfBooking - res.data[0].cs.bookingToday;
        todayCount.ps =
          res.data[0].ps.noOfBooking - res.data[0].ps.bookingToday;
        getAvailableChalets(res.data[0])
      }
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  const togglePsModel = () => {
    setTogglePs(!togglePs)
  }
  const toggleScModel = () => {
    setToggleSc(!toggleSc)
  }
  const toggleDcModel = () => {
    setToggleDc(!toggleDc)
  }
  const noModel = () => {

  }
  //function

  const getChaletDetails = (bookings) => {
    const bookedDlx = [];
    const bookedStd = [];
    const bookedPsd = [];
    bookings.map((book) => {
      book.ticketMembers.map((type) => {
        if (type.ticketType === 1) {
          bookedDlx.push(type.chaletNo)
        } else if (type.ticketType === 2) {
          bookedStd.push(type.chaletNo)
        } else if (type.ticketType === 7) {
          bookedPsd.push(type.chaletNo)
        }
      })
    })
    const rtnVal = {
      bookedDlx, bookedPsd, bookedStd
    }
    return rtnVal;
  }
  const getAvailableChalets = async (businessHours) => {
    var arrayDlx = [];
    var arrayStd = [];
    var arrayPsd = [];
    var allDlx = [];
    var allStd = [];
    var allPsd = [];

    const resPsd = await PS.GET_ALL();
    const resStd = await CS.GET_ALL();
    const resDlx = await DC.GET_ALL();

    const todayBookings = await BOOKING.GET_BY_DATE(data.date);
    console.log("todayBookings", todayBookings)

    var bookedDlx = [];
    var bookedStd = []
    var bookedPsd = []
    if (todayBookings.data.length > 0) {
      const bookedChalets = getChaletDetails(todayBookings.data)
      bookedDlx = bookedChalets.bookedDlx;
      bookedStd = bookedChalets.bookedStd;
      bookedPsd = bookedChalets.bookedPsd;
    }


    resPsd.data.map((itm) => {
      var req = itm.title;
      allPsd.push(req);
      return allPsd;
    });
    resStd.data.map((itm) => {
      var req = itm.title;
      allStd.push(req);
      return allStd;
    });
    resDlx.data.map((itm) => {
      var req = itm.title;
      allDlx.push(req);
      return allDlx;
    });

    allStd.map((itm) => {
      // if (businessHours.bookedCS.includes(itm)) {
      if (bookedStd.includes(itm)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayStd.push(itm);
      }
      return arrayStd;
    });
    allDlx.map((itm) => {
      // if (businessHours.bookedCD.includes(itm)) {
      if (bookedDlx.includes(itm)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayDlx.push(itm);
      }
      return arrayDlx;
    });
    allPsd.map((itm) => {
      // if (businessHours.bookedPS.includes(itm)) {
      if (bookedPsd.includes(itm)) {
        // console.log(`${itm} is already booked.`);
      } else {
        arrayPsd.push(itm);
      }
      return arrayPsd;
    });
    setChaletNo({
      ...chaletNo,
      dlx: arrayDlx,
      std: arrayStd,
      psd: arrayPsd,
    });
    chaletNo.dlx = arrayDlx;
    chaletNo.std = arrayStd;
    chaletNo.psd = arrayPsd;
  }


  //useEffect
  useEffect(() => {
    if (togglePs || toggleDc || toggleSc) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [togglePs, toggleDc, toggleSc]);
  useEffect(() => {
    fetchInitialOffers();
    getWorkingHours(data.date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.date]);

  //useEffect

  const ODT_CARD = {
    park: "Aqua Magic Water Park",
    title: "One Day Ticket",
    subTxt: "All-day access to unlimited fun",

    icon: PARKICON,
    iconTp: PARKHUMAN,
    iconMd: SAICON,
    iconBt: MDICON,

    img: PARK,
    miniTxt1: "Kids under 3 enter free - ID required",
    miniTxt2: "",
    // miniTxt2: `( ${
    //   todayCount.odt - (counter + counterMd + counterSa) >= 0
    //     ? todayCount.odt - (counter + counterMd + counterSa)
    //     : Math.abs(todayCount.odt - (counter + counterMd + counterSa))
    // } ${
    //   todayCount.odt - (counter + counterMd + counterSa) >= 0
    //     ? "Bookings Left"
    //     : "Over Bookings"
    // })`,
    txtColor:
      todayCount.odt - (counter + counterMd + counterSa) >= 0 ? "black" : "red",
    priceTp: priceData.odt,
    priceMd: priceData.sa,
    priceBt: priceData.md,

    subTxtTp: "Guest",
    subTxtMd: "Specially Abled",
    subTxtBt: "Nanny",

    decrementCounterTp: decrementCounter,
    incrementCounterTp: incrementCounter,

    decrementCounterMd: decrementCounterSa,
    incrementCounterMd: incrementCounterSa,

    decrementCounterBt: decrementCounterMd,
    incrementCounterBt: incrementCounterMd,

    counterTp: counter,
    counterMd: counterSa,
    counterBt: counterMd,

    isBlocked: false,
    isButton: false,
    toggleTp: noModel,
    toggleMd: noModel,
    toggleBt: noModel,
  };
  const CD_CARD = {
    park: "Aqua Magic",
    title: "Chalet Deluxe",
    subTxt: "Experience the true luxury",

    img: CHALET,
    miniTxt1: ` Get ${numberToWords(free.DC_FREE).toLowerCase()} free park ticket`,
    miniTxt2: `( ${todayCount.cd - counter1} Bookings left )`,
    priceTp: priceData.cd,
    priceMd: "",
    priceBt: "",

    icon: TENT,
    iconTp: TENTICON,
    iconMd: "",
    iconBt: "",

    subTxtTp: "Chalet Deluxe",
    subTxtMd: "",
    subTxtBt: "",

    decrementCounterTp: decrementCounter1,
    incrementCounterTp: incrementCounter1,

    decrementCounterMd: "",
    incrementCounterMd: "",

    decrementCounterBt: "",
    incrementCounterBt: "",
    counterTp: counter1,
    counterMd: "",
    counterBt: "",

    txtColor: "black",
    isBlocked: false,
    isButton: true,
    toggleTp: toggleDcModel,
    toggleMd: noModel,
    toggleBt: noModel,
  };
  const CS_CARD = {
    park: "Aqua Magic",
    title: "Chalet Standard",
    subTxt: "Enjoy comfort all-day long",

    icon: HOUSE,
    iconTp: CSICON,
    iconMd: CSICON,
    iconBt: "",

    img: STANDARD,
    miniTxt1: ` Get ${numberToWords(free.SC_FREE).toLowerCase()} free park ticket`,
    miniTxt2: `( ${todayCount.ps - counterPc} Premium Chalets left | ${todayCount.cs - counter2
      } Normal Chalets left )`,
    priceTp: priceData.ps,
    priceMd: priceData.cs,
    priceBt: "",

    // subTxtTp: `${numberToWords(free.SC_FREE)} Free Park Tickets`,
    subTxtTp: "Premium Chalet",
    subTxtMd: "Normal Chalet",
    subTxtBt: "",

    decrementCounterTp: decrementCounterPc,
    incrementCounterTp: incrementCounterPc,

    decrementCounterMd: decrementCounter2,
    incrementCounterMd: incrementCounter2,

    decrementCounterBt: "",
    incrementCounterBt: "",

    counterTp: counterPc,
    counterMd: counter2,
    counterBt: "",

    txtColor: "black",
    isBlocked: false,
    isButton: true,
    toggleTp: togglePsModel,
    toggleMd: toggleScModel,
    toggleBt: noModel,
  };
  const [selectedChaletsPc, setSelectedChaletsPc] = useState([]);
  const handleChaletSelection = (chalet) => {
    if (selectedChaletsPc.includes(chalet)) {
      setSelectedChaletsPc(selectedChaletsPc.filter(selected => selected !== chalet));
      setCounterPc(selectedChaletsPc.length - 1)
    } else {
      setSelectedChaletsPc([...selectedChaletsPc, chalet]);
      setCounterPc(selectedChaletsPc.length + 1)
    }
  };
  const [selectedChaletsNc, setSelectedChaletsNc] = useState([]);
  const handleChaletSelectionNc = (chalet) => {
    if (selectedChaletsNc.includes(chalet)) {
      setSelectedChaletsNc(selectedChaletsNc.filter(selected => selected !== chalet));
      setCounter2(selectedChaletsNc.length - 1)
    } else {
      setSelectedChaletsNc([...selectedChaletsNc, chalet]);
      setCounter2(selectedChaletsNc.length + 1)
    }
  };
  const [selectedChaletsDc, setSelectedChaletsDc] = useState([]);
  const handleChaletSelectionDc = (chalet) => {
    if (selectedChaletsDc.includes(chalet)) {
      setSelectedChaletsDc(selectedChaletsDc.filter(selected => selected !== chalet));
      setCounter1(selectedChaletsDc.length - 1)
    } else {
      setSelectedChaletsDc([...selectedChaletsDc, chalet]);
      setCounter1(selectedChaletsDc.length + 1)
    }
  };

  const getTempData = async (id) => {
    try {
      const res = await TEMP_BOOKING.GET_BY_ID(id);
      if (res.status === 200) {
        console.log("res-temp_by_id", res.data);
        return res.data[0]
      } else {
        return null
        throw new Error("Failed to fetch temporary booking data");
      }
    } catch (error) {
      return null
      // console.error("Error fetching temporary booking data:", error);
    }
  };

  const getWorkingHourRequest = async (tempBooking) => {
    var rtnVal = null
    var workingHoursReq = await WORKING_HOURS.GET_BY_DATE(tempBooking.date);
    const workingHours = workingHoursReq.data
    if (workingHoursReq !== null) {

      var psCount = 0;
      var csCount = 0;
      var cdCount = 0;

      tempBooking.ticketMembers.map((req) => {
        if (req.ticketType === 1) {
          if (workingHours[0].bookedCD.includes(req.chaletNo)) {
            cdCount = cdCount + 1;
            let index = workingHours[0].bookedCD.indexOf(req.chaletNo);
            if (index !== -1) {
              workingHours[0].bookedCD.splice(index, 1);
            }
          }
        } else if (req.ticketType === 2) {
          if (workingHours[0].updatedCS.includes(req.chaletNo)) {
            csCount = csCount + 1;
            let index = workingHours[0].updatedCS.indexOf(req.chaletNo);
            if (index !== -1) {
              workingHours[0].updatedCS.splice(index, 1);
            }
          }
        } else if (req.ticketType === 7) {
          if (workingHours[0].bookedPS.includes(req.chaletNo)) {
            psCount = psCount + 1;
            let index = workingHours[0].bookedPS.indexOf(req.chaletNo);
            if (index !== -1) {
              workingHours[0].bookedPS.splice(index, 1);
            }
          }
        }
      });
      const cdBookingLeft =
        workingHours[0].cd.noOfBooking -
        (workingHours[0].cd.bookingToday - cdCount);
      const csBookingLeft =
        workingHours[0].cs.noOfBooking -
        (workingHours[0].cs.bookingToday - csCount);
      const psBookingLeft =
        workingHours[0].ps.noOfBooking !== undefined
          ? workingHours[0].ps.noOfBooking -
          (workingHours[0].ps.bookingToday - psCount)
          : 0;
      const updatedData = {
        bookedCD: workingHours[0].bookedCD,
        bookedCS: workingHours[0].bookedCS,
        bookedPS: workingHours[0].bookedPS,
        cd: {
          price: workingHours[0].cd.price,
          noOfBooking: workingHours[0].cd.noOfBooking,
          bookingToday: workingHours[0].cd.bookingToday - cdCount,
          extraBooking: cdBookingLeft >= 0 ? 0 : Math.abs(cdBookingLeft),
        },
        cs: {
          price: workingHours[0].cs.price,
          noOfBooking: workingHours[0].cs.noOfBooking,
          bookingToday: workingHours[0].cs.bookingToday - csCount,
          extraBooking: csBookingLeft >= 0 ? 0 : Math.abs(csBookingLeft),
        },
        ps: {
          price:
            workingHours[0].ps.price !== undefined ? workingHours[0].ps.price : 0,
          noOfBooking:
            workingHours[0].ps.noOfBooking !== undefined
              ? workingHours[0].ps.noOfBooking
              : 0,
          bookingToday:
            workingHours[0].ps.bookingToday !== undefined
              ? workingHours[0].ps.bookingToday - psCount
              : 0,
          extraBooking: psBookingLeft >= 0 ? 0 : Math.abs(psBookingLeft),
        },
      };
      rtnVal = {
        id: workingHours[0]._id,
        updatedData: updatedData
      }
    }
    return rtnVal;
  }
  const deleteTempData = async (id) => {
    try {
      const res = await TEMP_BOOKING.DELETE(id);
      if (res.status === 200) {
        console.log("res-temp_by_id", res.data);
        setTemp("null");
        saveData();
      } else {
        throw new Error("Failed to fetch temporary booking data");
      }
    } catch (error) {
      // console.error("Error fetching temporary booking data:", error);
    }
  };

  const clearWorkingHour = async () => {
    if (!showPayment) {
      //get if from temp user
      console.log("temp", temp)
      // get temp booking data from the backend
      // assign temp data to a value
      const tempBooking = await getTempData(temp)
      if (tempBooking !== null) {
        // create working hour request without the temp data bookings
        console.log("tempBooking", tempBooking)
        const workingHourRequest = await getWorkingHourRequest(tempBooking)
        // update working hours
        await WORKING_HOURS.UPDATE(workingHourRequest.updatedData, workingHourRequest.id)
        // if its success remove temp booking
        await deleteTempData(tempBooking._id)
      }
    }
  }


  ////routing

  const location = useLocation();



  const clearStateData = (newpath, when, event) => {
    if (newpath !== location.pathname) {
      console.log("in", newpath, "curr", location.pathname, "dfdf", when)
      if (!when) {
        clearWorkingHour()
        setShowPayment(true)
        console.log("clear function")
      } else {
        navigate(newpath, { replace: true });
      }
    } else {
      event.preventDefault(); // Prevents unnecessary action
      navigate(newpath, { replace: true });
    }
  };


  function useNavigationPrompt(message, when, redirectPath) {
    const navigate = useNavigate();
    const location = useLocation();
    const previousPath = location.state?.from || redirectPath;

    useEffect(() => {
      window.history.pushState({ noBackExitsApp: true }, '');

      // const handleClose = ((event) => {
      //   console.log("event", event)
      //   if (!when) {
      //     event.preventDefault();
      //     event.returnValue = ""; // Triggers the confirmation dialog
      //   } else {
      //     return true; // Allow unload
      //   }
      // })

      const handleClose = (event) => {
        if (!when) {
          const confirmLeave = window.confirm(message || "You have unsaved changes. Are you sure you want to leave?");
          if (confirmLeave) {
            console.log("confirmed")
            clearWorkingHour()
            event.preventDefault();
          } else {
            console.log("cancelled")
            event.preventDefault();
            event.returnValue = ''; // Triggers the confirmation dialog
          }
        } else {
          return true; // Allow unload
        }
      };

      // Function to handle back button click
      const handlePopState = (event) => {
        if (event.state) {
          // if (event.state && event.state.noBackExitsApp) {
          console.log("Back button pressed, redirecting...");
          if (!when) {
            const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
            if (confirmLeave) {
              clearWorkingHour()
              navigate(previousPath, { replace: true });
            }
            else {
              console.log("no clicked")
            }
          }
          else {
            console.log("no clicked")
            navigate(previousPath, { replace: true });
          }
        }
        else {
          console.log("no clicked")
          navigate(previousPath, { replace: true });
        }
      };

      // window.addEventListener("beforeunload", handleClose)
      window.addEventListener('popstate', handlePopState);

      return () => {
        // window.removeEventListener("beforeunload", handleClose)
        window.removeEventListener('popstate', handlePopState);
      };
    }, [when, message, navigate, location.pathname]);
  }

  // useEffect(() => {
  //   document.body.addEventListener('click', (event) => {
  //     console.log(event.view.location.pathname)
  //     // handleNavigationState(event.view.location.pathname, isChanged, event)
  //     clearStateData(event.view.location.pathname, showPayment, event)
  //   });
  // }, [showPayment]);

  useNavigationPrompt("You have unsaved changes. Are you sure you want to leave?", showPayment, "/");

  ///// routing end



  return (
    <div className="h-auto w-auto flex justify-center py-25 ">
      <div className="w-full h-auto px-2 max-w-screen-2xl">
        {showUser ? (
          <ShowDateHeader data={data} />
        ) : (
          <ShowSelectDate
            data={data}
            setData={setData}
            startDateRef={startDateRef}
          />
        )}
        <div className="w-full h-auto flex flex-col gap-10 lg:grid grid-cols-2 lg:grid-cols-3 mb-20">
          <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3 col-span-2">
            <CardBook data={ODT_CARD} showPayment={showPayment} />
            <CardBook data={CS_CARD} showPayment={showPayment} selectedChaletsPc={selectedChaletsPc} selectedChaletsNc={selectedChaletsNc} />
            <CardBook data={CD_CARD} showPayment={showPayment} selectedChaletsDc={selectedChaletsDc} />
          </div>
          <PurchaseBook
            counter={counter}
            total={total}
            priceData={priceData}
            counter2={counter2}
            free={free}
            numberToWords={numberToWords}
            counter1={counter1}
            counterSa={counterSa}
            counterMd={counterMd}
            counterPc={counterPc}
            offers={offers}
            setOffers={setOffers}
            discount={discount}
            discountAmount={discountAmount}
            totalDiscountedPrice={totalDiscountedPrice}
          />
        </div>
        {!showUser ? (
          <div className="w-[100%] h-auto flex justify-center items-center lg:w-[66%]">
            <button
              className="bg-blue px-15 py-2 text-white rounded-md text-2xl"
              onClick={() => {
                if (
                  counter > 0 ||
                  counter1 > 0 ||
                  counter2 > 0 ||
                  counterSa > 0 ||
                  counterMd > 0 ||
                  counterPc > 0
                ) {
                  setShowUser(true);
                } else {
                  toast.info("Add at least one ticket before proceeding!");
                }
              }}
            >
              Continue
            </button>
          </div>
        ) : (
          <>
            <GuestBook
              data={data}
              validate={validate}
              showPayment={showPayment}
              isPhoneNumberValid={isPhoneNumberValid}
              setData={setData}
              isEmailValid={isEmailValid}
            />
            {showPayment ? (
              <div className="w-[100%] h-auto flex justify-center items-center lg:w-[66%]">
                <button
                  className="bg-blue px-15 py-2 text-white rounded-md text-2xl"
                  onClick={() => {
                    proceedToPayment();
                  }}
                >
                  {loading ? (
                    <div className="flex px-8.5 py-1 w-full h-auto items-center justify-center ">
                      <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    "Pay Now"
                  )}
                </button>
              </div>
            ) : (
              <>
                <div className="w-[100%] h-auto rounded-lg  pt-5 mt-20 mb-10 flex flex-col gap-4 lg:w-[66%] p-10 bg-white">
                  <p className="text-3xl font-bold text-poppins text-center lg:text-left pt-5 ">
                    Payment Method
                  </p>
                  <div className="w-full">
                    <Checkout
                      price={totalDiscountedPrice}
                      tempId={tempId}
                      data={data}
                      pnr={data.pnr}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {togglePs && (
        <Model close={togglePsModel}>
          <div className="bg-primary w-full h-full flex flex-col ">
            <div className="w-full h-full grid grid-cols-2 gap-3">
              <div className="col-span-1 bg-pr">
                <p className="text-white font-poppins text-base font-bold p-2 ">SELECT CHALETS</p>
              </div>
              <div className="col-span-1 bg-pr">
                <p className="text-white text-end font-semibold text-base font-mont p-2 ">
                  {selectedChaletsPc.length} Ticket{selectedChaletsPc.length !== 1 && 's'} selected
                </p>
              </div>
            </div>
            <div className="w-auto h-auto grid grid-cols-5 gap-2 p-2">
              {chaletNo.psd.map((chalet) => (
                <div
                  key={chalet}
                  className={`w-auto h-auto col-span-1 p-2 rounded-md border-2 flex justify-center items-center font-semibold text-base italic cursor-pointer
        ${selectedChaletsPc.includes(chalet) ? 'bg-white text-red-500 border-red-500' : 'text-white hover:bg-white hover:text-primary border-white'}`}
                  onClick={() => handleChaletSelection(chalet)}
                >
                  {chalet}
                </div>
              ))}
            </div>

          </div>
        </Model>
      )}
      {toggleSc && (
        <Model close={toggleScModel}>
          <div className="bg-primary w-full h-full flex flex-col ">
            <div className="w-full h-full grid grid-cols-2 gap-3">
              <div className="col-span-1 bg-pr">
                <p className="text-white font-poppins text-base font-bold p-2 ">SELECT CHALETS</p>
              </div>
              <div className="col-span-1 bg-pr">
                <p className="text-white text-end font-semibold text-base font-mont p-2 ">
                  {selectedChaletsNc.length} Ticket{selectedChaletsNc.length !== 1 && 's'} selected
                </p>
              </div>
            </div>
            <div className="w-auto h-auto grid grid-cols-5 gap-2 p-2">
              {chaletNo.std.map((chalet) => (
                <div
                  key={chalet}
                  className={`w-auto h-auto col-span-1 p-2 rounded-md border-2 flex justify-center items-center font-semibold text-base italic cursor-pointer
                  ${selectedChaletsNc.includes(chalet) ? 'bg-white text-red-500 border-red-500' : 'text-white hover:bg-white hover:text-primary border-white'}`}
                  onClick={() => handleChaletSelectionNc(chalet)}
                >
                  {chalet}
                </div>
              ))}
            </div>

          </div>
        </Model>
      )}
      {toggleDc && (
        <Model close={toggleDcModel}>
          <div className="bg-primary w-full h-full flex flex-col ">
            <div className="w-full h-full grid grid-cols-2 gap-3">
              <div className="col-span-1 bg-pr">
                <p className="text-white font-poppins text-base font-bold p-2 ">SELECT CHALETS</p>
              </div>
              <div className="col-span-1 bg-pr">
                <p className="text-white text-end font-semibold text-base font-mont p-2 ">
                  {selectedChaletsDc.length} Ticket{selectedChaletsDc.length !== 1 && 's'} selected
                </p>
              </div>
            </div>
            <div className="w-auto h-auto grid grid-cols-5 gap-2 p-2">
              {chaletNo.dlx.map((chalet) => (
                <div
                  key={chalet}
                  className={`w-auto h-auto col-span-1 p-2 rounded-md border-2 flex justify-center items-center font-semibold text-base italic cursor-pointer
        ${selectedChaletsDc.includes(chalet) ? 'bg-white text-red-500 border-red-500' : 'text-white hover:bg-white hover:text-primary border-white'}`}
                  onClick={() => handleChaletSelectionDc(chalet)}
                >
                  {chalet}
                </div>
              ))}
            </div>

          </div>
        </Model>
      )}

    </div>
  );
}
const Model = ({ children, close }) => {
  const modalRef = useRef();

  useEffect(() => {
    // Function to check if the click is outside the modal
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        close(); // Close the modal if the click is outside
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [close]);
  return (
    <div className="h-screen w-full bg-[#000000ad] fixed  top-0 flex justify-center items-center z-999 ">
      <div ref={modalRef} className="h-auto bg-primary rounded-md w-[400px] ">
        <div className="w-full  flex justify-end p-1">
          <button onClick={() => { close() }}>
            {/* <img src={CLOSE}
           alt="close" 
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="25px"
              height="25px"
              className="p-0.3"
            >
              <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" fill="white" />
            </svg>
          </button>
        </div>
        <div className="w-full h-full px-2 pb-2 bg-primary rounded-md">
          {children}
        </div>
      </div>
    </div>
  )
}
const ShowDateHeader = ({ data }) => {
  return (
    <div>
      <DateBook data={data} formatDateString={formatDateString} />
    </div>
  );
};
const ShowSelectDate = ({ data, setData, startDateRef }) => {
  const handleStartDate = () => {
    startDateRef.current.showPicker();
  };
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="w-auto h-auto py-10">
      <div className="w-auto">
        <p className="font-mont font-light text-3xl  w-self">
          Please Select Your Ticket
        </p>
        <p className="font-mont font-normal text-lg py-1 text-[#959595]">
          Choose Your Park Pass And Embark On An Unforgettable Journey!
        </p>
      </div>
      <button onClick={handleStartDate} className="w-full relative">
        <div className="flex px-3 py-2.5 w-full md:w-[90%] rounded-md  items-center justify-between shadow-lg mt-4 lg:w-[50%]">
          <div className="font-light text-start">
            <p className="text-sm"> {formatDateString(data.date)}</p>
          </div>
          <div className="w-auto h-auto mr-2.5">
            <svg
              id="calendar_2_"
              data-name="calendar (2)"
              xmlns="http://www.w3.org/2000/svg"
              width="21.238"
              height="21.238"
              viewBox="0 0 21.238 21.238"
            >
              <g id="Group_6" data-name="Group 6">
                <g id="Group_5" data-name="Group 5">
                  <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M279,280.609a1.661,1.661,0,0,0,1.659,1.659h1.95a1.661,1.661,0,0,0,1.659-1.659v-1.95A1.661,1.661,0,0,0,282.609,277h-1.95A1.661,1.661,0,0,0,279,278.659Zm1.659-1.95h1.95v1.95h-1.951Z"
                    transform="translate(-267.427 -265.51)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(14.983 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(11.653 8.324)"
                    fill="#0e6097"
                  />
                  <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M20.409,14.6a.83.83,0,0,0,.83-.83V4.978A3.322,3.322,0,0,0,17.92,1.659H16.841V.83a.83.83,0,1,0-1.659,0v.83H11.407V.83a.83.83,0,0,0-1.659,0v.83H6.015V.83a.83.83,0,1,0-1.659,0v.83H3.319A3.322,3.322,0,0,0,0,4.978V17.92a3.322,3.322,0,0,0,3.319,3.319h14.6a3.322,3.322,0,0,0,3.319-3.319.83.83,0,1,0-1.659,0,1.661,1.661,0,0,1-1.659,1.659H3.319A1.661,1.661,0,0,1,1.659,17.92V4.978A1.661,1.661,0,0,1,3.319,3.319H4.356v.83a.83.83,0,1,0,1.659,0v-.83H9.748v.83a.83.83,0,0,0,1.659,0v-.83h3.775v.83a.83.83,0,1,0,1.659,0v-.83H17.92a1.661,1.661,0,0,1,1.659,1.659v8.794A.83.83,0,0,0,20.409,14.6Z"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 14.983)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 14.983)"
                    fill="#0e6097"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>

        <input
          type="date"
          ref={startDateRef}
          min={today}
          onChange={(e) => {
            setData({
              ...data,
              date: e.target.value,
            });
          }}
          className="opacity-0 absolute  h-full w-full top-0 left-0" // Hide the input
        />
      </button>
    </div>
  );
};
