import React, { useEffect, useState } from 'react'
import { OFFERS } from '../../../api/offers';
export default function DateAdd(
  { data, setData, today, startDateRef, formatDateString, handleStartDate }
) {
  const [offers, setOffers] = useState([]);
  const handleDateChange = async (e) => {
    const selectedDate = e.target.value;
    setData((prevData) => ({
      ...prevData,
      date: selectedDate,
    }));
    // console.log("dddd", selectedDate)

    try {
      const res = await OFFERS.GET_BY_DATE(selectedDate);
      setOffers(res.data);
      // console.log("first", res.data)
    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  useEffect(() => {
    const fetchInitialOffers = async () => {
      try {
        const res = await OFFERS.GET_BY_DATE(data.date);
        setOffers(res.data);
        // console.log("Initial offers:", res.data);
      } catch (error) {
        console.error('Error fetching initial offers:', error);
      }
    };

    fetchInitialOffers();
  }, [data.date])
  return (
    <div className="w-auto h-auto py-10 lg:flex">
      <div className="w-auto lg:mr-8">
        <p className="font-mont font-light text-3xl  w-self">
          Please Select Your Ticket
        </p>
        <p className="font-mont font-normal text-lg py-1 text-[#959595]">
          Choose Your Park Pass And Embark On An Unforgettable Journey!
        </p>
      </div>
      <button onClick={handleStartDate} className="lg:w-[25%] w-full relative">
        <div className="flex px-3 py-2.5 w-full md:w-full rounded-md  items-center justify-between shadow-lg mt-4 lg:w-full">
          <div className="font-light text-start">
            <p className="text-sm mb-1">Selected Date</p>
            <p className="text-sm font-semibold">
              {" "}
              {formatDateString(data.date)}
            </p>
          </div>
          <div className="w-auto h-auto mr-2.5">
            <svg
              id="calendar_2_"
              data-name="calendar (2)"
              xmlns="http://www.w3.org/2000/svg"
              width="21.238"
              height="21.238"
              viewBox="0 0 21.238 21.238"
            >
              <g id="Group_6" data-name="Group 6">
                <g id="Group_5" data-name="Group 5">
                  <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M279,280.609a1.661,1.661,0,0,0,1.659,1.659h1.95a1.661,1.661,0,0,0,1.659-1.659v-1.95A1.661,1.661,0,0,0,282.609,277h-1.95A1.661,1.661,0,0,0,279,278.659Zm1.659-1.95h1.95v1.95h-1.951Z"
                    transform="translate(-267.427 -265.51)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(14.983 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(11.653 8.324)"
                    fill="#0e6097"
                  />
                  <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M20.409,14.6a.83.83,0,0,0,.83-.83V4.978A3.322,3.322,0,0,0,17.92,1.659H16.841V.83a.83.83,0,1,0-1.659,0v.83H11.407V.83a.83.83,0,0,0-1.659,0v.83H6.015V.83a.83.83,0,1,0-1.659,0v.83H3.319A3.322,3.322,0,0,0,0,4.978V17.92a3.322,3.322,0,0,0,3.319,3.319h14.6a3.322,3.322,0,0,0,3.319-3.319.83.83,0,1,0-1.659,0,1.661,1.661,0,0,1-1.659,1.659H3.319A1.661,1.661,0,0,1,1.659,17.92V4.978A1.661,1.661,0,0,1,3.319,3.319H4.356v.83a.83.83,0,1,0,1.659,0v-.83H9.748v.83a.83.83,0,0,0,1.659,0v-.83h3.775v.83a.83.83,0,1,0,1.659,0v-.83H17.92a1.661,1.661,0,0,1,1.659,1.659v8.794A.83.83,0,0,0,20.409,14.6Z"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 14.983)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(4.162 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 11.653)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 8.324)"
                    fill="#0e6097"
                  />
                  <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="0.832"
                    cy="0.832"
                    r="0.832"
                    transform="translate(8.324 14.983)"
                    fill="#0e6097"
                  />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <input
          type="date"
          ref={startDateRef}
          min={today}
          // onChange={(e) => {
          //   setData({
          //     ...data,
          //     date: e.target.value,
          //   });
          // }}
          onChange={handleDateChange}
          value={data.date}
          className="opacity-0 absolute bg-red h-full w-full top-0 left-0" // Hide the input
        />
      </button>
    </div>
  )
}
