import React from "react";
export default function PurchaseData({
  total,
  priceData,
  counter1,
  counter,
  numberToWords,
  free,
  counter2,
  counterSa,
  counterMd,
  counterPc,
  offers,
  discount,
  discountAmount,
  totalDiscountedPrice,
}) {
  const applyDiscount = (price, count, type) => {
    let discountAmount = 0;
    offers?.forEach((offer) => {
      if (offer.typeOfBooking === type) {
        if (offer.activeLength && count >= offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        } else if (!offer.activeLength) {
          discountAmount = Math.max(discountAmount, offer.discountAmount);
        }
      }
    });
    const discountedPrice = Math.max(0, price - discountAmount);
    return {
      originalPrice: price,
      discountedPrice,
      discountApplied: discountAmount > 0,
    };
  };

  const parkTicket = applyDiscount(priceData.odt, counter, "One Day Ticket");
  const speciallyAbledTicket = applyDiscount(
    priceData.sa,
    counterSa,
    "Specially Abled"
  );
  const nannyTicket = applyDiscount(
    priceData.md,
    counterMd,
    "Maid One Day Ticket"
  );
  const chaletStandard = applyDiscount(
    priceData.cs,
    counter2,
    "Chalet Standard"
  );
  const premiumStandard = applyDiscount(
    priceData.ps,
    counterPc,
    "Premium Standard"
  );
  const chaletDeluxe = applyDiscount(priceData.cd, counter1, "Chalet Deluxe");

  const totalOriginalPrice =
    discount.parkTicket.originalPrice * counter +
    discount.speciallyAbledTicket.originalPrice * counterSa +
    discount.nannyTicket.originalPrice * counterMd +
    discount.chaletStandard.originalPrice * counter2 +
    discount.premiumStandard.originalPrice * counterPc +
    discount.chaletDeluxe.originalPrice * counter1;

  // const totalDiscountedPrice =
  //   discount.parkTicket.discountedPrice * counter +
  //   discount.speciallyAbledTicket.discountedPrice * counterSa +
  //   discount.nannyTicket.discountedPrice * counterMd +
  //   discount.chaletStandard.discountedPrice * counter2 +
  //   discount.premiumStandard.discountedPrice * counterPc +
  //   discount.chaletDeluxe.discountedPrice * counter1;

  // const discountAmount = totalOriginalPrice - totalDiscountedPrice;

  // console.log("counter", totalDiscountedPrice);

  return (
    <div className="flex justify-center items-start lg:col-span-1 ">
      <div className=" w-full h-auto p-4 rounded-2xl bg-white shadow-lg">
        <div className="w-full h-auto">
          <p className="text-poppins text-primary text-2xl xl:text-3xl font-semibold">
            Purchase summary
          </p>
        </div>
        {/* park Ticket Guest*/}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-">
              Park Ticket [{counter}×{priceData.odt}]
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold">
              : KWD {(counter * priceData.odt).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
                className={`text-sm xl:text-lg font-lighter ${
                  parkTicket.discountApplied ? "line-through opacity-50" : ""
                }`}
              > */}
            <p className="text-sm xl:text-lg font-lighter ">
              Park Ticket
              {parkTicket.discountApplied ? (
                <span className="ml-2">
                  [{counter}×{parkTicket.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    parkTicket.discountApplied ? "line-through opacity-50" : ""
                  }`}
                >
                  [{counter}×{priceData.odt}]
                </span>
              )}
            </p>
            <span className="text-[10px] xl:text-sm text-mont font-thin">
              {numberToWords(free.ODT_FREE)} free park ticket for 2 guest
            </span>
            {/* {parkTicket.discountApplied && (
              <div className="flex gap-2">
                <p className="text-sm xl:text-lg font-lighter invisible">
                  Park Ticket
                </p>
                <p className="text-sm xl:text-lg font-lighter text-red">
                  [{counter}×{parkTicket.discountedPrice}]
                </p>
              </div>
            )} */}
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                parkTicket.discountApplied && counter > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD {(counter * parkTicket.originalPrice).toLocaleString()}
            </p>
            {parkTicket.discountApplied && counter > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD {(counter * parkTicket.discountedPrice).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        {/* park Ticket Specially abled*/}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-"> */}
        {/* Park Ticket(Specially Abled) [{counterSa}×{priceData.odt}]
         */}
        {/* Park Ticket (Specially Abled) [{counterSa}×20]
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold"> */}
        {/* : KWD {(counterSa * priceData.odt).toLocaleString()} */}
        {/* : KWD{" "} {(counterSa * 20).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
                className={`text-sm xl:text-lg font-lighter ${
                  speciallyAbledTicket.discountApplied
                    ? "line-through opacity-50"
                    : ""
                }`}
              >
                Park Ticket (Specially Abled)
              </p> */}
            <p className="text-sm xl:text-lg font-lighter ">
              Park Ticket (Specially Abled)
              {speciallyAbledTicket.discountApplied ? (
                <span className="ml-2">
                  [{counterSa}×{speciallyAbledTicket.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    speciallyAbledTicket.discountApplied
                      ? "line-through opacity-50"
                      : ""
                  }`}
                >
                  [{counterSa}×{priceData.sa}]
                </span>
              )}
            </p>
            {/* {speciallyAbledTicket.discountApplied && (
              <div className="flex gap-2">
                <p className="text-sm xl:text-lg font-lighter invisible">
                  Park Ticket (Specially Abled)
                </p>
                <p className="text-sm xl:text-lg font-lighter text-red bg-">
                  [{counterSa}×{speciallyAbledTicket.discountedPrice}]
                </p>
              </div>
            )} */}
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                speciallyAbledTicket.discountApplied && counterSa > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD{" "}
              {(
                counterSa * speciallyAbledTicket.originalPrice
              ).toLocaleString()}
            </p>
            {speciallyAbledTicket.discountApplied && counterSa > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD{" "}
                {(
                  counterSa * speciallyAbledTicket.discountedPrice
                ).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        {/* park Ticket Maids*/}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-"> */}
        {/* Park Ticket(Nanny) [{counterMd}×{priceData.odt}] */}
        {/* Park Ticket (Nanny) [{counterMd}×10]
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold">
              : KWD {(counterMd * 10).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
              className={`text-sm xl:text-lg font-lighter ${
                nannyTicket.discountApplied ? "line-through opacity-50" : ""
              }`}
            >
              Park Ticket (Nanny) [{counterMd}×{priceData.md}]
            </p> */}
            <p className="text-sm xl:text-lg font-lighter ">
              Park Ticket (Nanny)
              {nannyTicket.discountApplied ? (
                <span className="ml-2">
                  [{counterMd}×{nannyTicket.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    nannyTicket.discountApplied ? "line-through opacity-50" : ""
                  }`}
                >
                  [{counterMd}×{priceData.md}]
                </span>
              )}
            </p>

            {/* {nannyTicket.discountApplied && (
              <p className="text-sm xl:text-lg font-lighter text-red">
                [{counterMd}×{nannyTicket.discountedPrice}]
              </p>
            )} */}
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                nannyTicket.discountApplied && counterMd > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD {(counterMd * nannyTicket.originalPrice).toLocaleString()}
            </p>
            {nannyTicket.discountApplied && counterMd > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD{" "}
                {(counterMd * nannyTicket.discountedPrice).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        {/* Chalet Standared  */}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg leading-6">
              Normal Chalet Standard [{counter2}×{priceData.cs}]<br></br>
              <span className="text-[10px] xl:text-sm text-mont font-thin">
                {numberToWords(free.SC_FREE)} free park ticket per chalet
              </span>
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold">
              : KWD {(counter2 * priceData.cs).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
              className={`text-sm xl:text-lg font-lighter ${
                chaletStandard.discountApplied ? "line-through opacity-50" : ""
              }`}
            >
              Normal Chalet Standard [{counter2}×{priceData.cs}]
            </p> */}
            <p className="text-sm xl:text-lg font-lighter ">
              Normal Chalet Standard
              {chaletStandard.discountApplied ? (
                <span className="ml-2">
                  [{counter2}×{chaletStandard.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    chaletStandard.discountApplied
                      ? "line-through opacity-50"
                      : ""
                  }`}
                >
                  [{counter2}×{priceData.cs}]
                </span>
              )}
            </p>
            {/* {chaletStandard.discountApplied && (
              <p className="text-sm xl:text-lg font-lighter text-red">
                [{counter2}×{chaletStandard.discountedPrice}]
              </p>
            )} */}
            <span className="text-[10px] xl:text-sm text-mont font-thin">
              {numberToWords(free.SC_FREE)} free park ticket per chalet
            </span>
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                chaletStandard.discountApplied && counter2 > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD {(counter2 * chaletStandard.originalPrice).toLocaleString()}
            </p>
            {chaletStandard.discountApplied && counter2 > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD{" "}
                {(counter2 * chaletStandard.discountedPrice).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        {/* Premium Chalet Standard */}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg leading-6">
              Premium Chalet Standard [{counterPc}×60]<br></br>
              <span className="text-[10px] xl:text-sm text-mont font-thin">
                {numberToWords(free.SC_FREE)} free park ticket per chalet
              </span>
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold">
              : KWD {(counterPc * 60).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
              className={`text-sm xl:text-lg font-lighter ${
                premiumStandard.discountApplied ? "line-through opacity-50" : ""
              }`}
            >
              Premium Chalet Standard [{counterPc}×{priceData.ps}]
            </p> */}
            <p className="text-sm xl:text-lg font-lighter ">
              Premium Chalet Standard
              {premiumStandard.discountApplied ? (
                <span className="ml-2">
                  [{counterPc}×{premiumStandard.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    premiumStandard.discountApplied
                      ? "line-through opacity-50"
                      : ""
                  }`}
                >
                  [{counterPc}×{priceData.ps}]
                </span>
              )}
            </p>
            {/* {premiumStandard.discountApplied && (
              <p className="text-sm xl:text-lg font-lighter text-red">
                [{counterPc}×{premiumStandard.discountedPrice}]
              </p>
            )} */}
            <span className="text-[10px] xl:text-sm text-mont font-thin">
              {numberToWords(free.SC_FREE)} free park ticket per chalet
            </span>
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                premiumStandard.discountApplied && counterPc > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD{" "}
              {(counterPc * premiumStandard.originalPrice).toLocaleString()}
            </p>
            {premiumStandard.discountApplied && counterPc > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD{" "}
                {(counterPc * premiumStandard.discountedPrice).toLocaleString()}
              </p>
            )}
          </div>
        </div>

        {/* Chalet Deluxe */}
        {/* <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg leading-6 ">
              Chalet Deluxe [{counter1}×{priceData.cd}]<br></br>
              <span className="text-[10px] xl:text-sm text-mont font-thin">
                {numberToWords(free.DC_FREE)} free park ticket per chalet
              </span>
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-semibold">
              : KWD {(counter1 * priceData.cd).toLocaleString()}
            </p>
          </div>
        </div> */}

        <div className="w-full flex justify-between mt-2 lg:mt-5">
          <div className="w-[70%]">
            {/* <p
              className={`text-sm xl:text-lg font-lighter ${
                chaletDeluxe.discountApplied ? "line-through opacity-50" : ""
              }`}
            >
              Chalet Deluxe [{counter1}×{priceData.cd}]
            </p> */}
            <p className="text-sm xl:text-lg font-lighter ">
              Chalet Deluxe
              {chaletDeluxe.discountApplied ? (
                <span className="ml-2">
                  [{counter1}×{chaletDeluxe.discountedPrice}]
                </span>
              ) : (
                <span
                  className={`text-sm xl:text-lg font-lighter ml-2 ${
                    chaletDeluxe.discountApplied
                      ? "line-through opacity-50"
                      : ""
                  }`}
                >
                  [{counter1}×{priceData.cd}]
                </span>
              )}
            </p>
            {/* {chaletDeluxe.discountApplied && (
              <p className="text-sm xl:text-lg font-lighter text-red">
                [{counter1}×{chaletDeluxe.discountedPrice}]
              </p>
            )} */}
            <span className="text-[10px] xl:text-sm text-mont font-thin">
              {numberToWords(free.DC_FREE)} free park ticket per chalet
            </span>
          </div>
          <div className="w-[30%]">
            <p
              className={`text-sm xl:text-lg font-semibold ${
                chaletDeluxe.discountApplied && counter1 > 0
                  ? "line-through opacity-50"
                  : ""
              }`}
            >
              : KWD {(counter1 * chaletDeluxe.originalPrice).toLocaleString()}
            </p>
            {chaletDeluxe.discountApplied && counter1 > 0 && (
              <p className="text-sm xl:text-lg font-semibold text-red">
                : KWD{" "}
                {(counter1 * chaletDeluxe.discountedPrice).toLocaleString()}
              </p>
            )}
          </div>
        </div>
        <hr className="border-t border-dashed mt-3 w-full"></hr>
        <div className="w-full flex justify-between mt-8">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-poppins ">TOTAL</p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg  font-poppins">
              : KWD {totalOriginalPrice.toLocaleString()}
            </p>
          </div>
        </div>
        <div className="w-full flex justify-between mt-2">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-poppins  text-red">
              DISCOUNT
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg  font-poppins text-red">
              : KWD {(totalOriginalPrice - totalDiscountedPrice).toLocaleString()}
            </p>
          </div>
        </div>
        <div className="w-full flex justify-between mt-2">
          <div className="w-[70%]">
            <p className="text-sm xl:text-lg font-poppins font-bold">
              GRAND TOTAL
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-sm xl:text-lg font-bold font-poppins">
              : KWD {totalDiscountedPrice.toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
