import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const PAYMENT = {
  ADD(req) {
    const url = `${CONSTANT.URL}payment`;
    return API_REQUEST("POST", url, req);
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}payment`;
    return API_REQUEST("GET", url);
  },
  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}payment/${id}`;
    return API_REQUEST("GET", url);
  },
  SEARCH(val) {
    const url = `${CONSTANT.URL}payment/search/${val}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_PNR(pnr) {
    const url = `${CONSTANT.URL}payment/pnr/${pnr}`;
    return API_REQUEST("GET", url);
  },
  DELETE(req, id) {
    const url = `${CONSTANT.URL}payment/delete/${id}`;
    return API_REQUEST("PUT", url, req);
  },
  UPDATE(req, id) {
    const url = `${CONSTANT.URL}payment/${id}`;
    return API_REQUEST("PUT", url, req);
  },
};
