import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import { EVENTS } from "../../api/events";

export default function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await EVENTS.GET_ALL();
        const baseURL = "/event";
        const imageUrls = response.data.map((image) => ({
          ...image,
          img: `${baseURL}/${image.eventImage}`,
        }));
        setEvents(imageUrls);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, []);

  if (events.length === 0) {
    return null;
  }
  return (
    <>
      {!loading && (
        <div className="w-full h-auto flex justify-center items-center bg-intro  ">
          <div className="w-5/6 h-auto my-20 max-w-screen-2xl">
            <p className="h-auto  text-5xl  font-mont font-thin">AWESOME</p>
            <p className="h-auto  text-5xl  font-poppins font-extrabold lg:font-extrabold ">
              EVENTS
            </p>
            <div className="w-full h-auto flex items-center  mt-10 ">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#7B899C",
                }}
                spaceBetween={20}
                loop={true}
                speed={1000}
                preloadImages={false}
                watchSlidesVisibility={true}
                watchOverflow={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  374: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3, // Adjusted for 1024px
                  },
                  1400: {
                    slidesPerView: 4, // Adjusted for larger screens
                  },
                }}
                grabCursor={true}
                // centeredSlides={true}
                // coverflowEffect={{
                //   rotate: 50,
                //   stretch: 1,
                //   depth: 100,
                //   modifier: 1,
                //   slideShadows: true,
                // }}
                modules={[EffectCoverflow, Autoplay]}
                className="mySwiper"
              >
                {events.map((data) => (
                  <SwiperSlide key={data._id}>
                    <Link className="flex justify-center items-center p-1">
                      <div className="w-auto h-auto rounded-xl flex justify-center items-center shadow-lg hover:shadow-2xl ">
                        <div className=" px-5 md:h-auto bg- rounded-xl w-full mt-5 sm:mt-7 xl:mt-4">
                          <div className=" lg:h-80 h-80  flex justify-center items-center rounded-xl  md:h-70 w-full ">
                            <div className="w-full h-full rounded-xl relative ">
                              <img
                                src={data.img}
                                alt="images"
                                width={0}
                                height={0}
                                sizes="100vw"
                                style={{ width: "100%", height: "100%" }}
                                className=" object-cover"
                              />
                            </div>
                          </div>
                          <div className="w-auto h-auto flex  justify-center items-center my-5 ">
                            <div className="w-full h-auto ">
                              <p className="text-2xl  font-medium font-satoshi lg:text-lg lg:font-bold">
                                {data.title}
                              </p>
                              <p className="">location</p>
                              <p className="mt-3  font-satoshi text-body line-clamp-2">
                                {data.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
