import React, { useCallback } from "react";
import "./Terms.css";
import CONTACT from "../../../assets/contact.png";
import { TERMS } from "../../../assets";
import { useEffect } from "react";
import { useNavigate, useLocation, } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Terms() {


  const { i18n } = useTranslation();

  const show = true


  return (
    <div className="h-auto w-full pt-20">
      <div className="relative w-full h-30 md:h-50">
        <img
          src={CONTACT}
          alt="images"
          style={{ width: "100%", height: "100%" }}
          className=" object-cover"
        />
        <div className="w-full h-full bg-primary absolute top-0 opacity-80"></div>
        <div className="w-full h-full absolute top-0 flex justify-center items-center">
          <p className="text-white font-bold text-2xl sm:text-4xl md:text-6xl text-center">
            Terms and Conditions
          </p>
        </div>
      </div>
      {/* {i18n.language === "en" ? */}
        <div className="w-full bg-intro h-full flex justify-center items-center">
          <div className="w-[75%] h-auto max-w-screen-2xl my-10">
            <p className="title_txt">
              Aquamagic Water Theme Park Terms and Conditions
            </p>
            <p className="desp_txt">
              Aquamagic places great importance on the safety of its guests and
              strives to ensure high standards are maintained throughout the park.
              We request your assistance in maintaining a safe environment for
              enjoyable and exciting experiences.
            </p>
            <p className="title_txt">
              Definitions in These Terms and Conditions ("Entry Conditions"):
            </p>
            <p className="desp_txt">
              <span className="title_txt">Attraction: </span>Aquamagic Water Theme
              Park or any part of it.
            </p>
            <p className="desp_txt">
              <span className="title_txt">Us/We/Our: </span>Aquamagic Water Theme
              Park Operations.
            </p>
            <p className="desp_txt">
              <span className="title_txt">Ticket: </span>A valid ticket permitting
              the holder entry to the Attraction.
            </p>
            <p className="desp_txt">
              A binding contract between us is formed when you receive a booking
              reference upon completing the booking process. For all payments made
              via this website, Aquamagic Water Theme Park Operations will be the
              responsible contractual party. This contract and all related matters
              are governed by the laws of Kuwait.
            </p>
            <p className="desp_txt">
              Guests are admitted to the Attraction subject to these Entry
              Conditions. By purchasing or using a Ticket, guests agree to comply
              with these conditions.
            </p>
            <p className="desp_txt">
              Any person not complying with these Entry Conditions may be removed
              from the Attraction by Aquamagic personnel, security, or police
              officers without any right to a refund. This is without prejudice to
              any claim we may have against such a person. While inside the
              Attraction, all guests must comply with any reasonable instructions
              given by Aquamagic personnel or any third party acting on our
              behalf. Acting reasonably, Aquamagic reserves the right to amend
              these Entry Conditions at any time without prior notice.
            </p>
            <p className="desp_txt">
              By entering the Attraction, guests acknowledge their duty to take
              reasonable steps to ensure their own safety, considering any
              personal medical conditions. All persons in and around the
              Attraction should behave safely at all times.
            </p>
            <p className="desp_txt">
              If you have any questions regarding these Entry Conditions, please
              contact Aquamagic
            </p>
            <p className="title_txt">General</p>
            <p className="desp_txt">
              For health and safety reasons, wheelchairs and mobility scooters are
              the only transport devices permitted within the Attraction.
              Bicycles, hover-boards, roller-blades, or similar devices are not
              allowed.
            </p>
            <p className="title_txt">Our Commitment to Provide a Service</p>
            <p className="desp_txt">
              Aquamagic will endeavor to ensure that as many rides and attractions
              as possible are available for use by guests. However, we reserve the
              right, without prior notice, to close and change the program of
              rides and attractions and/or the Attraction's operating hours.
              Aquamagic reserves the right to close the whole or any part of the
              Attraction at any time or to restrict access. This may be due to
              technical or operational reasons, capacity, inclement weather,
              special events, or to ensure safety and security. For Tickets
              purchased in advance, if the whole Attraction is closed for any of
              the reasons set out above, we may offer replacement Tickets for
              admission on an alternative date as a substitute for any other form
              of redress.
            </p>
            <p className="title_txt">Our Right to Remove You</p>
            <p className="desp_txt">
              Aquamagic, acting reasonably at all times, reserves the right to
              refuse admission to the Attraction, ban entry, or remove any person
              without any right to a refund if their presence or behavior affects
              the enjoyment and/or safety of other guests or staff, or if they:
            </p>
            <p className="desp_txt">
              a) Act inappropriately while traveling to the Attraction, as
              reported by a reliable source; b) Have been convicted of a criminal
              offense related to Aquamagic or civil unrest at Aquamagic property /
              attractions, use violent, aggressive, threatening, abusive,
              discriminatory, or insulting words or behavior; c) Climb or stand on
              fences, barriers, walls, safety fences, rock arrangements, exhibits,
              or any other buildings at the Attraction; d) Display signs or
              representations deemed threatening, abusive, or insulting; e) Have
              previously acted in a manner causing an Attraction or person to
              report them; f) Attempt to gain admission without a valid Ticket or
              are found inside without a valid Ticket; g) Purchase or obtain
              Tickets or discount vouchers from unauthorized sources.
            </p>
            <p className="desp_txt">
              If any guest in breach of these Entry Conditions is part of a group,
              the entire group may also be denied entry or escorted from the
              Attraction without any right to a refund. Aquamagic reserves the
              right to initiate legal proceedings against any person found
              damaging or defacing any part of the grounds, fences, barriers,
              walls, exhibits, fabrics, or buildings at the Attraction. Future
              access may be denied to any guest previously denied entry or
              escorted from the Attraction.
            </p>
            <p className="title_txt">Tickets/Admissions</p>
            <p className="desp_txt">
              <span className="title_txt"> Your Contract: </span>A binding
              contract between us (Aquamagic Water Theme Park Operations) and the
              purchaser comes into existence when you receive a booking reference
              upon completing the booking process. This contract and all related
              matters are governed by Kuwaiti law.
            </p>
            <p className="desp_txt">
              THE ATTRACTION IS PRIVATE PROPERTY. All persons entering the
              Attraction must pay for admission or hold a valid Ticket obtained
              from Aquamagic, the Attraction's website, or an authorized third
              party. The sale of Tickets/vouchers by anyone other than Aquamagic
              or an expressly authorized party is strictly prohibited. Only
              persons authorized by Aquamagic are permitted to sell or offer items
              for sale within the Attraction grounds. All Tickets are
              non-transferable, not for resale, and will become void on any
              transfer for value.
            </p>
            <p className="desp_txt">
              Tickets purchased are only valid on the date printed on the Ticket.
              Please check the Attraction's operating calendar for opening dates
              and times. Tickets may not be valid for special events or concerts
              requiring a separate admission fee. Tickets will only be exchanged
              or refunded at Aquamagic's discretion. Pre-booked Tickets are
              non-refundable if admission is refused or relinquished for any
              reason referred to in these Entry Conditions. Non-dated Tickets
              booked in advance do not guarantee admission, especially during busy
              times or special events.
            </p>
            <p className="desp_txt">
              Tickets booked online will be delivered per Aquamagic’s delivery
              procedure. If Tickets are bought for others, the buyer accepts these
              Entry Conditions on behalf of every guest and ensures compliance by
              each person in the group. If re-entry into the Attraction is
              required, an appropriate hand-stamp or Ticket must be obtained
              before exiting and shown at re-entry. Tickets must be retained at
              all times and submitted for inspection if required by Aquamagic.
            </p>
            <p className="desp_txt">
              No person under the age of 13 will be admitted unless accompanied by
              an adult aged 18 years or over who must remain under the control or
              supervision of the adult at all times. Aquamagic reserves the right
              to change age restrictions if necessary for safety and security.
            </p>
            <p className="desp_txt">
              The Attraction cannot accept card payments online from cardholders
              registered overseas.
            </p>
            <p className="title_txt">Annual Pass Holder</p>
            <p className="desp_txt">
              Annual passes are subject to separate terms and conditions, which
              can be found on the Attraction's website.
            </p>
            <p className="desp_txt">
              Annual Passes are subject to the operating calendar. Opening and
              closing dates, including applicable exclusion dates, can be found on
              the Attraction's website. Guests should check these before visiting.
            </p>
            <p className="desp_txt">
              Annual Passes may also be subject to additional restrictions or
              charges. Please see the Attraction's website for further details.
            </p>
            <p className="title_txt">Guest Behavior</p>
            <p className="desp_txt">
              Unnecessary noise (such as from portable electronic devices) or any
              behavior likely to cause annoyance or confusion is not permitted.
              Smoking (including e-cigarettes) is strictly prohibited except in
              designated smoking areas.
            </p>
            <p className="title_txt">Dress & Appearance</p>
            <p className="desp_txt">
              Guests must wear appropriate clothing (including a top and shoes) at
              all times. Indecent or offensive clothing will result in refusal of
              entry or removal without a refund. Certain items of clothing may not
              be permitted on some rides for health and safety reasons.
            </p>
            <p className="title_txt">Parking</p>
            <p className="desp_txt">
              Vehicles are parked at the owner’s risk. We take no responsibility
              for damage caused to any vehicle using the car park. A parking fee
              applies. Lost car park tickets will require a new ticket purchase.
              No vehicles are permitted to remain in the Attraction outside normal
              operating hours without prior permission. Motor homes, camper vans,
              caravans, or similar vehicles are not permitted without express
              prior permission from a senior member of the Attraction. Photocopies
              of permissions will not be accepted.
            </p>
            <p className="title_txt">Security and Safety</p>
            <p className="desp_txt">
              To prevent offensive weapons or dangerous articles from being
              brought into the Attraction, guests are admitted subject to the
              condition that they may be searched if requested. It is prohibited
              to bring weapons, fireworks, smoke bombs, glass bottles, or other
              articles which may cause injury. Throwing articles that could cause
              injury or affect the enjoyment of others is strictly prohibited.
              Possession of offensive or dangerous articles will result in
              immediate removal without a refund and may lead to prosecution.
              Intoxicating liquor may only be consumed in authorized places.
              Alcohol may not be taken into the Attraction without express
              permission, and persons found possessing alcohol or under the
              influence may be refused admission or escorted out. Illegal
              substances and legal highs are strictly prohibited.
            </p>
            <p className="desp_txt">
              CCTV cameras are used to assist in the proper running of the
              Attraction. We accept no liability for any loss or damage to
              property brought into the Attraction. Height, weight, or age
              restrictions may apply to certain rides or elements. Certain rides
              or shows may use lighting effects, strobe lighting, or flashing
              lights.
            </p>
            <p className="title_txt">Animals</p>
            <p className="desp_txt">
              Pets or animals are prohibited except for guide dogs, hearing dogs,
              and registered assistance dogs. Animals should not be left in
              vehicles parked at the Attraction. Aquamagic reserves the right to
              contact relevant authorities regarding any animals left unsupervised
              or in distress.
            </p>
            <p className="title_txt">Photography</p>
            <p className="desp_txt">
              Guests are permitted to take photographs and recordings for private
              use unless expressly prohibited. Guests must adhere to all
              photography/filming restrictions and not intentionally photograph or
              capture video footage of any individual without permission. From
              time to time, Aquamagic or authorized parties may carry out
              photography and/or video recording, which may feature guests. Entry
              to the Attraction is deemed acceptance of these Entry Conditions,
              and guests agree that Aquamagic or any authorized party may use such
              images for promotional, advertising, or publicity material.
              Copyright in these materials rests with Aquamagic or the authorized
              party.
            </p>
            <p className="title_txt">Warranty Disclaimer</p>
            <p className="desp_txt">
              Aquamagic waterpark is not responsible or liable in any manner for
              any Content posted on our Website or in connection with our
              Services, or the behavior of guests who visit our Waterpark.
              Although we provide rules for guest conduct, we do not control and
              are not responsible for how guests ultimately dress, behave while
              using our facility, Website or social media and are not responsible
              for any offensive, inappropriate, obscene, unlawful, or otherwise
              objectionable behavior or content you may encounter using our
              website or Waterpark facility. Aquamagic Waterpark is not
              responsible for the online or offline conduct of any User of our
              Website or Services.
            </p>
            <p className="desp_txt">
              Our Website or entire Waterpark facility or a segment of our
              Waterpark facility may be temporarily unavailable from time to time
              for maintenance or other reasons. Park Vega Waterpark assumes no
              responsibility for any error, omission, service interruption,
              deletion, defect, delay in operation or transmission, communication
              failure, theft or destruction, unauthorized access by guests.
            </p>
            <p className="desp_txt">
              Aquamagic waterpark is not responsible for any slide, electrical,
              structural, mechanical or technical malfunction or any other
              problems of any Waterpark facility equipment, installations,
              telephone network or service, computer system, server or provider,
              computer or mobile phone equipment, or software, or for any failure
              of payment terminals, email on account of technical problems or
              traffic congestion on the Internet, or for any combination thereof –
              including injury or damage to guests or any other person’s computer,
              mobile phone, or other hardware or software – related to or
              resulting from the use within the Waterpark facility or downloading
              of materials in connection with our Website.
            </p>
            <p className="desp_txt">
              Under no circumstances will Aquamagic waterpark be responsible for
              any loss or damage, including any loss or damage, personal injury,
              or death resulting from guests or anyone’s use of our Waterpark
              facility, website or Services, or any interactions between guests
              on/of our Website or waterpark, whether online or offline.
            </p>
            <p className="desp_txt">
              Social Media or website reference to any products, services,
              processes, or other information by trade name, trademark,
              manufacturer, supplier, or otherwise does not constitute or imply
              endorsement, sponsorship, recommendation, or any affiliation with
              Aquamagic Waterpark Limited by third parties.
            </p>
            <p className="desp_txt">
              THE INFORMATION, CONTENT, AND DOCUMENTS FROM OR THROUGH OUR WEBSITE
              ARE PROVIDED ‘AS-IS’, ‘AS AVAILABLE’, WITH ‘ALL FAULTS’, AND ALL
              EXPRESS OR IMPLIED WARRANTIES ARE DISCLAIMED (INCLUDING, BUT NOT
              LIMITED TO, THE DISCLAIMER OF ANY IMPLIED WARRANTIES OF
              MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE). OUR WEBSITE
              AND WATERPARK FACILITY MAY HAVE ERRORS, BUGS, PROBLEMS, OR OTHER
              LIMITATIONS.
            </p>
            <p className="desp_txt">
              AQUAMAGIC WATERPARK, INCLUDING ALL OUR AFFILIATES, HAS NO LIABILITY
              WHATSOEVER FOR GUEST(S) OF OUR WATERPARK FACILITY OR WEBSITE.
              AWUAMAGIC WATERPARK CANNOT GUARANTEE AND DOES NOT PROMISE ANY
              SPECIFIC RESULTS FROM THE USE OF OUR WATERPARK FACILITY OR WEBSITE.
              AQUAMAGIC WATERPARK DOES NOT REPRESENT OR WARRANT THAT OUR
              EQUIPMENT, BUILDINGS, INSTALLATIONS, CONTENT, SERVICES ARE ACCURATE,
              COMPLETE, RELIABLE, CURRENT, ERROR-FREE, FREE OR RISKS OR OTHER
              HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN ITS
              USE. ALL RESPONSIBILITY OR LIABILITY FOR ANY DAMAGES OR BODILY HARM
              CAUSED BY THE USE OF PARK VEGA WATERPARK OR SOMEHOW ATTRIBUTED TO
              OUR WATERPARK, CONTENT OR SERVICES, IS DISCLAIMED
            </p>
            <p className="desp_txt">
              WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU
              USE AQUAMAGIC WATERPARK FACILITY AND WEBSITE AT YOUR OWN RISK AND
              THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY
              INJURY, LOSS OF LIFE, BODILY HARM, PROBLEM, DAMAGES TO YOUR MOBILE
              DEVICE, LOSS OF DATA, THEFT, OR OTHER HARM OF ANY KIND THAT MAY
              RESULT. WE AND ALL OUR AFFILIATES ARE NOT LIABLE FOR ANY INDIRECT,
              SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR
              LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER
              BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
              NEGLIGENCE), SERVICE OR PRODUCT LIABILITY, OR OTHERWISE, EVEN IF
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE NEGATION AND
              LIMITATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF
              THE BASIS OF THE BARGAIN BETWEEN YOU AND AQUAMAGIC WATERPARK.
            </p>
            <p className="desp_txt">
              OUR SERVICES AND WEBSITE WOULD NOT BE PROVIDED WITHOUT SUCH
              LIMITATIONS. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
              OBTAINED BY YOU FROM US THROUGH OUR WEBSITE, SOCIAL MEDIA HANDLES OR
              SERVICES WILL CREATE ANY WARRANTY, REPRESENTATION, OR GUARANTEE NOT
              EXPRESSLY STATED IN THIS AGREEMENT.
            </p>
            <p className="title_txt">Limitation of Liability</p>
            <p className="desp_txt">
              IN NO EVENT WILL AQUAMAGIC WATERPARK LIMITED OR ITS DIRECTORS,
              EMPLOYEES, OR AGENTS BE LIABLE TO GUESTS OR ANY THIRD PERSON FOR ANY
              INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING FOR ANY LOSS OF LIFE OR PROPERTY, LOST PROFITS OR
              LOST DATA ARISING FROM YOUR USE OF OUR WEBSITE, WATERPARK FACILITY,
              CONTENT, SERVICES. EVEN IF AQUAMAGIC WATERPARK IS AWARE OR HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING
              TO THE CONTRARY CONTAINED HEREIN, AQUAMAGIC WATERPARK LIABILITY TO
              YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
              ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY
              YOU TO US FOR THE SERVICE ACCESSED JUST PRIOR TO THE EVENT GIVING
              RISE TO LIABILITY.
            </p>
            <p className="title_txt">Complimentary Tickets</p>
            <p className="desp_txt">
              Under certain circumstances, complimentary tickets may be
              distributed at Aquamagic's discretion.
            </p>
          </div>
        </div>
       {/* : */}
        <div className="w-full bg-intro h-full flex justify-center items-center">
          <div className="w-[75%] h-auto max-w-screen-2xl my-10">
            <img src={TERMS.ONE} className="w-full" alt="terms" />
            <img src={TERMS.TWO} className="w-full" alt="terms" />
            <img src={TERMS.THR} className="w-full" alt="terms" />
            <img src={TERMS.FOU} className="w-full" alt="terms" />
            <img src={TERMS.FIV} className="w-full" alt="terms" />
            <img src={TERMS.SIX} className="w-full" alt="terms" />
            <img src={TERMS.SEV} className="w-full" alt="terms" />
            <img src={TERMS.EIG} className="w-full" alt="terms" />
          </div>
        </div>
       {/* } */}

      <div className="w-full bg-intro h-full flex justify-center items-center">
        <div className="w-[75%] h-auto max-w-screen-2xl my-10">
          <div className="bg-black h-[1px] w-full"></div>
        </div>
      </div>

    </div>
  );
}
