import { CONSTANT } from "../constant/Constant";
import { API_REQUEST } from "./api";

export const WORKING_HOURS = {
  ADD(req, token) {
    const url = `${CONSTANT.URL}workinghours`;
    return API_REQUEST("POST", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  UPDATE(req, id) {
    const url = `${CONSTANT.URL}workinghours/${id}`;
    return API_REQUEST("PUT", url, req);
  },
  DELETE(req, id, token) {
    const url = `${CONSTANT.URL}workinghours/delete/${id}`;
    return API_REQUEST("PUT", url, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  GET_ALL() {
    const url = `${CONSTANT.URL}workinghours/search`;
    return API_REQUEST("GET", url);
  },
  SEARCH(val) {
    const url = `${CONSTANT.URL}workinghours/search/date/${val}`;
    return API_REQUEST("GET", url);
  },

  GET_BY_ID(id) {
    const url = `${CONSTANT.URL}workinghours/${id}`;
    return API_REQUEST("GET", url);
  },
  GET_BY_DATE(date) {
    const url = `${CONSTANT.URL}workinghours/date/${date}`;
    return API_REQUEST("GET", url);
  },
};
