// import React, { useRef } from "react";
// import { useState } from "react";
// import { useEffect } from "react";
// import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
// import PaginatedItems from "../../../components/pagination/PaginatedItems";
// import { useNavigate, useParams } from "react-router-dom";
// import { BOOKING } from "../../../api/booking";
// import { REPORT } from "../../../api/report";
// import { formatDateString } from "../../../utils/FormatDateString";

// export default function ReportView() {
//   let { date } = useParams();
//   //states
//   const [reportData, setReportData] = useState(null);
//   const [refresh, setRefresh] = useState(false);

//   const getReportDataForDate = async () => {
//     try {
//       const res = await REPORT.GET_TOTALS(date); // Call your API with the date parameter
//       if (res.status !== 200) {
//         throw new Error("Failed to fetch report data");
//       }
//       setReportData(res.data.totals);
//     } catch (error) {
//       console.error("Error fetching report data:", error.message);
//     }
//   };

//   useEffect(() => {
//     if (date) {
//       getReportDataForDate();
//     }
//   }, [date]);

//   return (
//     <div>
//       <div
//       >
//         <Breadcrumbs pageName="Report Details" />
//       </div>

//       <div
//         className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
//       >
//         <div className="max-w-full overflow-x-auto">
//           <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
//             {reportData && reportData.workingHours.length > 0 ? (
//               reportData.workingHours.map((workingHours) => (
//                 <div
//                   key={workingHours._id}
//                   className="max-w-full overflow-x-auto">
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">Date</h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {formatDateString(workingHours.date)}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       One day Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.odtBookingToday}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Deluxe Chalet Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.cdBookingToday}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Standard Chalet Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.csBookingToday}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Free Deluxe Chalet Bookings                    </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalCdFree}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Free Standard Chalet Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalCsFree}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Scanned Qr
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalScanned}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Not Scanned Qr
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalUnscanned}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Online Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalOnlinePayment}
//                     </h5>
//                   </div>
//                   <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
//                     <h5 className="font-medium text-black dark:text-white">
//                       Offline Bookings
//                     </h5>
//                     <h5 className="font-medium text-black dark:text-white">
//                       {workingHours.totalOfflinePayment}
//                     </h5>
//                   </div>
//                 </div>
//               ))) : (
//               <div colSpan="5" className="border-b border-[#eee] py-5 px-4 dark:border-strokedark text-center">
//                 <h5 className="font-medium text-black dark:text-white">
//                   No data available
//                 </h5>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { REPORT } from "../../../api/report";
import { formatDateString } from "../../../utils/FormatDateString";

export default function ReportView() {
  let { date } = useParams();
  const navigate = useNavigate();
  //states
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);

  const getReportDataByDate = async (date) => {
    try {
      const res = await REPORT.GET_TOTALS_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch report data");
      }
      // console.log("111111111111",res.data.totals)
      setReportData(res.data.totals);
    } catch (error) {
      setError(error.message && "No data found for the specified date");
    }
  };

  useEffect(() => {
    getReportDataByDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <div>
      <div>
        <Breadcrumbs pageName="Report Details" />
      </div>

      <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="max-w-full overflow-x-auto">
          {error && (
            <div className="text-center">
              <h5 className="text-red font-semibold">{error}</h5>
            </div>
          )}

          {reportData && (
            <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Date</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {formatDateString(date)}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">One day Bookings</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalOdtNo}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Deluxe Chalet Bookings</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalCdNo}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Standard Chalet Bookings</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalCsNo}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Free Deluxe Chalet Bookings</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalCdFree}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Free Standard Chalet Bookings</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalCsFree}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Scanned Qr</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalScanned}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">UnScanned Qr</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalUnscanned}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Offline Booking</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalOfflinePayment}</h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Online Booking</h5>
                <h5 className="font-medium text-black dark:text-white">{reportData.totalOnlinePayment}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

