import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import { formatDateString } from "../../../utils/FormatDateString";
import { PAYMENT } from "../../../api/payment";
import { toast } from "react-toastify";

const convertToKuwaitTime = (timestamp) => {
  const date = new Date(timestamp);
  const utcDate = new Date(date.toISOString());
  const kuwaitOffset = 3 * 60; // offset in minutes
  const kuwaitTime = new Date(utcDate.getTime() + kuwaitOffset * 60 * 1000);

  const year = kuwaitTime.getFullYear();
  const month = String(kuwaitTime.getMonth() + 1).padStart(2, '0');
  const day = String(kuwaitTime.getDate()).padStart(2, '0');
  let hours = kuwaitTime.getHours();
  const minutes = String(kuwaitTime.getMinutes()).padStart(2, '0');
  const seconds = String(kuwaitTime.getSeconds()).padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
};

export default function PaymentView() {
  let { pnr } = useParams();
  //states
  const [data, setData] = useState(null);
  //states

  //get
  const getPaymentDetails = async (pnr) => {
    try {
      const res = await PAYMENT.GET_BY_ID(pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      if (res.data[0] !== undefined) setData(res.data[0]);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const [refresh, setRefresh] = useState(false);
  const handleUpdateData = async () => {
    const confirmed = window.confirm(
      "You are about to mark this as paid! Are you sure?"
    );

    if (confirmed) {
      try {
        var request = {
          status: "paid",
        };
        var res = await PAYMENT.UPDATE(request, data._id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch admin information");
        }
        toast.success("Status changed!");
        setRefresh(!refresh);
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  useEffect(() => {
    getPaymentDetails(pnr);
  }, [pnr, refresh]);
  //get

  return (
    <div>
      <div>
        <Breadcrumbs pageName="Payment Details" />
      </div>

      {data !== null ? (
        <>
          <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              {/* <h5 className="font-medium text-2xl text-black dark:text-white border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                Basic Details
              </h5> */}
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-2xl text-black dark:text-white dark:border-strokedark">
                  Basic Details
                </h5>
                {data.status === "refund" && (
                  <button
                    className="bg-primary px-10 py-2 text-white rounded-lg"
                    onClick={() => {
                      handleUpdateData();
                    }}
                  >
                    Mark as Paid
                  </button>
                )}
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Payment Status
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.status === "paid" && "REFUND"}{" "}
                  {data.status.toUpperCase()}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Date</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {convertToKuwaitTime(data.timeStamp)}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">Date (UTC Time)</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.timeStamp}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">PNR</h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.bookingPnr}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Amount
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.amount} {data.currencyCode}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Customer Name
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.customerName}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Customer Contact
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.customerPhone} - {data.customerEmail}
                </h5>
              </div>
            </div>
          </div>
          <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <h5 className="font-medium text-2xl text-black dark:text-white border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                Payment Details
              </h5>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Payment ID
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.paymentId}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Transaction Id
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.transId}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Tracking ID
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.trackId}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Reference Id
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.refId}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Reference Number
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.refNo}
                </h5>
              </div>
              <div className="w-full border-b border-[#eee] py-5 px-4 dark:border-strokedark flex justify-between">
                <h5 className="font-medium text-black dark:text-white">
                  Session ID
                </h5>
                <h5 className="font-medium text-black dark:text-white">
                  {data.sessionId}
                </h5>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center mt-[5%]">
          <p className="text-5xl"> Cant Fetch Data At The Moment !</p>
          <p className="text-3xl mt-2"> Sorry For The Trouble 🙁</p>
        </div>
      )}
    </div>
  );
}
