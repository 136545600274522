import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import { OFFERS } from "../../../api/offers";
import { formatDateString } from "../../../utils/FormatDateString";

export default function OfferView() {
  const IMG_PATH = "/offer/";
  const { id } = useParams();
  const [data, setData] = useState({
    title: "",
    description: "",
    eventImage: "",
    typeOfBooking: "",
    date: "",
    startDate: "",
    endDate: "",
    activeLength: "",
    discountPercentage: "",
    discountAmount: "",
    discountType: true,
    typeOfOfferDay: true,
    offerPriceReduction: true,
    isActive: true,
    isShow: true,
  });

  const getDepartment = async (id) => {
    try {
      const res = await OFFERS.GET_BY_ID(id);
      if (res.status !== 200) {
        throw new Error("Failed to fetch events information");
      }
      console.log("s", res);
      setData({
        _id: res.data._id,
        title: res.data.title,
        description: res.data.description,
        eventImage: IMG_PATH + res.data.offerImage,
        typeOfBooking: res.data.typeOfBooking,
        date: res.data.date,
        startDate: res.data.startDate,
        endDate: res.data.endDate,
        activeLength: res.data.activeLength,
        discountPercentage: res.data.discountPercentage,
        discountAmount: res.data.discountAmount,
        discountType: res.data.discountType,
        typeOfOfferDay: res.data.typeOfOfferDay,
        offerPriceReduction: res.data.offerPriceReduction,
        isActive: res.data.isActive,
        isShow: res.data.isShow,
      });
    } catch (error) {
      console.log("Error loading topics: ", error);
    }
  };
  useEffect(() => {
    getDepartment(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Breadcrumbs pageName="View Offer" />
      <div className=" rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
          <h3 className="font-medium text-black dark:text-white">
            {data.title}
          </h3>
        </div>

        <div className="grid grid-cols-1 gap-9 sm:grid-cols-2">
          <div className="flex flex-col gap-9 ">
            <div className="p-6.5">
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Title <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.title}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Description <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.description}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Type Of Booking <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.typeOfBooking}
                </p>
              </div>
              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Date <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {formatDateString(data.date)}
                </p>
              </div>
              {/* <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Type Of Discount <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.discountType ? "Percentage" : "Amount"}
                </p>
              </div> */}
              {data.discountType ? (
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Discount Perecntage <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.discountPercentage}
                  </p>
                </div>
              ) : (
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Discount Amount (KWD) <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.discountAmount}
                  </p>
                </div>
              )}
              <div className="py-6.5 flex gap-5">
                <div className="w-full h-auto">
                  <i>Now Offer is {data.isActive ? "Active" : "Inactive"} </i>
                  <button
                    type="button"
                    className={`flex w-full justify-center ${
                      data && data.isActive
                        ? "bg-success px-10"
                        : "bg-red px-12.5"
                    } rounded bg-primary p-3 font-medium text-gray`}
                  >
                    {data && data.isActive ? "Active" : "InActive"}
                  </button>
                </div>
                <div className="w-full h-auto">
                  <i>Now Offer is {data.isShow ? "Shown" : "Hidden"} </i>
                  <button
                    type="button"
                    className={`flex w-full justify-center ${
                      data && data.isShow
                        ? "bg-primary px-10"
                        : "bg-black-2 px-12.5"
                    } rounded p-3 font-medium text-gray`}
                  >
                    {data && data.isShow
                      ? "Show to Customer"
                      : "Hide from Customer"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 h-auto">
            <div className="p-6.5 h-auto">
              <div className="mb-3 flex flex-col gap-6 xl:flex-row ">
                <div className="w-full ">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Profile Image
                    <span className="text-meta-1">*</span>
                  </label>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="logo-file"
                      className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-50 hover:bg-gray dark:border-gray dark:hover:border-gray dark:hover:bg-gray"
                    >
                      <div className="w-full group relative cursor-pointer h-full flex justify-center items-center rounded ">
                        <img
                          src={data.eventImage}
                          alt="offer"
                          className="h-full object-contain"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Type of Offer Day <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.typeOfOfferDay ? "Single Day" : "Multi Day"}
                </p>
              </div> */}

              {/* {data.typeOfOfferDay ? (
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Date <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {formatDateString(data.date)}
                  </p>
                </div>
              ) : (
                <div className="mb-4.5 flex gap-19 w-full justify-between h-auto">
                  <div className="w-[50%] h-auto">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Start Date <span className="text-meta-1">*</span>
                    </label>
                    <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                      {formatDateString(data.startDate)}
                    </p>
                  </div>
                  <div className="w-[50%] h-auto">
                    <label className="mb-2.5 block text-black dark:text-white">
                      End Date <span className="text-meta-1">*</span>
                    </label>
                    <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                      {formatDateString(data.endDate)}
                    </p>
                  </div>
                </div>
              )} */}

              <div className="mb-4.5">
                <label className="mb-2.5 block text-black dark:text-white">
                  Type Of Offer <span className="text-meta-1">*</span>
                </label>
                <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                  {data.offerPriceReduction ? "Single" : "Combo"}
                </p>
              </div>
              {data.offerPriceReduction ? (
                <></>
              ) : (
                <div className="mb-4.5">
                  <label className="mb-2.5 block text-black dark:text-white">
                    No. of Bookings <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.activeLength}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
