import React, { useEffect, useState } from "react";
import SecondaryHeader from "../components/ui/SecondaryHeader";
import Footer from "../components/ui/Footer";
import LanguageSwitcher from "../components/ui/LanguageSwitcher";

export default function SecondaryLayout({ children }) {
  const [isHeaderFixed, setIsHeaderFixed] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsHeaderFixed(
        currentScrollPos < prevScrollPos && currentScrollPos > 0
      );
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);
  return (
    <div className=" relative overflow-x-hidden">
      <div className="rounded-full fixed text-black-2 z-50 bottom-10 xl:right-10 lg:right-7 md:right-6 right-2">
        {/* <LanguageSwitcher /> */}
      </div>
      <div
        className={`${isHeaderFixed ? "fixed " : "absolute"
          } transition-all duration-500 ease-in-out z-999 top-0 w-full bg-white`}
      >
        <SecondaryHeader />
      </div>
      <div>{children}</div>
      <div className=" w-full">
        <Footer />
      </div>
    </div>
  );
}
