import React from "react";

export default function AddNew({
  data,
  onKeyDown,
  validate,
  setData,
  today,
  loading,
  errorMessage,
  handleAdd,
  handlePriceCs,
  handlePricePs,
  handlePriceMd,
  handlePriceSa,
  handlePriceCd,
  handlePriceOdt,
  handleInputChange,
  handleDateEnd,
  handleDateChange,
}) {
  return (
    <div className="rounded-sm mb-5 border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="max-w-full overflow-x-auto">
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex flex-col">
            <div className="px-2 py-2">
              <label className="mb-2.5 block text-black dark:text-white">
                Start Date <span className="text-meta-1">*</span>
              </label>
              <input
                onChange={handleDateChange}
                type="date"
                min={today} // Set min attribute to disable past dates
                placeholder="Enter name of event"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="date"
                value={data.startDate}
              />
              {data.startDate === "" && validate && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                End Date <span className="text-meta-1">*</span>
              </label>
              <input
                type="date"
                min={today} // Set min attribute to disable past dates
                onChange={handleDateEnd}
                placeholder="Enter price for this event"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="price"
                value={data.endDate}
              />
              {data.endDate === "" && validate && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted One day tickets - (
                {data.odtBookings - data.odtToday >= 0
                  ? data.odtBookings - data.odtToday
                  : 0}
                &nbsp;left)
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                onKeyDown={onKeyDown}
                min={0}
                onChange={handleInputChange}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtNoOfBooking"
                value={data.odtBookings}
              />
              {errorMessage && (
                <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Deluxe Chalet
                <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.cdBookings}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Standard Chalet
                <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.csBookings}
              </p>
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Accepted Premium Standard Chalet
                <span className="text-meta-1">*</span>
              </label>
              <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                {data.psBookings}
              </p>
            </div>
          </div>

          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Chalet Deluxe Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceCd}
                placeholder="Enter number of bookings per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="chaletDeluxePrice"
                value={data.cdPrice}
              />
              {(data.cdPrice === 0 || data.cdPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Chalet Standard Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceCs}
                placeholder="Enter number of bookings per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="chaletStandardPrice"
                value={data.csPrice}
              />
              {(data.csPrice === 0 || data.csPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Premium Standard Chalet Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePricePs}
                placeholder="Enter number of bookings per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="chaletStandardPrice"
                value={data.psPrice}
              />
              {(data.psPrice === 0 || data.psPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                One Day Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceOdt}
                placeholder="Enter number of price per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="oneDayTicketPrice"
                value={data.odtPrice}
              />
              {(data.odtPrice === 0 || data.odtPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div>
          {/* <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Nanny Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceMd}
                placeholder="Enter number of price per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="oneDayTicketPrice"
                value={data.mdPrice}
              />
              {(data.mdPrice === 0 || data.mdPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div> */}
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Nanny Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceMd}
                placeholder="Enter number of price per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="oneDayTicketPrice"
                value={data.mdPrice}
              />
              {(data.mdPrice === 0 || data.mdPrice === "") && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Specially Abled Ticket Price
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min={0}
                onKeyDown={onKeyDown}
                onChange={handlePriceSa}
                placeholder="Enter number of price per day"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="oneDayTicketPrice"
                value={data.saPrice}
              />
              {(data.saPrice === 0 || data.saPrice === "") &&
                validate === true && (
                  <p className="text-red text-sm mt-1">* Required</p>
                )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Per Deluxe
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    cdFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="cdFree"
                value={data.cdFree}
              />
              {data.cdFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Per Standard
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    csFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="csFree"
                value={data.csFree}
              />
              {data.csFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto">
            <div className="px-2 py-2 h-auto">
              <label className="mb-2.5 block text-black dark:text-white">
                Free Ticket Guest
                <span className="text-meta-1">*</span>
              </label>
              <input
                type="number"
                min="0"
                onKeyDown={onKeyDown}
                onChange={(e) => {
                  setData({
                    ...data,
                    odtFree: e.target.value,
                  });
                }}
                placeholder="No of bookings accepted today"
                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                name="odtFree"
                value={data.odtFree}
              />
              {data.odtFree === "" && validate === true && (
                <p className="text-red text-sm mt-1">* Required</p>
              )}
            </div>
          </div>
          <div className="flex flex-col h-auto sm:pt-7">
            <div className="px-2 py-3 h-auto">
              <button
                onClick={() => {
                  handleAdd();
                }}
                type="submit"
                className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
              >
                {loading ? (
                  <div className="flex h-auto items-center justify-center ">
                    <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                  </div>
                ) : (
                  "Add Data"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
