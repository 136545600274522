// import React, { useEffect, useRef, useState } from 'react'
// import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
// import ExcelJS from 'exceljs';
// import { formatDateString } from '../../../../utils/FormatDateString';
// import { useNavigate } from 'react-router-dom';
// import { REPORT } from '../../../../api/report';
// import { toast } from 'react-toastify';
// import { WORKING_HOURS } from '../../../../api/workingHours';
// import Select from 'react-select'
// import ReactPaginate from "react-paginate";
// export default function DetailedReport() {
//     const [searchVal, setSearchVal] = useState("");
//     const cachedEmp = useRef(null);
//     const [emp, setEmp] = useState([]);
//     const [loadingDel, setLoadingDel] = useState(false);
//     const [loadingEx, setloadingEx] = useState(false);
//     const [showSingleDateInput, setShowSingleDateInput] = useState(false);
//     const [showMultiDateInput, setShowMultiDateInput] = useState(false);
//     const [selectedOption, setSelectedOption] = useState(null);

//     const [filteredData, setFilteredData] = useState([])

//     const [data, setData] = useState({
//         startDate: "",
//     });
//     const [filter, setFilter] = useState({
//         bookingType: "all",
//         paymentType: "all",
//         scannedType: "all",
//     });
//     const [MultiData, setMultiData] = useState({
//         startDate: "",
//         endDate: ""
//     });
//     const getChaletName = (num) => {
//         var rtnVal = ""
//         if (num == 0) {
//             rtnVal = "One Day Ticket"
//         } else if (num == 1) {
//             rtnVal = "Chalet Deluxe"
//         } else if (num == 2) {
//             rtnVal = "Chalet Standard"
//         } else if (num == 3) {
//             rtnVal = "Maid One Day Ticket"
//         } else if (num == 4) {
//             rtnVal = "Specially Abled"
//         } else if (num == 5) {
//             rtnVal = "Premium Standard"
//         } else if (num == 6) {
//             rtnVal = "Online Payment Amount"
//         } else if (num == 7) {
//             rtnVal = "Offline Payment Amount"
//         } else if (num == "all") {
//             rtnVal = "All"
//         }
//         return rtnVal
//     }

//     const bookingOptions = [
//         { value: "all", label: "All" },
//         { value: 0, label: "One Day Ticket" },
//         { value: 1, label: "Chalet Deluxe" },
//         { value: 2, label: "Chalet Standard" },
//         { value: 3, label: "Maid One Day Ticket" },
//         { value: 4, label: "Specially Abled" },
//         { value: 5, label: "Premium Standard" },
//         { value: 6, label: "Online Payment Amount" },
//         { value: 7, label: "Offline Payment Amount" },
//     ];
//     const paymentOptions = [
//         { value: "all", label: "All" },
//         { value: "Online Payment", label: "Online Payment" },
//         { value: "Cash", label: "Cash" },
//         { value: "KNET", label: "KNET" },
//         { value: "Credit Card", label: "Credit Card" },
//         { value: "Link", label: "Link" },
//     ];
//     const scannedOptions = [
//         { value: "all", label: "All" },
//         { value: true, label: "Scanned" },
//         { value: false, label: "Not Scanned" },
//     ];

//     const itemsPerPage = 10;
//     const [itemOffset, setItemOffset] = useState(0);
//     const endOffset = itemOffset + itemsPerPage;

//     const topSessionRef = useRef(null);
//     const tableSessionRef = useRef(null);

//     const getReportListRange = async (startDate, endDate, pageNo, pageLength) => {
//         try {
//             if (!startDate || !endDate) return;
//             const res = await REPORT.GET_DETAILED_REPORT(startDate, endDate, pageNo, pageLength);
//             console.log("getting data", res.data)
//             setLoadingDel(false)

//             if (res.status !== 200) {
//                 throw new Error("Failed to fetch report information");
//             }
//             setEmp(res.data.bookings);
//             setPagination({
//                 ...pagination,
//                 totalLength:res.data.totalNumber,
//             })
//         } catch (error) {
//             console.error("Error fetching report list:", error.message);
//         }
//     };

//     const sortedEmp = Array.isArray(filteredData)
//         ? filteredData.sort((a, b) => {
//             const dateA = a.date.split("T")[0];
//             const dateB = b.date.split("T")[0];
//             return dateB.localeCompare(dateA); // Sort in descending order
//         })
//         : [];

//     const currentItems = sortedEmp.slice(itemOffset, endOffset);
//     const pageCount = Math.ceil(sortedEmp.length / itemsPerPage);

//     const [pagination, setPagination] = useState({
//         currentPage:1,
//         totalLength:0,
//         pageLength:10
//     })


//     const handleMultiDateChangeStart = (e) => {
//         const selectedDate = e.target.value;
//         setMultiData((prevData) => ({
//             ...prevData,
//             startDate: selectedDate,
//         }));
//         setData({
//             startDate: "",
//         });
//     };

//     const handleMultiDateChangeEnd = (e) => {
//         const selectedDate = e.target.value;
//         setMultiData((prevData) => ({
//             ...prevData,
//             endDate: selectedDate,
//         }));
//         setData({
//             endDate: "",
//         });
//     };

//     useEffect(() => {
//         if (cachedEmp.current) {
//             setEmp(cachedEmp.current);
//         }
//     }, []);

//     const handleGetReport = () => {
//         if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
//             setLoadingDel(true)
//             getReportListRange(MultiData.startDate, MultiData.endDate, pagination.currentPage, pagination.pageLength).then(data => {
//                 cachedEmp.current = data;
//             });
//         } else {
//             setEmp([])
//             toast.warn("Please select a valid date or date range.");
//         }
//     };



//     const exportToExcel = (singleDate, startDate, endDate, filteredData) => {
//         setloadingEx(true)
//         const workbook = new ExcelJS.Workbook();
//         const worksheet = workbook.addWorksheet('Report');

//         worksheet.columns = [
//             { header: 'Date', key: 'date', width: 20 },
//             { header: 'Customer Name', key: 'name', width: 20 },
//             { header: 'Pnr', key: 'pnr', width: 20 },
//             { header: 'Contact No', key: 'mobNo', width: 20 },
//             { header: 'One day Ticket Nos', key: 'oneDay', width: 20 },
//             { header: 'Deluxe Chalet Nos', key: 'deluxeChalet', width: 20 },
//             { header: 'Premium Chalet Nos', key: 'premiumChalet', width: 20 },
//             { header: 'Standard Chalet Nos', key: 'standardChalet', width: 20 },
//             { header: 'Free Tickets Nos', key: 'freeTickets', width: 20 },
//             { header: 'Nanny Ticket Nos', key: 'nanny', width: 20 },
//             { header: 'Specially Abled Nos', key: 'speciallyAbled', width: 20 },
//             { header: 'One Day Ticket Amount', key: 'onedayAmount', width: 30 },
//             { header: 'Deluxe Chalet Amount', key: 'deluxeAmount', width: 30 },
//             { header: 'Premium Chalet Amount', key: 'premiumChaletAmount', width: 30 },
//             { header: 'Standard Chalet Amount', key: 'standardAmount', width: 30 },
//             { header: 'Nanny Ticket Amount', key: 'nannyAmount', width: 30 },
//             { header: 'Specially Abled Amount', key: 'saAmount', width: 30 },
//             { header: 'Standard Chalet', key: 'csName', width: 30 },
//             { header: 'Premium Chalet', key: 'psName', width: 30 },
//             { header: 'Deluxe Chalet', key: 'dcName', width: 30 },
//             { header: 'Payment Type', key: 'paymentType', width: 20 },
//             { header: 'Total Amount (KWD)', key: 'totalAmount', width: 20 },
//             { header: 'Scanned Detail', key: 'scanned', width: 20 },
//         ];

//         worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//             cell.fill = {
//                 type: 'pattern',
//                 pattern: 'solid',
//                 fgColor: { argb: 'FF156097' },
//             };
//             cell.font = {
//                 color: { argb: 'FFFFFFFF' },
//                 bold: true,
//             };
//         });

//         filteredData.forEach((data) => {
//             worksheet.addRow({
//                 date: formatDateString(data.date),
//                 name: data.name,
//                 pnr: data.pnr,
//                 mobNo: data.mobNo,
//                 oneDay: data.odt.number,
//                 deluxeChalet: data.cd.number,
//                 premiumChalet: data.ps.number,
//                 standardChalet: data.cs.number,
//                 freeTickets: data.free.ps + data.free.cs + data.free.cd,
//                 nanny: data.md.number,
//                 speciallyAbled: data.sa.number,
//                 onedayAmount: data.odt.totalAmount,
//                 deluxeAmount: data.cd.totalAmount,
//                 premiumChaletAmount: data.ps.totalAmount,
//                 standardAmount: data.cs.totalAmount,
//                 nannyAmount: data.md.totalAmount,
//                 saAmount: data.sa.totalAmount,
//                 csName: data.chalet.cs,
//                 psName: data.chalet.ps,
//                 dcName: data.chalet.cd,
//                 paymentType: data.paymentType,
//                 totalAmount: data.totalAmount,
//                 scanned: data.isScanned,
//             });
//         });

//         const totalOneDay = filteredData.reduce((acc, data) => acc + data.odt.number, 0);
//         const totalCD = filteredData.reduce((acc, data) => acc + data.cd.number, 0);
//         const totalSC = filteredData.reduce((acc, data) => acc + data.cs.number, 0);
//         const totalFree = filteredData.reduce((acc, data) => acc + (data.free.cs + data.free.ps + data.free.cd), 0);
//         const totalNanny = filteredData.reduce((acc, data) => acc + data.md.number, 0);
//         const totalSA = filteredData.reduce((acc, data) => acc + data.sa.number, 0);
//         const totalOdtAmountPerDay = filteredData.reduce((acc, data) => acc + data.odt.totalAmount, 0);
//         const totalCdAmountPerDay = filteredData.reduce((acc, data) => acc + data.cd.totalAmount, 0);
//         const totalPsAmountPerDay = filteredData.reduce((acc, data) => acc + data.ps.totalAmount, 0);
//         const totalCsAmountPerDay = filteredData.reduce((acc, data) => acc + data.cs.totalAmount, 0);
//         const totalNannyAmountPerDay = filteredData.reduce((acc, data) => acc + data.md.totalAmount, 0);
//         const totalSaAmountPerDay = filteredData.reduce((acc, data) => acc + data.sa.totalAmount, 0)


//         // Add total row at the bottom
//         worksheet.addRow({
//             date: 'Total',
//             name: "",
//             pnr: "",
//             mobNo: "",
//             oneDay: totalOneDay,
//             deluxeChalet: totalCD,
//             premiumChalet: totalPC,
//             standardChalet: totalSC,
//             freeTickets: totalFree,
//             nanny: totalNanny,
//             speciallyAbled: totalSA,
//             onedayAmount: totalOdtAmountPerDay,
//             totalAmount: totalAmountDay,
//             scanned: "",
//             paymentType: "",
//             deluxeAmount: totalCdAmountPerDay,
//             premiumChaletAmount: totalPsAmountPerDay,
//             standardAmount: totalCsAmountPerDay,
//             nannyAmount: totalNannyAmountPerDay,
//             saAmount: totalSaAmountPerDay
//         }).eachCell({ includeEmpty: true }, (cell) => {
//             cell.font = { bold: true };
//         });

//         workbook.xlsx.writeBuffer().then((buffer) => {
//             const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const url = URL.createObjectURL(blob);

//             let fileName;
//             if (singleDate) {
//                 fileName = `Report-${formatDateString(singleDate)}.xlsx`;
//             } else if (startDate && endDate) {
//                 fileName = `Report-${formatDateString(startDate)}-${formatDateString(endDate)}.xlsx`;
//             } else {
//                 fileName = 'Report.xlsx';
//             }

//             const a = document.createElement('a');
//             a.href = url;
//             a.download = fileName;
//             a.click();
//             URL.revokeObjectURL(url);
//             setloadingEx(false)
//         });
//     };

//     const calculateTotals = (filteredData) => {
//         return {
//             totalOneDay: filteredData.reduce((acc, data) => acc + data.odt.number, 0),
//             totalCD: filteredData.reduce((acc, data) => acc + data.cd.number, 0),
//             totalPC: filteredData.reduce((acc, data) => acc + data.ps.number, 0),
//             totalSC: filteredData.reduce((acc, data) => acc + data.cs.number, 0),
//             totalFree: filteredData.reduce((acc, data) => acc + (data.free.cs + data.free.ps + data.free.cd), 0),
//             totalNanny: filteredData.reduce((acc, data) => acc + data.md.number, 0),
//             totalSA: filteredData.reduce((acc, data) => acc + data.sa.number, 0),
//             totalAmountDay: filteredData.reduce((acc, data) => acc + data.totalAmount, 0),
//             totalOdtAmountPerDay: filteredData.reduce((acc, data) => acc + data.odt.totalAmount, 0),
//             totalCdAmountPerDay: filteredData.reduce((acc, data) => acc + data.cd.totalAmount, 0),
//             totalPsAmountPerDay: filteredData.reduce((acc, data) => acc + data.ps.totalAmount, 0),
//             totalCsAmountPerDay: filteredData.reduce((acc, data) => acc + data.cs.totalAmount, 0),
//             totalNannyAmountPerDay: filteredData.reduce((acc, data) => acc + data.md.totalAmount, 0),
//             totalSaAmountPerDay: filteredData.reduce((acc, data) => acc + data.sa.totalAmount, 0)
//         };
//     };

//     const {
//         totalOneDay,
//         totalCD,
//         totalPC,
//         totalSC,
//         totalFree,
//         totalNanny,
//         totalSA,
//         totalAmountDay,
//         totalOdtAmountPerDay,
//         totalCdAmountPerDay,
//         totalPsAmountPerDay,
//         totalCsAmountPerDay,
//         totalNannyAmountPerDay,
//         totalSaAmountPerDay
//     } = calculateTotals(filteredData);

//     useEffect(() => {
//         var dataArray = [...emp]
//         var filterByScannedType = [...emp]
//         var filterByPaymentType = [...emp]
//         if (emp.length > 0) {
//             if (filter.paymentType !== "all") {
//                 filterByPaymentType = dataArray.filter((obj) => obj.paymentType === filter.paymentType);
//             }
//             else {
//                 console.log("in if   ffff")
//                 filterByPaymentType = [...emp]
//             }
//             console.log("filter", filter)

//             if (filter.scannedType !== "all") {
//                 filterByScannedType = dataArray.filter((obj) => obj.isScanned === filter.scannedType);
//             }
//             else {
//                 console.log("in else")
//                 filterByScannedType = [...emp]
//             }
//         }
//         console.log("filterByPaymentType", filterByPaymentType)
//         console.log("filterByScannedType", filterByScannedType)
//         const commonObjects = filterByPaymentType.filter(obj1 =>
//             filterByScannedType.some(obj2 => obj1.pnr === obj2.pnr)
//         );
//         console.log("commonObjects", commonObjects)
//         setFilteredData(commonObjects)
//     }, [filter, emp]);



//     // console.log("emp", emp)
//     console.log("filteredData", filteredData)

//     return (
//         <div>
//             <div ref={topSessionRef}>
//                 <Breadcrumbs pageName="Detailed Report" />
//             </div>
//             <div
//                 ref={tableSessionRef}
//                 className=" rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
//             >
//                 <div className="max-w-full">
//                     <div className="py-3 lg:flex items-center ">

//                         <div className=" md:flex items-center lg:w-[50%] w-full">
//                             <div className=" flex flex-col h-auto py-2 lg:w-[50%] w-full mr-[4%]">
//                                 <label className="font-semibold text-black dark:text-white">
//                                     Start Date
//                                 </label>
//                                 <input
//                                     onChange={handleMultiDateChangeStart}
//                                     type="date"
//                                     className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
//                                     name="date"
//                                     value={MultiData.startDate}
//                                 />
//                             </div>
//                             <div className="flex flex-col h-auto py-2 lg:w-[50%] w-full">
//                                 <label className="font-semibold text-black dark:text-white">
//                                     End Date
//                                 </label>
//                                 <input
//                                     onChange={handleMultiDateChangeEnd}
//                                     type="date"
//                                     className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
//                                     name="date"
//                                     value={MultiData.endDate}
//                                 />
//                             </div>

//                         </div>


//                         {(MultiData.startDate && MultiData.endDate) && (
//                             <div className="flex items-center xl:justify-end xl:pl-0 pl-6 pt-6 gap-8 lg:w-[50%] justify-center">
//                                 {filteredData.length > 0 && (
//                                     <button
//                                         type="submit"
//                                         onClick={() => exportToExcel("", MultiData.startDate, MultiData.endDate, filteredData)}
//                                         className="flex w-aut justify-center rounded bg-success py-5 w-[200px] font-medium text-gray"
//                                     >
//                                         {loadingEx ? (
//                                             <div className="flex h-auto items-center justify-center px-[27.5px]">
//                                                 <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
//                                             </div>
//                                         ) : (
//                                             "Export to Excel"
//                                         )}
//                                     </button>
//                                 )}
//                                 <button
//                                     type="submit"
//                                     onClick={handleGetReport}
//                                     className="flex w-aut justify-center rounded bg-black-2 py-5 w-[200px] font-medium text-gray"
//                                 >
//                                     {loadingDel ? (
//                                         <div className="flex h-auto items-center justify-center px-[27.5px] ">
//                                             <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
//                                         </div>
//                                     ) : (
//                                         "Get Report"
//                                     )}
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                     {console.log("pooooooooooo", emp)}
//                     {((MultiData.startDate && MultiData.endDate) && emp.length > 0) && (
//                         <div className='grid grid-cols-2 gap-5 mb-10'>
//                             <Select
//                                 options={paymentOptions}
//                                 defaultValue={paymentOptions[0]}
//                                 onChange={(e) => {
//                                     setFilter({
//                                         ...filter,
//                                         paymentType: e.value
//                                     })
//                                 }} />
//                             <Select
//                                 options={scannedOptions}
//                                 defaultValue={scannedOptions[0]}
//                                 onChange={(e) => {
//                                     setFilter({
//                                         ...filter,
//                                         scannedType: e.value
//                                     })
//                                 }} />
//                         </div>)}
//                     {filteredData.length > 0 && (
//                         <div className=" overflow-x-auto ">
//                             <table className="w-full table-auto ">
//                                 <thead>
//                                     <tr className=" bg-gray-2 text-left dark:bg-meta-4 position-sticky">
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Date
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Customer Name
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             PNR
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Contact No
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             One day Ticket Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Free Tickets Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Nanny Tickets Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Specially Abled Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             One Day Ticket Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Nanny Ticket Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Specially Abled Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Payment Type
//                                         </th>

//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Total Amount (KWD)
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Scanned Detail
//                                         </th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {filteredData.length > 0 ? (
//                                         filteredData.map((data) => (
//                                             <tr key={data._id}>
//                                                 <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {formatDateString(data.date)}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.name}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.pnr}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.mobNo}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.odt.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cd.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.ps.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cs.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.free.cs + data.free.cd + data.free.ps}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.md.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.sa.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.odt.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cd.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.ps.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cs.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.md.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.sa.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.cs}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.ps}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.cd}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.paymentType}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.isScanned ? "Scanned" : "Un Scanned"}
//                                                     </h5>
//                                                 </td>
//                                             </tr>
//                                         ))
//                                     ) : (
//                                         <tr>
//                                             <td colSpan="11" className="py-5 text-center font-bold text-red">
//                                                 No data available
//                                             </td>
//                                         </tr>
//                                     )}
//                                     {filteredData.length > 0 && (
//                                         <tr className="bg-gray text-left dark:bg-meta-4">
//                                             <td className="border-b border-[#eee] py-5 px-11 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     Total
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalOneDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCD}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalPC}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSC}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalFree}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalNanny}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSA}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalOdtAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCdAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalPsAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCsAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalNannyAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSaAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>

//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalAmount} */}
//                                                     {totalAmountDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalScanned} */}
//                                                 </h5>
//                                             </td>

//                                         </tr>
//                                     )}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//                 </div>
//             </div>

//             <div className="flex flex-col justify-center py-7 w-[100%] h-[100%]">
//         <ReactPaginate
//           activeClassName={"item active "}
//           breakClassName={"item text-black"}
//           breakLabel={"..."}
//           className="flex items-center justify-center h-15 w-[100%] relative bg-gray-2 dark:bg-meta-4"
//           disabledClassName={"disabled-page"}
//           marginPagesDisplayed={2}
//           nextClassName={"item next "}
//           nextLabel={
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//               alt="next-pagination"
//               className="h-10"
//             />
//           }
//           previousLabel={
//             <img
//               src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//               alt="previous-pagination"
//               className="h-10 rotate-180"
//             />
//           }
//           onPageChange={(e)=>{
//             getReportListRange(MultiData.startDate, MultiData.endDate, e.selected+1, pagination.pageLength)
//             setPagination({
//                 ...pagination,
//                 currentPage:e.selected+1
//             })
//         }}
//           pageCount={ Math.ceil(pagination.totalLength/ pagination.pageLength)}
//           pageClassName={"item pagination-page "}
//           pageRangeDisplayed={2}
//           previousClassName={"item previous"}
//           renderOnZeroPageCount={null}
//         />
//       </div>
//         </div>
//     )
// }



//filter

// import React, { useEffect, useRef, useState } from 'react'
// import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
// import ExcelJS from 'exceljs';
// import { formatDateString } from '../../../../utils/FormatDateString';
// import { useNavigate } from 'react-router-dom';
// import { REPORT } from '../../../../api/report';
// import { toast } from 'react-toastify';
// import { WORKING_HOURS } from '../../../../api/workingHours';
// import Select from 'react-select'
// import ReactPaginate from "react-paginate";
// export default function DetailedReport() {
//     const [searchVal, setSearchVal] = useState("");
//     const cachedEmp = useRef(null);
//     const [emp, setEmp] = useState([]);
//     const [loadingDel, setLoadingDel] = useState(false);
//     const [loadingEx, setloadingEx] = useState(false);
//     const [showSingleDateInput, setShowSingleDateInput] = useState(false);
//     const [showMultiDateInput, setShowMultiDateInput] = useState(false);
//     const [selectedOption, setSelectedOption] = useState(null);

//     const [filteredData, setFilteredData] = useState([])

//     const [data, setData] = useState({
//         startDate: "",
//     });
//     const [filter, setFilter] = useState({
//         bookingType: "All",
//         paymentType: "All",
//         scannedType: "All",
//     });
//     const [MultiData, setMultiData] = useState({
//         startDate: "",
//         endDate: ""
//     });
//     const getChaletName = (num) => {
//         var rtnVal = ""
//         if (num == 0) {
//             rtnVal = "One Day Ticket"
//         } else if (num == 1) {
//             rtnVal = "Chalet Deluxe"
//         } else if (num == 2) {
//             rtnVal = "Chalet Standard"
//         } else if (num == 3) {
//             rtnVal = "Maid One Day Ticket"
//         } else if (num == 4) {
//             rtnVal = "Specially Abled"
//         } else if (num == 5) {
//             rtnVal = "Premium Standard"
//         } else if (num == 6) {
//             rtnVal = "Online Payment Amount"
//         } else if (num == 7) {
//             rtnVal = "Offline Payment Amount"
//         } else if (num == "all") {
//             rtnVal = "All"
//         }
//         return rtnVal
//     }

//     const bookingOptions = [
//         { value: "all", label: "All" },
//         { value: 0, label: "One Day Ticket" },
//         { value: 1, label: "Chalet Deluxe" },
//         { value: 2, label: "Chalet Standard" },
//         { value: 3, label: "Maid One Day Ticket" },
//         { value: 4, label: "Specially Abled" },
//         { value: 5, label: "Premium Standard" },
//         { value: 6, label: "Online Payment Amount" },
//         { value: 7, label: "Offline Payment Amount" },
//     ];
//     const paymentOptions = [
//         { value: "All", label: "All" },
//         { value: "Online Payment", label: "Online Payment" },
//         { value: "Cash", label: "Cash" },
//         { value: "KNET", label: "KNET" },
//         { value: "Credit Card", label: "Credit Card" },
//         { value: "Link", label: "Link" },
//     ];
//     const scannedOptions = [
//         { value: "All", label: "All" },
//         { value: true, label: "Scanned" },
//         { value: false, label: "Not Scanned" },
//     ];

//     const itemsPerPage = 10;
//     const [itemOffset, setItemOffset] = useState(0);
//     const endOffset = itemOffset + itemsPerPage;

//     const topSessionRef = useRef(null);
//     const tableSessionRef = useRef(null);

//     // const getReportListRange = async (startDate, endDate, pageNo, pageLength, paymentType, scannedType) => {
//     //     try {
//     //         if (!startDate || !endDate) return;
//     //         const res = await REPORT.GET_DETAILED_REPORT(startDate, endDate, pageNo, pageLength, paymentType, scannedType);
//     //         console.log("getting data", res.data)
//     //         setLoadingDel(false)

//     //         if (res.status !== 200) {
//     //             throw new Error("Failed to fetch report information");
//     //         }
//     //         setEmp(res.data.bookings);
//     //         setPagination({
//     //             ...pagination,
//     //             totalLength: res.data.totalNumber,
//     //         })
//     //     } catch (error) {
//     //         toast.error("No data found for the specified filters")
//     //         setEmp([])
//     //         setPagination([])
//     //         setLoadingDel(false)
//     //         console.error("Error fetching report list:", error.message);
//     //     }
//     // };

//     const getReportListRange = async (startDate, endDate, pageNo = 1, pageLength = 10, paymentType, scannedType) => {
//         try {
//             if (!startDate || !endDate) return;

//             pageNo = pageNo || 1;
//             pageLength = pageLength || 10;

//             const res = await REPORT.GET_DETAILED_REPORT(startDate, endDate, pageNo, pageLength, paymentType, scannedType);

//             console.log("Data fetched:", res.data);
//             setLoadingDel(false);

//             if (res.status !== 200 || !res.data.bookings) {
//                 throw new Error("Failed to fetch report information");
//             }

//             setEmp(res.data.bookings);
//             setPagination(prev => ({
//                 ...prev,
//                 totalLength: res.data.totalNumber,
//                 currentPage: pageNo,
//             }));

//         } catch (error) {
//             toast.error("No data found for the specified filters");
//             setEmp([]);
//             setPagination(prev => ({
//                 ...prev,
//                 totalLength: 0,
//             }));
//             setLoadingDel(false);
//             console.error("Error fetching report list:", error.message);
//         }
//     };

//     const getExcel = async (startDate, endDate, paymentType, scannedType) => {
//         try {
//             if (!startDate || !endDate) return;

//             // Make the API request to download the file
//             const response = await fetch(`http://localhost:8080/api/report/export-detailed-report/${startDate}/to/${endDate}/isScanned-${scannedType}/paymentType-${paymentType}`, {
//                 method: 'GET',
//                 headers: {
//                     'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//                 },
//             });
//             // const response = await REPORT.GET_EXCEL(startDate, endDate, paymentType, scannedType);
//             if (!response.ok) {
//                 throw new Error("Failed to download the Excel file");
//             }

//             const blob = await response.blob(); // Convert response to Blob
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = `report_${startDate}_${endDate}.xlsx`;
//             link.click();
//             window.URL.revokeObjectURL(link.href); // Clean up

//         } catch (error) {
//             toast.error("Failed to download the Excel file");
//             console.error("Error fetching report list:", error.message);
//         }
//     };


//     const sortedEmp = Array.isArray(filteredData)
//         ? filteredData.sort((a, b) => {
//             const dateA = a.date.split("T")[0];
//             const dateB = b.date.split("T")[0];
//             return dateB.localeCompare(dateA); // Sort in descending order
//         })
//         : [];

//     const currentItems = sortedEmp.slice(itemOffset, endOffset);
//     const pageCount = Math.ceil(sortedEmp.length / itemsPerPage);

//     const [pagination, setPagination] = useState({
//         currentPage: 1,
//         totalLength: 0,
//         pageLength: 10
//     })


//     const handleMultiDateChangeStart = (e) => {
//         const selectedDate = e.target.value;
//         setMultiData((prevData) => ({
//             ...prevData,
//             startDate: selectedDate,
//         }));
//         setData({
//             startDate: "",
//         });
//     };

//     const handleMultiDateChangeEnd = (e) => {
//         const selectedDate = e.target.value;
//         setMultiData((prevData) => ({
//             ...prevData,
//             endDate: selectedDate,
//         }));
//         setData({
//             endDate: "",
//         });
//     };

//     useEffect(() => {
//         if (cachedEmp.current) {
//             setEmp(cachedEmp.current);
//         }
//     }, []);

//     // const handleGetReport = () => {
//     //     if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
//     //         setLoadingDel(true)
//     //         getReportListRange(MultiData.startDate, MultiData.endDate, pagination.currentPage, pagination.pageLength, filter.scannedType, filter.paymentType).then(data => {
//     //             cachedEmp.current = data;
//     //         });
//     //     } else {
//     //         setEmp([])
//     //         toast.warn("Please select a valid date or date range.");
//     //     }
//     // };

//     const handleGetReport = () => {
//         if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
//             setLoadingDel(true);

//             const currentPage = pagination.currentPage || 1;
//             const pageLength = pagination.pageLength || 10;

//             getReportListRange(
//                 MultiData.startDate,
//                 MultiData.endDate,
//                 currentPage,
//                 pageLength,
//                 filter.scannedType,
//                 filter.paymentType
//             ).then(data => {
//                 cachedEmp.current = data;
//             });
//         } else {
//             setEmp([]);
//             toast.warn("Please select a valid date or date range.");
//         }
//     };


//     // useEffect(() => {
//     //     if (filter.paymentType, filter.scannedType) {
//     //         setLoadingDel(true);
//     //         getReportListRange(filter.paymentType, filter.scannedType);
//     //     } else {
//     //         console.log("no filter")
//     //     }
//     // }, [filter]);



//     // const exportToExcel = (singleDate, startDate, endDate, filteredData) => {
//     //     setloadingEx(true)
//     //     const workbook = new ExcelJS.Workbook();
//     //     const worksheet = workbook.addWorksheet('Report');

//     //     worksheet.columns = [
//     //         { header: 'Date', key: 'date', width: 20 },
//     //         { header: 'Customer Name', key: 'name', width: 20 },
//     //         { header: 'Pnr', key: 'pnr', width: 20 },
//     //         { header: 'Contact No', key: 'mobNo', width: 20 },
//     //         { header: 'One day Ticket Nos', key: 'oneDay', width: 20 },
//     //         { header: 'Deluxe Chalet Nos', key: 'deluxeChalet', width: 20 },
//     //         { header: 'Premium Chalet Nos', key: 'premiumChalet', width: 20 },
//     //         { header: 'Standard Chalet Nos', key: 'standardChalet', width: 20 },
//     //         { header: 'Free Tickets Nos', key: 'freeTickets', width: 20 },
//     //         { header: 'Nanny Ticket Nos', key: 'nanny', width: 20 },
//     //         { header: 'Specially Abled Nos', key: 'speciallyAbled', width: 20 },
//     //         { header: 'One Day Ticket Amount', key: 'onedayAmount', width: 30 },
//     //         { header: 'Deluxe Chalet Amount', key: 'deluxeAmount', width: 30 },
//     //         { header: 'Premium Chalet Amount', key: 'premiumChaletAmount', width: 30 },
//     //         { header: 'Standard Chalet Amount', key: 'standardAmount', width: 30 },
//     //         { header: 'Nanny Ticket Amount', key: 'nannyAmount', width: 30 },
//     //         { header: 'Specially Abled Amount', key: 'saAmount', width: 30 },
//     //         { header: 'Standard Chalet', key: 'csName', width: 30 },
//     //         { header: 'Premium Chalet', key: 'psName', width: 30 },
//     //         { header: 'Deluxe Chalet', key: 'dcName', width: 30 },
//     //         { header: 'Payment Type', key: 'paymentType', width: 20 },
//     //         { header: 'Total Amount (KWD)', key: 'totalAmount', width: 20 },
//     //         { header: 'Scanned Detail', key: 'scanned', width: 20 },
//     //     ];

//     //     worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
//     //         cell.fill = {
//     //             type: 'pattern',
//     //             pattern: 'solid',
//     //             fgColor: { argb: 'FF156097' },
//     //         };
//     //         cell.font = {
//     //             color: { argb: 'FFFFFFFF' },
//     //             bold: true,
//     //         };
//     //     });

//     //     filteredData.forEach((data) => {
//     //         worksheet.addRow({
//     //             date: formatDateString(data.date),
//     //             name: data.name,
//     //             pnr: data.pnr,
//     //             mobNo: data.mobNo,
//     //             oneDay: data.odt.number,
//     //             deluxeChalet: data.cd.number,
//     //             premiumChalet: data.ps.number,
//     //             standardChalet: data.cs.number,
//     //             freeTickets: data.free.ps + data.free.cs + data.free.cd,
//     //             nanny: data.md.number,
//     //             speciallyAbled: data.sa.number,
//     //             onedayAmount: data.odt.totalAmount,
//     //             deluxeAmount: data.cd.totalAmount,
//     //             premiumChaletAmount: data.ps.totalAmount,
//     //             standardAmount: data.cs.totalAmount,
//     //             nannyAmount: data.md.totalAmount,
//     //             saAmount: data.sa.totalAmount,
//     //             csName: data.chalet.cs,
//     //             psName: data.chalet.ps,
//     //             dcName: data.chalet.cd,
//     //             paymentType: data.paymentType,
//     //             totalAmount: data.totalAmount,
//     //             scanned: data.isScanned,
//     //         });
//     //     });

//     //     const totalOneDay = filteredData.reduce((acc, data) => acc + data.odt.number, 0);
//     //     const totalCD = filteredData.reduce((acc, data) => acc + data.cd.number, 0);
//     //     const totalSC = filteredData.reduce((acc, data) => acc + data.cs.number, 0);
//     //     const totalFree = filteredData.reduce((acc, data) => acc + (data.free.cs + data.free.ps + data.free.cd), 0);
//     //     const totalNanny = filteredData.reduce((acc, data) => acc + data.md.number, 0);
//     //     const totalSA = filteredData.reduce((acc, data) => acc + data.sa.number, 0);
//     //     const totalOdtAmountPerDay = filteredData.reduce((acc, data) => acc + data.odt.totalAmount, 0);
//     //     const totalCdAmountPerDay = filteredData.reduce((acc, data) => acc + data.cd.totalAmount, 0);
//     //     const totalPsAmountPerDay = filteredData.reduce((acc, data) => acc + data.ps.totalAmount, 0);
//     //     const totalCsAmountPerDay = filteredData.reduce((acc, data) => acc + data.cs.totalAmount, 0);
//     //     const totalNannyAmountPerDay = filteredData.reduce((acc, data) => acc + data.md.totalAmount, 0);
//     //     const totalSaAmountPerDay = filteredData.reduce((acc, data) => acc + data.sa.totalAmount, 0)


//     //     // Add total row at the bottom
//     //     worksheet.addRow({
//     //         date: 'Total',
//     //         name: "",
//     //         pnr: "",
//     //         mobNo: "",
//     //         oneDay: totalOneDay,
//     //         deluxeChalet: totalCD,
//     //         premiumChalet: totalPC,
//     //         standardChalet: totalSC,
//     //         freeTickets: totalFree,
//     //         nanny: totalNanny,
//     //         speciallyAbled: totalSA,
//     //         onedayAmount: totalOdtAmountPerDay,
//     //         totalAmount: totalAmountDay,
//     //         scanned: "",
//     //         paymentType: "",
//     //         deluxeAmount: totalCdAmountPerDay,
//     //         premiumChaletAmount: totalPsAmountPerDay,
//     //         standardAmount: totalCsAmountPerDay,
//     //         nannyAmount: totalNannyAmountPerDay,
//     //         saAmount: totalSaAmountPerDay
//     //     }).eachCell({ includeEmpty: true }, (cell) => {
//     //         cell.font = { bold: true };
//     //     });

//     //     workbook.xlsx.writeBuffer().then((buffer) => {
//     //         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     //         const url = URL.createObjectURL(blob);

//     //         let fileName;
//     //         if (singleDate) {
//     //             fileName = `Report-${formatDateString(singleDate)}.xlsx`;
//     //         } else if (startDate && endDate) {
//     //             fileName = `Report-${formatDateString(startDate)}-${formatDateString(endDate)}.xlsx`;
//     //         } else {
//     //             fileName = 'Report.xlsx';
//     //         }

//     //         const a = document.createElement('a');
//     //         a.href = url;
//     //         a.download = fileName;
//     //         a.click();
//     //         URL.revokeObjectURL(url);
//     //         setloadingEx(false)
//     //     });
//     // };




//     const calculateTotals = (emp) => {
//         return {
//             totalOneDay: emp.reduce((acc, data) => acc + data.odt.number, 0),
//             totalCD: emp.reduce((acc, data) => acc + data.cd.number, 0),
//             totalPC: emp.reduce((acc, data) => acc + data.ps.number, 0),
//             totalSC: emp.reduce((acc, data) => acc + data.cs.number, 0),
//             totalFree: emp.reduce((acc, data) => acc + (data.free.cs + data.free.ps + data.free.cd), 0),
//             totalNanny: emp.reduce((acc, data) => acc + data.md.number, 0),
//             totalSA: emp.reduce((acc, data) => acc + data.sa.number, 0),
//             totalAmountDay: emp.reduce((acc, data) => acc + data.totalAmount, 0),
//             totalOdtAmountPerDay: emp.reduce((acc, data) => acc + data.odt.totalAmount, 0),
//             totalCdAmountPerDay: emp.reduce((acc, data) => acc + data.cd.totalAmount, 0),
//             totalPsAmountPerDay: emp.reduce((acc, data) => acc + data.ps.totalAmount, 0),
//             totalCsAmountPerDay: emp.reduce((acc, data) => acc + data.cs.totalAmount, 0),
//             totalNannyAmountPerDay: emp.reduce((acc, data) => acc + data.md.totalAmount, 0),
//             totalSaAmountPerDay: emp.reduce((acc, data) => acc + data.sa.totalAmount, 0)
//         };
//     };

//     const {
//         totalOneDay,
//         totalCD,
//         totalPC,
//         totalSC,
//         totalFree,
//         totalNanny,
//         totalSA,
//         totalAmountDay,
//         totalOdtAmountPerDay,
//         totalCdAmountPerDay,
//         totalPsAmountPerDay,
//         totalCsAmountPerDay,
//         totalNannyAmountPerDay,
//         totalSaAmountPerDay
//     } = calculateTotals(emp);



//     // useEffect(() => {
//     //     var dataArray = [...emp]
//     //     var filterByScannedType = [...emp]
//     //     var filterByPaymentType = [...emp]
//     //     if (emp.length > 0) {
//     //         if (filter.paymentType !== "All") {
//     //             filterByPaymentType = dataArray.filter((obj) => obj.paymentType === filter.paymentType);
//     //         }
//     //         else {
//     //             console.log("in if   ffff")
//     //             filterByPaymentType = [...emp]
//     //         }

//     //         if (filter.scannedType !== "All") {
//     //             filterByScannedType = dataArray.filter((obj) => obj.isScanned === filter.scannedType);
//     //         }
//     //         else {
//     //             console.log("in else")
//     //             filterByScannedType = [...emp]
//     //         }
//     //     }
//     //     console.log("filterByPaymentType", filterByPaymentType)
//     //     console.log("filterByScannedType", filterByScannedType)
//     //     const commonObjects = filterByPaymentType.filter(obj1 =>
//     //         filterByScannedType.some(obj2 => obj1.pnr === obj2.pnr)
//     //     );
//     //     console.log("commonObjects", commonObjects)
//     //     setFilteredData(commonObjects)
//     // }, [filter, emp]);

//     // useEffect(() => {
//     //     if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
//     //         setLoadingDel(true)
//     //         getReportListRange(MultiData.startDate, MultiData.endDate, pagination.currentPage, pagination.pageLength, filter.scannedType, filter.paymentType).then(data => {
//     //             cachedEmp.current = data;
//     //         });
//     //     } else {
//     //         setEmp([])
//     //         // toast.warn("Please select a valid date or date range.");
//     //     }
//     // }, [MultiData, filter]);


//     console.log("filter", filter)

//     // console.log("emp", emp)
//     console.log("filteredData", filteredData)

//     return (
//         <div>
//             <div ref={topSessionRef}>
//                 <Breadcrumbs pageName="Detailed Report" />
//             </div>
//             <div
//                 ref={tableSessionRef}
//                 className=" rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
//             >
//                 <div className="max-w-full">
//                     <div className="py-3 lg:flex items-center ">

//                         <div className=" md:flex items-center lg:w-[50%] w-full">
//                             <div className=" flex flex-col h-auto py-2 lg:w-[50%] w-full mr-[4%]">
//                                 <label className="font-semibold text-black dark:text-white">
//                                     Start Date
//                                 </label>
//                                 <input
//                                     onChange={handleMultiDateChangeStart}
//                                     type="date"
//                                     className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
//                                     name="date"
//                                     value={MultiData.startDate}
//                                 />
//                             </div>
//                             <div className="flex flex-col h-auto py-2 lg:w-[50%] w-full">
//                                 <label className="font-semibold text-black dark:text-white">
//                                     End Date
//                                 </label>
//                                 <input
//                                     onChange={handleMultiDateChangeEnd}
//                                     type="date"
//                                     className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
//                                     name="date"
//                                     value={MultiData.endDate}
//                                 />
//                             </div>

//                         </div>

//                         {(MultiData.startDate && MultiData.endDate) && (
//                             <div className="flex items-center xl:justify-end xl:pl-0 pl-6 pt-6 gap-8 lg:w-[50%] justify-center">
//                                 {emp.length > 0 && (
//                                     <button
//                                         type="submit"
//                                         // onClick={() => exportToExcel("", MultiData.startDate, MultiData.endDate, filteredData)}
//                                         onClick={() => getExcel(MultiData.startDate, MultiData.endDate, filter.paymentType, filter.scannedType)}
//                                         className="flex w-aut justify-center rounded bg-success py-5 w-[200px] font-medium text-gray"
//                                     >
//                                         {loadingEx ? (
//                                             <div className="flex h-auto items-center justify-center px-[27.5px]">
//                                                 <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
//                                             </div>
//                                         ) : (
//                                             "Export to Excel"
//                                         )}
//                                     </button>
//                                 )}
//                                 <button
//                                     type="submit"
//                                     onClick={handleGetReport}
//                                     className="flex w-aut justify-center rounded bg-black-2 py-5 w-[200px] font-medium text-gray"
//                                 >
//                                     {loadingDel ? (
//                                         <div className="flex h-auto items-center justify-center px-[27.5px] ">
//                                             <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
//                                         </div>
//                                     ) : (
//                                         "Get Report"
//                                     )}
//                                 </button>
//                             </div>
//                         )}
//                     </div>
//                     {console.log("pooooooooooo", emp)}
//                     {((MultiData.startDate && MultiData.endDate)) && (
//                         <div className='grid grid-cols-2 gap-5 mb-10'>
//                             <Select
//                                 options={paymentOptions}
//                                 defaultValue={paymentOptions[0]}
//                                 onChange={(e) => {
//                                     setFilter({
//                                         ...filter,
//                                         paymentType: e.value
//                                     })
//                                     getReportListRange(MultiData.startDate, MultiData.endDate, e.selected + 1, pagination.pageLength, filter.scannedType, filter.paymentType)
//                                 }} />

//                             <Select
//                                 options={scannedOptions}
//                                 defaultValue={scannedOptions[0]}
//                                 onChange={(e) => {
//                                     setFilter({
//                                         ...filter,
//                                         scannedType: e.value
//                                     })
//                                 }} />
//                         </div>)}
//                     {emp.length > 0 && (
//                         <div className=" overflow-x-auto ">
//                             <table className="w-full table-auto ">
//                                 <thead>
//                                     <tr className=" bg-gray-2 text-left dark:bg-meta-4 position-sticky">
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Date
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Customer Name
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             PNR
//                                         </th>
//                                         <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
//                                             Contact No
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             One day Ticket Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Free Tickets Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Nanny Tickets Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Specially Abled Nos:
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             One Day Ticket Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Nanny Ticket Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Specially Abled Amount
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Standard Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Premium Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Deluxe Chalet
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Payment Type
//                                         </th>

//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Total Amount (KWD)
//                                         </th>
//                                         <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
//                                             Scanned Detail
//                                         </th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {emp.length > 0 ? (
//                                         emp.map((data) => (
//                                             <tr key={data._id}>
//                                                 <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {formatDateString(data.date)}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.name}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.pnr}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.mobNo}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.odt.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cd.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.ps.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cs.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.free.cs + data.free.cd + data.free.ps}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.md.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.sa.number}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.odt.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cd.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.ps.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.cs.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.md.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.sa.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.cs}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.ps}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.chalet.cd}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.paymentType}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.totalAmount}
//                                                     </h5>
//                                                 </td>
//                                                 <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                     <h5 className="font-medium text-black dark:text-white">
//                                                         {data.isScanned ? "Scanned" : "Un Scanned"}
//                                                     </h5>
//                                                 </td>
//                                             </tr>
//                                         ))
//                                     ) : (
//                                         <tr>
//                                             <td colSpan="11" className="py-5 text-center font-bold text-red">
//                                                 No data available
//                                             </td>
//                                         </tr>
//                                     )}
//                                     {emp.length > 0 && (
//                                         <tr className="bg-gray text-left dark:bg-meta-4">
//                                             <td className="border-b border-[#eee] py-5 px-11 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     Total
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalOneDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCD}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalPC}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSC}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalFree}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalNanny}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSA}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalOdtAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCdAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalPsAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalCsAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalNannyAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {totalSaAmountPerDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalOnlinePay} */}
//                                                 </h5>
//                                             </td>

//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalAmount} */}
//                                                     {totalAmountDay}
//                                                 </h5>
//                                             </td>
//                                             <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
//                                                 <h5 className="font-bold text-red dark:text-white">
//                                                     {/* {totalScanned} */}
//                                                 </h5>
//                                             </td>

//                                         </tr>
//                                     )}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//                 </div>
//             </div>

//             <div className="flex flex-col justify-center py-7 w-[100%] h-[100%]">
//                 {emp.length > 0 &&
//                     // <ReactPaginate
//                     //     activeClassName={"item active "}
//                     //     breakClassName={"item text-black"}
//                     //     breakLabel={"..."}
//                     //     className="flex items-center justify-center h-15 w-[100%] relative bg-gray-2 dark:bg-meta-4"
//                     //     disabledClassName={"disabled-page"}
//                     //     marginPagesDisplayed={2}
//                     //     nextClassName={"item next "}
//                     //     nextLabel={
//                     //         <img
//                     //             src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//                     //             alt="next-pagination"
//                     //             className="h-10"
//                     //         />
//                     //     }
//                     //     previousLabel={
//                     //         <img
//                     //             src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//                     //             alt="previous-pagination"
//                     //             className="h-10 rotate-180"
//                     //         />
//                     //     }
//                     //     onPageChange={(e) => {
//                     //         getReportListRange(MultiData.startDate, MultiData.endDate, e.selected + 1, pagination.pageLength, filter.scannedType, filter.paymentType)
//                     //         setPagination({
//                     //             ...pagination,
//                     //             currentPage: e.selected + 1
//                     //         })
//                     //     }}
//                     //     pageCount={Math.ceil(pagination.totalLength / pagination.pageLength)}
//                     //     pageClassName={"item pagination-page "}
//                     //     pageRangeDisplayed={2}
//                     //     previousClassName={"item previous"}
//                     //     renderOnZeroPageCount={null}
//                     // />
//                     <ReactPaginate
//                         activeClassName={"item active "}
//                         breakClassName={"item text-black"}
//                         breakLabel={"..."}
//                         className="flex items-center justify-center h-15 w-[100%] relative bg-gray-2 dark:bg-meta-4"
//                         disabledClassName={"disabled-page"}
//                         marginPagesDisplayed={2}
//                         nextClassName={"item next "}
//                         nextLabel={
//                             <img
//                                 src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//                                 alt="next-pagination"
//                                 className="h-10"
//                             />
//                         }
//                         previousLabel={
//                             <img
//                                 src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
//                                 alt="previous-pagination"
//                                 className="h-10 rotate-180"
//                             />
//                         }
//                         onPageChange={(e) => {
//                             const newPage = e.selected + 1; // React Paginate uses zero-indexing
//                             getReportListRange(
//                                 MultiData.startDate,
//                                 MultiData.endDate,
//                                 newPage,
//                                 pagination.pageLength,
//                                 filter.scannedType,
//                                 filter.paymentType
//                             );

//                             setPagination(prev => ({
//                                 ...prev,
//                                 currentPage: newPage
//                             }));
//                         }}
//                         pageCount={Math.ceil(pagination.totalLength / pagination.pageLength)}  // Calculate page count dynamically
//                         pageClassName={"item pagination-page "}
//                         pageRangeDisplayed={2}
//                         previousClassName={"item previous"}
//                         renderOnZeroPageCount={null}
//                     />
//                 }
//             </div>
//         </div>
//     )
// }











import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import ExcelJS from 'exceljs';
import { formatDateString } from '../../../../utils/FormatDateString';
import { useNavigate } from 'react-router-dom';
import { REPORT } from '../../../../api/report';
import { toast } from 'react-toastify';
import { WORKING_HOURS } from '../../../../api/workingHours';
import Select from 'react-select'
import ReactPaginate from "react-paginate";
import { CONSTANT } from '../../../../constant/Constant';
export default function DetailedReport() {
    const [searchVal, setSearchVal] = useState("");
    const cachedEmp = useRef(null);
    const [emp, setEmp] = useState([]);
    const [loadingDel, setLoadingDel] = useState(false);
    const [loadingEx, setloadingEx] = useState(false);
    const [showSingleDateInput, setShowSingleDateInput] = useState(false);
    const [showMultiDateInput, setShowMultiDateInput] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const [filteredData, setFilteredData] = useState([])

    const [data, setData] = useState({
        startDate: "",
    });
    const [filter, setFilter] = useState({
        bookingType: "All",
        paymentType: "All",
        scannedType: "All",
    });
    const [MultiData, setMultiData] = useState({
        startDate: "",
        endDate: ""
    });
    const getChaletName = (num) => {
        var rtnVal = ""
        if (num == 0) {
            rtnVal = "One Day Ticket"
        } else if (num == 1) {
            rtnVal = "Chalet Deluxe"
        } else if (num == 2) {
            rtnVal = "Chalet Standard"
        } else if (num == 3) {
            rtnVal = "Maid One Day Ticket"
        } else if (num == 4) {
            rtnVal = "Specially Abled"
        } else if (num == 5) {
            rtnVal = "Premium Standard"
        } else if (num == 6) {
            rtnVal = "Online Payment Amount"
        } else if (num == 7) {
            rtnVal = "Offline Payment Amount"
        } else if (num == "all") {
            rtnVal = "All"
        }
        return rtnVal
    }

    const bookingOptions = [
        { value: "all", label: "All" },
        { value: 0, label: "One Day Ticket" },
        { value: 1, label: "Chalet Deluxe" },
        { value: 2, label: "Chalet Standard" },
        { value: 3, label: "Maid One Day Ticket" },
        { value: 4, label: "Specially Abled" },
        { value: 5, label: "Premium Standard" },
        { value: 6, label: "Online Payment Amount" },
        { value: 7, label: "Offline Payment Amount" },
    ];
    const paymentOptions = [
        { value: "All", label: "All" },
        { value: "Online Payment", label: "Online Payment" },
        { value: "Cash", label: "Cash" },
        { value: "KNET", label: "KNET" },
        { value: "Credit Card", label: "Credit Card" },
        { value: "Link", label: "Link" },
        // { value: "Split Payment", label: "Split Payment" },
    ];
    const scannedOptions = [
        { value: "All", label: "All" },
        { value: true, label: "Scanned" },
        { value: false, label: "Not Scanned" },
    ];

    const itemsPerPage = 10;
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;

    const topSessionRef = useRef(null);
    const tableSessionRef = useRef(null);


    const getReportListRange = async (startDate, endDate, pageNo = 1, pageLength = 10, paymentType, scannedType) => {
        try {
            if (!startDate || !endDate) return;

            pageNo = pageNo || 1;
            pageLength = pageLength || 10;

            const res = await REPORT.GET_DETAILED_REPORT(startDate, endDate, pageNo, pageLength, paymentType, scannedType);

            console.log("Data fetched:", res.data);
            setLoadingDel(false);

            if (res.status !== 200 || !res.data.bookings) {
                throw new Error("Failed to fetch report information");
            }

            setEmp(res.data.bookings);
            setPagination(prev => ({
                ...prev,
                totalLength: res.data.totalNumber,
                currentPage: pageNo,
            }));

            // toast.success("Report generated successfully")
        } catch (error) {
            setEmp([]);
            setPagination(prev => ({
                ...prev,
                totalLength: 0,
            }));
            setLoadingDel(false);
            toast.error("No data found for the specified filters");
            console.error("Error fetching report list:", error.message);
        }
    };
    console.log("pppppppppp", emp)

    const getExcel = async (startDate, endDate, paymentType, scannedType) => {
        try {
            if (!startDate || !endDate) return;

            setloadingEx(true)
            const response = await fetch(`${CONSTANT.URL}report/export-detailed-report/${startDate}/to/${endDate}/isScanned-${scannedType}/paymentType-${paymentType}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            });
            // const response = await REPORT.GET_EXCEL(startDate, endDate, paymentType, scannedType);
            console.log("response", response)
            if (!response.ok) {
                throw new Error("Failed to download the Excel file");
            }
            setloadingEx(false)

            const blob = await response.blob(); // Convert response to Blob
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Detailed_report_${startDate}_${endDate}.xlsx`;
            link.click();
            window.URL.revokeObjectURL(link.href); // Clean up
            toast.success("FIle downloaded successfully");


        } catch (error) {
            toast.error("Failed to download the Excel file");
            console.error("Error fetching report list:", error.message);
            setloadingEx(false)

        }
    };


    const sortedEmp = Array.isArray(filteredData)
        ? filteredData.sort((a, b) => {
            const dateA = a.date.split("T")[0];
            const dateB = b.date.split("T")[0];
            return dateB.localeCompare(dateA);
        })
        : [];

    const currentItems = sortedEmp.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(sortedEmp.length / itemsPerPage);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalLength: 0,
        pageLength: 10
    })


    const handleMultiDateChangeStart = (e) => {
        const selectedDate = e.target.value;
        setMultiData((prevData) => ({
            ...prevData,
            startDate: selectedDate,
        }));
        setEmp([])
        setData({
            startDate: "",
        });
    };

    const handleMultiDateChangeEnd = (e) => {
        const selectedDate = e.target.value;
        setMultiData((prevData) => ({
            ...prevData,
            endDate: selectedDate,
        }));
        setEmp([])
        setData({
            endDate: "",
        });
    };

    useEffect(() => {
        if (cachedEmp.current) {
            setEmp(cachedEmp.current);
        }
    }, []);

    const handleGetReport = () => {
        if (MultiData.startDate && MultiData.endDate && (MultiData.startDate <= MultiData.endDate)) {
            setLoadingDel(true);

            // const currentPage = pagination.currentPage || 1;
            const currentPage = 1;
            const pageLength = pagination.pageLength || 10;

            getReportListRange(
                MultiData.startDate,
                MultiData.endDate,
                currentPage,
                pageLength,
                filter.scannedType,
                filter.paymentType
            ).then(data => {
                cachedEmp.current = data;
            });
        } else {
            setEmp([]);
            toast.warn("Please select a valid date or date range.");
        }
    };


    const calculateTotals = (emp) => {
        return {
            totalOneDay: emp.reduce((acc, data) => acc + data.odt.number, 0),
            totalCD: emp.reduce((acc, data) => acc + data.cd.number, 0),
            totalPC: emp.reduce((acc, data) => acc + data.ps.number, 0),
            totalSC: emp.reduce((acc, data) => acc + data.cs.number, 0),
            totalFree: emp.reduce((acc, data) => acc + (data.free.cs + data.free.ps + data.free.cd + data.free.odt || 0), 0),
            totalNanny: emp.reduce((acc, data) => acc + data.md.number, 0),
            totalSA: emp.reduce((acc, data) => acc + data.sa.number, 0),
            totalAmountDay: emp.reduce((acc, data) => acc + data.totalAmount, 0),
            totalOdtAmountPerDay: emp.reduce((acc, data) => acc + data.odt.totalAmount, 0),
            totalCdAmountPerDay: emp.reduce((acc, data) => acc + data.cd.totalAmount, 0),
            totalPsAmountPerDay: emp.reduce((acc, data) => acc + data.ps.totalAmount, 0),
            totalCsAmountPerDay: emp.reduce((acc, data) => acc + data.cs.totalAmount, 0),
            totalNannyAmountPerDay: emp.reduce((acc, data) => acc + data.md.totalAmount, 0),
            totalSaAmountPerDay: emp.reduce((acc, data) => acc + data.sa.totalAmount, 0)
        };
    };

    const {
        totalOneDay,
        totalCD,
        totalPC,
        totalSC,
        totalFree,
        totalNanny,
        totalSA,
        totalAmountDay,
        totalOdtAmountPerDay,
        totalCdAmountPerDay,
        totalPsAmountPerDay,
        totalCsAmountPerDay,
        totalNannyAmountPerDay,
        totalSaAmountPerDay
    } = calculateTotals(emp);


    console.log("filter", filter)

    return (
        <div>
            <div ref={topSessionRef}>
                <Breadcrumbs pageName="Detailed Report" />
            </div>
            <div
                ref={tableSessionRef}
                className=" rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1"
            >
                <div className="max-w-full">
                    <div className="py-3 lg:flex items-center ">

                        <div className=" md:flex items-center lg:w-[50%] w-full">
                            <div className=" flex flex-col h-auto py-2 lg:w-[50%] w-full mr-[4%]">
                                <label className="font-semibold text-black dark:text-white">
                                    Start Date
                                </label>
                                <input
                                    onChange={handleMultiDateChangeStart}
                                    type="date"
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    name="date"
                                    value={MultiData.startDate}
                                />
                            </div>
                            <div className="flex flex-col h-auto py-2 lg:w-[50%] w-full">
                                <label className="font-semibold text-black dark:text-white">
                                    End Date
                                </label>
                                <input
                                    onChange={handleMultiDateChangeEnd}
                                    type="date"
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                    name="date"
                                    value={MultiData.endDate}
                                />
                            </div>

                        </div>

                        {(MultiData.startDate && MultiData.endDate) && (
                            <div className="flex items-center xl:justify-end xl:pl-0 pl-6 pt-6 gap-8 lg:w-[50%] justify-center">
                                {emp.length > 0 && (
                                    <button
                                        type="submit"
                                        onClick={() => getExcel(MultiData.startDate, MultiData.endDate, filter.paymentType, filter.scannedType)}
                                        className="flex w-aut justify-center rounded bg-success py-5 w-[200px] font-medium text-gray"
                                    >
                                        {loadingEx ? (
                                            <div className="flex h-auto items-center justify-center px-[27.5px]">
                                                <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                                            </div>
                                        ) : (
                                            "Export to Excel"
                                        )}
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    onClick={handleGetReport}
                                    className="flex w-aut justify-center rounded bg-black-2 py-5 w-[200px] font-medium text-gray"
                                >
                                    {loadingDel ? (
                                        <div className="flex h-auto items-center justify-center px-[27.5px] ">
                                            <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                                        </div>
                                    ) : (
                                        "Get Report"
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    {((MultiData.startDate && MultiData.endDate)) && (
                        <div className='grid grid-cols-2 gap-5 mb-10'>
                            <Select
                                // options={paymentOptions}
                                // defaultValue={paymentOptions[0]}
                                // onChange={(e) => {
                                //     setFilter({
                                //         ...filter,
                                //         paymentType: e.value
                                //     })
                                //     getReportListRange(MultiData.startDate, MultiData.endDate, e.selected + 1, pagination.pageLength, filter.scannedType, filter.paymentType)
                                // }}
                                options={paymentOptions}
                                defaultValue={paymentOptions[0]}
                                onChange={(e) => {
                                    setFilter(prev => ({
                                        ...prev,
                                        paymentType: e.value
                                    }));
                                }}
                            // options={paymentOptions}
                            // defaultValue={paymentOptions[0]}
                            // onChange={(e) => {
                            //     const updatedFilter = {
                            //         ...filter,
                            //         paymentType: e.value
                            //     };
                            //     setFilter(updatedFilter);
                            //     getReportListRange(
                            //         MultiData.startDate,
                            //         MultiData.endDate,
                            //         pagination.currentPage,
                            //         pagination.pageLength,
                            //         updatedFilter.paymentType,
                            //         updatedFilter.scannedType
                            //     );
                            // }}
                            />

                            <Select
                                options={scannedOptions}
                                defaultValue={scannedOptions[0]}
                                onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        scannedType: e.value
                                    })
                                }}
                            // options={scannedOptions}
                            // defaultValue={scannedOptions[0]}
                            // onChange={(e) => {
                            //     const updatedFilter = {
                            //         ...filter,
                            //         scannedType: e.value
                            //     };
                            //     setFilter(updatedFilter); // Update filter state

                            //     // Trigger API call with updated scannedType
                            //     getReportListRange(
                            //         MultiData.startDate,
                            //         MultiData.endDate,
                            //         pagination.currentPage, // Keep the current page
                            //         pagination.pageLength,
                            //         updatedFilter.paymentType, // Keep the current paymentType
                            //         updatedFilter.scannedType // Use the updated scannedType
                            //     );
                            // }}
                            />
                        </div>)}
                    {emp.length > 0 && (
                        <div className=" overflow-x-auto ">
                            <table className="w-full table-auto ">
                                <thead>
                                    <tr className=" bg-gray-2 text-left dark:bg-meta-4 position-sticky">
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            Date
                                        </th>
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            Customer Name
                                        </th>
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            PNR
                                        </th>
                                        <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                            Contact No
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            One day Ticket Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Deluxe Chalet Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Premium Chalet Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Standard Chalet Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Free Tickets Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Nanny Tickets Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Specially Abled Nos:
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            One Day Ticket Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Deluxe Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Premium Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Standard Chalet Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Nanny Ticket Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Specially Abled Amount
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Standard Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Premium Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Deluxe Chalet
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Payment Type
                                        </th>

                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Total Amount (KWD)
                                        </th>
                                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                            Scanned Detail
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emp.length > 0 ? (
                                        emp.map((data) => (
                                            <tr key={data._id}>
                                                <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {formatDateString(data.date)}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.name}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.pnr}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.mobNo}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.odt.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.cd.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.ps.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.cs.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.free.cs + data.free.cd + data.free.ps + (data.free.odt || 0)}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.md.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.sa.number}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.odt.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.cd.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.ps.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.cs.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.md.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.sa.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.chalet.cs}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.chalet.ps}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.chalet.cd}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.paymentType}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.totalAmount}
                                                    </h5>
                                                </td>
                                                <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                    <h5 className="font-medium text-black dark:text-white">
                                                        {data.isScanned ? "Scanned" : "Un Scanned"}
                                                    </h5>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="11" className="py-5 text-center font-bold text-red">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                    {emp.length > 0 && (
                                        <tr className="bg-gray text-left dark:bg-meta-4">
                                            <td className="border-b border-[#eee] py-5 px-11 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    Total
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalOneDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCD}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalPC}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSC}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalFree}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalNanny}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSA}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalOdtAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCdAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalPsAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalCsAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalNannyAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {totalSaAmountPerDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalOnlinePay} */}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalOnlinePay} */}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalOnlinePay} */}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalOnlinePay} */}
                                                </h5>
                                            </td>

                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalAmount} */}
                                                    {totalAmountDay}
                                                </h5>
                                            </td>
                                            <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                                <h5 className="font-bold text-red dark:text-white">
                                                    {/* {totalScanned} */}
                                                </h5>
                                            </td>

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-col justify-center py-7 w-[100%] h-[100%]">
                {emp.length > 0 &&
                    <ReactPaginate
                        activeClassName={"item active "}
                        breakClassName={"item text-black"}
                        breakLabel={"..."}
                        className="flex items-center justify-center h-15 w-[100%] relative bg-gray-2 dark:bg-meta-4"
                        disabledClassName={"disabled-page"}
                        marginPagesDisplayed={2}
                        nextClassName={"item next "}
                        nextLabel={
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
                                alt="next-pagination"
                                className="h-10"
                            />
                        }
                        previousLabel={
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/2989/2989988.png"
                                alt="previous-pagination"
                                className="h-10 rotate-180"
                            />
                        }
                        onPageChange={(e) => {
                            const newPage = e.selected + 1; // React Paginate uses zero-indexing
                            getReportListRange(
                                MultiData.startDate,
                                MultiData.endDate,
                                newPage,
                                pagination.pageLength,
                                filter.scannedType,
                                filter.paymentType
                            );

                            setPagination(prev => ({
                                ...prev,
                                currentPage: newPage
                            }));
                        }}
                        pageCount={Math.ceil(pagination.totalLength / pagination.pageLength)}  // Calculate page count dynamically
                        pageClassName={"item pagination-page "}
                        pageRangeDisplayed={2}
                        previousClassName={"item previous"}
                        renderOnZeroPageCount={null}
                    />
                }
            </div>
        </div>
    )
}