import React from 'react'
export default function AddStandard(
  { data, setData, loading, handleAdd, validate }
) {
  return (
    <div className="max-w-full overflow-x-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 ">
        <div className="flex flex-col ">
          <div className="px-2 py-2">
            <label className="mb-2.5 block text-black dark:text-white">
              Title <span className="text-meta-1">*</span>
            </label>
            <input
              onChange={(e) => {
                setData({
                  ...data,
                  title: e.target.value,
                });
              }}
              type="text"
              placeholder="Enter number of standard chalet"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              name="title"
              value={data.title}
            />
            {data.title === "" && validate && (
              <p className="text-red text-sm mt-1">* Required</p>
            )}
          </div>
        </div>

        <div className="flex flex-col h-auto sm:pt-7">
          <div className="px-2 py-3 h-auto">
            <button
              onClick={() => {
                handleAdd();
              }}
              type="submit"
              className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-white"
            >
              {loading ? (
                <div className="flex h-auto items-center justify-center ">
                  <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                </div>
              ) : (
                "Add Data"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
