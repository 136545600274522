import React, { useState } from "react";
import LOGO from "../../../src/assets/logo.png";
import CLOCK from "../../../src/assets/clock.png";
import PHONE from "../../../src/assets/phone.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const array = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About Us",
    link: "/about",
  },
  {
    id: 3,
    title: "FAQ",
    link: "/faq",
  },
  {
    id: 4,
    title: "NEWS",
    link: "/news",
  },
  {
    id: 6,
    title: "Contact Us",
    link: "/contact",
  },
];
const Hamburger = ({ open, setOpen }) => {
  return (
    <div
      className="flex relative w-8 h-8 flex-col justify-between items-center lg:hidden z-20"
      onClick={() => {
        setOpen(!open);
      }}
    >
      {/* hamburger button */}
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${open ? "rotate-45 translate-y-3.5 bg-yellow" : "bg-black"
          }`}
      />
      <span
        className={`h-1 bg-black rounded-lg transition-all duration-300 ease-in-out ${open ? "w-[0px] bg-black" : "w-8"
          }`}
      />
      <span
        className={`h-1 w-8 rounded-lg transform transition duration-300 ease-in-out ${open ? "-rotate-45 -translate-y-3.5 bg-yellow" : "bg-black"
          }`}
      />
    </div>
  );
};

function MobileNav({ open, setOpen }) {
  const { t } = useTranslation();
  return (
    <div
      className={`absolute lg:hidden top-0 left-0 h-auto w-screen bg-blue transform ${open ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out filter drop-shadow-md z-10`}
    >
      <div className="flex items-center justify-between filter drop-shadow-md px-10 mt-2">
        <Link to="/" onClick={() => setOpen(false)}>
          <img src={LOGO} alt="images" className=" object-cover h-[80px]" />
        </Link>
      </div>
      <div>
        <div className="flex flex-col mt-5">
          {array.map((data) => (
            <Link
              key={data.link}
              to={data.link}
              className="text-sm font-medium text-yellow my-2 text-center font-mont"
              onClick={() =>
                setTimeout(() => {
                  setOpen(!open);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 100)
              }
            >
              {data.title}
            </Link>
          ))}

          <div className="h-auto flex w-auto flex-col justify-center items-center">
            <div className="h-auto w-auto flex items-center mb-[2%]">
              <img
                src={CLOCK}
                alt="images"
                className="object-contain h-4 w-4"
              />
              <p className="text-white text-xs font-light font-mont pl-1">
              {t('opening_time')}
              </p>
            </div>
            {/* <div className="h-auto w-auto flex justify-end ">
              <p className="text-white text-xs font-light font-mont pl-1">
                {t('second_time')}
              </p>
            </div> */}
            {/* <div className="h-auto w-auto flex items-center my-5">
              <img
                src={PHONE}
                alt="images"
                className=" object-contain h-4 w-4"
              />
              <p className=" text-white text-xs font-light font-mont pl-1">
                <span className="font-semibold">For Enquiries : </span> +971 5
                000 3333{" "}
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function SecondaryHeader() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className=" h-auto w-full flex justify-center">
      <div className=" h-full w-[90%] flex justify-between items-center max-w-screen-2xl">
        <MobileNav open={open} setOpen={setOpen} />
        <Link className=" h-full w-auto " to="/">
          <img
            src={LOGO}
            alt="images"
            className=" object-contain mt-2 lg:mt-0 h-[80px] lg:h-[100px]"
          />
        </Link>
        <Hamburger open={open} setOpen={setOpen} />
        <div className="hidden h-auto w-full lg:flex flex-col items-end">
          <div className="h-auto  w-auto">
            <div className="h-auto w-auto flex items-center">
              <img
                src={CLOCK}
                alt="images"
                className="object-contain h-4 w-4"
              />
              <p className="text-black text-xs font-light font-mont pl-1">
                {t('opening_time')}
              </p>
            </div>
            {/* <div className="h-auto w-auto flex justify-end  ">
              <p className="text-black text-xs font-light font-mont pl-1">
                {t('second_time')}
              </p>
            </div> */}
            {/* <div className="h-auto w-auto flex items-center pl-5">
              <img
                src={PHONE}
                alt="images"
                className=" object-contain h-4 w-4"
              />
              <p className=" text-black text-xs font-light font-mont pl-1">
                <span className="font-semibold">For Enquiries : </span> +971 5
                000 3333{" "}
              </p>
            </div> */}
          </div>
          <div className=" h-auto w-auto flex items-center mt-4">
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/"
              className="text-black font-light text-base font-mont hover:text-yellow"
            >
              Home
            </Link>
            <div className="h-[10px] w-[2px] bg-black mx-3 rounded"></div>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/about"
              className="text-black font-light text-base font-mont hover:text-yellow"
            >
              About Us
            </Link>
            <div className="h-[10px] w-[2px] bg-black mx-3 rounded"></div>

            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/faq"
              className="text-black font-light text-base font-mont hover:text-yellow"
            >
              FAQ
            </Link>
            <div className="h-[10px] w-[2px] bg-black mx-3 rounded"></div>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/news"
              className="text-black font-light text-base font-mont hover:text-yellow"
            >
              News
            </Link>
            <div className="h-[10px] w-[2px] bg-black mx-3 rounded"></div>
            <Link
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              to="/contact"
              className="text-black font-light text-base font-mont hover:text-yellow"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
