import React, { useContext, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import PaginatedItems from "../../../components/pagination/PaginatedItems";
import { toast } from "react-toastify";
import { UseAuth } from "../../../utils/UseAuth";
import { TokenContext } from "../../../utils/TokenContext";
import { WORKING_HOURS } from "../../../api/workingHours";
import { formatDateString } from "../../../utils/FormatDateString";
import { DC } from "../../../api/dlxChalet";
import { CS } from "../../../api/stdChalet";
import Select from "react-select";

export default function ManageFreeTicket() {
  // states
  const [id, setId] = useState("");
  const [emp, setEmp] = useState([]);
  const [count, setCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [itemOffset, setItemOffset] = useState(0);
  const [validate, setValidate] = useState(false);
  const [reRunData, setReRunData] = useState(null);
  const [refreshAdd, setRefreshAdd] = useState(false);
  const [refreshUpdate, setRefreshUpdate] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [length, setLength] = useState({
    dlx: 0,
    std: 0,
    odt: 100,
  });
  const [data, setData] = useState({
    _id: "",
    startDate: "",
    endDate: "",
    odtBookings: length.odt,
    cdBookings: length.dlx,
    csBookings: length.std,
    odtToday: 0,
    cdToday: 0,
    csToday: 0,
    odtPrice: 0,
    cdPrice: 0,
    csPrice: 0,
    odtExtra: 0,
    cdExtra: 0,
    csExtra: 0,
    isPurged: false,
    // odt: {
    //   price: 0,
    //   noOfBooking: 0,
    //   bookingToday: 0,
    //   extraBooking: 0,
    // },
    // cd: {
    //   price: 0,
    //   noOfBooking: 0,
    //   bookingToday: 0,
    //   extraBooking: 0,
    // },
    // cs: {
    //   price: 0,
    //   noOfBooking: 0,
    //   bookingToday: 0,
    //   extraBooking: 0,
    // },
  });
  // states

  //constant variables
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const topSessionRef = useRef(null);
  const tableSessionRef = useRef(null);
  const { token } = useContext(TokenContext);
  const endOffset = itemOffset + itemsPerPage;
  const { checkAccessTokenValidity } = UseAuth();
  const currentItems = emp.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(emp.length / itemsPerPage);
  emp.sort((a, b) => {
    const dateA = a.date.split("T")[0];
    const dateB = b.date.split("T")[0];
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });
  //constant variables

  //dependent functions
  const scrollToSection = () => {
    topSessionRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  const scrollToTableSection = () => {
    tableSessionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  const backToAdd = (item) => {
    setIsEdit(false);
    clearData();
  };
  const clearData = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
      odtPrice: 0,
      odtBookings: length.odt,
      cdPrice: 0,
      cdBookings: length.dlx,
      csPrice: 0,
      csBookings: length.std,
      isPurged: false,
    });
  };
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  function getDates(startDate, endDate) {
    const datesArray = [];
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    while (currentDate <= lastDate) {
      datesArray.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return datesArray;
  }

  // console.log("data : data :", data)
  const handleEdit = (item) => {
    scrollToSection();
    setIsEdit(true);
    // console.log("item.", item.bookedCD);
    // console.log("item.", item.bookedCS);
    // console.log("item", item.odt)
    var arrayDlx = [];
    var arrayStd = [];
    item.bookedCD.map((itm) => {
      var req = { value: itm, label: itm };
      arrayDlx.push(req);
      return arrayDlx;
    });
    item.bookedCS.map((itm) => {
      var req = { value: itm, label: itm };
      arrayStd.push(req);
      return arrayStd;
    });
    setSelectVal({
      ...selectVal,
      dlxIni: arrayDlx,
      stdIni: arrayStd,
    });
    selectVal.dlxIni = arrayDlx;
    selectVal.stdIni = arrayStd;

    setData({
      ...data,
      _id: item._id,
      date: item.date,
      odtPrice: item.odt.price,
      cdPrice: item.cd.price,
      csPrice: item.cs.price,
      odtBookings: item.odt.noOfBooking,
      cdBookings: item.cd.noOfBooking,
      csBookings: item.cs.noOfBooking,
      odtToday: item.odt.bookingToday,
      cdToday: item.cd.bookingToday,
      csToday: item.cs.bookingToday,
      odtExtra: item.odt.extraBooking,
      cdExtra: item.cd.extraBooking,
      csExtra: item.cs.extraBooking,
      isPurged: false,
    });
    data.date = item.date;
    data.odtBookings = item.odt.noOfBooking;
    data.cdBookings = item.cd.noOfBooking;
    data.csBookings = item.cs.noOfBooking;
    data.odtToday = item.odt.bookingToday;
    data.cdToday = item.cd.bookingToday;
    data.csToday = item.cs.bookingToday;
    data.odtPrice = item.odt.price;
    data.cdPrice = item.cd.price;
    data.csPrice = item.cs.price;
    data.odtExtra = item.odt.extraBooking;
    data.cdExtra = item.cd.extraBooking;
    data.csExtra = item.cs.extraBooking;
  };
  // console.log("edited", data);
  //dependent functions

  //delete
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "You are about to delete this data! Are you sure?"
    );

    if (confirmed) {
      try {
        const res = await WORKING_HOURS.GET_BY_ID(id);
        if (res.status !== 200) {
          throw new Error("Failed to fetch booking information");
        } else {
          var req = {
            date: res.data[0].date,
            price: res.data[0].price,
            isPurged: false,
          };
          deleteData(req, id);
        }
      } catch (error) {
        // console.log("Error loading topics: ", error);
      }
    }
  };
  const deleteData = async (req, id) => {
    try {
      const updateRes = await WORKING_HOURS.DELETE(req, id, token);
      if (updateRes.status === 200) {
        clearData();
        setIsEdit(false);
        setRefreshDelete(!refreshDelete);
        setReRunData(null);
        toast.success("Deleted Working day");
        navigate("/business-days");
      } else {
        toast.error("Failed to delete data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(req);
        setId(id);
        setRefreshDelete(!refreshDelete);
      }
    }
  };
  //delete

  //update
  const updateData = async (values) => {
    setLoading(true);
    try {
      const res = await WORKING_HOURS.UPDATE(values, data._id);
      setLoading(false);
      clearData();
      setIsEdit(false);
      if (res.status === 200) {
        setRefreshUpdate(!refreshUpdate);
        toast.success("Updated business day");
        navigate("/business-days");
      } else {
        toast.error("Failed to updated data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshUpdate(!refreshUpdate);
      }
    }
  };
  const handleUpdate = async () => {
    if (
      data.date !== "" &&
      data.odtBookings !== 0 &&
      data.cdBookings !== 0 &&
      data.csBookings !== 0 &&
      data.odtPrice &&
      data.cdPrice &&
      data.csPrice
    ) {
      setValidate(false);
      setLoading(true);
      var req = {
        date: data.date,
        isPurged: false,
        bookedCD: data.bookedCD,
        bookedCS: data.bookedCS,
        odt: {
          price: data.odtPrice,
          noOfBooking: data.odtBookings,
          bookingToday: data.odtToday,
          extraBooking: data.extraBooking === 0 ? data.extraBooking : Math.abs(data.odtBookings - data.odtToday),
        },
        cd: {
          price: data.cdPrice,
          noOfBooking: data.cdBookings,
          bookingToday: data.cdToday,
          extraBooking: data.extraBooking === 0 ? data.extraBooking : Math.abs(data.cdBookings - data.cdToday),
        },
        cs: {
          price: data.csPrice,
          noOfBooking: data.csBookings,
          bookingToday: data.csToday,
          extraBooking: data.extraBooking === 0 ? data.extraBooking : Math.abs(data.csBookings - data.csToday),
        },
      };
      updateData(req);
      // console.log("upd", req);
    } else {
      setValidate(true);
    }
  };
  //update

  ///add
  const addData = async (values) => {
    // console.log(values, "values");
    setLoading(true);
    try {
      const res = await WORKING_HOURS.ADD(values, token);
      setReRunData(null);
      clearData();
      if (res.status === 200 || res.status === 201) {
        if (res.status === 200) {
          setRefreshAdd(!refreshAdd);
          setCount(count + 1);
        }
      } else {
        toast.error("Failed to create data");
        throw new Error("failed to create an topic");
      }
    } catch (error) {
      setLoading(false);
      // console.log("error", error);
      if (error.response && error.response.status === 401) {
        await checkAccessTokenValidity();
        setReRunData(values);
        setRefreshAdd(!refreshAdd);
      }
    }
  };
  const handleAdd = async () => {
    if (
      data.startDate !== "" &&
      data.endDate !== "" &&
      (data.odtBookings !== 0 || data.odtBookings !== "") &&
      (data.cdBookings !== 0 || data.cdBookings !== "") &&
      (data.csBookings !== 0 || data.csBookings !== "") &&
      (data.odtPrice !== 0 || data.odtPrice !== "") &&
      (data.cdPrice !== 0 || data.cdPrice !== "") &&
      (data.csPrice !== 0 || data.csPrice !== "")
    ) {
      const dateArray = getDates(data.startDate, data.endDate);
      // console.log("data", data);
      setValidate(false);

      await dateArray.map((item) => {
        var req = {
          date: item,
          isPurged: false,
          bookedCD: [],
          bookedCS: [],
          odt: {
            price: data.odtPrice,
            noOfBooking: data.odtBookings,
            bookingToday: 0,
            extraBooking: 0,
          },
          cd: {
            price: data.cdPrice,
            noOfBooking: data.cdBookings,
            bookingToday: 0,
            extraBooking: 0,
          },
          cs: {
            price: data.csPrice,
            noOfBooking: data.csBookings,
            bookingToday: 0,
            extraBooking: 0,
          },
        };
        return addData(req);
      });
      setLoading(false);
      setRefreshAdd(!refreshAdd);
      toast.success(`Created business dates`);
      // toast.success(`Created ${count} business dates`);
      navigate("/business-days");
      // setCount(0);
    } else {
      setValidate(true);
    }
  };
  ///add

  //get
  const getEmpList = async () => {
    try {
      const res = await WORKING_HOURS.GET_ALL();
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      // console.log("res", res.data);
      setEmp(res.data);
      // console.log("getall", res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  const getSearchEmpList = async (date) => {
    try {
      const res = await WORKING_HOURS.GET_BY_DATE(date);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      setEmp(res.data);
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //get

  //useEffect
  useEffect(() => {
    if (reRunData !== null) deleteData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDelete]);
  useEffect(() => {
    if (reRunData !== null) updateData(reRunData, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUpdate]);
  useEffect(() => {
    if (reRunData !== null) addData(reRunData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshAdd]);
  useEffect(() => {
    if (searchVal === "") {
      getEmpList();
    } else getSearchEmpList(searchVal);
  }, [refreshAdd, refreshDelete, refreshUpdate, searchVal]);

  const fetchDeluxeChaletLength = async () => {
    try {
      const res = await DC.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        dlx: array,
      });
      selectVal.dlx = array;
      setLength({
        ...length,
        dlx: res.data.length,
      });
      setData({
        ...data,
        cdBookings: res.data.length,
      });
      length.dlx = res.data.length;
      data.cdBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  const fetchStandardChaletLength = async () => {
    try {
      const res = await CS.GET_ALL();
      var array = [];
      res.data.map((itm) => {
        var req = { value: itm.title, label: itm.title };
        array.push(req);
        return array;
      });
      setSelectVal({
        ...selectVal,
        std: array,
      });
      selectVal.std = array;
      setLength({
        ...length,
        std: res.data.length,
      });
      setData({
        ...data,
        csBookings: res.data.length,
      });
      length.std = res.data.length;
      data.csBookings = res.data.length;
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  useEffect(() => {
    fetchDeluxeChaletLength();
    fetchStandardChaletLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //useEffect

  const [selectVal, setSelectVal] = useState({
    dlxIni: [],
    stdIni: [],
    dlx: [],
    std: [],
  });

  const handleCSBlock = (e) => {
    var array = [];
    e.map((itm) => {
      array.push(itm.value);
      return array;
    });
    setSelectVal({
      ...selectVal,
      stdIni: e,
    });
    selectVal.stdIni = e;
    setData({
      ...data,
      bookedCS: array,
    });
  };
  const handleCDBlock = (e) => {
    var array = [];
    e.map((itm) => {
      array.push(itm.value);
      return array;
    });
    setSelectVal({
      ...selectVal,
      dlxIni: e,
    });
    selectVal.dlxIni = e;
    setData({
      ...data,
      bookedCD: array,
    });
  };
  // console.log("selectVal", selectVal);
  return (
    <div>
      <div ref={topSessionRef}>
        <Breadcrumbs pageName="Manage Free Ticket" />
      </div>
      {!isEdit && (
        <div className="rounded-sm mb-5 border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="flex flex-col">
                <div className="px-2 py-2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Start Date <span className="text-meta-1">*</span>
                  </label>
                  <input
                    onChange={(e) => {
                      setData({
                        ...data,
                        startDate: e.target.value,
                      });
                    }}
                    type="date"
                    placeholder="Enter name of event"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="date"
                    value={data.startDate}
                  />
                  {data.startDate === "" && validate && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    End Date <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="date"
                    onChange={(e) => {
                      setData({
                        ...data,
                        endDate: e.target.value,
                      });
                    }}
                    placeholder="Enter price for this event"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="price"
                    value={data.endDate}
                  />
                  {data.endDate === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted One day Tickets
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        odtBookings: e.target.value,
                      });
                    }}
                    placeholder="Enter number of bookings per day"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="odtNoOfBooking"
                    value={data.odtBookings}
                  />
                  {(data.odtBookings === 0 || data.odtBookings === "") &&
                    validate === true && (
                      <p className="text-red text-sm mt-1">* Required</p>
                    )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted Deluxe Chalet
                    <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.cdBookings}
                  </p>
                 
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted Standard Chalet
                    <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.csBookings}
                  </p>
                 
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    One Day Ticket Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        odtPrice: e.target.value,
                      });
                    }}
                    placeholder="Enter number of bookings per day"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="oneDayTicketPrice"
                    value={data.odtPrice}
                  />
                  {(data.odtPrice === 0 || data.odtPrice === "") &&
                    validate === true && (
                      <p className="text-red text-sm mt-1">* Required</p>
                    )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Chalet Deluxe Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        cdPrice: e.target.value,
                      });
                    }}
                    placeholder="Enter number of bookings per day"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="chaletDeluxePrice"
                    value={data.cdPrice}
                  />
                  {(data.cdPrice === 0 || data.cdPrice === "") &&
                    validate === true && (
                      <p className="text-red text-sm mt-1">* Required</p>
                    )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Chalet Standard Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        csPrice: e.target.value,
                      });
                    }}
                    placeholder="Enter number of bookings per day"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="chaletStandardPrice"
                    value={data.csPrice}
                  />
                  {(data.csPrice === 0 || data.csPrice === "") &&
                    validate === true && (
                      <p className="text-red text-sm mt-1">* Required</p>
                    )}
                </div>
              </div>
              <div className="flex flex-col h-auto sm:pt-7">
                <div className="px-2 py-3 h-auto">
                  <button
                    onClick={() => {
                      handleAdd();
                    }}
                    type="submit"
                    className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                  >
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "Add Data"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEdit && (
        <div className="rounded-sm border border-stroke bg-white px-5 py-4 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="flex flex-col">
                <div className="px-2 py-2">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Date <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {formatDateString(data.date)}
                  </p>
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Block Chalet Deluxe
                    <span className="text-meta-1">*</span>
                  </label>
                  <Select
                    isMulti
                    value={selectVal.dlxIni}
                    options={selectVal.dlx}
                    onChange={(e) => {
                      handleCDBlock(e);
                    }}
                  />
                  {data.cdPrice === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Block Chalet Standard
                    <span className="text-meta-1">*</span>
                  </label>
                  <Select
                    isMulti
                    value={selectVal.stdIni}
                    options={selectVal.std}
                    onChange={(e) => {
                      handleCSBlock(e);
                    }}
                  />
                  {data.csPrice === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted One day tickets - (
                    {data.odtBookings - data.odtToday >= 0
                      ? data.odtBookings - data.odtToday
                      : 0}
                    &nbsp;left)
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        odtBookings: e.target.value,
                      });
                    }}
                    placeholder="No of bookings accepted today"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="odtNoOfBooking"
                    value={data.odtBookings}
                  />
                  {data.odtBookings === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted Deluxe Chalet - (
                    {data.cdBookings - data.cdToday >= 0
                      ? data.cdBookings - data.cdToday
                      : 0}
                    &nbsp;left)
                    <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.cdBookings}
                  </p>
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Accepted Standard Clalet - (
                    {data.csBookings - data.csToday >= 0
                      ? data.csBookings - data.csToday
                      : 0}
                    &nbsp;left)
                    <span className="text-meta-1">*</span>
                  </label>
                  <p className="w-full rounded border-[1.5px] border-stroke bg-whiter py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                    {data.csBookings}
                  </p>
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    One Day Ticket Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        odtPrice: e.target.value,
                      });
                    }}
                    placeholder="No of bookings accepted today"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="odtPrice"
                    value={data.odtPrice}
                  />
                  {data.odtPrice === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Chalet Deluxe Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        cdPrice: e.target.value,
                      });
                    }}
                    placeholder="No of bookings accepted today"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="cdPrice"
                    value={data.cdPrice}
                  />
                  {data.cdPrice === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col h-auto">
                <div className="px-2 py-2 h-auto">
                  <label className="mb-2.5 block text-black dark:text-white">
                    Chalet Standard Price
                    <span className="text-meta-1">*</span>
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setData({
                        ...data,
                        csPrice: e.target.value,
                      });
                    }}
                    placeholder="No of bookings accepted today"
                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                    name="csPrice"
                    value={data.csPrice}
                  />
                  {data.csPrice === "" && validate === true && (
                    <p className="text-red text-sm mt-1">* Required</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col h-auto sm:pt-7">
                <div className="px-2 py-3 h-auto">
                  <button
                    onClick={() => {
                      handleUpdate();
                    }}
                    type="submit"
                    className="border-[1.5px] border-primary flex w-full justify-center rounded bg-primary p-3 font-medium text-gray"
                  >
                    {loading ? (
                      <div className="flex h-auto items-center justify-center ">
                        <div className="h-6 w-6 animate-spin rounded-full border-4 border-solid border-white border-t-transparent"></div>
                      </div>
                    ) : (
                      "Update Data"
                    )}
                  </button>
                  <button
                    onClick={() => {
                      backToAdd();
                    }}
                    type="button"
                    className="flex w-full justify-center py-3 font-sm text-sm text-red"
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div ref={tableSessionRef}>
        <div className="rounded-sm mt-5 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="max-w-full overflow-x-auto">
            <input
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              value={searchVal}
              type="text"
              className="w-full mb-4 rounded-xl border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
              placeholder="Search by booking date..."
            />

            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-2 text-left dark:bg-meta-4">
                  <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                    Date
                  </th>
                  {/* <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    Bookings Accepted
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    Bookings Today
                  </th> */}
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    One Day Ticket Price
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    Chalet Deluxe Price
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    Chalet Standard Price
                  </th>
                  <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems !== undefined && (
                  <>
                    {currentItems.map((data) => (
                      <tr key={data._id}>
                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {formatDateString(data.date)}
                          </h5>
                        </td>
                        {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.noOfBooking}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.bookingToday}
                          </h5>
                        </td> */}
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.odt.price}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.cd.price}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <h5 className="font-medium text-black dark:text-white">
                            {data.cs.price}
                          </h5>
                        </td>

                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <div className="flex items-center space-x-3.5">
                            <button
                              className="hover:text-primary"
                              onClick={() => handleEdit(data)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  fill=""
                                  d="M13.6568542,2.34314575 C14.4379028,3.12419433 14.4379028,4.39052429 13.6568542,5.17157288 L6.27039414,12.558033 C5.94999708,12.87843 5.54854738,13.105727 5.10896625,13.2156223 L2.81796695,13.7883721 C2.45177672,13.8799197 2.12008033,13.5482233 2.21162789,13.182033 L2.78437771,10.8910338 C2.894273,10.4514526 3.12156995,10.0500029 3.44196701,9.72960586 L10.8284271,2.34314575 C11.6094757,1.56209717 12.8758057,1.56209717 13.6568542,2.34314575 Z M10.1212441,4.46435931 L4.14907379,10.4367126 C3.95683556,10.6289509 3.82045738,10.8698207 3.75452021,11.1335694 L3.38388341,12.6161166 L4.86643062,12.2454798 C5.1301793,12.1795426 5.37104912,12.0431644 5.56328736,11.8509262 L11.5352441,5.87835931 L10.1212441,4.46435931 Z M11.5355339,3.05025253 L10.8282441,3.75735931 L12.2422441,5.17135931 L12.9497475,4.46446609 C13.3402718,4.0739418 13.3402718,3.44077682 12.9497475,3.05025253 C12.5592232,2.65972824 11.9260582,2.65972824 11.5355339,3.05025253 Z"
                                ></path>
                              </svg>
                            </button>
                            <button
                              className="hover:text-primary"
                              onClick={() => handleDelete(data._id)}
                            >
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                  fill=""
                                />
                                <path
                                  d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                  fill=""
                                />
                                <path
                                  d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                  fill=""
                                />
                                <path
                                  d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <PaginatedItems
            itemsPerPage={itemsPerPage}
            array={emp}
            setItemOffset={setItemOffset}
            scrollToSection={scrollToTableSection}
            pageCount={pageCount}
          />
        </div>
      </div>
    </div>
  );
}
