export const formatNumb = (num) => {
    // Convert to a number to ensure proper formatting
    const number = parseFloat(num);
    
    // Check if the number has a decimal part
    if (Number.isInteger(number)) {
      // If it's an integer, format it to two decimal places
      return number.toFixed(2);
    } else {
      // If it has a decimal part, return as is
      return num.toString();
    }
  };