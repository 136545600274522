export
    const TentIcon = () => {
        return (
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 119.63"
                fill="white"
                className="w-[18px]"
            >
                <g>
                    <path d="M44.27,115.28H78.5L60.7,77.5L44.27,115.28L44.27,115.28z M59.51,1.93c0-1.06,0.86-1.93,1.93-1.93 c1.06,0,1.93,0.86,1.93,1.93v18.08l3.75,6.94l9.72-16.83c0.53-0.92,1.7-1.24,2.62-0.71c0.92,0.53,1.24,1.7,0.71,2.62l-10.9,18.89 l45.78,84.86h5.92c1.06,0,1.93,0.86,1.93,1.93c0,1.06-0.86,1.93-1.93,1.93H1.93c-1.06,0-1.93-0.86-1.93-1.93 c0-1.06,0.86-1.93,1.93-1.93h5.92l45.71-84.99L42.72,12.03c-0.53-0.92-0.21-2.09,0.71-2.62s2.09-0.21,2.62,0.71l9.64,16.69 l3.83-7.12V1.93L59.51,1.93z" />
                </g>
            </svg>
        );
    };