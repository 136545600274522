import SEND from "../../../assets/send.png";
import NAT from "../../../assets/nature.png";
import HOUSE from "../../../assets/tent.png";
import DWLD from "../../../assets/dwnld.png";
import TENT from "../../../assets/house.png";
import { BOOKING } from "../../../api/booking";
import CHALET from "../../../assets/chalet.png";
import PARK from "../../../assets/parkticket.png";
import React, { useEffect, useRef, useState } from "react";
import STANDARD from "../../../assets/standard.png";
import { CONSTANT } from "../../../constant/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { numberToWords } from "../../../utils/NumberToWords";
import { formatDateString } from "../../../utils/FormatDateString";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import usePreventBackNavigation from "../../../utils/usePreventBackNavigation";
export default function Ticket() {
  usePreventBackNavigation("/book-now");
  let { date, pnr } = useParams();
  //constant variables
  const navigate = useNavigate();
  const CD_DATA = {
    img: CHALET,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Deluxe Chalet and One Park Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: HOUSE,
  };
  const CS_DATA = {
    img: STANDARD,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Standard Chalet and One Park Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: TENT,
  };
  const OD_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "One Day Ticket",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  const FREE_CD_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Additional 1 Park Ticket with Deluxe Chalet ",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  const FREE_CS_DATA = {
    img: PARK,
    topTxt: "Aqua Magic Water Park",
    titleTxt: "Additional 1 Park Ticket with Standard Chalet ",
    subTxt: "All-day access to unlimited fun",
    icon: NAT,
  };
  //constant variables
  //useState
  const [id, setId] = useState(0);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [price, setPrice] = useState({});
  const [type, setType] = useState("CD");
  const [array, setArray] = useState({
    odt: [],
    cd: [],
    cs: [],
    freeCd: [],
    freeCs: [],
  });
  //useState
  //dependant variables
  const shareUrl = (ticket) => {
    return `${CONSTANT.BASE_URL}ticket/${ticket}`;
    // return `${CONSTANT.BASE_URL}booking-details/date/2024-05-23/pnr/6681491318/identity/${id}`;
  };
  const downloadImage = (data) => {
    // Replace 'image_url.jpg' with the URL of the image you want to download
    const imageUrl = `${CONSTANT.BASE_URL}ticket/${data.ticket}`;
    window.open(imageUrl, "_blank");
  };
  const filterCdFreeArrayByChalet = (chaletNo) => {
    var rtnArray = [];
    array.freeCd.map((itm) => {
      if (itm.chaletNo === chaletNo) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };
  const filterCsFreeArrayByChalet = (chaletNo) => {
    var rtnArray = [];
    array.freeCs.map((itm) => {
      if (itm.chaletNo === chaletNo) {
        rtnArray.push(itm);
      }
    });
    // console.log("freeCd", freeCd)
    return rtnArray;
  };

  //dependant variables
  //function
  const getBookingList = async (date, pnr) => {
    const odtArray = [];
    const cdArray = [];
    const csArray = [];
    const freeCdArray = [];
    const freeCsArray = [];
    try {
      const res = await BOOKING.GET_BY_PNR(date, pnr);
      if (res.status !== 200) {
        throw new Error("Failed to fetch admin information");
      }
      console.log("res.data",res.data)
      setData(res.data);
      setPrice(res.data[0].groupSize);
      res.data.map((itm) => {
        if (itm.type === 0) {
          odtArray.push(itm);
        } else if (itm.type === 1) {
          cdArray.push(itm);
        } else if (itm.type === 2) {
          csArray.push(itm);
        } else if (itm.type === 3) {
          freeCdArray.push(itm);
        } else if (itm.type === 4) {
          freeCsArray.push(itm);
        }
        setArray({
          ...array,
          odt: odtArray,
          cd: cdArray,
          cs: csArray,
          freeCd: freeCdArray,
          freeCs: freeCsArray,
        });
        array.odt = odtArray;
        array.cd = cdArray;
        array.cs = csArray;
        array.freeCd = freeCdArray;
        array.freeCs = freeCsArray;
        return data;
      });
    } catch (error) {
      // console.log("Error loading topics: ", error);
    }
  };
  //function

  const divRef = useRef(null);
  const buttonRef = useRef(null);
  //useEffect
  useEffect(() => {
    if (date && pnr) {
      getBookingList(date, pnr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, pnr]);
  useEffect(() => {
    // Function to handle back button click
    const handlePopState = () => {
      navigate("/book-now", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handleClickOutside = (event) => {
    if (
      divRef.current && !divRef.current.contains(event.target) &&
      buttonRef.current && !buttonRef.current.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show]);
  //useEffect

  return (
    <div className="h-auto w-auto flex justify-center py-25 bg-bgbg ">
      <div className=" w-[90%] h-auto max-w-screen-2xl ">
        <div className="w-auto h-auto py-5">
          <p className=" font-mont font-light text-3xl  w-self">My Ticket</p>
        </div>
        <div className=" w-auto h-auto  grid grid-cols-1 lg:grid-cols-4 gap-4 mb-2 ">
          <div className="col-span-3 w-full flex flex-col md:flex-row justify-between gap-5 ">
            <div className="w-full">
              <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                Name
              </p>
              <p className="font-semibold font-mont text-xl leading-3">
                {data.length > 0 ? data[0].name : "...."}
              </p>
            </div>
            <div className="w-full  ">
              <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                Email
              </p>
              <p className="font-semibold font-mont text-xl leading-3">
                {data.length > 0 ? data[0].email : "...."}
              </p>
            </div>
            <div className="w-full">
              <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                Phone Number
              </p>
              <p className="font-semibold font-mont text-xl leading-3">
                {data.length > 0 ? data[0].mobNo : "...."}
              </p>
            </div>
            <div className="w-full">
              <p className="mb-2.5 block dark:text-white text-[14px] font-poppins text-[#959595] ">
                Booking Date
              </p>
              <p className="font-semibold font-mont text-xl leading-3">
                {data.length > 0 ? formatDateString(data[0].date) : "...."}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-5 ">
          <div className="col-span-2 w-full">
            {array.cd.length > 0 && (
              <div className="w-full mt-15 grid ">
                <p className="font-poppins font-bold font-lg mb-3 ">
                  Deluxe Chalet
                </p>
                <div className="w-full  h-auto  rounded-md  ">
                  {array.cd.map((itm, index) => (
                    <div className="bg-white rounded-lg">
                      <TicketCard
                        itm={itm}
                        index={index}
                        shareUrl={shareUrl}
                        id={id}
                        setId={setId}
                        downloadImage={downloadImage}
                        setShow={setShow}
                        ticketType="CD"
                        show={show}
                        type={type}
                        setType={setType}
                        data={CD_DATA}
                        buttonRef={buttonRef}
                        divRef={divRef}
                      />

                      {filterCdFreeArrayByChalet(itm.chaletNo).map((val) => (
                        <TicketCard
                          itm={val}
                          index={index}
                          shareUrl={shareUrl}
                          id={id}
                          setId={setId}
                          downloadImage={downloadImage}
                          setShow={setShow}
                          ticketType="FD"
                          show={show}
                          type={type}
                          setType={setType}
                          data={FREE_CD_DATA}
                          buttonRef={buttonRef}
                          divRef={divRef}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {array.cs.length > 0 && (
              <div className="w-full mt-10 grid  ">
                <p className="font-poppins font-bold font-lg mb-3">
                  Standard Chalet
                </p>
                {array.cs.map((itm, index) => (
                  <div className="bg-white rounded-lg">
                    <TicketCard
                      itm={itm}
                      index={index}
                      shareUrl={shareUrl}
                      id={id}
                      setId={setId}
                      downloadImage={downloadImage}
                      setShow={setShow}
                      ticketType="CS"
                      show={show}
                      type={type}
                      setType={setType}
                      data={CS_DATA} buttonRef={buttonRef}
                      divRef={divRef}
                    />
                    {filterCsFreeArrayByChalet(itm.chaletNo).map((val) => (
                      <TicketCard
                        itm={val}
                        index={index}
                        shareUrl={shareUrl}
                        id={id}
                        setId={setId}
                        downloadImage={downloadImage}
                        setShow={setShow}
                        ticketType="FS"
                        show={show}
                        type={type}
                        setType={setType}
                        data={FREE_CS_DATA}
                        buttonRef={buttonRef}
                        divRef={divRef}
                      />
                    ))}
                  </div>
                ))}
              </div>
            )}
            {array.odt.length > 0 && (
              <div className="w-full mt-15 grid">
                <p className="font-poppins font-bold font-lg mb-3">
                  Park Tickets
                </p>
                {array.odt.map((itm, index) => (
                  <TicketCard
                    itm={itm}
                    index={index}
                    shareUrl={shareUrl}
                    id={id}
                    setId={setId}
                    downloadImage={downloadImage}
                    setShow={setShow}
                    ticketType="ODT"
                    show={show}
                    type={type}
                    setType={setType}
                    data={OD_DATA}
                    buttonRef={buttonRef}
                    divRef={divRef}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-1 w-full   mt-24">
            <div className="flex justify-center items-start lg:col-span-1 ">
              <div className=" w-full h-auto p-5 bg-white rounded-xl">
                <div className="w-full h-auto">
                  <p className="text-poppins text-primary text-2xl xl:text-3xl font-semibold">
                    Purchase summary
                  </p>
                </div>
                <div className="w-full flex justify-between mt-2 lg:mt-5">
                  <div className="w-[70%]">
                    <p className="text-sm xl:text-lg font-">
                      Park Ticket [{price.odtNo}×{price.odtPrice}]
                    </p>
                  </div>
                  <div className="w-[30%]">
                    <p className="text-sm xl:text-lg font-semibold">
                      : KWD {price.odtNo * price.odtPrice}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between   mt-2  lg:mt-5">
                  <div className="w-[70%]">
                    <p className="text-sm xl:text-lg leading-6">
                      Chalet Deluxe [{price.cdNo}×{price.cdPrice}]<br></br>
                      {price.cdFree > 0 && (
                        <span className="text-[10px] xl:text-sm text-mont font-thin">
                          {/* One free park ticket per chalet You have earned{" "} */}
                          You have earned {numberToWords(price.cdFree)} free
                          Park Tickets
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="w-[30%]">
                    <p className="text-sm xl:text-lg font-semibold">
                      : KWD {price.cdNo * price.cdPrice}
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-between mt-2 lg:mt-5">
                  <div className="w-[70%]">
                    <p className="text-sm xl:text-lg leading-6">
                      Chalet Standard [{price.csNo}×{price.csPrice}]<br></br>
                      {price.scFree > 0 && (
                        <span className="text-[10px] xl:text-sm text-mont font-thin">
                          {/* One free park ticket per chalet */}
                          You have earned {numberToWords(price.scFree)} free
                          Park Tickets
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="w-[30%]">
                    <p className="text-sm xl:text-lg font-semibold">
                      : KWD {price.csNo * price.csPrice}
                    </p>
                  </div>
                </div>
                <hr className="border-t border-2 border-dashed mt-3 w-full "></hr>
                <div className="w-full flex justify-between  mt-8">
                  <div className="w-[70%]">
                    <p className="text-sm xl:text-lg font-poppins font-bold">
                      TOTAL
                    </p>
                  </div>
                  <div className="w-[35%]">
                    <p className="text-sm xl:text-lg font-poppins font-bold">
                      : KWD {price.totalPrice}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TicketCard = ({
  itm,
  index,
  shareUrl,
  id,
  setId,
  downloadImage,
  setShow,
  ticketType,
  show,
  type,
  setType,
  data,
  buttonRef,
  divRef
}) => {
  return (
    <div className="w-full bg-white flex flex-col md:flex-row justify-between rounded-lg p-2  mb-5">
      <div className="w-full h-[200px] md:h-auto md:w-[30%] rounded-md flex justify-center items-center ">
        <img
          src={data.img}
          alt="images"
          className=" object-cover  rounded-md w-[95%] h-[95%]"
        />
      </div>
      <div className="w-full md:w-[43%]  ">
        <div className="w-full h-auto flex justify-between py-3">
          <div className=" 2xl:w-[65%] lg:w-[70%]  md:w-[75%] h-auto px-2 ">
            <p className="font-poppins text-[#0E6097] font-thin text-sm leading-5  md:text-xs sm:text-[11px]">
              {data.topTxt} <br></br>
              <span className="font-bold font-mont 2xl:text-xl lg:text-lg md:text-[20px] sm:text-[16px]">
                {" "}
                {data.titleTxt}{" "}
                {(itm.type === 3 || itm.type === 4) && itm.chaletNo}
              </span>
            </p>
            <p className="text-sm font-mont font-thin text-[#9C9C9C] md:text-xs sm:text-[11px] ">
              {data.subTxt}
            </p>
          </div>
          <div className="sm:w-[25%] w-[35%] lg:w-[30%] md:w-[25%] flex  lg:justify-center md:justify-center items-center">
            <img
              src={data.icon}
              alt="images"
              style={{ width: "60%", height: "80%" }}
              className=" object-contain"
            />
          </div>
        </div>
        <div className="mb-6 w-[90%] h-auto  rounded px-2">
          {ticketType === "ODT" ? (
            <div className="w-full flex  justify-between   mt-2">
              <div className="w-[60%]">
                <p className="2xl:text-base font-mont lg:text-sm md:text-[14px] text-[12px]">
                  No. of Guests{" "}
                </p>
              </div>
              <div className="w-[40%] lg:w-[60%]  md:w-[62%]">
                <p className="text-base font-semibold font-poppins lg:text-sm md:text-[14px] text-[12px]">
                  : 01
                </p>
              </div>
            </div>
          ) : (
            <div className="w-full flex  justify-between   mt-2">
              <div className="w-[60%]">
                <p className="2xl:text-base font-mont lg:text-sm md:text-[14px] text-[12px]">
                  Chalet Number{" "}
                </p>
              </div>
              <div className="w-[40%] lg:w-[60%]  md:w-[62%]]">
                <p className="2xl:text-base font-mont lg:text-sm md:text-[14px] text-[12px]">
                  : {itm.chaletNo}
                </p>
              </div>
            </div>
          )}
          <div className="w-full flex justify-between   mt-2">
            <div className="w-[60%]">
              <p className="text-base font-mont lg:text-sm md:text-[14px] text-[12px] ">
                Booking Date
              </p>
            </div>
            <div className="w-[40%] lg:w-[60%]  md:w-[62%]]">
              <p className="text-base font-semibold font-poppins lg:text-sm md:text-[14px] text-[12px]">
                : {formatDateString(itm.date)}
              </p>
            </div>
          </div>
          <div className="w-full flex justify-between  mt-2">
            <div className="w-[60%]">
              <p className="text-base font-mont lg:text-sm md:text-[14px] text-[12px]">
                Payment Method
              </p>
            </div>
            <div className="w-[40%] lg:w-[60%]  md:w-[62%]">
              <p className="text-base font-semibold font-poppins lg:text-sm md:text-[14px] text-[12px]">
                : {itm.payment.paymentMethod}
              </p>
            </div>
          </div>
          <div className="w-full flex justify-between  mt-2">
            <div className="w-[62%]">
              <p className="text-base font-mont lg:text-sm md:text-[14px] text-[12px]">
                Confirmation ID
              </p>
            </div>
            <div className="w-[40%] lg:w-[62%] md:w-[62%] ">
              <p className="text-base font-mont lg:text-[12px] md:text-[12px] text-[12px] font-semibold">
                : {itm.pnr}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden w-[4%]  md:relative">
        <div className="border-l-2 border-dashed h-full ml-4"></div>
        <div className="bg-bgbg rounded-full w-8 h-8 absolute top-[-8%] "></div>
        <div className="bg-bgbg rounded-full w-8 h-8 absolute bottom-[-8%]"></div>
      </div>
      <div className="w-full h-[20px] relative md:hidden flex items-center">
        <div className="border-t-2 border-dashed w-full"></div>
        <div className="bg-bgbg rounded-full w-8 h-8 absolute right-[-25px] "></div>
        <div className="bg-bgbg rounded-full w-8 h-8 absolute left-[-25px]"></div>
      </div>
      <div className="w-full md:w-[23%] flex justify-center items-center h-auto rounded-md">
        <div className=" w-[100%] flex flex-col justify-center items-center">
          <img
            className="w-[30%] md:w-[90%] h-[30%] md:h-[90%] m-[5%] rounded-md"
            src={`${CONSTANT.BASE_URL}qr/${itm.qr}`}
            alt=""
          />
          <div className="w-full col-span-3 sm:col-span-1 relative flex justify-center items-center h-10 py-5">
            <div className="w-full flex  justify-center items-center">
              <button onClick={() => downloadImage(itm)} className="mr-5 ">
                <img src={DWLD} alt="" className="w-6 mr-5" />
              </button>
              <button
                ref={buttonRef}
                onClick={() => {
                  setId(index);
                  if (!show) {
                    setShow(true);
                  }
                  else {
                    setShow(false)
                  }
                  // setShow(!show);
                  setType(ticketType);
                }}
                className=""
              >
                <img src={SEND} alt="" className="w-6 ml-5" />
              </button>
            </div>

            {/* {id === index && type === ticketType && ( */}
            {show && id === index && type === ticketType && (
              <div className="absolute flex bottom-[-35%]  sm:bottom-[-40%] right-0 z-99" ref={divRef}>
                <FacebookShareButton
                  url={shareUrl(itm.ticket)}
                  className="mx-1"
                >
                  <FacebookIcon size={25} round={true} />
                </FacebookShareButton>
                <WhatsappShareButton
                  url={shareUrl(itm.ticket)}
                  className="mx-1"
                >
                  <WhatsappIcon size={25} round={true} />
                </WhatsappShareButton>
                <EmailShareButton url={shareUrl(itm.ticket)} className="mx-1">
                  <EmailIcon size={25} round={true} />
                </EmailShareButton>
                <TelegramShareButton
                  url={shareUrl(itm.ticket)}
                  className="mx-1"
                >
                  <TelegramIcon size={25} round={true} />
                </TelegramShareButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
