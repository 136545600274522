import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServerTimeout() {
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate("/admin-login");
  };

  return (
    <div className="flex justify-center items-center w-full h-screen ">
      <div className="flex-1 flex flex-col justify-center rounded-lg shadow-2xl overflow-hidden max-w-sm lg:max-w-2xl lg:h-1/2">
        <div className="h-1/2">
          <img
            src="https://img.freepik.com/free-vector/504-error-gateway-timeout-concept-illustration_114360-1887.jpg"
            className="w-full h-full object-contain"
            alt="logo"
          />
        </div>
        <div className="flex justify-center">
          <p className="text-3xl font-bold text-red">Session Expired!!!</p>
        </div>
        <div className="flex justify-center py-5">
          <div className="">
            <h3>Do you wish to continue?</h3>
            <button
              className="px-10 justify-center rounded bg-primary p-3 font-medium text-gray mt-5 mx-7"
              onClick={handleClickLogin}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
